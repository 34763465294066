const UPDATE_SUBMISSION_FUNDER = (state, payload) => {
  const submissionItem = state.submissionItem;
  let submissionFunder = submissionItem.funders.find(
    (funder) => funder.gid === payload.id
  );

  if (!submissionFunder && payload.index) {
    submissionFunder = submissionItem.funders[payload.index];
  }

  if (submissionFunder) {
    submissionFunder[payload.field] = payload.value;
  }
};

export default UPDATE_SUBMISSION_FUNDER;
