<template>
  <div class="authorized-actions-institution">
    <v-row justify="end" align="center">
      <v-btn
        class="authorized-actions-institution__submit-button mr-2"
        elevation="0"
        color="primary"
        outlined
      >
        <span>Submit your content</span>
      </v-btn>
      <div v-if="isLoggedOut(user)" class="ml-2">
        <v-btn
          class="authorized-actions-institution__login-button white--text"
          elevation="0"
          color="primary"
        >
          <span>Log in</span>
        </v-btn>
        <div class="authorized-actions-institution__anonymous-menu">
          <AnonymousMenu :partner="partner" />
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AnonymousMenu from "~/components/page/components/menus/AnonymousMenu.vue";

import isLoggedOut from "~/plugins/utilities/isLoggedOut";

export default {
  name: "AuthorizedActionsInstitution",
  components: { AnonymousMenu },
  computed: {
    ...mapState({
      user: (state) => state.user,
      theme: (state) => state.theme,
    }),

    partner() {
      return this.$config.partner;
    },
  },
  methods: {
    isLoggedOut(value) {
      return isLoggedOut(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.authorized-actions-institution {
  .v-btn {
    font-weight: 400 !important;
  }
  .v-btn--outlined {
    border-width: 1px !important;
    border-color: #8d8d8d !important;
  }
  &__login-button {
    display: block;
  }
  &__anonymous-menu {
    display: none;
  }
}

@media only screen and (max-width: 960px) {
  .authorized-actions-institution {
    &__submit-button {
      display: none;
    }
    &__login-button {
      display: none;
    }
    &__anonymous-menu {
      display: block;
    }
  }
}
</style>
