import concatenateValues from "~/plugins/utilities/concatenateValues";
import sorted from "~/plugins/utilities/sorted";

const SET_ADD_VOR_DETAILS = (state, payload) => {
  const authors = concatenateValues(
    sorted(payload.selectedItem.authors, "displayOrder"),
    ["firstName", "lastName"]
  );

  state.selectedItem = {
    ...payload.selectedItem,
    proposedVorDoi: undefined,
    authors,
  };
  state.user = payload.user;
};

export default SET_ADD_VOR_DETAILS;
