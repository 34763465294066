import retractStepPageLoad from "~/store/graphql/queries/retractStepPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadRetractProcess = async function ({ commit }, { req, res, params }) {
  commit("CLEAR_RETRACTION");
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    retractStepPageLoad,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_RETRACTION_WORKFLOW", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadRetractProcess;
