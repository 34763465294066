import curators from "~/store/graphql/queries/curators.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

export default async function loadCurators({ commit }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(curators, null, this.$axios);

  commit("SET_CURATORS", data);
  commit("END_LOADING");

  return Promise.resolve(data);
}
