import newSiteModerators from "~/store/graphql/mutations/newSiteModerators.gql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

export default async function addSiteModerators({ commit }, { params }) {
  const data = await runGraphQLQuery(newSiteModerators, params, this.$axios);
  commit("SET_SITE_MODERATOR_WORKFLOW");

  return Promise.resolve(data.addSiteModerators);
}
