import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";
import { hasCurationPrivilege } from "~/plugins/utilities/checkUserRoles";

const submitForRequestRetraction = async function submitForRequestRetraction() {
  const { user, $config } = this;
  const { partner } = $config;

  const path = hasCurationPrivilege(user)
    ? `/engage/${partner}/administration-dashboard`
    : `/engage/${partner}/author-dashboard`;

  this.showErrors = true;

  await this.$store.dispatch("validateRetraction");

  if (this.pageErrors && this.pageErrors.length > 0) {
    return;
  }

  try {
    await this.$store.dispatch("submitRequestRetraction");
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }

  this.$router.push({ path });
};

export default submitForRequestRetraction;
