<template>
  <div class="StatusListener" />
</template>

<script>
import { mapState } from "vuex";

import submitForSubmitArticle from "~/actions/tasks/submitForSubmitArticle";
import approveForSubmitArticle from "~/actions/tasks/approveForSubmitArticle";
import reviseForSubmitArticle from "~/actions/tasks/reviseForSubmitArticle";
import removeForLoadedArticle from "~/actions/tasks/removeForLoadedArticle";
import removeForRejectedArticle from "~/actions/tasks/removeForRejectedArticle";
import submitForRequestRetraction from "~/actions/tasks/submitForRequestRetraction";
import submitForRespondRetraction from "~/actions/tasks/submitForRespondRetraction";
import rejectForRespondRetraction from "~/actions/tasks/rejectForRespondRetraction";
import newForSubmitArticle from "~/actions/tasks/newForSubmitArticle";

import publishForEvent from "~/actions/tasks/publishForEvent";

export default {
  name: "StatusListener",
  data() {
    return {
      item: undefined,
      status: undefined,
    };
  },
  computed: {
    ...mapState({
      SUBMISSION_SUCCESSFUL: (state) =>
        state.theme.information.SUBMISSION_SUCCESSFUL,
      itemRejectionReasons: (state) => state.referenceData.itemRejectionReasons,
    }),
  },
  mounted() {
    this.$root.$on("update-status", this.onUpdateStatus);
  },
  methods: {
    onUpdateStatus({ item, event, status, currentTarget }) {
      if (item) {
        this.onUpdateItem({ item, status, currentTarget });
      } else if (event) {
        this.onUpdateEvent({ event, status, currentTarget });
      }
    },
    onUpdateEvent({ event, status, currentTarget }) {
      this.item = event;
      this.status = status;

      if (status === "PUBLISH") {
        this.$root.$emit("show-confirmation", {
          title: `Are you sure you want to publish "${this.item.title}" ?`,
          instruction: `Type the word PUBLISH to publish this event.`,
          code: "Publish",
          action: publishForEvent.bind(this),
          currentTarget,
        });
      }
    },
    onUpdateItem({ item, status, currentTarget }) {
      this.item = item;
      this.status = status;

      switch (status) {
        case "APPROVE":
          this.$root.$emit("show-confirmation", {
            title: `Are you sure you want to approve "${this.item.title}" ?`,
            confirmationButtonText: "Approve",
            action: approveForSubmitArticle.bind(this),
            currentTarget,
          });
          break;
        case "SUBMIT":
          this.$root.$emit("show-information", {
            title: this.SUBMISSION_SUCCESSFUL.title,
            message: this.SUBMISSION_SUCCESSFUL.message,
          });

          submitForSubmitArticle.call(this);
          break;
        case "REJECT":
          this.$root.$emit("show-rejection-options", {
            title: `Please select the main reason for rejecting this content:`,
            item: this.item,
            options: this.itemRejectionReasons,
            currentTarget,
          });
          break;
        case "REVISE":
          this.$root.$emit("show-verification", {
            title: `Are you sure you want to send "${this.item.title}" for revision?`,
            instruction:
              "This content will be sent back to the author with comments, please enter your comments below.",
            confirmationButtonText: "Send",
            item: this.item,
            action: reviseForSubmitArticle,
            currentTarget,
          });
          break;
        case "DELETE":
          this.$root.$emit("show-confirmation", {
            title: `Are you sure you want to remove "${this.item.title}" ?`,
            instruction: `Type the word REMOVE to delete this item from your dashboard.`,
            code: "Remove",
            action: removeForLoadedArticle.bind(this),
            currentTarget,
          });
          break;
        case "REMOVE":
          this.$root.$emit("show-confirmation", {
            title: `Are you sure you want to remove "${this.item.title}" ?`,
            instruction: `Type the word REMOVE to delete this item from your dashboard.`,
            code: "Remove",
            action: removeForRejectedArticle.bind(this),
            currentTarget,
          });
          break;
        case "NEW":
          this.$root.$emit("show-confirmation", {
            title: `Are you sure you want to submit a new version of "${this.item.title}" ?`,
            instruction: `Type the word NEW to submit a new version of this item.`,
            code: "New",
            action: newForSubmitArticle.bind(this),
            currentTarget,
          });
          break;
        case "RETRACT_REQUESTED":
          this.$root.$emit("show-confirmation", {
            title: `Are you sure you want to retract "${this.item.title}" ?`,
            instruction: `Then type the word RETRACT to request a retraction of this item.`,
            code: "Retract",
            action: submitForRequestRetraction.bind(this),
            currentTarget,
          });
          break;
        case "RETRACT":
          this.$root.$emit("show-confirmation", {
            title: `Are you sure you want to retract "${this.item.title}" ?`,
            instruction: `Then type the word APPROVE to retract this item.`,
            code: "Approve",
            action: submitForRespondRetraction.bind(this),
            currentTarget,
          });
          break;
        case "REFUSE":
          this.$root.$emit("show-confirmation", {
            title: `Are you sure you want to reject the retraction request for "${this.item.title}" ?`,
            instruction: `Type the word REJECT to complete this action.`,
            code: "Reject",
            action: rejectForRespondRetraction.bind(this),
            currentTarget,
          });
          break;
        case "BESPOKE_EMAIL_REJECTED":
          this.$root.$emit("show-bespoke-email", {
            title: this.item.title,
            item: this.item,
            emailType: "ITEM_REJECTED_AUTHOR",
            rejectionReason: this.item.rejectionReason,
            currentTarget,
          });
          break;
        case "BESPOKE_EMAIL_APPROVED":
          this.$root.$emit("show-bespoke-email", {
            title: this.item.title,
            item: this.item,
            emailType: "ITEM_APPROVED_AUTHOR",
            currentTarget,
          });
          break;
      }
    },
  },
};
</script>
