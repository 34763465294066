import { set } from "vue";

const ADD_SUBMISSION_WEBLINK = (state, payload) => {
  if (!state.submissionItem.webLinks) {
    set(state.submissionItem, "webLinks", []);
  }

  set(
    state.submissionItem.webLinks,
    state.submissionItem.webLinks.length,
    payload
  );
};

export default ADD_SUBMISSION_WEBLINK;
