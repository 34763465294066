import discussionReplyCreate from "~/store/graphql/mutations/discussionReplyCreate.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import communityDiscussionDetailPageLoad from "~/store/graphql/queries/communityDiscussionDetailPageLoad.graphql";

const createDiscussionReply = async function (
  { commit },
  discussionReplyInput
) {
  commit("START_LOADING");

  await runGraphQLQuery(
    discussionReplyCreate,
    { discussionReplyInput },
    this.$axios
  );

  const data = await runGraphQLQuery(
    communityDiscussionDetailPageLoad,
    { discussionId: discussionReplyInput.discussionId },
    this.$axios
  );

  commit("SELECT_DISCUSSION", data);

  commit("END_LOADING");
  return Promise.resolve(data);
};

export default createDiscussionReply;
