<template>
  <v-snackbar
    ref="AccessibleSnackbar"
    class="AccessibleSnackbar"
    :timeout="-1"
    :color="color"
    top
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="confirmationMessage" />
    <template #action="{ attrs }">
      <v-btn
        text
        small
        v-bind="attrs"
        aria-label="Close notification"
        @click="onClose"
      >
        <v-icon dark aria-hidden="true">{{ mdiClose }}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mdiClose } from "@mdi/js";
export default {
  name: "AccessibleSnackbar",
  data: () => {
    return {
      showSaved: false,
      confirmationMessage: "",
      container: "",
      color: "success",
      // icons
      mdiClose,
    };
  },
  mounted() {
    this.container = this.$refs.AccessibleSnackbar;
    this.container?.$el
      .querySelector(".v-snack__wrapper")
      .setAttribute("style", "visibility: hidden;");
    this.$root.$on("show-accessible-snackbar", this.onShowInformation);
  },
  methods: {
    onShowInformation({ confirmationMessage, color = "success" }) {
      this.color = color;
      this.confirmationMessage = confirmationMessage;
      this.container?.$el
        .querySelector(".v-snack__wrapper")
        .removeAttribute("style");
      this.showSaved = true;
    },
    onClose() {
      this.confirmationMessage = "";
      this.container?.$el
        .querySelector(".v-snack__wrapper")
        .setAttribute("style", "visibility: hidden;");
      this.showSaved = false;
    },
  },
};
</script>
