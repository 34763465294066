import addModerationNote from "~/store/graphql/mutations/addModerationNote.graphql";
import reviewAdminStepPageLoad from "~/store/graphql/queries/reviewAdminStepPageLoad.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const postModerationNote = async function (
  { commit, dispatch },
  { note, itemId }
) {
  await runGraphQLQuery(addModerationNote, { note, itemId }, this.$axios);

  commit("START_LOADING");

  const data = await runGraphQLQuery(
    reviewAdminStepPageLoad,
    { id: itemId },
    this.$axios
  );

  const featureToggles = {
    multipleCategoriesSubmission: this.$unleash.isEnabled(
      "multipleCategoriesSubmission"
    ),
  };

  commit("SET_REVISION_WORKFLOW", { payload: data, featureToggles });
  dispatch("validateSubmission");
  commit("END_LOADING");

  return data;
};

export default postModerationNote;
