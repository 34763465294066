const eventApprovedSpeakersEmailChecks = [
  {
    severity: "ERROR",
    type: "EVENT_APPROVED_SPEAKER",
    fieldName: "approvedSpeakers",
    errorMessage:
      "Following e-mail addresses are not valid and have been removed from the list: ",
    resolutionMessage:
      "Please fix the invalid email addresses and add them to the list again",
  },
  {
    severity: "ERROR",
    type: "EVENT_APPROVED_SPEAKER",
    fieldName: "approvedSpeakers",
    errorMessage: "Limit exceeded",
    resolutionMessage: "Approved Speakers can only contain 200,000 characters",
  },
];

export default eventApprovedSpeakersEmailChecks;
