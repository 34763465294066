<template>
  <v-container class="ContainerFill" fill-height :fluid="fullWidth">
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: "ContainerFill",
  props: {
    fullWidth: { type: Boolean, default: false },
  },
};
</script>
