const UPDATE_MATERIAL = (state, payload) => {
  const submissionItem = state.submissionItem;

  const submissionMaterial = submissionItem.supplementaryAssets.find(
    (asset) => asset.id === payload.id
  );

  if (submissionMaterial) {
    submissionMaterial[payload.field] = payload.value;
  }
};

export default UPDATE_MATERIAL;
