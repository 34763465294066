// MOBILE  XS          < 600px
// TABLET  SM 600px  > < 960px
// DESKTOP MD 960px  > < 1264px
//         LG 1264px > < 1904px
//         XL          > 1904px

export function displayIs() {
  if (this.$store.state.isMounted === true) {
    if (this.$vuetify.breakpoint.xs) {
      return "MOBILE";
    } else if (this.$vuetify.breakpoint.sm) {
      return "TABLET";
    } else {
      return "DESKTOP";
    }
  } else if (this.$device.isMobile) {
    return "MOBILE";
  } else if (this.$device.isTablet) {
    return "TABLET";
  } else {
    return "DESKTOP";
  }
}

export function displayIsMobile() {
  if (this.$store.state.isMounted === true) {
    return this.$vuetify.breakpoint.xs;
  } else {
    return this.$device.isMobile;
  }
}

export function displayIsTablet() {
  if (this.$store.state.isMounted === true) {
    return this.$vuetify.breakpoint.sm;
  } else {
    return this.$device.isTablet;
  }
}

export function displayIsDesktop() {
  if (this.$store.state.isMounted === true) {
    return this.$vuetify.breakpoint.mdAndUp;
  } else {
    return this.$device.isDesktop;
  }
}

export function displayIsTabletOrDesktop() {
  return displayIsTablet() || displayIsDesktop();
}

export function displayIsMobileOrTablet() {
  return displayIsMobile() || displayIsTablet();
}
