const state = () => ({
  unleash: {},
  theme: {},
  partners: [],
  user: {},

  isLoading: false,
  isMounted: false,
  browserSupport: {},

  apiKey: undefined,
  usageEventsDisabled: undefined,
  networkMapUrl: undefined,

  referenceData: {
    categoryTypes: [],
    confirmationTypes: [],
    contentTypes: [],
    filteredContentTypes: [],
    events: [],
    authorTitleTypes: [],
    eventTypes: [],
    keywordTypes: [],
    licenseTypes: [],
    commentReportReasonTypes: [],
    discussionReportReasonTypes: [],
    itemRejectionReasons: [],
    communities: [],
    questions: [],
    relatedContent: [],
    collections: [],
  },

  currentOrigin: {
    categorySummaries: [],
    subjectSummaries: [],
    eventSummaries: [],
    latestItems: [],
    latestEvents: [],
    metrics: [],
    communitySummaries: [],
    communityContent: [],
    departmentSummaries: [],
  },

  allDepartmentsCount: undefined,

  currentAdministrator: {
    submittedItems: [],
    retractedItems: [],
    publishedItems: [],
    revisionItems: [],
    vorRequests: [],
    deletedItems: [],
    events: [],
    reportedComments: [],
    processedComments: [],
    reportedDiscussions: [],
    reportedDiscussionReplies: [],

    submittedItemsCount: undefined,
    retractedItemsCount: undefined,
    publishedItemsCount: undefined,
    revisionItemsCount: undefined,
    vorRequestsCount: undefined,
    deletedItemsCount: undefined,
    eventsCount: undefined,
    reportedCommentsCount: undefined,
    processedCommentsCount: undefined,
    reportedDiscussionsCount: undefined,
    reportedDiscussionRepliesCount: undefined,

    mostReadItem: undefined,
    mostSharedItem: undefined,
    mostPopularItems: [],

    submittedItemsSearchPhrase: undefined,
    publishedItemsSearchPhrase: undefined,

    metrics: [],

    submissionsReport: {
      items: [],
      totalCount: null,
      isFetching: false,
      startDate: undefined,
      endDate: undefined,
    },
    citationsReport: {
      items: [],
      totalCount: null,
      isFetching: false,
    },
    fundersReport: {
      items: [],
      isFetching: false,
      startDate: undefined,
      endDate: undefined,
    },
  },

  currentAuthor: {
    items: [],
    metrics: [],
  },

  submissionItem: undefined,
  submissionItemDependencies: undefined,
  submissionErrors: [],

  retractionItem: undefined,
  retractionErrors: [],

  proposedEvent: undefined,
  proposedErrors: [],

  proposedDiscussion: {},
  discussionErrors: [],
  discussionsLoadedAll: false,
  discussionRepliesLoadedAll: false,

  siteModerators: {
    proposed: [],
    current: [],
  },
  proposedSiteModeratorsErrors: [],

  submissionsReportErrors: [],
  fundersReportErrors: [],

  curators: [],

  externalExperts: {
    current: [],
  },

  selectedCategory: {
    id: undefined,
    name: undefined,
    description: undefined,

    mostReadItem: undefined,
    mostSharedItem: undefined,
    latestItems: [],
  },

  selectedItem: {
    id: undefined,
    title: undefined,
    abstract: undefined,
    contentTypeId: undefined,
    licenseId: undefined,
    status: undefined,
    statusLabel: undefined,
    version: undefined,
    termsAcceptance: undefined,

    authors: undefined,
    metrics: undefined,
    funders: undefined,
    keywords: undefined,
    weblinks: undefined,

    asset: undefined,
    supplementaryAssets: undefined,

    createdById: undefined,
    citations: [],
    isStartingVideo: false,
  },

  selectedCommunity: {
    id: undefined,
    name: undefined,
    description: undefined,
    longDescription: undefined,
    helpDescription: undefined,
    latestContent: [],
    events: [],
    collections: [],
  },

  selectedDiscussion: {
    id: undefined,
    title: undefined,
    type: undefined,
    description: undefined,
    origin: undefined,
    createdDate: undefined,
    author: {
      title: undefined,
      firstName: undefined,
      lastName: undefined,
    },
    votesCount: undefined,
    hasUserVoted: undefined,
  },

  selectedDepartment: {
    id: undefined,
    title: undefined,
    bannerAsset: undefined,
    latestItems: [],
    latestEvents: [],
  },

  selectedReportedDiscussionReply: {},

  selectedOrigin: {
    defaultCitationStyle: undefined,
  },

  selectedEvent: {},

  journals: [],
  journalsLoadedAll: false,
  selectedJournal: undefined,

  searchRequest: {
    text: undefined,
    sortBy: undefined,

    skip: 0,

    category: undefined,
    content: undefined,

    categories: [],
    contents: [],
    events: [],
    publishedDates: [],
  },

  searchResult: {
    totalCount: undefined,

    text: undefined,
    sortBy: undefined,

    results: [],
    categoryBuckets: [],
    contentBuckets: [],
    publishedDateBuckets: [],
  },

  archiveRequest: {
    period: undefined,
  },

  archiveResult: {
    totalCount: undefined,

    results: [],
  },

  communityDetails: {
    communityContentFilter: undefined,
  },

  graphQL: null,
});

export default state;
