import generateGId from "~/store/manage/utilities/generateGId";

const addSubmissionWebLink = function ({ commit }) {
  const newWeblink = {
    gid: generateGId(),
    url: "",
    title: "",
    description: "",
  };

  commit("ADD_SUBMISSION_WEBLINK", newWeblink);

  return Promise.resolve(newWeblink);
};

export default addSubmissionWebLink;
