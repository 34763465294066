const SET_DISCUSSIONS = (state, payload) => {
  state.selectedCommunity.latestDiscussions.results = [
    ...state.selectedCommunity.latestDiscussions.results,
    ...payload.discussionsByCommunityId.results,
  ];
  state.discussionsLoadedAll =
    payload.discussionsByCommunityId.results.length === 0;
};

export default SET_DISCUSSIONS;
