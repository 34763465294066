import engine from "store/src/store-engine";

const localStorage = [
  require("store/storages/localStorage"),
  require("store/storages/cookieStorage"),
  require("store/storages/memoryStorage"),
];
const sessionStorage = [
  require("store/storages/sessionStorage"),
  require("store/storages/memoryStorage"),
];

export default (context, inject) => {
  inject("localStorage", engine.createStore(localStorage));
  inject("sessionStorage", engine.createStore(sessionStorage));
};
