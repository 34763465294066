// prettier-ignore
const DEFAULT_VIEW = {
  LOADED:     'submit-process/step-one',
  PROCESSING_SUBMISSION: null,
  SUBMITTED:  'submit-process/review-article',
  REJECTED:   'submit-process/review-article',
  DELETED:    'article-details/not-found',
  APPROVED:   'article-details',
  PUBLISHED:  'article-details',
  RETRACTING: 'retract-process/step-one',
  RETRACTED:  'article-details',
  REVISION_REQUESTED:  'submit-process/step-one',
};

export default DEFAULT_VIEW;
