import submissionReportChecks from "~/store/manage/validation/checks/submissionReportChecks";
import createError from "~/store/manage/validation/utilities/createError";

const doiStatuses = ["APPROVED", "PUBLISHED", "RETRACTING", "RETRACTED"];
const statusMap = {
  PUBLISHED: "Live",
};

const SET_FUNDERS_REPORT_PART = (state, { payload }) => {
  state.currentAdministrator.fundersReport.totalCount = payload.totalCount;

  if (payload.totalCount === 0) {
    const check = submissionReportChecks.find(
      (check) => check.type === "NO_DATA"
    );
    state.fundersReportErrors.push(
      createError(state.currentAdministrator.fundersReport, check)
    );
  }

  const reportPart = payload.results.flatMap((i) => {
    return i.funders.map((funder) => {
      return {
        title: i.title || "",
        contentType: i.contentType?.name,
        status: mapStatus(i.status),
        version: i.version || "",
        doi: doiStatuses.includes(i.status) ? i.doi : "",
        publishedDate: i.publishedDate || "",
        funderName: funder.name,
        funderDoi: funder.funderId,
        FunderGrantNumber: funder.grantNumber,
      };
    });
  });
  state.currentAdministrator.fundersReport.items.push(...reportPart);
};

function mapStatus(status) {
  if (!status) {
    return "";
  }

  if (Object.keys(statusMap).includes(status)) {
    return statusMap[status];
  }

  return status;
}

export default SET_FUNDERS_REPORT_PART;
