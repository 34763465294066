const acceptanceItemChecks = [
  {
    severity: "ERROR",
    type: "CONFIRMATION",
    fieldName: "termsAcceptance",
    errorMessage: "Terms of submission missing",
    resolutionMessage: "Please accept terms of submission",
  },
];

export default acceptanceItemChecks;
