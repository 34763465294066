import Vue from "vue";

const REMOVE_ITEM = (state, payload) => {
  const { items, id } = payload;

  if (items) {
    const removeindex = items.findIndex((item) => item.id === id);

    if (removeindex > -1) {
      Vue.delete(items, removeindex);
    }
  }
};

export default REMOVE_ITEM;
