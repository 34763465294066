import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import { eventSponsorAssetDelete } from "~/store/graphql/mutations/eventSponsorAssetDelete.graphql";
import eventSponsorUploadDataRefresh from "~/store/graphql/queries/eventSponsorUploadDataRefresh.graphql";

const deleteEventSponsorAsset = async function ({ commit }, eventSponsor) {
  await runGraphQLQuery(
    eventSponsorAssetDelete,
    { id: eventSponsor.id },
    this.$axios
  );
  const { proposedEvent } = await runGraphQLQuery(
    eventSponsorUploadDataRefresh,
    { id: eventSponsor.eventId },
    this.$axios
  );
  proposedEvent.sponsors.forEach((sponsor) => {
    commit("MODIFY_EVENT_SPONSOR", {
      eventSponsorId: sponsor.id,
      field: "asset",
      value: sponsor.asset,
    });
  });
};

export default deleteEventSponsorAsset;
