const contentMediaTypeCheck = {
  severity: "ERROR",
  type: "CONTENT",
  fieldName: "asset",
  errorMessage:
    "We are only accepting video submissions for particular events at this time",
  resolutionMessage: "Please upload your content as PDF or PPT to proceed",
};

export default contentMediaTypeCheck;
