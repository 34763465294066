function addOrdinalSuffix(i) {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

const createError = (item, check, index, type) => {
  const id = item.id || item.gid || item.funderId;

  const error = { ...check, id };

  if (index !== undefined) {
    const indexNo = index + 1;
    const marker = addOrdinalSuffix(indexNo);

    error.resolutionMessage = `${error.resolutionMessage} for the ${marker} ${type}`;
  }

  return error;
};

export default createError;
