const SET_ARTICLE_CITATIONS = (state, payload) => {
  const { citations, total } = payload.citationsById;
  state.selectedItem = {
    ...state.selectedItem,
    citations,
    citationsTotal: total,
  };
};

export default SET_ARTICLE_CITATIONS;
