const SET_PUBLIC_DASHBOARD = (state, payload) => {
  state.currentOrigin.subjectSummaries = payload.subjectSummaries;
  state.currentOrigin.categorySummaries = payload.categorySummaries;

  state.currentOrigin.metrics = payload.origin.metrics;

  state.currentOrigin.latestEvents = payload.origin.eventSearch.results;

  state.currentOrigin.popularItems = payload.popularItems
    ? payload.popularItems.results.map((hit) => hit.item)
    : [];
  state.currentOrigin.latestItems = payload.latestItems
    ? payload.latestItems.results.map((hit) => hit.item)
    : [];

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;

  state.usageEventsDisabled = payload.usageEventsDisabled;

  state.referenceData.subjectTypes = payload.subjectTypes;
  state.referenceData.contentTypes = payload.contentTypes;
};

export default SET_PUBLIC_DASHBOARD;
