import commentCreate from "~/store/graphql/mutations/commentCreate.graphql";
import articleDetailPageLoad from "~/store/graphql/queries/articleDetailPageLoad.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const postArticleComment = async function (
  { commit },
  { content, articleId, partner, parentCommentId }
) {
  const commentInput = {
    entityId: articleId,
    entityType: "ITEM",
    content,
    parentCommentId,
  };
  await runGraphQLQuery(commentCreate, { commentInput }, this.$axios);

  // reload article with new comment
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    articleDetailPageLoad,
    { partner, id: articleId },
    this.$axios
  );

  commit("SET_ARTICLE_DETAILS", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default postArticleComment;
