import submitToJournal from "~/store/graphql/mutations/submitToJournal.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const submitItemToJournal = async function ({ commit }, { item, journal }) {
  const { id, origin } = item;

  const journalInput = {
    itemId: id,
    origin,
    journalId: journal.id,
  };

  const data = await runGraphQLQuery(
    submitToJournal,
    { journalInput },
    this.$axios
  );

  return Promise.resolve(data);
};

export default submitItemToJournal;
