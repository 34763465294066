import {
  isRootCategory,
  hasParentCategory,
} from "~/plugins/utilities/category";

const SET_REVISION_WORKFLOW = (state, { payload, featureToggles }) => {
  state.referenceData.subjectTypes = payload.subjectTypes;
  state.referenceData.categoryTypes = payload.categoryTypes;
  state.referenceData.contentTypes = payload.contentTypes;
  state.referenceData.filteredContentTypes =
    state.referenceData.contentTypes.filter(
      (contentType) =>
        (payload.submissionItemDependencies.community?.journalId &&
          contentType.allowResearchDirectionSubmission) ||
        (payload.submissionItemDependencies.community &&
          !payload.submissionItemDependencies.community.journalId &&
          contentType.allowCommunitySubmission) ||
        (!payload.submissionItemDependencies.community &&
          contentType.allowSubmission)
    );
  state.referenceData.communities = payload.communities;

  const itemQuestion = payload.submissionItemDependencies.question;
  state.referenceData.questions = itemQuestion ? [itemQuestion] : [];

  const events = payload.origin.events.results;
  const itemEvent = payload.submissionItemDependencies.event;
  state.referenceData.events =
    !itemEvent || events.some((event) => event.id === itemEvent.id)
      ? events
      : events.concat(itemEvent);

  state.referenceData.confirmationTypes = payload.confirmationTypes.map(
    (item) => {
      item.label = item.description.substring(
        0,
        item.description.indexOf(" / ")
      );
      item.detail = item.description.substring(item.description.indexOf(" / "));
      return item;
    }
  );
  // HACK remove when data cleaned up
  state.referenceData.licenseTypes = payload.licenseTypes.map((item) => {
    item.label = item.label
      .replace("Licence", "")
      .replace("License", "")
      .trim();
    return item;
  });

  state.referenceData.authorTitleTypes = payload.authorTitleTypes.map(
    (item) => {
      return { id: item, name: item, description: item };
    }
  );

  state.referenceData.itemRejectionReasons = payload.itemRejectionReasons;

  const { contentTypeId, eventId, communityId } = payload.submissionItem;
  state.approvalOriginalValues = { contentTypeId, eventId, communityId };

  const submissionItem = payload.submissionItem;
  if (submissionItem.categoryIds) {
    if (featureToggles.multipleCategoriesSubmission) {
      const submittedCategories = state.referenceData.categoryTypes
        .filter(({ id }) => submissionItem.categoryIds.includes(id))
        .sort(
          ({ id: a }, { id: b }) =>
            submissionItem.categoryIds.indexOf(a) -
            submissionItem.categoryIds.indexOf(b)
        );

      submissionItem.subcategoryIds = submittedCategories
        .filter(hasParentCategory)
        .map((category) => category.id);
      submissionItem.majorCategoryIds = submittedCategories
        .filter(isRootCategory)
        .map((category) => category.id);
      submissionItem.mainCategoryId = submissionItem.majorCategoryIds[0];
    } else {
      submissionItem.categoryId = payload.submissionItem.categoryIds[0];
    }

    delete submissionItem.categoryIds;
  }

  state.submissionItem = submissionItem;
  state.submissionItemDependencies = payload.submissionItemDependencies;

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_REVISION_WORKFLOW;
