<template>
  <div class="quick-action-button d-inline-block">
    <a
      v-if="externalLink"
      :href="externalLink"
      target="_blank"
      :title="externalLink"
      class="v-btn v-btn--flat v-btn--text theme--light v-size--default button-action--text px-2"
    >
      <div class="d-inline-block">
        <strong v-if="boldLink">{{ buttonText }}</strong>
        <div v-else>{{ buttonText }}</div>
        <span class="d-sr-only">[opens in a new tab]</span>
        <v-icon small color="primary" aria-hidden="true">{{
          mdiOpenInNew
        }}</v-icon>
      </div>
    </a>
    <nuxt-link
      v-else
      :to="`/${path}/${link}`"
      class="v-btn v-btn--flat v-btn--text theme--light v-size--default button-action--text px-2"
    >
      <strong v-if="boldLink">{{ buttonText }}</strong>
      <div v-else style="font-weight: 400">{{ buttonText }}</div>
    </nuxt-link>
  </div>
</template>

<script>
import { mdiOpenInNew } from "@mdi/js";

export default {
  props: {
    partner: { type: String, default: undefined },
    link: { type: String, default: undefined },
    externalLink: { type: String, default: undefined },
    buttonText: { type: String, required: true },
    boldLink: { type: Boolean, default: true },
  },
  data() {
    return {
      // Icons
      mdiOpenInNew,
    };
  },
  computed: {
    path() {
      const partner = this.partner || this.$config.partner;
      return `engage/${partner}`;
    },
    buttonColor() {
      return this.inverted ? "inverted" : "button-action";
    },
    newsLinkChemRxiv() {
      return "https://connect.acspubs.org/chemrxiv";
    },
  },
};
</script>
