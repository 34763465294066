import itemCitations from "~/store/graphql/queries/itemCitations.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const getArticleCitations = async function ({ commit }, { id }) {
  commit("START_LOADING");
  const data = await runGraphQLQuery(
    itemCitations,
    { id, ipp: 20 },
    this.$axios
  );

  commit("SET_ARTICLE_CITATIONS", data);
  commit("END_LOADING");
  return Promise.resolve(data);
};

export default getArticleCitations;
