<template>
  <div
    class="banner-heading inverted--text text-left"
    :class="[solidHeader && 'text-center']"
    :style="`width: ${headingWidth}%`"
  >
    <nuxt-link
      v-if="isSubjectLink"
      :to="headerLink"
      :class="displayIsMobile && 'subtitle-1'"
      class="no-underline display-1 pl-0 mr-8"
    >
      <span class="d-sr-only">Back to</span>
      {{ pageTitle }}
    </nuxt-link>
    <h1 v-else class="display-1 mr-8" :class="displayIsMobile && 'subtitle-1'">
      {{ pageTitle }}
      <span v-if="currentStep" class="d-sr-only"
        >&nbsp;Step {{ currentStep }}
      </span>
    </h1>
  </div>
</template>

<script>
import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "BannerHeading",
  props: {
    solidHeader: { type: Boolean, default: false },
    pageTitle: { type: String, default: null },
    currentStep: { type: Number, default: null },
    rssFeed: { type: Boolean, default: false },
    isSubjectLink: { type: Boolean, default: false },
    category: { type: Object, default: () => {} },
    subject: { type: Object, default: () => {} },
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
    headerLink() {
      return this.category
        ? `/engage/${this.partner}/category-dashboard/${this.category.id}`
        : this.subject
        ? `/engage/${this.partner}/subject-dashboard/${this.subject.id}`
        : null;
    },
    headingWidth() {
      if (this.solidHeader) {
        return this.displayIsDesktop && this.rssFeed
          ? 55
          : this.displayIsTablet && this.rssFeed
          ? 65
          : this.displayIsMobile && this.rssFeed
          ? 75
          : this.displayIsMobile
          ? 95
          : 65;
      } else
        return this.displayIsDesktop
          ? 70
          : this.displayIsMobile || this.displayIsTablet
          ? 80
          : 75;
    },
  },
};
</script>
