<template>
  <div class="PlatformHeader">
    <v-div width-100-percent height-100-percent relative>
      <v-div
        foreground-inverted
        layer-1
        height-100-percent
        mt-0
        absolute
        left="0"
      >
        <a href="/engage/coe/public-dashboard">
          <img
            alt="Cambridge Open Engage home"
            src="~/static/images/OpenEngageWhiteLogoWithText.svg"
          />
        </a>
      </v-div>
      <v-div
        v-if="showQuickActions && displayIsDesktop"
        height-100-percent
        absolute
        width-100-percent
        text-center
      >
        <QuickActions inverted no-gutters no-padding />
      </v-div>
      <v-div
        v-if="displayIsTablet || displayIsDesktop"
        layer-1
        height-100-percent
        width-512
        mt-0
        absolute
        right="0"
        text-right
      >
        <PlatformActions
          v-if="platformHeaderPartnerSearch"
          :show-menu="showMenu"
          :show-search="showSearch"
          inverted
          no-gutters
          no-padding
        />
        <v-div v-else foreground-inverted pt-4 :pr-1="displayIsTablet">
          <a
            href="/engage/coe/contact-information?show=faqs"
            class="no-underline"
            >What is Cambridge Open Engage?</a
          >
        </v-div>
      </v-div>
    </v-div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mapFlags from "~/plugins/utilities/mapFlags";

import PlatformActions from "~/components/page/components/actions/PlatformActions.vue";
import QuickActions from "~/components/page/components/actions/QuickActions.vue";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "PlatformHeader",
  components: {
    PlatformActions,
    QuickActions,
  },
  props: {
    showMenu: { type: Boolean, default: true },
    showSearch: { type: Boolean, default: true },
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      baseUrl: (state) => state.theme.baseUrl,
      user: (state) => state.user,
      isLoading: (state) => state.isLoading,
      information: (state) => state.theme.information,
    }),
    ...mapFlags(["platformHeaderPartnerSearch"]),
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
    showQuickActions() {
      return this.partner === "engage";
    },
  },
};
</script>

<style>
.PlatformHeader {
  width: 100%;
  height: 100%;
}

.PlatformHeader a:link {
  height: 100%;
  display: inline-flex;
}

.PlatformHeader img {
  height: 38px;
  margin-top: 4px;
  object-fit: contain;
  object-position: 0 50%;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
}
</style>
