<template>
  <v-div class="loginButtons">
    <div v-if="isModalDisplay">
      <v-btn
        v-if="supportCoreLogin"
        ref="coreLoginBtn"
        class="loginBtn my-2"
        :aria-label="coreBtnText"
        :rounded="buttonRounded"
        :outlined="buttonOutlined"
        color="accent"
        @click="onCoreLogin"
      >
        <span>{{ coreBtnText }}</span>
      </v-btn>
      <v-btn
        v-if="supportOrcidLogin"
        ref="orcidLoginBtn"
        class="loginBtn my-2"
        :aria-label="orcidBtnText"
        :rounded="buttonRounded"
        :outlined="buttonOutlined"
        color="accent"
        @click="onOrcidLogin"
      >
        <span>{{ orcidBtnText }}</span>
        <v-div ml-2>
          <img :src="orcidLogo" alt="orcid logo" />
        </v-div>
      </v-btn>
    </div>
    <v-list v-else style="min-width: 200px; border-radius: 5px" class="pa-0">
      <v-list-item
        v-if="supportCoreLogin"
        class="px-2"
        :aria-label="coreBtnText"
        @click="onCoreLogin"
      >
        <v-btn
          class="loginBtn"
          :rounded="buttonRounded"
          :outlined="buttonOutlined"
          color="accent"
        >
          <span>{{ coreBtnText }}</span>
        </v-btn>
      </v-list-item>
      <v-list-item
        v-if="supportOrcidLogin"
        class="px-2"
        :aria-label="orcidBtnText"
        @click="onOrcidLogin"
      >
        <v-btn
          class="loginBtn"
          :rounded="buttonRounded"
          :outlined="buttonOutlined"
          color="accent"
        >
          <span>{{ orcidBtnText }}</span>
          <v-div ml-2>
            <img :src="orcidLogo" alt="orcid logo" />
          </v-div>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-div>
</template>

<script>
import { mapState } from "vuex";
import { login, orcidLogin } from "~/store/manage/pages/userDefaults/login";
import mapFlags from "~/plugins/utilities/mapFlags";

export default {
  name: "LoginButtons",

  props: {
    redirectUrl: { type: String, default: null, required: false },
    isModalDisplay: { type: Boolean, default: false, required: false },
  },
  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
      user: (state) => state.user,
      theme: (state) => state.theme,
    }),
    ...mapFlags(["supportCoreLogin", "supportOrcidLogin"]),
    focusedStyle() {
      return this.layout.loginButtonFocusedStyle;
    },
    buttonRounded() {
      return !this.focusedStyle;
    },
    buttonOutlined() {
      return !this.focusedStyle;
    },
    orcidLogo() {
      return this.theme.images.orcidLogo;
    },
    orcidBtnText() {
      return `Log in with Orcid ID`;
    },
    coreBtnText() {
      return `Log in with Core Account`;
    },
  },
  mounted() {
    if (this.isModalDisplay) this.setInitialFocus();
  },
  methods: {
    onCoreLogin({ currentTarget }) {
      this.$root.$emit("show-core-login-information", {
        login: (newPath) => login(this.$config, this.res, newPath),
        newPath: this.redirectUrl,
        currentTarget,
      });
    },
    onOrcidLogin() {
      orcidLogin(this.$config, this.res, this.redirectUrl);
    },
    setInitialFocus() {
      this.$nextTick(() => {
        const firstElementToFocus = this.supportCoreLogin
          ? this.$refs.coreLoginBtn.$el
          : this.supportOrcidLogin
          ? this.$refs.orcidLoginBtn.$el
          : null;
        firstElementToFocus?.focus();
      });
    },
  },
};
</script>
