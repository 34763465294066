import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/manage/initialState.js'), 'manage/initialState.js')
  resolveStoreModules(require('../store/manage/pages/SET_BROWSER_SUPPORT.js'), 'manage/pages/SET_BROWSER_SUPPORT.js')
  resolveStoreModules(require('../store/manage/pages/SET_IS_MOUNTED.js'), 'manage/pages/SET_IS_MOUNTED.js')
  resolveStoreModules(require('../store/manage/pages/SET_UNLEASH.js'), 'manage/pages/SET_UNLEASH.js')
  resolveStoreModules(require('../store/manage/utilities/cacheBustAssetUrls.js'), 'manage/utilities/cacheBustAssetUrls.js')
  resolveStoreModules(require('../store/manage/utilities/checkIfAssetsAreProcessing.js'), 'manage/utilities/checkIfAssetsAreProcessing.js')
  resolveStoreModules(require('../store/manage/utilities/createEventForMutation.js'), 'manage/utilities/createEventForMutation.js')
  resolveStoreModules(require('../store/manage/utilities/createItemForMutation.js'), 'manage/utilities/createItemForMutation.js')
  resolveStoreModules(require('../store/manage/utilities/debounce.js'), 'manage/utilities/debounce.js')
  resolveStoreModules(require('../store/manage/utilities/generateGId.js'), 'manage/utilities/generateGId.js')
  resolveStoreModules(require('../store/manage/utilities/isMainVideoContentAllowed.js'), 'manage/utilities/isMainVideoContentAllowed.js')
  resolveStoreModules(require('../store/manage/utilities/runGraphQLQuery.js'), 'manage/utilities/runGraphQLQuery.js')
  resolveStoreModules(require('../store/manage/utilities/unique.js'), 'manage/utilities/unique.js')
  resolveStoreModules(require('../store/manage/validation/checkDiscussionForErrors.js'), 'manage/validation/checkDiscussionForErrors.js')
  resolveStoreModules(require('../store/manage/validation/checkProposalForErrors.js'), 'manage/validation/checkProposalForErrors.js')
  resolveStoreModules(require('../store/manage/validation/checkRetractionForErrors.js'), 'manage/validation/checkRetractionForErrors.js')
  resolveStoreModules(require('../store/manage/validation/checkSubmissionForErrors.js'), 'manage/validation/checkSubmissionForErrors.js')
  resolveStoreModules(require('../store/manage/validation/checkSubmissionsReportForErrors.js'), 'manage/validation/checkSubmissionsReportForErrors.js')
  resolveStoreModules(require('../store/manage/validation/removeErrorsForItemChoices.js'), 'manage/validation/removeErrorsForItemChoices.js')
  resolveStoreModules(require('../store/manage/pages/addVor/loadAddVor.js'), 'manage/pages/addVor/loadAddVor.js')
  resolveStoreModules(require('../store/manage/pages/addVor/MODIFY_SELECTED_ITEM.js'), 'manage/pages/addVor/MODIFY_SELECTED_ITEM.js')
  resolveStoreModules(require('../store/manage/pages/addVor/modifySelectedItem.js'), 'manage/pages/addVor/modifySelectedItem.js')
  resolveStoreModules(require('../store/manage/pages/addVor/SET_ADD_VOR_DETAILS.js'), 'manage/pages/addVor/SET_ADD_VOR_DETAILS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/ASSIGN_ITEM_TO_MODERATOR.js'), 'manage/pages/adminstrationDashboard/ASSIGN_ITEM_TO_MODERATOR.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/assignToModerator.js'), 'manage/pages/adminstrationDashboard/assignToModerator.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/filterAdministrationDashboardResults.js'), 'manage/pages/adminstrationDashboard/filterAdministrationDashboardResults.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdministrationDashboard.js'), 'manage/pages/adminstrationDashboard/loadAdministrationDashboard.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdministrationMoreReportedDiscussionReplies.js'), 'manage/pages/adminstrationDashboard/loadAdministrationMoreReportedDiscussionReplies.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdministrationMoreReportedDiscussions.js'), 'manage/pages/adminstrationDashboard/loadAdministrationMoreReportedDiscussions.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdministrationSubmissionsWithFilters.js'), 'manage/pages/adminstrationDashboard/loadAdministrationSubmissionsWithFilters.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdminstrationMoreComments.js'), 'manage/pages/adminstrationDashboard/loadAdminstrationMoreComments.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdminstrationMoreDeleted.js'), 'manage/pages/adminstrationDashboard/loadAdminstrationMoreDeleted.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdminstrationMoreEvents.js'), 'manage/pages/adminstrationDashboard/loadAdminstrationMoreEvents.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdminstrationMoreProcessedComments.js'), 'manage/pages/adminstrationDashboard/loadAdminstrationMoreProcessedComments.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdminstrationMorePublished.js'), 'manage/pages/adminstrationDashboard/loadAdminstrationMorePublished.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdminstrationMoreRetracted.js'), 'manage/pages/adminstrationDashboard/loadAdminstrationMoreRetracted.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdminstrationMoreRevisions.js'), 'manage/pages/adminstrationDashboard/loadAdminstrationMoreRevisions.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdminstrationMoreSubmitted.js'), 'manage/pages/adminstrationDashboard/loadAdminstrationMoreSubmitted.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadAdminstrationMoreVorRequests.js'), 'manage/pages/adminstrationDashboard/loadAdminstrationMoreVorRequests.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadExternalExperts.js'), 'manage/pages/adminstrationDashboard/loadExternalExperts.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/loadItemByDoi.js'), 'manage/pages/adminstrationDashboard/loadItemByDoi.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/REMOVE_ITEM_FROM_SITE_MODERATOR_SUBMISSIONS.js'), 'manage/pages/adminstrationDashboard/REMOVE_ITEM_FROM_SITE_MODERATOR_SUBMISSIONS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/removeItem.js'), 'manage/pages/adminstrationDashboard/removeItem.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/sendCoAuthorSyncOrcidEmail.js'), 'manage/pages/adminstrationDashboard/sendCoAuthorSyncOrcidEmail.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_DASHBOARD.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_COMMENTS.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_COMMENTS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_DELETED.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_DELETED.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_EVENTS.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_EVENTS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_PROCESSED_COMMENTS.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_PROCESSED_COMMENTS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_PUBLISHED.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_PUBLISHED.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_REPORTED_DISCUSSION_REPLIES.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_REPORTED_DISCUSSION_REPLIES.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_REPORTED_DISCUSSIONS.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_REPORTED_DISCUSSIONS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_RETRACTED.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_RETRACTED.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_REVISIONS.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_REVISIONS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_SUBMITTED.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_SUBMITTED.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_VOR_REQUESTS.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_VOR_REQUESTS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_SEARCH_PHRASE.js'), 'manage/pages/adminstrationDashboard/SET_ADMINISTRATION_SEARCH_PHRASE.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/SET_EXTERNAL_EXPERTS.js'), 'manage/pages/adminstrationDashboard/SET_EXTERNAL_EXPERTS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/unassignFromModerator.js'), 'manage/pages/adminstrationDashboard/unassignFromModerator.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/UPDATE_CURATION_PROGRESS.js'), 'manage/pages/adminstrationDashboard/UPDATE_CURATION_PROGRESS.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/updateCurationProgress.js'), 'manage/pages/adminstrationDashboard/updateCurationProgress.js')
  resolveStoreModules(require('../store/manage/pages/archiveDashboard/loadArchiveDashboard.js'), 'manage/pages/archiveDashboard/loadArchiveDashboard.js')
  resolveStoreModules(require('../store/manage/pages/archiveDashboard/SET_ARCHIVE_DASHBOARD.js'), 'manage/pages/archiveDashboard/SET_ARCHIVE_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/archiveDashboard/SET_ARCHIVE_REQUEST.js'), 'manage/pages/archiveDashboard/SET_ARCHIVE_REQUEST.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/getArticleCitations.js'), 'manage/pages/articleDetails/getArticleCitations.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/getArticleIdByCoreProductId.js'), 'manage/pages/articleDetails/getArticleIdByCoreProductId.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/getIthenticateReportLink.js'), 'manage/pages/articleDetails/getIthenticateReportLink.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/loadArticleDetails.js'), 'manage/pages/articleDetails/loadArticleDetails.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/loadMoreComments.js'), 'manage/pages/articleDetails/loadMoreComments.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/postArticleComment.js'), 'manage/pages/articleDetails/postArticleComment.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/registerUsageEvent.js'), 'manage/pages/articleDetails/registerUsageEvent.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/reportComment.js'), 'manage/pages/articleDetails/reportComment.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/SET_ARTICLE_CITATIONS.js'), 'manage/pages/articleDetails/SET_ARTICLE_CITATIONS.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/SET_ARTICLE_DETAILS.js'), 'manage/pages/articleDetails/SET_ARTICLE_DETAILS.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/SET_ARTICLE_MORE_COMMENTS.js'), 'manage/pages/articleDetails/SET_ARTICLE_MORE_COMMENTS.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/SET_LAST_GRAPHQL.js'), 'manage/pages/articleDetails/SET_LAST_GRAPHQL.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/START_VIDEO.js'), 'manage/pages/articleDetails/START_VIDEO.js')
  resolveStoreModules(require('../store/manage/pages/articleDetails/startVideoForSelectedItem.js'), 'manage/pages/articleDetails/startVideoForSelectedItem.js')
  resolveStoreModules(require('../store/manage/pages/authorDashboard/loadAuthorDashboard.js'), 'manage/pages/authorDashboard/loadAuthorDashboard.js')
  resolveStoreModules(require('../store/manage/pages/authorDashboard/processSubmissions.js'), 'manage/pages/authorDashboard/processSubmissions.js')
  resolveStoreModules(require('../store/manage/pages/authorDashboard/SET_AUTHOR_DASHBOARD.js'), 'manage/pages/authorDashboard/SET_AUTHOR_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/browseDashboard/loadBrowseDashboard.js'), 'manage/pages/browseDashboard/loadBrowseDashboard.js')
  resolveStoreModules(require('../store/manage/pages/browseDashboard/SET_BROWSE_DASHBOARD.js'), 'manage/pages/browseDashboard/SET_BROWSE_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/categoryDashboard/loadCategoryDashboard.js'), 'manage/pages/categoryDashboard/loadCategoryDashboard.js')
  resolveStoreModules(require('../store/manage/pages/categoryDashboard/SET_CATEGORY_DASHBOARD.js'), 'manage/pages/categoryDashboard/SET_CATEGORY_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/communitiesDashboard/loadCommunitiesDashboard.js'), 'manage/pages/communitiesDashboard/loadCommunitiesDashboard.js')
  resolveStoreModules(require('../store/manage/pages/communitiesDashboard/SET_COMMUNITIES_DASHBOARD.js'), 'manage/pages/communitiesDashboard/SET_COMMUNITIES_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/CLEAR_PROPOSED_DISCUSSION.js'), 'manage/pages/communityDetails/CLEAR_PROPOSED_DISCUSSION.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/clearProposedDiscussion.js'), 'manage/pages/communityDetails/clearProposedDiscussion.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/CREATE_DISCUSSION_REPLY_VOTE.js'), 'manage/pages/communityDetails/CREATE_DISCUSSION_REPLY_VOTE.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/CREATE_DISCUSSION_VOTE.js'), 'manage/pages/communityDetails/CREATE_DISCUSSION_VOTE.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/createCommunityDiscussionReply.js'), 'manage/pages/communityDetails/createCommunityDiscussionReply.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/createDiscussion.js'), 'manage/pages/communityDetails/createDiscussion.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/createDiscussionReplyVote.js'), 'manage/pages/communityDetails/createDiscussionReplyVote.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/createDiscussionVote.js'), 'manage/pages/communityDetails/createDiscussionVote.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/getCommunityIdByQuestionId.js'), 'manage/pages/communityDetails/getCommunityIdByQuestionId.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/loadAllCommunityCollectionsListing.js'), 'manage/pages/communityDetails/loadAllCommunityCollectionsListing.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/loadCommunityContent.js'), 'manage/pages/communityDetails/loadCommunityContent.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/loadCommunityDetails.js'), 'manage/pages/communityDetails/loadCommunityDetails.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/loadCommunityDiscussionDetails.js'), 'manage/pages/communityDetails/loadCommunityDiscussionDetails.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/loadCommunityDiscussionListing.js'), 'manage/pages/communityDetails/loadCommunityDiscussionListing.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/loadMoreCommunityDiscussionListing.js'), 'manage/pages/communityDetails/loadMoreCommunityDiscussionListing.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/loadMoreCommunityDiscussionReplies.js'), 'manage/pages/communityDetails/loadMoreCommunityDiscussionReplies.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/modifyProposedDiscussion.js'), 'manage/pages/communityDetails/modifyProposedDiscussion.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/REMOVE_DISCUSSION_REPLY_VOTE.js'), 'manage/pages/communityDetails/REMOVE_DISCUSSION_REPLY_VOTE.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/REMOVE_DISCUSSION_VOTE.js'), 'manage/pages/communityDetails/REMOVE_DISCUSSION_VOTE.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/removeDiscussionReplyVote.js'), 'manage/pages/communityDetails/removeDiscussionReplyVote.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/removeDiscussionVote.js'), 'manage/pages/communityDetails/removeDiscussionVote.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/reportDiscussion.js'), 'manage/pages/communityDetails/reportDiscussion.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/reportDiscussionReply.js'), 'manage/pages/communityDetails/reportDiscussionReply.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/SELECT_COMMUNITY_CONTENT_FILTER.js'), 'manage/pages/communityDetails/SELECT_COMMUNITY_CONTENT_FILTER.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/SELECT_DISCUSSION.js'), 'manage/pages/communityDetails/SELECT_DISCUSSION.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/selectCommunityContentFilter.js'), 'manage/pages/communityDetails/selectCommunityContentFilter.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/SET_COMMUNITY_COLLECTIONS.js'), 'manage/pages/communityDetails/SET_COMMUNITY_COLLECTIONS.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/SET_COMMUNITY_CONTENT.js'), 'manage/pages/communityDetails/SET_COMMUNITY_CONTENT.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/SET_COMMUNITY_DETAILS.js'), 'manage/pages/communityDetails/SET_COMMUNITY_DETAILS.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/SET_DISCUSSION_REPLIES_MORE.js'), 'manage/pages/communityDetails/SET_DISCUSSION_REPLIES_MORE.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/SET_DISCUSSIONS_MORE.js'), 'manage/pages/communityDetails/SET_DISCUSSIONS_MORE.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/SET_DISCUSSIONS.js'), 'manage/pages/communityDetails/SET_DISCUSSIONS.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/UPDATE_PROPOSED_DISCUSSION.js'), 'manage/pages/communityDetails/UPDATE_PROPOSED_DISCUSSION.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/VALIDATE_DISCUSSION.js'), 'manage/pages/communityDetails/VALIDATE_DISCUSSION.js')
  resolveStoreModules(require('../store/manage/pages/communityDetails/validateDiscussion.js'), 'manage/pages/communityDetails/validateDiscussion.js')
  resolveStoreModules(require('../store/manage/pages/departmentDetails/loadDepartmentDetails.js'), 'manage/pages/departmentDetails/loadDepartmentDetails.js')
  resolveStoreModules(require('../store/manage/pages/departmentDetails/SET_DEPARTMENT_DETAILS.js'), 'manage/pages/departmentDetails/SET_DEPARTMENT_DETAILS.js')
  resolveStoreModules(require('../store/manage/pages/departmentsDashboard/loadDepartmentsDashboard.js'), 'manage/pages/departmentsDashboard/loadDepartmentsDashboard.js')
  resolveStoreModules(require('../store/manage/pages/departmentsDashboard/SET_DEPARTMENTS_DASHBOARD.js'), 'manage/pages/departmentsDashboard/SET_DEPARTMENTS_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/discussionReplyReview/loadDiscussionReplyReview.js'), 'manage/pages/discussionReplyReview/loadDiscussionReplyReview.js')
  resolveStoreModules(require('../store/manage/pages/discussionReplyReview/SET_DISCUSSION_REPLY_REVIEW.js'), 'manage/pages/discussionReplyReview/SET_DISCUSSION_REPLY_REVIEW.js')
  resolveStoreModules(require('../store/manage/pages/eventDetails/loadEventDetails.js'), 'manage/pages/eventDetails/loadEventDetails.js')
  resolveStoreModules(require('../store/manage/pages/eventDetails/SET_EVENT_DETAILS.js'), 'manage/pages/eventDetails/SET_EVENT_DETAILS.js')
  resolveStoreModules(require('../store/manage/pages/eventsDashboard/loadEventsDashboard.js'), 'manage/pages/eventsDashboard/loadEventsDashboard.js')
  resolveStoreModules(require('../store/manage/pages/eventsDashboard/SET_EVENTS_DASHBOARD.js'), 'manage/pages/eventsDashboard/SET_EVENTS_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/journalDetails/loadJournalDetails.js'), 'manage/pages/journalDetails/loadJournalDetails.js')
  resolveStoreModules(require('../store/manage/pages/journalDetails/loadSpecificJournalDetails.js'), 'manage/pages/journalDetails/loadSpecificJournalDetails.js')
  resolveStoreModules(require('../store/manage/pages/journalDetails/SET_JOURNAL_DETAILS_MORE.js'), 'manage/pages/journalDetails/SET_JOURNAL_DETAILS_MORE.js')
  resolveStoreModules(require('../store/manage/pages/journalDetails/SET_JOURNAL_DETAILS.js'), 'manage/pages/journalDetails/SET_JOURNAL_DETAILS.js')
  resolveStoreModules(require('../store/manage/pages/journalDetails/SET_SELECTED_JOURNAL.js'), 'manage/pages/journalDetails/SET_SELECTED_JOURNAL.js')
  resolveStoreModules(require('../store/manage/pages/journalDetails/setSelectedJournal.js'), 'manage/pages/journalDetails/setSelectedJournal.js')
  resolveStoreModules(require('../store/manage/pages/journalDetails/submitToJournalLoadMore.js'), 'manage/pages/journalDetails/submitToJournalLoadMore.js')
  resolveStoreModules(require('../store/manage/pages/latestContent/loadLatestContent.js'), 'manage/pages/latestContent/loadLatestContent.js')
  resolveStoreModules(require('../store/manage/pages/latestContent/SET_LATEST_CONTENT.js'), 'manage/pages/latestContent/SET_LATEST_CONTENT.js')
  resolveStoreModules(require('../store/manage/pages/manageCurators/assignToCurator.js'), 'manage/pages/manageCurators/assignToCurator.js')
  resolveStoreModules(require('../store/manage/pages/manageCurators/loadCurators.js'), 'manage/pages/manageCurators/loadCurators.js')
  resolveStoreModules(require('../store/manage/pages/manageCurators/SET_CURATORS.js'), 'manage/pages/manageCurators/SET_CURATORS.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/addSiteModerators.js'), 'manage/pages/manageSiteModerators/addSiteModerators.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/loadSiteModerators.js'), 'manage/pages/manageSiteModerators/loadSiteModerators.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/loadSiteModeratorsProcess.js'), 'manage/pages/manageSiteModerators/loadSiteModeratorsProcess.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/MODIFY_SITE_MODERATORS.js'), 'manage/pages/manageSiteModerators/MODIFY_SITE_MODERATORS.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/modifySiteModerators.js'), 'manage/pages/manageSiteModerators/modifySiteModerators.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/removeSiteModerator.js'), 'manage/pages/manageSiteModerators/removeSiteModerator.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/SET_SITE_MODERATOR_WORKFLOW.js'), 'manage/pages/manageSiteModerators/SET_SITE_MODERATOR_WORKFLOW.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/SET_SITE_MODERATORS.js'), 'manage/pages/manageSiteModerators/SET_SITE_MODERATORS.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/UPDATE_SITE_MODERATOR_VALIDATION.js'), 'manage/pages/manageSiteModerators/UPDATE_SITE_MODERATOR_VALIDATION.js')
  resolveStoreModules(require('../store/manage/pages/manageSiteModerators/validateSiteModeratorAccounts.js'), 'manage/pages/manageSiteModerators/validateSiteModeratorAccounts.js')
  resolveStoreModules(require('../store/manage/pages/platformDashboard/loadPlatformDashboard.js'), 'manage/pages/platformDashboard/loadPlatformDashboard.js')
  resolveStoreModules(require('../store/manage/pages/platformDashboard/loadPlatformDashboardSelectedArticles.js'), 'manage/pages/platformDashboard/loadPlatformDashboardSelectedArticles.js')
  resolveStoreModules(require('../store/manage/pages/platformDashboard/SET_PLATFORM_DASHBOARD.js'), 'manage/pages/platformDashboard/SET_PLATFORM_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/platformDashboard/SET_SELECTED_ARTICLES.js'), 'manage/pages/platformDashboard/SET_SELECTED_ARTICLES.js')
  resolveStoreModules(require('../store/manage/pages/publicDashboard/loadPublicDashboard.js'), 'manage/pages/publicDashboard/loadPublicDashboard.js')
  resolveStoreModules(require('../store/manage/pages/publicDashboard/SET_PUBLIC_DASHBOARD.js'), 'manage/pages/publicDashboard/SET_PUBLIC_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/CLEAR_RETRACTION.js'), 'manage/pages/retractArticle/CLEAR_RETRACTION.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/clearRetractionItem.js'), 'manage/pages/retractArticle/clearRetractionItem.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/loadRetractProcess.js'), 'manage/pages/retractArticle/loadRetractProcess.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/modifyRetractionRequest.js'), 'manage/pages/retractArticle/modifyRetractionRequest.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/modifyRetractionResponse.js'), 'manage/pages/retractArticle/modifyRetractionResponse.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/SET_RETRACTION_UPDATE.js'), 'manage/pages/retractArticle/SET_RETRACTION_UPDATE.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/SET_RETRACTION_WORKFLOW.js'), 'manage/pages/retractArticle/SET_RETRACTION_WORKFLOW.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/submitConfirmRetraction.js'), 'manage/pages/retractArticle/submitConfirmRetraction.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/submitRejectRetraction.js'), 'manage/pages/retractArticle/submitRejectRetraction.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/submitRequestRetraction.js'), 'manage/pages/retractArticle/submitRequestRetraction.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/UPDATE_RETRACTION_REQUEST.js'), 'manage/pages/retractArticle/UPDATE_RETRACTION_REQUEST.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/UPDATE_RETRACTION_RESPONSE.js'), 'manage/pages/retractArticle/UPDATE_RETRACTION_RESPONSE.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/updateConfirmRetraction.js'), 'manage/pages/retractArticle/updateConfirmRetraction.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/updateRequestRetraction.js'), 'manage/pages/retractArticle/updateRequestRetraction.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/VALIDATE_RETRACTION.js'), 'manage/pages/retractArticle/VALIDATE_RETRACTION.js')
  resolveStoreModules(require('../store/manage/pages/retractArticle/validateRetraction.js'), 'manage/pages/retractArticle/validateRetraction.js')
  resolveStoreModules(require('../store/manage/pages/reviewArticle/postModerationNote.js'), 'manage/pages/reviewArticle/postModerationNote.js')
  resolveStoreModules(require('../store/manage/pages/reviewProcessed/loadReviewProcessed.js'), 'manage/pages/reviewProcessed/loadReviewProcessed.js')
  resolveStoreModules(require('../store/manage/pages/searchDashboard/loadSearchDashboard.js'), 'manage/pages/searchDashboard/loadSearchDashboard.js')
  resolveStoreModules(require('../store/manage/pages/searchDashboard/loadSearchMoreMatches.js'), 'manage/pages/searchDashboard/loadSearchMoreMatches.js')
  resolveStoreModules(require('../store/manage/pages/searchDashboard/modifySearch.js'), 'manage/pages/searchDashboard/modifySearch.js')
  resolveStoreModules(require('../store/manage/pages/searchDashboard/SET_SEARCH_DASHBOARD.js'), 'manage/pages/searchDashboard/SET_SEARCH_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/searchDashboard/SET_SEARCH_MORE.js'), 'manage/pages/searchDashboard/SET_SEARCH_MORE.js')
  resolveStoreModules(require('../store/manage/pages/searchDashboard/SET_SEARCH_REQUEST.js'), 'manage/pages/searchDashboard/SET_SEARCH_REQUEST.js')
  resolveStoreModules(require('../store/manage/pages/searchDashboard/UPDATE_SEARCH.js'), 'manage/pages/searchDashboard/UPDATE_SEARCH.js')
  resolveStoreModules(require('../store/manage/pages/subjectDashboard/loadSubjectDashboard.js'), 'manage/pages/subjectDashboard/loadSubjectDashboard.js')
  resolveStoreModules(require('../store/manage/pages/subjectDashboard/SET_SUBJECT_DASHBOARD.js'), 'manage/pages/subjectDashboard/SET_SUBJECT_DASHBOARD.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/ADD_SUBMISSION_AUTHOR.js'), 'manage/pages/submitArticle/ADD_SUBMISSION_AUTHOR.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/ADD_SUBMISSION_FUNDER.js'), 'manage/pages/submitArticle/ADD_SUBMISSION_FUNDER.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/ADD_SUBMISSION_WEBLINK.js'), 'manage/pages/submitArticle/ADD_SUBMISSION_WEBLINK.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/addSubmissionAuthor.js'), 'manage/pages/submitArticle/addSubmissionAuthor.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/addSubmissionFunder.js'), 'manage/pages/submitArticle/addSubmissionFunder.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/addSubmissionWebLink.js'), 'manage/pages/submitArticle/addSubmissionWebLink.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/CLEAR_AUTHOR_ORCID.js'), 'manage/pages/submitArticle/CLEAR_AUTHOR_ORCID.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/CLEAR_SUBMISSION_ITEM.js'), 'manage/pages/submitArticle/CLEAR_SUBMISSION_ITEM.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/CLEAR_SUBMISSION.js'), 'manage/pages/submitArticle/CLEAR_SUBMISSION.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/clearSubmissionItem.js'), 'manage/pages/submitArticle/clearSubmissionItem.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/CREATE_MATERIAL.js'), 'manage/pages/submitArticle/CREATE_MATERIAL.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/createSubmissionItem.js'), 'manage/pages/submitArticle/createSubmissionItem.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/createSubmissionMaterial.js'), 'manage/pages/submitArticle/createSubmissionMaterial.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/DELETE_FILE.js'), 'manage/pages/submitArticle/DELETE_FILE.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/DELETE_MATERIAL.js'), 'manage/pages/submitArticle/DELETE_MATERIAL.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/DELETE_SUPPLEMENTARY_FILE.js'), 'manage/pages/submitArticle/DELETE_SUPPLEMENTARY_FILE.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/deleteAuthorOrcid.js'), 'manage/pages/submitArticle/deleteAuthorOrcid.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/deleteFile.js'), 'manage/pages/submitArticle/deleteFile.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/deleteSubmissionItem.js'), 'manage/pages/submitArticle/deleteSubmissionItem.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/deleteSubmissionMaterial.js'), 'manage/pages/submitArticle/deleteSubmissionMaterial.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/deleteSupplementaryFile.js'), 'manage/pages/submitArticle/deleteSupplementaryFile.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/FINISHED_UPLOAD.js'), 'manage/pages/submitArticle/FINISHED_UPLOAD.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/finishedUpload.js'), 'manage/pages/submitArticle/finishedUpload.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/loadAdminRevisionProcess.js'), 'manage/pages/submitArticle/loadAdminRevisionProcess.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/loadChooseProcess.js'), 'manage/pages/submitArticle/loadChooseProcess.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/loadEmailTemplate.js'), 'manage/pages/submitArticle/loadEmailTemplate.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/loadSubmitProcess.js'), 'manage/pages/submitArticle/loadSubmitProcess.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/loadUserRevisionProcess.js'), 'manage/pages/submitArticle/loadUserRevisionProcess.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/modifySubmissionAuthor.js'), 'manage/pages/submitArticle/modifySubmissionAuthor.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/modifySubmissionAuthorOrder.js'), 'manage/pages/submitArticle/modifySubmissionAuthorOrder.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/modifySubmissionCommunity.js'), 'manage/pages/submitArticle/modifySubmissionCommunity.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/modifySubmissionFunder.js'), 'manage/pages/submitArticle/modifySubmissionFunder.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/modifySubmissionItem.js'), 'manage/pages/submitArticle/modifySubmissionItem.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/modifySubmissionMaterial.js'), 'manage/pages/submitArticle/modifySubmissionMaterial.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/modifySubmissionQuestion.js'), 'manage/pages/submitArticle/modifySubmissionQuestion.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/modifySubmissionWebLink.js'), 'manage/pages/submitArticle/modifySubmissionWebLink.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/newSubmissionVersion.js'), 'manage/pages/submitArticle/newSubmissionVersion.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/refreshSubmissionAssets.js'), 'manage/pages/submitArticle/refreshSubmissionAssets.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/REMOVE_AUTHOR.js'), 'manage/pages/submitArticle/REMOVE_AUTHOR.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/REMOVE_ITEM.js'), 'manage/pages/submitArticle/REMOVE_ITEM.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/removeSubmissionAuthor.js'), 'manage/pages/submitArticle/removeSubmissionAuthor.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/removeSubmissionFunder.js'), 'manage/pages/submitArticle/removeSubmissionFunder.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/removeSubmissionItem.js'), 'manage/pages/submitArticle/removeSubmissionItem.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/removeSubmissionWebLink.js'), 'manage/pages/submitArticle/removeSubmissionWebLink.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/REPLACE_SUBMISSION_FUNDER.js'), 'manage/pages/submitArticle/REPLACE_SUBMISSION_FUNDER.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/replaceSubmissionFunder.js'), 'manage/pages/submitArticle/replaceSubmissionFunder.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/retriggerVimeo.js'), 'manage/pages/submitArticle/retriggerVimeo.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/reviseSubmissionStatus.js'), 'manage/pages/submitArticle/reviseSubmissionStatus.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/SET_REVISION_WORKFLOW.js'), 'manage/pages/submitArticle/SET_REVISION_WORKFLOW.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/SET_SUBMISSION_CHOOSE.js'), 'manage/pages/submitArticle/SET_SUBMISSION_CHOOSE.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/SET_SUBMISSION_WORKFLOW.js'), 'manage/pages/submitArticle/SET_SUBMISSION_WORKFLOW.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/STARTED_UPLOAD.js'), 'manage/pages/submitArticle/STARTED_UPLOAD.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/startedUpload.js'), 'manage/pages/submitArticle/startedUpload.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_ITEM.js'), 'manage/pages/submitArticle/UPDATE_ITEM.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_MATERIAL.js'), 'manage/pages/submitArticle/UPDATE_MATERIAL.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_SUBMISSION_ASSET.js'), 'manage/pages/submitArticle/UPDATE_SUBMISSION_ASSET.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_SUBMISSION_ASSETS.js'), 'manage/pages/submitArticle/UPDATE_SUBMISSION_ASSETS.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_SUBMISSION_AUTHOR_ORDER.js'), 'manage/pages/submitArticle/UPDATE_SUBMISSION_AUTHOR_ORDER.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_SUBMISSION_AUTHOR.js'), 'manage/pages/submitArticle/UPDATE_SUBMISSION_AUTHOR.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_SUBMISSION_COMMUNITY.js'), 'manage/pages/submitArticle/UPDATE_SUBMISSION_COMMUNITY.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_SUBMISSION_FUNDER.js'), 'manage/pages/submitArticle/UPDATE_SUBMISSION_FUNDER.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_SUBMISSION_QUESTION.js'), 'manage/pages/submitArticle/UPDATE_SUBMISSION_QUESTION.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_SUBMISSION_WEBLINK.js'), 'manage/pages/submitArticle/UPDATE_SUBMISSION_WEBLINK.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/UPDATE_SUBMISSION.js'), 'manage/pages/submitArticle/UPDATE_SUBMISSION.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/updateMarketingPreferences.js'), 'manage/pages/submitArticle/updateMarketingPreferences.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/updateSubmissionItem.js'), 'manage/pages/submitArticle/updateSubmissionItem.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/updateSubmissionReviewItem.js'), 'manage/pages/submitArticle/updateSubmissionReviewItem.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/updateSubmissionStatus.js'), 'manage/pages/submitArticle/updateSubmissionStatus.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/VALIDATE_SUBMISSION.js'), 'manage/pages/submitArticle/VALIDATE_SUBMISSION.js')
  resolveStoreModules(require('../store/manage/pages/submitArticle/validateSubmission.js'), 'manage/pages/submitArticle/validateSubmission.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/ADD_EVENT_TO_REFERENCE_DATA.js'), 'manage/pages/submitEvent/ADD_EVENT_TO_REFERENCE_DATA.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/CLEAR_EVENT_ASSET.js'), 'manage/pages/submitEvent/CLEAR_EVENT_ASSET.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/CLEAR_EVENT_SPONSOR_ASSET.js'), 'manage/pages/submitEvent/CLEAR_EVENT_SPONSOR_ASSET.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/CLEAR_EVENT.js'), 'manage/pages/submitEvent/CLEAR_EVENT.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/clearEventAsset.js'), 'manage/pages/submitEvent/clearEventAsset.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/clearEventSponsorAsset.js'), 'manage/pages/submitEvent/clearEventSponsorAsset.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/CREATE_EVENT_GROUP.js'), 'manage/pages/submitEvent/CREATE_EVENT_GROUP.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/CREATE_EVENT_SPONSOR.js'), 'manage/pages/submitEvent/CREATE_EVENT_SPONSOR.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/createEvent.js'), 'manage/pages/submitEvent/createEvent.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/createEventGroup.js'), 'manage/pages/submitEvent/createEventGroup.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/createEventSponsor.js'), 'manage/pages/submitEvent/createEventSponsor.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/DELETE_EVENT_SPONSOR.js'), 'manage/pages/submitEvent/DELETE_EVENT_SPONSOR.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/deleteEvent.js'), 'manage/pages/submitEvent/deleteEvent.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/deleteEventBannerAsset.js'), 'manage/pages/submitEvent/deleteEventBannerAsset.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/deleteEventSponsor.js'), 'manage/pages/submitEvent/deleteEventSponsor.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/deleteEventSponsorAsset.js'), 'manage/pages/submitEvent/deleteEventSponsorAsset.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/loadEventProcess.js'), 'manage/pages/submitEvent/loadEventProcess.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/MODIFY_EVENT_APPROVED_SPEAKERS.js'), 'manage/pages/submitEvent/MODIFY_EVENT_APPROVED_SPEAKERS.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/MODIFY_EVENT_SPONSOR.js'), 'manage/pages/submitEvent/MODIFY_EVENT_SPONSOR.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/MODIFY_EVENT.js'), 'manage/pages/submitEvent/MODIFY_EVENT.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/modifyEventSponsor.js'), 'manage/pages/submitEvent/modifyEventSponsor.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/modifyProposedEvent.js'), 'manage/pages/submitEvent/modifyProposedEvent.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/modifyProposedEventApprovedSpeakers.js'), 'manage/pages/submitEvent/modifyProposedEventApprovedSpeakers.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/refreshEventAsset.js'), 'manage/pages/submitEvent/refreshEventAsset.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/refreshEventSponsorAsset.js'), 'manage/pages/submitEvent/refreshEventSponsorAsset.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/removeEvent.js'), 'manage/pages/submitEvent/removeEvent.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/SET_EVENT_WORKFLOW.js'), 'manage/pages/submitEvent/SET_EVENT_WORKFLOW.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/SET_RELATED_CONTENT.js'), 'manage/pages/submitEvent/SET_RELATED_CONTENT.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/UPDATE_EVENT_MODERATOR_VALIDATION.js'), 'manage/pages/submitEvent/UPDATE_EVENT_MODERATOR_VALIDATION.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/UPDATE_EVENT.js'), 'manage/pages/submitEvent/UPDATE_EVENT.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/updateEventStatus.js'), 'manage/pages/submitEvent/updateEventStatus.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/updateProposedEvent.js'), 'manage/pages/submitEvent/updateProposedEvent.js')
  resolveStoreModules(require('../store/manage/pages/submitEvent/validateModeratorAccounts.js'), 'manage/pages/submitEvent/validateModeratorAccounts.js')
  resolveStoreModules(require('../store/manage/pages/submitToJournal/submitItemToJournal.js'), 'manage/pages/submitToJournal/submitItemToJournal.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/CLEAR_USER.js'), 'manage/pages/userDefaults/CLEAR_USER.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/loadUserDefaults.js'), 'manage/pages/userDefaults/loadUserDefaults.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/login.js'), 'manage/pages/userDefaults/login.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/logout.js'), 'manage/pages/userDefaults/logout.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/SET_API_KEY.js'), 'manage/pages/userDefaults/SET_API_KEY.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/SET_NO_EVENTS.js'), 'manage/pages/userDefaults/SET_NO_EVENTS.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/SET_PARTNERS.js'), 'manage/pages/userDefaults/SET_PARTNERS.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/SET_THEME.js'), 'manage/pages/userDefaults/SET_THEME.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/SET_USER_DEFAULTS.js'), 'manage/pages/userDefaults/SET_USER_DEFAULTS.js')
  resolveStoreModules(require('../store/manage/pages/userDefaults/UPDATE_USER.js'), 'manage/pages/userDefaults/UPDATE_USER.js')
  resolveStoreModules(require('../store/manage/validation/checks/acceptanceItemChecks.js'), 'manage/validation/checks/acceptanceItemChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/acceptanceRequestChecks.js'), 'manage/validation/checks/acceptanceRequestChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/contentAllowedFileTypeCheck.js'), 'manage/validation/checks/contentAllowedFileTypeCheck.js')
  resolveStoreModules(require('../store/manage/validation/checks/contentMediaTypeCheck.js'), 'manage/validation/checks/contentMediaTypeCheck.js')
  resolveStoreModules(require('../store/manage/validation/checks/discussionChecks.js'), 'manage/validation/checks/discussionChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/eventApprovedSpeakersEmailChecks.js'), 'manage/validation/checks/eventApprovedSpeakersEmailChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/eventModeratorsAccountChecks.js'), 'manage/validation/checks/eventModeratorsAccountChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/eventModeratorsEmailChecks.js'), 'manage/validation/checks/eventModeratorsEmailChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryAssetContentChecks.js'), 'manage/validation/checks/mandatoryAssetContentChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryAuthorChecks.js'), 'manage/validation/checks/mandatoryAuthorChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryCommunityChecks.js'), 'manage/validation/checks/mandatoryCommunityChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryContentChecks.js'), 'manage/validation/checks/mandatoryContentChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryEventChecks.js'), 'manage/validation/checks/mandatoryEventChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryEventSponsorChecks.js'), 'manage/validation/checks/mandatoryEventSponsorChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryFirstAuthorChecks.js'), 'manage/validation/checks/mandatoryFirstAuthorChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryFunderChecks.js'), 'manage/validation/checks/mandatoryFunderChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryMaterialChecks.js'), 'manage/validation/checks/mandatoryMaterialChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryRequestChecks.js'), 'manage/validation/checks/mandatoryRequestChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryResponseChecks.js'), 'manage/validation/checks/mandatoryResponseChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryVersionChecks.js'), 'manage/validation/checks/mandatoryVersionChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/mandatoryWebLinkChecks.js'), 'manage/validation/checks/mandatoryWebLinkChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/regexAuthorChecks.js'), 'manage/validation/checks/regexAuthorChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/regexContentChecks.js'), 'manage/validation/checks/regexContentChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/regexEventSponsorChecks.js'), 'manage/validation/checks/regexEventSponsorChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/regexMaterialChecks.js'), 'manage/validation/checks/regexMaterialChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/regexVersionChecks.js'), 'manage/validation/checks/regexVersionChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/regexWebLinkChecks.js'), 'manage/validation/checks/regexWebLinkChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/siteModeratorsAccountChecks.js'), 'manage/validation/checks/siteModeratorsAccountChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/siteModeratorsEmailChecks.js'), 'manage/validation/checks/siteModeratorsEmailChecks.js')
  resolveStoreModules(require('../store/manage/validation/checks/submissionReportChecks.js'), 'manage/validation/checks/submissionReportChecks.js')
  resolveStoreModules(require('../store/manage/validation/utilities/checkForAcceptanceErrors.js'), 'manage/validation/utilities/checkForAcceptanceErrors.js')
  resolveStoreModules(require('../store/manage/validation/utilities/checkForMissingErrors.js'), 'manage/validation/utilities/checkForMissingErrors.js')
  resolveStoreModules(require('../store/manage/validation/utilities/checkForRegexErrors.js'), 'manage/validation/utilities/checkForRegexErrors.js')
  resolveStoreModules(require('../store/manage/validation/utilities/checkForValueErrors.js'), 'manage/validation/utilities/checkForValueErrors.js')
  resolveStoreModules(require('../store/manage/validation/utilities/checkIfMatches.js'), 'manage/validation/utilities/checkIfMatches.js')
  resolveStoreModules(require('../store/manage/validation/utilities/checkIfMissing.js'), 'manage/validation/utilities/checkIfMissing.js')
  resolveStoreModules(require('../store/manage/validation/utilities/checkIfNotEqual.js'), 'manage/validation/utilities/checkIfNotEqual.js')
  resolveStoreModules(require('../store/manage/validation/utilities/checkNoMatches.js'), 'manage/validation/utilities/checkNoMatches.js')
  resolveStoreModules(require('../store/manage/validation/utilities/createError.js'), 'manage/validation/utilities/createError.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/END_CITATION_REPORT_FETCHING.js'), 'manage/pages/adminstrationDashboard/reports/END_CITATION_REPORT_FETCHING.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/END_FUNDER_REPORT_FETCHING.js'), 'manage/pages/adminstrationDashboard/reports/END_FUNDER_REPORT_FETCHING.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/END_SUBMISSION_REPORT_FETCHING.js'), 'manage/pages/adminstrationDashboard/reports/END_SUBMISSION_REPORT_FETCHING.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/generateCitationsReport.js'), 'manage/pages/adminstrationDashboard/reports/generateCitationsReport.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/generateFundersReport.js'), 'manage/pages/adminstrationDashboard/reports/generateFundersReport.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/generateSubmissionsReport.js'), 'manage/pages/adminstrationDashboard/reports/generateSubmissionsReport.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/SET_CITATIONS_REPORT_PART.js'), 'manage/pages/adminstrationDashboard/reports/SET_CITATIONS_REPORT_PART.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/SET_FUNDER_REPORT_DATE_RANGE.js'), 'manage/pages/adminstrationDashboard/reports/SET_FUNDER_REPORT_DATE_RANGE.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/SET_FUNDERS_REPORT_PART.js'), 'manage/pages/adminstrationDashboard/reports/SET_FUNDERS_REPORT_PART.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/SET_SUBMISSION_REPORT_DATE_RANGE.js'), 'manage/pages/adminstrationDashboard/reports/SET_SUBMISSION_REPORT_DATE_RANGE.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/SET_SUBMISSIONS_REPORT_PART.js'), 'manage/pages/adminstrationDashboard/reports/SET_SUBMISSIONS_REPORT_PART.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/START_CITATION_REPORT_FETCHING.js'), 'manage/pages/adminstrationDashboard/reports/START_CITATION_REPORT_FETCHING.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/START_FUNDER_REPORT_FETCHING.js'), 'manage/pages/adminstrationDashboard/reports/START_FUNDER_REPORT_FETCHING.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/START_SUBMISSION_REPORT_FETCHING.js'), 'manage/pages/adminstrationDashboard/reports/START_SUBMISSION_REPORT_FETCHING.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/updateFundersReportDateRange.js'), 'manage/pages/adminstrationDashboard/reports/updateFundersReportDateRange.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/updateSubmissionsReportDateRange.js'), 'manage/pages/adminstrationDashboard/reports/updateSubmissionsReportDateRange.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/VALIDATE_FUNDER_REPORT.js'), 'manage/pages/adminstrationDashboard/reports/VALIDATE_FUNDER_REPORT.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/VALIDATE_SUBMISSION_REPORT.js'), 'manage/pages/adminstrationDashboard/reports/VALIDATE_SUBMISSION_REPORT.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/validateFunderReport.js'), 'manage/pages/adminstrationDashboard/reports/validateFunderReport.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/reports/validateSubmissionReport.js'), 'manage/pages/adminstrationDashboard/reports/validateSubmissionReport.js')
  resolveStoreModules(require('../store/manage/pages/adminstrationDashboard/utilities/processItems.js'), 'manage/pages/adminstrationDashboard/utilities/processItems.js')
  resolveStoreModules(require('../store/manage/pages/eventDetails/event-submissions/loadEventSubmissions.js'), 'manage/pages/eventDetails/event-submissions/loadEventSubmissions.js')
  resolveStoreModules(require('../store/manage/pages/eventDetails/event-submissions/SET_EVENT_SUBMISSIONS.js'), 'manage/pages/eventDetails/event-submissions/SET_EVENT_SUBMISSIONS.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
