import Vue from "vue";

const REMOVE_AUTHOR = (state, payload) => {
  const { submissionItem } = state;
  const { authors } = submissionItem || {};

  if (authors) {
    const removeindex = authors.findIndex((item) => item.gid === payload);

    if (removeindex > -1) {
      Vue.delete(authors, removeindex);
    }
  }
};

export default REMOVE_AUTHOR;
