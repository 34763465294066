import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

import communityDiscussionRepliesLoad from "~/store/graphql/queries/communityDiscussionRepliesLoad.graphql";

const loadMoreCommunityDiscussionReplies = async function (
  { commit },
  { params }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    communityDiscussionRepliesLoad,
    params,
    this.$axios
  );

  commit("SET_DISCUSSION_REPLIES_MORE", data);

  commit("END_LOADING");
  return Promise.resolve(data);
};

export default loadMoreCommunityDiscussionReplies;
