import submissionReportChecks from "~/store/manage/validation/checks/submissionReportChecks";
import createError from "~/store/manage/validation/utilities/createError";

const doiStatuses = ["APPROVED", "PUBLISHED", "RETRACTING", "RETRACTED"];
const statusMap = {
  SUBMITTED: "Awaiting approval",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  PUBLISHED: "Live",
  DELETED: "Deleted",
  RETRACTING: "Retraction in progress",
  RETRACTED: "Retracted",
};

const vorContentTypes = {
  "journal-article": "Journal Article",
  "book-chapter": "Book Chapter",
  "proceedings-article": "Proceedings Article",
  monograph: "Monograph",
};

const getVorNameBaseOnVorType = (vorInfo) => {
  if (vorInfo.type === "journal-article" || vorInfo.type === "book-chapter")
    return vorInfo.journalName;

  if (vorInfo.type === "proceedings-article") return vorInfo.conferenceName;

  if (vorInfo.type === "monograph") return "N/A";

  return "";
};

const getCountriesString = (institutions) => {
  let countriesString = "None";

  if (institutions.length) {
    const affiliationCountries = institutions.map(
      (institution) => institution.country
    );

    if (!affiliationCountries.length) {
      return countriesString;
    }

    const uniqueAffiliationCountries = affiliationCountries.filter(
      (country, index) => {
        return affiliationCountries.indexOf(country) === index;
      }
    );
    countriesString = uniqueAffiliationCountries.join("; ");
  }

  return countriesString;
};

const SET_SUBMISSIONS_REPORT_PART = (state, { payload, featureToggles }) => {
  state.currentAdministrator.submissionsReport.totalCount = payload.totalCount;

  if (payload.totalCount === 0) {
    const check = submissionReportChecks.find(
      (check) => check.type === "NO_DATA"
    );
    state.submissionsReportErrors.push(
      createError(state.currentAdministrator.submissionsReport, check)
    );
  }

  const reportPart = payload.results.map((i) => {
    const firstAuthor = i.authors.find((a) => a.firstAuthor) || {};
    const vorInfo = i.vor?.metadata;
    const latestRejectedDate = i.updateHistory
      .filter((status) => status.type === "STATUS" && status.key === "REJECTED")
      .map((history) => history.date)
      .pop();

    const report1 = {
      title: i.title || "",
      contentType: i.contentType?.name,
      status: mapStatus(i.status),
      rejectionReason: i.rejectionReason ? i.rejectionReason.name : "",
      version: i.version || "",
      doi: doiStatuses.includes(i.status) ? i.doi : "",
      ...(featureToggles.submissionReportShowEvent && {
        eventTitle: getEventTitle(i),
      }),
    };

    const report2 = {
      ...(featureToggles.submissionReportShowCommunity && {
        communityName: i.community ? i.community.name : "",
      }),
      submittedDate: i.submittedDate || "",
      approvedDate: i.approvedDate || "",
      publishedDate: i.publishedDate || "",
      rejectedDate: latestRejectedDate || "",
      firstName: firstAuthor.firstName || "",
      lastName: firstAuthor.lastName || "",
      affiliationCountry: getCountriesString(firstAuthor.institutions),
      userId: mapUserId(firstAuthor),
      orcid: firstAuthor.orcid || "",
      VorTitle: vorInfo?.title || "",
      VorDoi: i.vor?.vorDoi || "",
      VorPublicationOnlineDate: vorInfo?.publishedOnlineDate || "",
      VorPublicationPrintDate: vorInfo?.publishedPrintDate || "",
      VorContentType: vorInfo?.type ? vorContentTypes[vorInfo.type] : "",
      VorName: vorInfo?.type ? getVorNameBaseOnVorType(vorInfo) : "",
      Comments: i.liveCommentsCount || 0,
    };

    const reportSubjectOrCategory = {};
    if (i.origin !== "COE") {
      reportSubjectOrCategory.category = i.mainCategory
        ? i.mainCategory.name
        : "";
    } else {
      reportSubjectOrCategory.subject = i.subject ? i.subject.name : "";
    }

    return {
      ...report1,
      ...reportSubjectOrCategory,
      ...report2,
    };
  });

  state.currentAdministrator.submissionsReport.items.push(...reportPart);
};

function getEventTitle(i) {
  return i.event ? i.event.title : "";
}

function mapUserId(author) {
  if (author.apsaMeta) {
    return author.apsaMeta.apsaId || "";
  }

  return author.gid || "";
}

function mapStatus(status) {
  if (!status) {
    return "";
  }

  if (Object.keys(statusMap).includes(status)) {
    return statusMap[status];
  }

  return status;
}

export default SET_SUBMISSIONS_REPORT_PART;
