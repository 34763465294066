<template>
  <ContainerFill class="QuickActions" :text-center="actionsCenter" full-width>
    <v-row
      :align-center="actionsCenter"
      :justify-center="actionsCenter"
      fill-height
      :no-gutters="noGutters"
    >
      <v-col :class="noPadding ? 'pb-0' : undefined">
        <QuickActionButton
          link="submission-information"
          button-text="How to Submit"
        />
        <QuickActionButton link="browse-dashboard" button-text="Browse" />
        <QuickActionButton
          v-if="quickActionsShowEvents"
          link="events-dashboard"
          button-text="Events"
        />
        <QuickActionButton
          v-if="quickActionShowCommunities"
          link="communities-dashboard"
          button-text="Communities"
        />
        <QuickActionButton link="about-information" button-text="About" />
        <QuickActionButton
          v-if="quickActionShowNews"
          :external-link="newsLinkChemRxiv"
          button-text="News"
        />
      </v-col>
    </v-row>
  </ContainerFill>
</template>

<script>
import ContainerFill from "~/components/layout/ContainerFill";
import QuickActionButton from "~/components/page/components/buttons/QuickActionButton";

import { displayIsTablet } from "~/components/utilities/displayBreakpoints";
import mapFlags from "~/plugins/utilities/mapFlags";

export default {
  name: "QuickActions",
  components: {
    ContainerFill,
    QuickActionButton,
  },
  props: {
    partner: { type: String, default: undefined },
    noGutters: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    inverted: { type: Boolean, default: false },
    small: { type: Boolean, default: true },
    actionsCenter: { type: Boolean, default: false },
  },
  computed: {
    ...mapFlags([
      "quickActionsShowEvents",
      "quickActionShowCommunities",
      "quickActionShowNews",
    ]),
    displayIsTablet,
    path() {
      const partner = this.partner || this.$config.partner;

      if (partner === "engage") {
        return "engage";
      }

      return `engage/${partner}`;
    },
    newsLinkChemRxiv() {
      return "https://connect.acspubs.org/chemrxiv";
    },
  },
};
</script>

<style>
.QuickActions a {
  color: inherit;
  text-decoration: none;
}
</style>
