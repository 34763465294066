import administrationDashboardMoreSubmitted from "~/store/graphql/queries/administrationDashboardMoreSubmitted.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadAdminstrationMoreSubmitted = async function (
  { commit, state },
  { params = {}, req, res }
) {
  params.searchPhrase = state.currentAdministrator.submittedItemsSearchPhrase;
  commit("START_LOADING");
  const data = await runGraphQLQuery(
    administrationDashboardMoreSubmitted,
    params,
    this.$axios,
    req,
    res
  );

  const featureToggles = {
    administrationDashboardSiteModerators: this.$unleash.isEnabled(
      "administrationDashboardSiteModerators"
    ),
    showModerationNotes: this.$unleash.isEnabled("showModerationNotes"),
    administrationDashboardAssignCurators: this.$unleash.isEnabled(
      "administrationDashboardAssignCurators"
    ),
  };

  commit("SET_ADMINISTRATION_MORE_SUBMITTED", {
    payload: data,
    featureToggles,
  });
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadAdminstrationMoreSubmitted;
