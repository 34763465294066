const readOnlyFields = [
  "eventGroup",
  "createdAt",
  "updatedAt",
  "bannerAsset",
  "sponsors",
  "url",
  "origin",
  "status",
  "statusLabel",
  "count",
];

export const checkType = (value) => {
  return value === null
    ? "Null"
    : value === undefined
    ? "Undefined"
    : Object.prototype.toString.call(value).slice(8, -1);
};

export const isEmpty = (value) =>
  value === undefined || value === null || value === "" || value === [];
export const isObject = (value) => checkType(value) === "Object";
export const isNumber = (value) => checkType(value) === "Number";
export const isBoolean = (value) => checkType(value) === "Boolean";
export const isString = (value) => checkType(value) === "String";
export const isNull = (value) => checkType(value) === "Null";
export const isArray = (value) => checkType(value) === "Array";
export const isRegExp = (value) => checkType(value) === "RegExp";
export const isFunction = (value) => checkType(value) === "Function";
export const isUndefined = (value) => checkType(value) === "Undefined";

const removePrivateFields = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (key.indexOf("_") === 0 || key.indexOf("#") === 0) {
      delete obj[key];
    } else if (val && typeof val === "object") {
      removePrivateFields(val);
    }
  });

  return obj;
};

const removeReadonlyFields = (obj) => {
  readOnlyFields.forEach((key) => {
    if (obj[key] !== undefined) {
      delete obj[key];
    }
  });
};

const cleanseObject = (obj) => {
  removeReadonlyFields(obj);
  removePrivateFields(obj);

  for (const field in obj) {
    if (isArray(obj[field])) {
      const data = obj[field];

      data.forEach((item) => {
        cleanseObject(item);
      });
    } else if (isObject(obj[field])) {
      const data = obj[field];

      removeReadonlyFields(data);
      removePrivateFields(data);
    }
  }
};

export default function createEventForMutation(input) {
  const output = JSON.parse(JSON.stringify(input));

  cleanseObject(output);

  return output;
}
