import submitToJournalLoad from "~/store/graphql/queries/submitToJournalLoadMore.graphql";
import runGraphQLQuery, {
  lastQuery,
} from "~/store/manage/utilities/runGraphQLQuery";

const submitToJournalLoadMore = async function (
  { commit },
  { params, req, res, saveLastGraphQL }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    submitToJournalLoad,
    params,
    this.$axios,
    req,
    res
  );
  if (saveLastGraphQL) {
    commit("SET_LAST_GRAPHQL", lastQuery());
  }

  if (params.skip === 0) {
    commit("SET_JOURNAL_DETAILS", data);
  } else {
    commit("SET_JOURNAL_DETAILS_MORE", data);
  }

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default submitToJournalLoadMore;
