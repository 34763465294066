import checkIfMatches from "~/store/manage/validation/utilities/checkIfMatches";
import checkNoMatches from "~/store/manage/validation/utilities/checkNoMatches";
import createError from "~/store/manage/validation/utilities/createError";

const checkForRegexErrors = (item, checks, index, type) => {
  const errors = [];

  checks.forEach((check) => {
    let value = item[check.fieldName];

    if (check.fieldData && check.fieldData.length > 0) {
      value = check.fieldData.reduce((result, name) => {
        const data =
          item[name] === null || item[name] === undefined
            ? ""
            : String(item[name]).trim();

        return result + data + "|";
      }, "");
    }

    const positiveRegex = check.positiveRegex;
    const negativeRegex = check.negativeRegex;
    const countRegex = check.countRegex;

    if (positiveRegex) {
      const isFound = checkIfMatches(value, positiveRegex);

      if (isFound === false) {
        errors.push(createError(item, check, index, type));
      }
    }

    if (negativeRegex) {
      const isFound = checkIfMatches(value, negativeRegex);

      if (isFound === true) {
        errors.push(createError(item, check, index, type));
      }
    }

    if (countRegex) {
      const noFound = checkNoMatches(value, countRegex);

      if (check.minCount && noFound < check.minCount) {
        errors.push(createError(item, check, index, type));
      } else if (check.maxCount && noFound > check.maxCount) {
        errors.push(createError(item, check, index, type));
      }
    }
  });

  return errors;
};

export default checkForRegexErrors;
