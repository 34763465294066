<template>
  <LayoutGutters>
    <ContainerArea class="PageFooter no-underline px-1">
      <v-div :float-left="displayIsTablet || displayIsDesktop">
        <PageFooterSponsorOrPartnerMobileView
          v-if="displayIsMobile"
          :partner="partner"
          :partner-logo-alt-text="partnerLogoAltText"
        />
        <v-div v-if="pageFooterActionLinks" :mt-5="displayIsMobile" mb-5>
          <v-div
            v-for="(link, index) in footerActionLinks"
            :key="index"
            :to="link.url"
            foreground-inverted
            mb-2
            pa-0
            text-capitalize
            has-action
            >{{ link.title }}</v-div
          >
          <v-div
            v-if="quickActionShowNews"
            foreground-inverted
            mb-2
            pa-0
            text-capitalize
            has-action
          >
            <a
              href="https://connect.acspubs.org/chemrxiv"
              target="_blank"
              rel="noopener"
            >
              News
              <span class="d-sr-only">[opens in a new tab]</span>
              <v-icon small aria-hidden="true" color="inverted">{{
                mdiOpenInNew
              }}</v-icon>
            </a>
          </v-div>
        </v-div>
        <v-div height-96 mt-5>
          <v-div foreground-inverted mb-2 caption>{{
            technologyProviderText
          }}</v-div>
          <a href="https://www.cambridge.org/" target="_blank" rel="noopener">
            <img
              src="~/static/images/CambridgeUniversityPressWhiteLogo.svg"
              alt="Cambridge University Press home"
              style="height: 45px; width: 204px"
            />
          </a>
        </v-div>
      </v-div>
      <PageFooterSponsorOrPartner
        v-if="displayIsTablet || displayIsDesktop"
        :partner="partner"
        :partner-logo-alt-text="partnerLogoAltText"
      />
    </ContainerArea>
  </LayoutGutters>
</template>

<script>
import { mapState } from "vuex";
import { mdiOpenInNew } from "@mdi/js";
import LayoutGutters from "~/components/layout/LayoutGutters.vue";

import ContainerArea from "~/components/layout/ContainerArea";
import PageFooterSponsorOrPartner from "~/components/page/components/PageFooterSponsorOrPartner.vue";
import PageFooterSponsorOrPartnerMobileView from "~/components/page/components/PageFooterSponsorOrPartnerMobileView.vue";
import mapFlags from "~/plugins/utilities/mapFlags";

import getSiteName from "~/plugins/utilities/getSiteName.js";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "PageFooter",
  components: {
    LayoutGutters,
    ContainerArea,
    PageFooterSponsorOrPartner,
    PageFooterSponsorOrPartnerMobileView,
  },
  data() {
    return {
      // Icons
      mdiOpenInNew,
    };
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    partner() {
      return this.$config.partner;
    },
    ...mapState({
      siteName: (state) => getSiteName(state.theme),
      isInstitution: (state) => state.theme.isInstitution,
    }),
    ...mapFlags([
      "pageFooterActionLinks",
      "pageFooterActionLinksShowEvents",
      "quickActionShowCommunities",
      "quickActionShowNews",
    ]),
    partnerLogoAltText() {
      return `${this.siteName} home`;
    },
    technologyProviderText() {
      return this.partner === "chemrxiv" || this.isInstitution
        ? "Technology Provided By"
        : "Brought To You By";
    },
    footerActionLinks() {
      const actionLinks = [
        {
          url: `/engage/${this.partner}/submission-information`,
          title: "How to submit",
          showLink: true,
        },
        {
          url: `/engage/${this.partner}/browse-dashboard`,
          title: "Browse",
          showLink: !this.isInstitution,
        },
        {
          url: `/engage/${this.partner}/departments-dashboard`,
          title: "Departments",
          showLink: this.isInstitution,
        },
        {
          url: `/engage/${this.partner}/events-dashboard`,
          title: "Events",
          showLink: this.pageFooterActionLinksShowEvents,
        },
        {
          url: `/engage/${this.partner}/communities-dashboard`,
          title: "Communities",
          showLink: this.quickActionShowCommunities,
        },
        {
          url: `/engage/${this.partner}/about-information`,
          title: "About us",
          showLink: true,
        },
      ];

      return actionLinks.filter((link) => link.showLink);
    },
  },
};
</script>

<style>
.PageFooter img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center left;
  box-sizing: border-box;
  cursor: pointer;
}

.PageFooter img.partnerLogo {
  max-width: 250px;
}

.PageFooter img.center-right {
  object-position: center right;
}

.PageFooter img.logo-placeholder--small {
  object-position: 0 0;
}

.PageFooter img.logo-placeholder--large {
  object-position: 100% 0;
}

.PageFooter a:link {
  height: 100%;
  display: flex;
}

.PageFooter .sponsorBanner {
  padding: 10px;
  background: white;
  height: auto;
}
</style>
