import administrationDashboardMoreDeleted from "~/store/graphql/queries/administrationDashboardMoreDeleted.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadAdminstrationMoreDeleted = async function (
  { commit },
  { params, req, res }
) {
  const data = await runGraphQLQuery(
    administrationDashboardMoreDeleted,
    params,
    this.$axios,
    req,
    res
  );

  const featureToggles = {
    administrationDashboardSiteModerators: this.$unleash.isEnabled(
      "administrationDashboardSiteModerators"
    ),
    showModerationNotes: this.$unleash.isEnabled("showModerationNotes"),
    administrationDashboardAssignCurators: this.$unleash.isEnabled(
      "administrationDashboardAssignCurators"
    ),
  };

  commit("SET_ADMINISTRATION_MORE_DELETED", { payload: data, featureToggles });

  return Promise.resolve(data);
};

export default loadAdminstrationMoreDeleted;
