import emailValidator from "email-validator";
import eventApprovedSpeakersEmailChecks from "./checks/eventApprovedSpeakersEmailChecks";
import mandatoryEventChecks from "~/store/manage/validation/checks/mandatoryEventChecks";
import mandatoryEventSponsorChecks from "~/store/manage/validation/checks/mandatoryEventSponsorChecks";
import regexEventSponsorChecks from "~/store/manage/validation/checks/regexEventSponsorChecks";
import eventModeratorsEmailChecks from "~/store/manage/validation/checks/eventModeratorsEmailChecks.js";
import eventModeratorsAccountChecks from "~/store/manage/validation/checks/eventModeratorsAccountChecks.js";

import checkForMissingErrors from "~/store/manage/validation/utilities/checkForMissingErrors";
import checkForRegexErrors from "~/store/manage/validation/utilities/checkForRegexErrors";
import createError from "~/store/manage/validation/utilities/createError";
import { uniqueBy } from "~/store/manage/utilities/unique";

const shouldIncludeEmailError = (error, currentProposals) =>
  error.email && currentProposals.includes(error.email);

export default function checkProposalForErrors(
  proposal,
  serverValidation,
  existingErrors
) {
  if (!proposal) {
    return [];
  }

  const errors = existingErrors.filter((error) =>
    shouldIncludeEmailError(error, proposal.admins)
  );

  const mandatoryEventErrors = checkForMissingErrors(
    proposal,
    mandatoryEventChecks
  );
  errors.push(...mandatoryEventErrors);

  proposal.sponsors.forEach((sponsor, index) => {
    const mandatoryEventSponsorErrors = checkForMissingErrors(
      sponsor,
      mandatoryEventSponsorChecks,
      index,
      "sponsor"
    );
    errors.push(...mandatoryEventSponsorErrors);

    if (sponsor.url) {
      const regexWebLinkErrors = checkForRegexErrors(
        sponsor,
        regexEventSponsorChecks,
        index,
        "sponsor"
      );
      errors.push(...regexWebLinkErrors);
    }
  });

  proposal.admins.forEach((admin) => {
    const isValidEmail = emailValidator.validate(admin);
    if (!isValidEmail) {
      const errorMessage = eventModeratorsEmailChecks[0].errorMessage + admin;
      errors.push(
        createError(proposal, {
          ...eventModeratorsEmailChecks[0],
          errorMessage,
          email: admin,
        })
      );
    }
  });

  const allSpeakerLength = proposal.approvedSpeakers.join("").length;
  if (allSpeakerLength > 200000) {
    errors.push(createError(proposal, eventApprovedSpeakersEmailChecks[1]));
  }

  if (serverValidation) {
    for (const serverError of serverValidation) {
      const errorMessage =
        eventModeratorsAccountChecks[0].errorMessage + serverError.value;
      errors.push(
        createError(proposal, {
          ...eventModeratorsAccountChecks[0],
          errorMessage,
          email: serverError.value,
        })
      );
    }
  }

  return uniqueBy(errors, (error) => error.errorMessage);
}
