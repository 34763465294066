import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

const FOUR_SECONDS = 4000;

const removeForRejectedArticle = async function removeForRejectedArticle() {
  try {
    /// TO BE REPLACED WHEN NEW MUTATION IS AVAILABLE
    await this.$store.dispatch("updateSubmissionStatus", {
      action: "DELETE",
      params: {
        id: this.item.id,
      },
    });

    this.$store.dispatch("loadAuthorDashboard", {});
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }

  this.$root.$emit("change-view", { view: "author-dashboard" });

  setTimeout(() => {
    this.$store.dispatch("loadAuthorDashboard", {});
  }, FOUR_SECONDS);
};

export default removeForRejectedArticle;
