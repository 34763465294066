import createError from "~/store/manage/validation/utilities/createError";
import eventModeratorsAccountChecks from "~/store/manage/validation/checks/eventModeratorsAccountChecks.js";

const UPDATE_EVENT_MODERATOR_VALIDATION = (state, payload) => {
  const proposal = state.proposedEvent;
  const errors = state.proposedErrors;
  const adminValidationErrors = [];
  for (const nonExistingAdmin of payload.filter((a) => !a.exists)) {
    const errorMessage =
      eventModeratorsAccountChecks[0].errorMessage + nonExistingAdmin.email;
    adminValidationErrors.push(
      createError(proposal, {
        ...eventModeratorsAccountChecks[0],
        errorMessage,
        email: nonExistingAdmin.email,
      })
    );
  }
  state.proposedErrors = [...errors, ...adminValidationErrors];
};

export default UPDATE_EVENT_MODERATOR_VALIDATION;
