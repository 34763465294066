export default function sorted(values, key) {
  if (Array.isArray(values) && typeof key === "string") {
    if (values.length === 0) {
      return values;
    } else if (values[0][key] === undefined || values[0][key] === null) {
      return values;
    }

    return values.slice().sort((a, b) => {
      const A = a[key];
      const B = b[key];

      if (A !== undefined && B !== undefined) {
        if (A < B) return -1;
        if (A > B) return 1;
        return 0;
      }
      return 0;
    });
  }
}
