import mandatoryRequestChecks from "~/store/manage/validation/checks/mandatoryRequestChecks";
import acceptanceRequestChecks from "~/store/manage/validation/checks/acceptanceRequestChecks";

import mandatoryResponseChecks from "~/store/manage/validation/checks/mandatoryResponseChecks";

import checkForMissingErrors from "~/store/manage/validation/utilities/checkForMissingErrors";
import checkForAcceptanceErrors from "~/store/manage/validation/utilities/checkForAcceptanceErrors";

export default function checkRetractionForErrors(item) {
  const retraction = item?.retraction;

  if (!retraction) {
    return [];
  }

  const errors = [];

  const mandatoryRequestErrors = checkForMissingErrors(
    retraction.request,
    mandatoryRequestChecks
  );
  const acceptanceRequestErrors = checkForAcceptanceErrors(
    retraction.request,
    acceptanceRequestChecks
  );

  const mandatoryReasonErrors = checkForMissingErrors(
    retraction.response,
    mandatoryResponseChecks
  );

  errors.push(...mandatoryRequestErrors);
  errors.push(...acceptanceRequestErrors);
  errors.push(...mandatoryReasonErrors);

  return errors;
}
