const handleCategoryUpdate = (payload, submissionItem) => {
  const mainCategoryId = (field) => {
    switch (field) {
      case "majorCategoryIds":
        return submissionItem.majorCategoryIds[0];
      case "categoryId":
        return submissionItem.categoryId;
    }
  };

  submissionItem.mainCategoryId = mainCategoryId(payload.field);
};

const UPDATE_SUBMISSION = (state, payload) => {
  const submissionItem = state.submissionItem;

  if (
    payload.field === "majorCategoryIds" ||
    payload.field === "subcategoryIds"
  ) {
    const itemIndex = submissionItem[payload.field].findIndex((id) => {
      return id === payload.value.id || id === payload.value;
    });

    submissionItem[payload.field] =
      itemIndex < 0
        ? [...submissionItem[payload.field], payload.value.id]
        : [
            ...submissionItem[payload.field].slice(0, itemIndex),
            ...submissionItem[payload.field].slice(itemIndex + 1),
          ];
  } else {
    submissionItem[payload.field] = payload.value;
  }

  handleCategoryUpdate(payload, submissionItem);
};

export default UPDATE_SUBMISSION;
