import checkIfNotEqual from "~/store/manage/validation/utilities/checkIfNotEqual";
import createError from "~/store/manage/validation/utilities/createError";

const checkForValueErrors = (item, checks, index, type) => {
  const errors = [];

  checks.forEach((check) => {
    const value = item[check.fieldName];
    const match = check.fieldValue;

    const isNotEqual = checkIfNotEqual(value, match);

    if (isNotEqual) {
      errors.push(createError(item, check, index, type));
    }
  });

  return errors;
};

export default checkForValueErrors;
