const mandatoryWebLinkChecks = [
  {
    severity: "ERROR",
    type: "WEBLINK",
    fieldName: "url",
    errorMessage: "URL missing",
    resolutionMessage: "Please add a URL, or remove item",
  },
  {
    severity: "ERROR",
    type: "WEBLINK",
    fieldName: "title",
    errorMessage: "Title missing",
    resolutionMessage: "Please enter a title",
  },
  {
    severity: "ERROR",
    type: "WEBLINK",
    fieldName: "description",
    errorMessage: "Description missing",
    resolutionMessage: "Please enter a description",
  },
];

export default mandatoryWebLinkChecks;
