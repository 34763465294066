<template>
  <div class="height-64 max-width-240 float-right">
    <a v-if="sponsor" :href="sponsor.url" target="_blank">
      <img
        :src="sponsor.logo"
        :alt="`${sponsor.name} [opens in a new tab]`"
        class="sponsorBanner"
      />
    </a>
    <nuxt-link v-else :to="`/engage/${partner}/public-dashboard`">
      <img
        class="center-right"
        :alt="partnerLogoAltText"
        :src="theme ? theme.images.whiteLogo : undefined"
      />
    </nuxt-link>
    <span v-if="ISSN" class="softened--text">{{ ISSN }}</span>
    <nuxt-link
      :to="`/engage/${partner}/archive-dashboard`"
      class="mb-2 pt-5 inverted--text"
      >{{ siteName }} Archive</nuxt-link
    >
  </div>
</template>

<script>
import { mapState } from "vuex";
import getSiteName from "~/plugins/utilities/getSiteName.js";

export default {
  name: "PageFooterSponsorOrPartner",
  props: {
    partner: { type: String, required: true },
    partnerLogoAltText: { type: String, required: true },
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      siteName: (state) => getSiteName(state.theme),
      sponsor: (state) => state.theme.sponsor,
      ISSN: (state) => state.theme.information?.ISSN,
    }),
  },
};
</script>
