const SET_EVENTS_DASHBOARD = (state, payload) => {
  state.currentOrigin.eventSummaries = payload.origin.eventSummaries.results;
  state.currentOrigin.latestEventArticles = payload.latestContent.results.map(
    (i) => i.item
  );

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_EVENTS_DASHBOARD;
