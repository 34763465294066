import { isRootCategory } from "~/plugins/utilities/category";

const SET_SEARCH_DASHBOARD = (state, { payload, featureToggles }) => {
  state.referenceData.subjectTypes = payload.subjectTypes;
  state.referenceData.contentTypes = payload.contentTypes;
  state.referenceData.categoryTypes = payload.categoryTypes;

  state.searchResult.totalCount = payload.searchItems.totalCount;
  state.searchResult.results = payload.searchItems.results;

  state.searchResult.text = state.searchRequest.text;
  state.searchResult.sortBy = state.searchRequest.sortBy;

  state.searchResult.subjectBuckets = payload.searchItems.subjectBuckets;
  state.searchResult.categoryBuckets = payload.searchItems.categoryBuckets;
  state.searchResult.partnerBuckets = payload.searchItems.partnerBuckets;
  state.searchResult.contentBuckets = payload.searchItems.contentBuckets;
  state.searchResult.eventBuckets = payload.searchItems.eventBuckets;
  state.searchResult.publishedDateBuckets =
    payload.searchItems.publishedDateBuckets;

  if (
    featureToggles.multipleCategoriesSubmission &&
    featureToggles.showSubcategorySearchFacets
  ) {
    state.searchResult.categoryBuckets = payload.searchItems.categoryBuckets;
  } else {
    const majorCategoryIds = new Set(
      state.referenceData.categoryTypes
        .filter(isRootCategory)
        .map((category) => category.id)
    );
    state.searchResult.categoryBuckets =
      payload.searchItems.categoryBuckets.filter((bucket) =>
        majorCategoryIds.has(bucket.key)
      );
  }

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_SEARCH_DASHBOARD;
