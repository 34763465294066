import administrationDashboardMoreReportedDiscussionReplies from "~/store/graphql/queries/administrationDashboardMoreReportedDiscussionReplies.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadAdministrationMoreReportedDiscussionReplies = async function (
  { commit },
  { params, req, res }
) {
  const data = await runGraphQLQuery(
    administrationDashboardMoreReportedDiscussionReplies,
    params,
    this.$axios,
    req,
    res
  );
  commit("SET_ADMINISTRATION_MORE_REPORTED_DISCUSSION_REPLIES", data);

  return Promise.resolve(data);
};

export default loadAdministrationMoreReportedDiscussionReplies;
