import { userUpdateMarketingEmailPreference } from "~/store/graphql/mutations/userUpdateMarketingEmailPreference.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const updateMarketingPreferences = async function (
  { commit },
  receiveMarketingEmails
) {
  const data = await runGraphQLQuery(
    userUpdateMarketingEmailPreference,
    {
      receiveMarketingEmails,
    },
    this.$axios
  );

  if (data) {
    commit("UPDATE_USER", {
      field: "receiveMarketingEmails",
      value: data.userUpdateMarketingPreferences.receiveMarketingEmails,
    });
  }

  return await Promise.resolve(data);
};

export default updateMarketingPreferences;
