import { set } from "vue";

import {
  hasParentCategory,
  isRootCategory,
} from "~/plugins/utilities/category";

const UPDATE_ITEM = (state, { payload, featureToggles }) => {
  const match = payload.itemSubmit || payload.itemUpdate || {};
  const matchId = match.id;
  const hasItems = !!(state.userItems && state.userItems.length > 0);
  const userItemIndex =
    hasItems === true
      ? state.userItems.findIndex((item) => item.id === matchId)
      : -1;
  const hasSubmission = !!(
    state.submissionItem && state.submissionItem.id === matchId
  );
  const submission = hasSubmission ? state.submissionItem : undefined;

  if (userItemIndex > -1) {
    set(state.userItems, userItemIndex, match);
  }

  if (submission) {
    if (featureToggles.multipleCategoriesSubmission) {
      const submittedCategories = state.referenceData.categoryTypes
        .filter(({ id }) => match.categoryIds.includes(id))
        .sort(
          ({ id: a }, { id: b }) =>
            match.categoryIds.indexOf(a) - match.categoryIds.indexOf(b)
        );

      match.subcategoryIds = submittedCategories
        .filter(hasParentCategory)
        .map((category) => category.id);
      match.majorCategoryIds = submittedCategories
        .filter(isRootCategory)
        .map((category) => category.id);
      match.mainCategoryId = match.majorCategoryIds[0];
    } else {
      match.categoryId = match.categoryIds[0];
    }

    delete match.categoryIds;

    state.submissionItem = match;
  }
};

export default UPDATE_ITEM;
