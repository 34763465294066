import communityCollectionsListingLoad from "~/store/graphql/queries/communityCollectionsListingLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadAllCommunityCollectionListing = async function (
  { commit },
  { params, req, res }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    communityCollectionsListingLoad,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_COMMUNITY_COLLECTIONS", data);

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadAllCommunityCollectionListing;
