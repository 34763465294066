<template>
  <v-expand-transition>
    <v-alert
      :value="showCookieMessage"
      dismissible
      color="muted"
      type="info"
      class="cookieMessage mb-0"
      role="region"
      aria-label="cookie information"
      close-label="close cookie information"
      transition="scale-transition"
      @input="onAcceptCookies"
    >
      <div>
        <span>{{ COOKIE_ACCEPTANCE.title }}</span>
        <span>{{ COOKIE_ACCEPTANCE.message }}</span>
        <a
          v-if="COOKIE_ACCEPTANCE.url"
          :href="COOKIE_ACCEPTANCE.url"
          target="_blank"
        >
          Learn more about our Privacy Notice...
          <span class="d-sr-only">[opens in a new tab]</span>
        </a>
      </div>
    </v-alert>
  </v-expand-transition>
</template>

<script>
import { mapState } from "vuex";
import { extractTitleMessage } from "~/components/utilities/extractTitleMessage";

export default {
  data() {
    return {
      showCookieMessage: false,
    };
  },
  computed: {
    ...mapState({
      COOKIE_ACCEPTANCE: (state) =>
        extractTitleMessage(state.theme.information, "COOKIE_ACCEPTANCE"),
    }),
  },
  mounted() {
    const storedCookieMessage = this.$localStorage.get("showCookieMessage");
    this.showCookieMessage = storedCookieMessage !== false;
  },
  methods: {
    onAcceptCookies() {
      this.$localStorage.set("showCookieMessage", false);
    },
  },
};
</script>

<style scoped>
.v-alert.cookieMessage {
  width: 100%;
  top: 0;
  left: 0;
  margin-top: 0;
  border-radius: 0;
}

.cookieMessage a {
  color: white;
  border-radius: 0;
}
</style>
