<template>
  <ContainerScrolling class="ContentRemovedPage">
    <LayoutFill :no-gutters="!bannerGutter" :no-padding="!bannerGutter">
      <MainBanner :title="pageTitle" :solid-header="solidHeader" />
    </LayoutFill>
    <LayoutGutters>
      <v-div text-center>
        <v-div
          >This content has been intentionally removed or has its access
          disabled.</v-div
        >
        <v-div class="mt-4">
          <strong>Reason:</strong>
          {{ error.message }}
        </v-div>
        <v-btn
          class="mt-8"
          rounded
          large
          outlined
          aria-label="return to public dashboard"
          @click="onGoto(`public-dashboard`)"
          >Return To Homepage</v-btn
        >
      </v-div>
    </LayoutGutters>
  </ContainerScrolling>
</template>

<script>
import { mapState } from "vuex";

import ContainerScrolling from "~/components/layout/ContainerScrolling.vue";
import LayoutGutters from "~/components/layout/LayoutGutters";
import LayoutFill from "~/components/layout/LayoutFill";

import MainBanner from "~/components/banner/MainBanner";

import getPageTitle from "~/plugins/utilities/getPageTitle.js";
import getSiteName from "~/plugins/utilities/getSiteName.js";

export default {
  name: "ContentRemovedPage",
  components: {
    ContainerScrolling,
    LayoutGutters,
    LayoutFill,
    MainBanner,
  },
  middleware: ["ensureUserLoaded"],
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      pageTitle: "Page no longer available",
    };
  },
  head() {
    const { siteName } = this;

    const title = getPageTitle(siteName);
    const pageTitle = "Error | " + title;

    return {
      title: pageTitle,

      meta: [
        {
          hid: "description",
          name: "description",
          content: `Page no longer available`,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      siteName: (state) => getSiteName(state.theme),
      layout: (state) => state.theme.layout,
    }),
    partner() {
      return this.$config.partner;
    },
    solidHeader() {
      return this.layout.mainBannerSolidHeader;
    },
    bannerGutter() {
      return this.layout.staticBannerGuttering;
    },
  },
  watchQuery: ["show"],
  methods: {
    onGoto(location) {
      this.$router.push({
        path: `/engage/${this.partner}/${location}`,
      });
    },
  },
};
</script>
