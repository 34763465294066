<template>
  <v-menu
    class="UserMenu"
    role
    attach
    bottom
    left
    nudge-left="0"
    nudge-bottom="42"
    :dark="darkStyling"
    :max-height="maxMenuHeight"
    :content-class="
      displayIsDesktop
        ? 'userMenuDesktop'
        : displayIsTablet
        ? 'userMenuTablet'
        : 'userMenuTablet'
    "
  >
    <!-- HACK styles for IE11 to display correctly  -->
    <template #activator="activator">
      <div ref="menuButtonContainer">
        <v-btn
          id="menubutton"
          aria-haspopup="true"
          aria-controls="menu"
          :aria-label="displayIsMobile ? 'Menu' : 'My Account Menu'"
          rounded
          icon
          outlined
          height="36"
          width="36"
          style="vertical-align: top"
          :color="inverted ? 'inverted' : undefined"
          v-bind="activator.attrs"
          data-test-id="myAccount"
          v-on="activator.on"
          @click="setMaxMenuHeight"
        >
          <v-icon aria-hidden="true">{{ mdiAccount }}</v-icon>
        </v-btn>
      </div>
    </template>
    <v-div class="userMenuContainer">
      <ul
        id="menu"
        role="menu"
        aria-labelledby="menubutton"
        class="v-list v-sheet no-bullet"
        :class="colourThemeClass"
      >
        <li role="none">
          <v-list-item>
            <v-col class="mx-0 px-0">
              <v-list-item-title>
                <strong>Logged in as {{ user | displayName }}</strong>
              </v-list-item-title>
            </v-col>
          </v-list-item>
        </li>

        <li v-if="orcidUser" role="none">
          <v-list-item
            v-if="orcidUser"
            :aria-label="`my ORCID I.D. ${user.orcid} [opens in a new tab]`"
            :href="orcidUrl"
            target="_blank"
          >
            <v-col class="mx-0 px-0 py-0">
              <img :src="orcidLogo" aria-hidden="true" alt="orcid logo" />
              <span class="ml-2">{{ user.orcid }}</span>
            </v-col>
          </v-list-item>
        </li>

        <template v-if="displayIsMobile || displayIsTablet">
          <li role="none">
            <v-divider aria-hidden="true" />
            <v-list-item
              v-if="isUser"
              data-test-id="myContent"
              @click="onGoto(`author-dashboard`)"
            >
              <v-list-item-title>{{ authorDashboardLabel }}</v-list-item-title>
            </v-list-item>
          </li>
          <li role="none">
            <v-list-item
              v-if="isAdministrator"
              data-test-id="adminDashboard"
              @click="onGoto(`administration-dashboard`)"
            >
              <v-list-item-title>{{ adminDashboardLabel }}</v-list-item-title>
            </v-list-item>
          </li>
          <li role="none">
            <v-divider aria-hidden="true" />
            <v-list-item :to="`/engage/${partner}/search-dashboard`">
              <v-list-item-title>Search</v-list-item-title>
            </v-list-item>
          </li>
          <li role="none">
            <v-divider aria-hidden="true" />
            <v-list-item @click="onGoto(`submission-information`)">
              <v-list-item-title>How to Submit</v-list-item-title>
            </v-list-item>
          </li>
          <li role="none">
            <v-list-item @click="onGoto(`browse-dashboard`)">
              <v-list-item-title>Browse</v-list-item-title>
            </v-list-item>
          </li>
          <li role="none">
            <v-list-item
              v-if="quickActionsShowEvents"
              @click="onGoto(`events-dashboard`)"
            >
              <v-list-item-title>Events</v-list-item-title>
            </v-list-item>
          </li>
          <li v-if="quickActionShowCommunities" role="none">
            <v-list-item @click="onGoto(`communities-dashboard`)">
              <v-list-item-title>Communities</v-list-item-title>
            </v-list-item>
          </li>
          <li role="none">
            <v-list-item @click="onGoto(`about-information`)">
              <v-list-item-title>About</v-list-item-title>
            </v-list-item>
          </li>
          <li v-if="quickActionShowNews" role="none">
            <v-list-item
              href="https://connect.acspubs.org/chemrxiv"
              target="_blank"
            >
              <v-list-item-title>
                News
                <span class="d-sr-only">[opens in a new tab]</span>
                <v-icon small color="primary" aria-hidden="true">{{
                  mdiOpenInNew
                }}</v-icon>
              </v-list-item-title>
            </v-list-item>
          </li>
          <li role="none">
            <v-list-item data-test-id="logOut" @click="onLogout">
              <v-list-item-title>
                <v-btn
                  :outlined="!buttonOutlined && displayIsDesktop"
                  :rounded="buttonRounded"
                  color="primary"
                  style="width: 100%"
                  >Logout</v-btn
                >
              </v-list-item-title>
            </v-list-item>
          </li>
        </template>

        <template v-else>
          <li v-if="isUser" role="none">
            <v-list-item
              data-test-id="myContent"
              @click="onGoto(`author-dashboard`)"
            >
              <v-list-item-title>
                <v-btn
                  :rounded="buttonRounded"
                  :outlined="buttonOutlined"
                  color="accent"
                  style="width: 100%"
                >
                  <span>{{ authorDashboardLabel }}</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </li>
          <li v-if="isAdministrator" role="none">
            <v-list-item
              data-test-id="adminDashboard"
              @click="onGoto(`administration-dashboard`)"
            >
              <v-list-item-title>
                <v-btn
                  :rounded="buttonRounded"
                  :outlined="buttonOutlined"
                  color="accent"
                  style="width: 100%"
                >
                  <span>{{ adminDashboardLabel }}</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </li>
          <li role="none">
            <v-list-item data-test-id="logOut" @click="onLogout">
              <v-list-item-title>
                <v-btn
                  :outlined="!buttonOutlined && displayIsDesktop"
                  :rounded="buttonRounded"
                  color="primary"
                  style="width: 100%"
                  >Logout</v-btn
                >
              </v-list-item-title>
            </v-list-item>
          </li>
        </template>
      </ul>
    </v-div>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import { mdiAccount, mdiOpenInNew } from "@mdi/js";
import mapFlags from "~/plugins/utilities/mapFlags";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

import isAuthorized from "~/plugins/utilities/isAuthorized";
import { hasModerationPrivilege } from "~/plugins/utilities/checkUserRoles";

export default {
  name: "UserMenu",
  props: {
    inverted: { type: Boolean, default: false },
    partner: { type: String, required: true },
  },
  data() {
    return {
      maxMenuHeight: 0,
      // Icons
      mdiAccount,
      mdiOpenInNew,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      storageName: (state) => state.theme.storageName,
      layout: (state) => state.theme.layout,
      theme: (state) => state.theme,
    }),
    ...mapFlags([
      "quickActionsShowEvents",
      "quickActionShowCommunities",
      "quickActionShowNews",
    ]),
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    isUser() {
      return isAuthorized(this.user, "USER");
    },
    isAdministrator() {
      return hasModerationPrivilege(this.user);
    },
    orcidUser() {
      return this.user?.accountType?.toUpperCase() === "ORCID";
    },
    darkStyling() {
      return this.displayIsMobile && this.layout.anonymousMenuDarkStyling;
    },
    colourThemeClass() {
      return this.darkStyling ? "theme--dark" : "theme--light";
    },
    focusedStyle() {
      return this.layout.loginButtonFocusedStyle;
    },
    buttonRounded() {
      return !this.focusedStyle;
    },
    buttonOutlined() {
      return !this.focusedStyle;
    },
    orcidLogo() {
      return this.theme.images.orcidLogo;
    },
    orcidUrl() {
      return `https://orcid.org/${this.user.orcid}`;
    },
    authorDashboardLabel() {
      return `My ${this.storageName} Content`;
    },
    adminDashboardLabel() {
      return `${this.storageName} Admin Dashboard`;
    },
  },
  methods: {
    setMaxMenuHeight() {
      const { top: menuTop, height: menuHeight } =
        this.$refs.menuButtonContainer.getBoundingClientRect();
      const paddingBottom = 20;

      this.maxMenuHeight =
        window.innerHeight - menuHeight - menuTop - paddingBottom;
    },
    onLogout() {
      this.$store.dispatch("logout", this.user?.accountType === "ORCID");
    },
    onGoto(location) {
      if (this.partner === this.$config.partner) {
        this.$router.push({
          path: `/engage/${this.partner}/${location}`,
        });
      } else {
        window.document.location.href = `/engage/${this.partner}/${location}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.UserMenu {
  z-index: 8 !important;
}

.v-menu__content {
  z-index: 24 !important;
}

.userMenuDesktop {
  width: 350px;
  margin-top: 13px;
  contain: initial;
  overflow: visible;
}
.userMenuDesktop::before {
  position: absolute;
  content: "";
  top: 0;
  right: 10px;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}
.userMenuTablet {
  min-width: 200px;
  overflow-y: scroll;
  border-radius: 4px;
}

.userMenuContainer {
  border-radius: 5px;
}
</style>
