import { UnleashClient } from "unleash-proxy-client";

export default async (context, inject) => {
  const unleash = new UnleashClient({
    url: context.$config.UNLEASH_PROXY_URL,
    clientKey: context.$config.UNLEASH_PROXY_CLIENT_KEY,
    appName: "engage-client",
    environment: context.$config.DEPLOY_ENV,
    storageProvider: {
      save: (name, data) => context.store.commit("SET_UNLEASH", { name, data }),
      get: (name) => context.store.state.unleash[name],
    },
    context: {
      origin: context.$config.partner,
    },
  });

  const unleashInitialized = new Promise((resolve, reject) => {
    unleash.once("initialized", resolve);
    unleash.once("error", reject);
  });

  unleash.start();

  await unleashInitialized;

  inject("unleash", unleash);
};
