const SET_LATEST_CONTENT = (state, payload) => {
  state.currentOrigin.latestItems = payload.latestItems.results.map(
    (hit) => hit.item
  );

  state.selectedCategory = payload.category;
  state.selectedSubject = payload.subject;

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_LATEST_CONTENT;
