import checkProposalForErrors from "~/store/manage/validation/checkProposalForErrors";

const CLEAR_EVENT_SPONSOR_ASSET = (state, { eventSponsorId }) => {
  if (state.proposedEvent) {
    const sponsor = state.proposedEvent.sponsors.filter(
      (sponsor) => sponsor.id === eventSponsorId
    );
    sponsor.asset = {};
    state.proposedErrors = checkProposalForErrors(
      state.proposedEvent,
      null,
      state.proposedErrors
    );
  }
};

export default CLEAR_EVENT_SPONSOR_ASSET;
