import validateAccounts from "~/store/graphql/queries/validateAccounts.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

export default async function validateSiteModeratorAccounts(
  { commit },
  emails
) {
  const data = await runGraphQLQuery(validateAccounts, { emails }, this.$axios);
  if (data) {
    commit("UPDATE_SITE_MODERATOR_VALIDATION", data.accountExistsVerification);

    return Promise.resolve(data);
  }
}
