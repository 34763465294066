import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import commentReport from "~/store/graphql/mutations/commentReport.graphql";

const reportComment = async function (
  _,
  { commentId, reasonId, captchaResponse }
) {
  const data = await runGraphQLQuery(
    commentReport,
    { commentId, reasonId, captchaResponse },
    this.$axios
  );

  return Promise.resolve(data);
};

export default reportComment;
