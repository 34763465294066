import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import getRelatedContentItems from "~/store/graphql/queries/getRelatedContentItems.graphql";

const modifySubmissionQuestion = async function (
  { commit, dispatch },
  { req, res, value }
) {
  commit("UPDATE_SUBMISSION_QUESTION", { value });
  dispatch("validateSubmission");

  if (!value) {
    return;
  }

  const data = await runGraphQLQuery(
    getRelatedContentItems,
    { questionId: value.id },
    this.$axios,
    req,
    res
  );
  commit("SET_RELATED_CONTENT", data);
};

export default modifySubmissionQuestion;
