export const formatCSSSize = (input) => {
  if (/^-?\d+$/.test(input)) {
    return input + "px";
  } else if (/^-?\d+\.\d+$/.test(input)) {
    return input + "%";
  } else if (/[0-9%a-z]\s[+-]\s[0-9]/.test(input)) {
    return "calc(" + input + ")";
  }

  return input;
};
