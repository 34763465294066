import submissionsReportPartLoad from "~/store/graphql/queries/submissionsReportPartLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const generateSubmissionsReport = async function (
  { commit, state },
  { req, res }
) {
  commit("START_SUBMISSION_REPORT_FETCHING");
  const { startDate, endDate } = state.currentAdministrator.submissionsReport;

  do {
    const skip = state.currentAdministrator.submissionsReport.items.length;
    const data = await runGraphQLQuery(
      submissionsReportPartLoad,
      { startDate, endDate, skip },
      this.$axios,
      req,
      res
    );
    const featureToggles = {
      submissionReportShowEvent: this.$unleash.isEnabled(
        "submissionReportShowEvent"
      ),
      submissionReportShowCommunity: this.$unleash.isEnabled(
        "submissionReportShowCommunity"
      ),
    };
    commit("SET_SUBMISSIONS_REPORT_PART", {
      payload: data.origin.mainItems,
      featureToggles,
    });
  } while (
    state.currentAdministrator.submissionsReport.items.length <
    state.currentAdministrator.submissionsReport.totalCount
  );

  commit("END_SUBMISSION_REPORT_FETCHING");
};

export default generateSubmissionsReport;
