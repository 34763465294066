import emailValidator from "email-validator";
import createError from "~/store/manage/validation/utilities/createError";
import validEmailsCheck from "~/store/manage/validation/checks/eventApprovedSpeakersEmailChecks";

const MODIFY_EVENT_APPROVED_SPEAKERS = (state, payload) => {
  const proposedEvent = state.proposedEvent;
  const { field, value } = payload;

  const emails = value
    .replace(/[,;]/gi, "\n") // support newlines, comma, semicolon separators
    .replace(/"(.*@.*)"/gi, "$1") // "jdoe@test.com" => jdoe@test.com
    .replace(/.*<(.*@.*)>/gi, "$1") // John Doe <jdoe@test.com> => jdoe@test.com
    .split("\n");

  const validatedEmails = emails
    .map((e) => e.trim())
    .map((e) => ({ email: e, isValid: emailValidator.validate(e) }))
    .filter((e) => e.email.length > 0);
  const validEmails = validatedEmails
    .filter((e) => e.isValid)
    .map((e) => e.email);
  const invalidEmails = validatedEmails
    .filter((e) => !e.isValid)
    .map((e) => e.email);
  const emailsLength = validEmails.join("").length;

  proposedEvent[field] = validEmails;

  const errors = [...state.proposedErrors].filter((e) => e.fieldName !== field);

  if (invalidEmails.length > 0) {
    const errorMessage =
      validEmailsCheck[0].errorMessage + invalidEmails.join(", ");
    errors.push(
      createError(proposedEvent, { ...validEmailsCheck[0], errorMessage })
    );
  } else if (emailsLength > 200000) {
    errors.push(createError(proposedEvent, validEmailsCheck[1]));
  }

  state.proposedErrors = errors;
};

export default MODIFY_EVENT_APPROVED_SPEAKERS;
