import { eventGroupCreate } from "~/store/graphql/mutations/eventGroupCreate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const createEventGroup = async function ({ state, commit }, newEventName) {
  const data = await runGraphQLQuery(
    eventGroupCreate,
    { eventGroup: { name: newEventName } },
    this.$axios
  );
  const event = data.eventGroupCreate;
  commit("CREATE_EVENT_GROUP", event);
  commit("ADD_EVENT_TO_REFERENCE_DATA", event);
  return Promise.resolve(event);
};

export default createEventGroup;
