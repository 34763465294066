<template>
  <div class="mb-5">
    <div class="height-64">
      <a v-if="sponsor" :href="sponsor.url" target="_blank">
        <img
          :src="sponsor.logo"
          :alt="`${sponsor.name} [opens in a new tab]`"
          class="sponsorBanner"
        />
      </a>
      <img
        v-else
        :src="theme ? theme.images.whiteLogo : undefined"
        :alt="partnerLogoAltText"
        class="partnerLogo"
        :to="`/engage/${partner}/public-dashboard`"
      />
    </div>
    <span v-if="ISSN" class="softened--text">{{ ISSN }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PageFooterSponsorOrPartnerMobileView",
  props: {
    partner: { type: String, required: true },
    partnerLogoAltText: { type: String, required: true },
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      sponsor: (state) => state.theme.sponsor,
      ISSN: (state) => state.theme.information?.ISSN,
    }),
  },
};
</script>
