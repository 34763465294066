const REPLACE = {
  ABSTRACT_VIEW_COUNT: "VIEWS",
  MAIN_ITEM_DOWNLOAD_COUNT: "DOWNLOADS",
  CITATION_COUNT: "CITATIONS",
};

export default function extractMetricsName(value) {
  let source = value;

  if (REPLACE[source]) {
    source = REPLACE[source];
  }

  let result = source.replace(/_/g, " ").toLowerCase();

  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
}
