import createError from "~/store/manage/validation/utilities/createError";
import siteModeratorsAccountChecks from "~/store/manage/validation/checks/siteModeratorsAccountChecks.js";

export default function UPDATE_SITE_MODERATOR_VALIDATION(state, payload) {
  const errors = state.proposedSiteModeratorsErrors || [];
  const validationErrors = [];
  for (const nonExistingAdmin of payload.filter((a) => !a.exists)) {
    const errorMessage =
      siteModeratorsAccountChecks[0].errorMessage + nonExistingAdmin.email;
    validationErrors.push(
      createError(
        {},
        {
          ...siteModeratorsAccountChecks[0],
          errorMessage,
          email: nonExistingAdmin.email,
        }
      )
    );
    state.siteModerators.proposed = state.siteModerators.proposed.filter(
      (proposedModerator) => proposedModerator !== nonExistingAdmin.email
    );
  }

  state.proposedSiteModeratorsErrors = [...errors, ...validationErrors];
}
