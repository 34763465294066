<template>
  <div class="AuthorizedActions">
    <v-div v-if="displayIsLargeDesktop">
      <SearchInput
        class="ml-2 pt-4 pr-2"
        :partner="partner"
        :label="`Search ${theme.title} ${theme.subTitle}`"
        :inverted="inverted"
        :single-line="small"
        :data-test-search-bar-id="dataTestSearchBarId"
        :data-test-search-icon-id="dataTestSearchIconId"
      />
    </v-div>
    <v-div v-if="displayIsTablet || displayIsSmallDesktop">
      <SearchButton
        class="ml-2"
        :partner="searchPartner || partner"
        :inverted="inverted"
      />
    </v-div>
    <v-div v-if="isLoggedOut(user) && displayIsDesktop" ml-2>
      <LoginMenu style="vertical-align: top" :inverted="inverted" />
    </v-div>
    <v-div v-if="isLoggedOut(user) && !displayIsDesktop" ml-2>
      <AnonymousMenu :partner="partner" />
    </v-div>
    <v-div v-if="!isLoggedOut(user)" :partner="adminPartner || partner" ml-2>
      <UserMenu :partner="partner" />
    </v-div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

import SearchInput from "~/components/search/SearchInput.vue";
import SearchButton from "~/components/page/components/buttons/SearchButton.vue";
import LoginMenu from "~/components/page/components/menus/LoginMenu.vue";
import UserMenu from "~/components/page/components/menus/UserMenu.vue";
import AnonymousMenu from "~/components/page/components/menus/AnonymousMenu.vue";

import isLoggedOut from "~/plugins/utilities/isLoggedOut";

export default {
  name: "AuthorizedActions",
  components: {
    SearchInput,
    SearchButton,
    LoginMenu,
    UserMenu,
    AnonymousMenu,
  },
  props: {
    noGutters: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    inverted: { type: Boolean, default: false },
    small: { type: Boolean, default: true },
  },
  data() {
    return {
      dataTestSearchBarId: "SearchBarText",
      dataTestSearchIconId: "SearchButton",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      theme: (state) => state.theme,
      searchPartner: (state) =>
        state.theme ? state.theme.searchPartner : undefined,
      adminPartner: (state) =>
        state.theme ? state.theme.adminPartner : undefined,
    }),
    displayIsTablet,
    displayIsDesktop,
    displayIsSmallDesktop() {
      return this.$vuetify.breakpoint.md;
    },
    displayIsLargeDesktop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    partner() {
      return this.$config.partner;
    },
    buttonColor() {
      return this.inverted ? "inverted" : "button-action";
    },
  },
  methods: {
    isLoggedOut(value) {
      return isLoggedOut(value);
    },
  },
};
</script>

<style>
.AuthorizedActions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.AuthorizedActions a:link {
  display: flex !important;
}

@media only screen and (max-width: 959px) {
  .AuthorizedActions {
    padding-right: 0 !important;
  }
}
</style>
