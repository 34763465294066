import { itemCreateVersion } from "~/store/graphql/mutations/itemCreateVersion.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const newSubmissionVersion = async function (context, { req, res, params }) {
  const data = await runGraphQLQuery(
    itemCreateVersion,
    params,
    this.$axios,
    req,
    res
  );

  return Promise.resolve(data);
};

export default newSubmissionVersion;
