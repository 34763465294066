import administrationDashboardPageLoadWithSubmissions from "~/store/graphql/queries/administrationDashboardPageLoadWithSubmissions.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadAdministrationSubmissionsWithFilters = async function (
  { commit, state },
  { params = {}, req, res }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    administrationDashboardPageLoadWithSubmissions,
    params,
    this.$axios,
    req,
    res
  );

  const featureToggles = {
    administrationDashboardSiteModerators: this.$unleash.isEnabled(
      "administrationDashboardSiteModerators"
    ),
    showModerationNotes: this.$unleash.isEnabled("showModerationNotes"),
    administrationDashboardAssignCurators: this.$unleash.isEnabled(
      "administrationDashboardAssignCurators"
    ),
  };

  commit("SET_ADMINISTRATION_DASHBOARD", { payload: data, featureToggles });

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadAdministrationSubmissionsWithFilters;
