import { itemDelete } from "~/store/graphql/mutations/itemDelete.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import createItemForMutation from "~/store/manage/utilities/createItemForMutation";

const deleteSubmissionItem = async function ({ commit, state, dispatch }) {
  const submission = createItemForMutation(state.submissionItem);

  const data = await runGraphQLQuery(
    itemDelete,
    {
      id: submission.id.toString(),
    },
    this.$axios
  );

  if (data) {
    commit("UPDATE_ITEM", data);
    dispatch("validateSubmission");

    return Promise.resolve(data);
  }
};

export default deleteSubmissionItem;
