<template>
  <div id="BespokeEmailListener">
    <v-dialog
      ref="bespokeEmailDialogRef"
      v-model="show"
      attach="#BespokeEmailListener"
      class="BespokeEmailListener"
      max-width="800"
      @keydown.esc="onClose"
    >
      <v-card class="pa-3">
        <v-card-title>
          <h2 id="bespoke-email-listener-title" class="headline">
            {{ messageTitle }}
          </h2>
        </v-card-title>
        <v-textarea
          ref="bespokeEmailText"
          v-model="model"
          :error-messages="maxLengthError()"
          :aria-label="messageTitle"
          class="bespoke-email-text"
          auto-grow
          outlined
          rows="4"
          :value="model"
          :aria-describedby="ariaDescribedByID"
          @input="updateMaxLengthSRMessage"
        ></v-textarea>

        <div
          v-if="!maxWordsLimitError"
          aria-hidden="true"
          class="font-small ml-3 mt-n6 mb-3"
        >
          {{ maxLengthMessage }}
        </div>
        <span :id="staticSRHint" class="d-sr-only">
          {{ limitSRHintMessage }}
        </span>
        <span role="status" aria-atomic="true" class="d-sr-only">
          {{ maxLengthSRMessage }}</span
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="closeBespokeEmailListener"
            color="warning"
            text
            aria-label="close"
            @click="onClose"
            >Close</v-btn
          >
          <v-btn
            class="approveBespokeEmailListener"
            color="success"
            text
            :aria-label="btnName"
            :disabled="disableConfirmButton"
            @click="onConfirm"
            >{{ btnName }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import rejectForSubmitArticle from "~/actions/tasks/rejectForSubmitArticle";
import approveForSubmitArticle from "~/actions/tasks/approveForSubmitArticle";

import {
  wordCount,
  maxLengthMessage,
  maxLengthError,
  limitSRHintMessage,
} from "~/components/forms/utilities/modelMaxLength";
import { displayIsMobile } from "~/components/utilities/displayBreakpoints";
import debounce from "~/store/manage/utilities/debounce";

const messageTitles = {
  ITEM_APPROVED_AUTHOR: "Approval",
  ITEM_REJECTED_AUTHOR: "Rejection",
};

const btnNames = {
  ITEM_APPROVED_AUTHOR: "Approve",
  ITEM_REJECTED_AUTHOR: "Reject",
};

export default {
  name: "BespokeEmailListener",
  data() {
    return {
      btnName: "",
      returnFocusTarget: undefined,
      emailType: "",
      item: {},
      maxWords: 1000,
      messageTitle: "",
      model: "",
      rejectionReason: "",
      show: false,
      maxLengthSRMessage: undefined,
    };
  },
  computed: {
    wordCount,
    maxLengthMessage,
    limitSRHintMessage,
    displayIsMobile,
    disableConfirmButton() {
      return Boolean(!this.model || this.maxLengthError());
    },
    maxWordsLimitError() {
      return this.wordCount > this.maxWords;
    },
    staticSRHint() {
      return "bespoke-email-static-hint";
    },
    ariaDescribedByID() {
      return this.maxWordsLimitError
        ? `bespoke-email-max-word-error-message ${this.staticSRHint}`
        : `bespoke-email-max-word-message${this.staticSRHint}`;
    },
  },
  mounted() {
    this.$root.$on("show-bespoke-email", this.onShowBespokeEmail);
  },
  created() {
    this.updateMaxLengthSRMessage = debounce(() => {
      this.maxLengthSRMessage = this.maxWordsLimitError
        ? this.maxLengthError()
        : this.maxLengthMessage;
    }, 500);
  },
  methods: {
    maxLengthError,
    rejectForSubmitArticle,
    approveForSubmitArticle,
    async onShowBespokeEmail({
      item,
      emailType,
      rejectionReason,
      currentTarget,
      title,
    }) {
      this.returnFocusTarget = currentTarget;
      this.item = item;
      this.emailType = emailType;
      this.messageTitle = this.displayIsMobile
        ? `${messageTitles[emailType]} email`
        : `${messageTitles[emailType]} email for ${title}`;
      this.rejectionReason = rejectionReason;
      this.btnName = btnNames[emailType];

      const emailTemplate = await this.$store.dispatch("loadEmailTemplate", {
        params: { emailType, itemId: this.item.id },
      });

      this.model = emailTemplate.origin.emailTemplate;
      this.show = true;
      this.setInitialFocus();
      this.setAriaLabelAttribute();
    },
    setInitialFocus() {
      this.$nextTick(() => {
        this.$refs.bespokeEmailText?.focus();
      });
    },
    setAriaLabelAttribute() {
      this.$nextTick(() => {
        const dialogRef = this?.$refs?.bespokeEmailDialogRef?.$children[0]?.$el;

        if (dialogRef) {
          const titleByElementId = document.getElementById(
            "bespoke-email-listener-title"
          ).textContent;
          dialogRef.setAttribute("aria-label", titleByElementId);
        }
      });
    },
    onClose() {
      this.show = false;
      this.returnFocus();
    },
    onConfirm() {
      this.show = false;

      if (this.emailType === "ITEM_REJECTED_AUTHOR") {
        this.$root.$emit("show-confirmation", {
          title: `Are you sure you want to reject "${this.item.title}" ?`,
          confirmationButtonText: "Reject",
          action: this.rejectForSubmitArticle.bind(this),
          currentTarget: this.returnFocusTarget,
        });
      }

      if (this.emailType === "ITEM_APPROVED_AUTHOR") {
        this.$root.$emit("show-confirmation", {
          title: `Are you sure you want to approve "${this.item.title}" ?`,
          confirmationButtonText: "Approve",
          action: this.approveForSubmitArticle.bind(this),
          currentTarget: this.returnFocusTarget,
        });
      }
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
  },
};
</script>

<style scoped>
#bespoke-email-listener-title {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.bespoke-email-text >>> textarea {
  white-space: pre-line;
  padding-bottom: 12px;
}
</style>
