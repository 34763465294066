import itemShortOverview from "~/store/graphql/queries/itemShortOverview.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadAddVor = async function ({ commit }, { req, res, params }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    itemShortOverview,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_ADD_VOR_DETAILS", data);
  commit("END_LOADING");

  return data;
};

export default loadAddVor;
