import unassignFromModerator from "~/store/graphql/mutations/unassignFromModerator.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

export default async function ({ commit }, { itemId, email }) {
  const data = await runGraphQLQuery(
    unassignFromModerator,
    { itemId, email },
    this.$axios
  );
  commit("REMOVE_ITEM_FROM_SITE_MODERATOR_SUBMISSIONS", itemId);
  return Promise.resolve(data);
}
