import getEmailTemplate from "~/store/graphql/queries/getEmailTemplate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadEmailTemplate = async function ({ commit }, { req, res, params }) {
  commit("START_LOADING");
  const data = await runGraphQLQuery(
    getEmailTemplate,
    params,
    this.$axios,
    req,
    res
  );
  commit("END_LOADING");
  return Promise.resolve(data);
};

export default loadEmailTemplate;
