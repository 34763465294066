<template>
  <div
    class="page-header d-flex justify-space-between align-center mx-auto fill-height width-percent-100 px-4"
  >
    <div
      v-if="dashboardLink"
      class="branding-logo fill-height"
      :class="{ 'width-percent-25': actionsCenter }"
    >
      <nuxt-link
        class="fill-height"
        :to="`/engage/${partner}/public-dashboard`"
      >
        <img
          v-if="theme.images.coloredLogo"
          :src="theme.images.coloredLogo"
          :alt="partnerLogoAltText"
          class="fill-height"
          :class="{ svgLogo: isSvg }"
        />
        <div v-else class="mt-4 pt-4">
          <v-btn
            class="toDashboard"
            text
            color="button-action"
            aria-label="dashboard"
          >
            <strong>Dashboard</strong>
          </v-btn>
        </div>
      </nuxt-link>
    </div>
    <div
      v-if="displayIsDesktop"
      class="action-region"
      :class="{ 'flex-grow-1': !actionsCenter }"
    >
      <QuickActions
        v-if="pageHeaderQuickActions"
        :no-gutters="reducedSize"
        :no-padding="reducedSize"
      />
    </div>
    <div class="user-region" :class="{ 'width-percent-25': actionsCenter }">
      <AuthorizedActions
        v-if="pageHeaderPartnerSearch"
        :no-gutters="reducedSize"
        :no-padding="reducedSize"
        :small="reducedSize"
        :inverted="inverted"
      />
      <div v-else>
        <a href="/engage/coe/contact-information?show=faqs"
          >What is Cambridge Open Engage?</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mapFlags from "~/plugins/utilities/mapFlags";

import QuickActions from "~/components/page/components/actions/QuickActions.vue";
import AuthorizedActions from "~/components/page/components/actions/AuthorizedActions.vue";
import getSiteName from "~/plugins/utilities/getSiteName.js";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

export default {
  name: "PageHeader",
  components: {
    QuickActions,
    AuthorizedActions,
  },
  props: {
    dashboardLink: { type: Boolean, default: true },
    reducedSize: { type: Boolean, default: false },
    actionsCenter: { type: Boolean, default: true },
    inverted: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.theme.baseUrl,
      theme: (state) => state.theme,
      user: (state) => state.user,
      information: (state) => state.theme.information,
      siteName: (state) => getSiteName(state.theme),
      altTextSiteName: (state) => state.theme.altTextTitle,
      isSvg: (state) => state.theme.images.coloredLogo.endsWith(".svg"),
    }),
    ...mapFlags(["pageHeaderQuickActions", "pageHeaderPartnerSearch"]),
    partner() {
      return this.$config.partner;
    },
    partnerLogoAltText() {
      return `${
        this.altTextSiteName ? this.altTextSiteName : this.siteName
      } Home`;
    },
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  max-width: 1300px;

  .branding-logo img {
    max-width: 260px;
  }

  a:link {
    height: 100%;
    display: inline-flex;
  }
}
</style>
