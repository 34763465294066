const SET_ARTICLE_MORE_COMMENTS = (state, payload) => {
  const results = payload.origin.comments.results;

  if (results.length > 0) {
    const { latestComments } = state.selectedItem;
    state.selectedItem.latestComments = [...latestComments, ...results];
  }
};

export default SET_ARTICLE_MORE_COMMENTS;
