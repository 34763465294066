import { mainItemRequestRevision } from "~/store/graphql/mutations/mainItemRequestRevision.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const reviseSubmissionStatus = async function (
  { commit, dispatch },
  { params }
) {
  const data = await runGraphQLQuery(
    mainItemRequestRevision,
    {
      id: params.id,
      note: params.note,
    },
    this.$axios
  );

  if (data) {
    const featureToggles = {
      multipleCategoriesSubmission: this.$unleash.isEnabled(
        "multipleCategoriesSubmission"
      ),
    };

    commit("UPDATE_ITEM", { payload: data, featureToggles });
    dispatch("validateSubmission");
  }

  return Promise.resolve(data);
};

export default reviseSubmissionStatus;
