<template>
  <v-btn
    class="toSearchDashboard"
    icon
    outlined
    height="36"
    width="36"
    aria-label="search"
    :color="inverted ? 'inverted' : 'button-action'"
    @click="onSearch"
  >
    <v-icon>{{ mdiMagnify }}</v-icon>
  </v-btn>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "SearchButton",
  props: {
    inverted: { type: Boolean, default: false },
    partner: { type: String, required: true },
  },
  data() {
    return {
      // Icons
      mdiMagnify,
    };
  },
  methods: {
    onSearch() {
      if (this.partner === this.$config.partner) {
        this.$router.push({
          path: `/engage/${this.partner}/search-dashboard`,
        });
      } else {
        window.document.location.href = `/engage/${this.partner}/search-dashboard`;
      }
    },
  },
};
</script>

<style>
a.SearchButton {
  color: inherit;
  text-decoration: none;
}
</style>
