const mandatoryMaterialChecks = [
  {
    severity: "ERROR",
    type: "MATERIAL",
    fieldName: "asset",
    errorMessage: "Supplementary Material file missing",
    resolutionMessage: "Please upload your file",
  },
  {
    severity: "ERROR",
    type: "MATERIAL",
    fieldName: "title",
    errorMessage: "Title missing",
    resolutionMessage: "Please enter a title",
  },
  {
    severity: "ERROR",
    type: "MATERIAL",
    fieldName: "description",
    errorMessage: "Description missing",
    resolutionMessage: "Please enter a description",
  },
];

export default mandatoryMaterialChecks;
