import checkProposalForErrors from "~/store/manage/validation/checkProposalForErrors";

const MODIFY_EVENT_SPONSOR = (state, payload) => {
  const { eventSponsorId, field, value } = payload;
  const sponsor = state.proposedEvent.sponsors.find(
    (sponsor) => sponsor.id === eventSponsorId
  );

  sponsor[field] = value;

  state.proposedErrors = checkProposalForErrors(
    state.proposedEvent,
    null,
    state.proposedErrors
  );
};

export default MODIFY_EVENT_SPONSOR;
