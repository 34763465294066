import ithenticateReportLinkGet from "~/store/graphql/queries/ithenticateReportLinkGet.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const getIthenticateReportLink = async function (
  { app, commit },
  { params, req, res }
) {
  const data = await runGraphQLQuery(
    ithenticateReportLinkGet,
    params,
    this.$axios,
    req,
    res
  );
  const link = data && data.user ? data.user.link : undefined;

  return Promise.resolve(link);
};

export default getIthenticateReportLink;
