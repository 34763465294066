const discussionChecks = [
  {
    severity: "ERROR",
    type: "DISCUSSION",
    fieldName: "type",
    errorMessage: "Discussion type is required",
    resolutionMessage: "Please enter a discussion type",
  },
  {
    severity: "ERROR",
    type: "DISCUSSION",
    fieldName: "title",
    errorMessage: "Discussion title is required",
    resolutionMessage: "Please enter a discussion title",
  },
  {
    severity: "ERROR",
    type: "DISCUSSION",
    fieldName: "description",
    errorMessage: "Discussion description is required",
    resolutionMessage: "Please enter a discussion description",
  },
];

export default discussionChecks;
