const CREATE_DISCUSSION_VOTE = (state, payload) => {
  const discussion = state.selectedCommunity.latestDiscussions.results.find(
    (discussion) => discussion.id === payload.discussionId
  );
  if (discussion && !discussion.hasUserVoted) {
    ++discussion.votesCount;
    discussion.hasUserVoted = true;
  }

  const selectedDiscussion = state.selectedDiscussion;
  if (
    selectedDiscussion.id === payload.discussionId &&
    !selectedDiscussion.hasUserVoted
  ) {
    ++selectedDiscussion.votesCount;
    selectedDiscussion.hasUserVoted = true;
  }
};

export default CREATE_DISCUSSION_VOTE;
