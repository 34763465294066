export default (actionName) => async (context) => {
  const { store, params, query, req, res, error, saveLastGraphQL, $unleash } =
    context;
  try {
    return await store.dispatch(actionName, {
      params,
      query,
      req,
      res,
      saveLastGraphQL,
      $unleash,
    });
  } catch (e) {
    error(e);
  }
};
