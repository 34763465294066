import checkIfMissing from "~/store/manage/validation/utilities/checkIfMissing";
import createError from "~/store/manage/validation/utilities/createError";

const checkForMissingErrors = (item, checks, index, type, featureToggles) => {
  const errors = [];

  checks.forEach((check) => {
    const value = item[check.fieldName];
    const altValue = check.alternativeFieldName
      ? item[check.alternativeFieldName]
      : undefined;

    const isMissing = checkIfMissing(value);
    const isAltMissing = altValue ? checkIfMissing(altValue) : false;

    const featureToggle = check.featureToggle
      ? !featureToggles[check.featureToggle]
      : false;

    // if feature toggle exists, but it is switched off
    if (featureToggle) {
      return;
    }

    if (isMissing) {
      if (altValue) {
        if (isAltMissing) {
          errors.push(createError(item, check, index, type));
        }
      } else {
        errors.push(createError(item, check, index, type));
      }
    }
  });

  return errors;
};

export default checkForMissingErrors;
