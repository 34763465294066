const updateSubmittedItemsSearchPhrase = (state, newSearchPhrase) => {
  state.currentAdministrator.submittedItemsSearchPhrase = newSearchPhrase;

  state.currentAdministrator.submittedItems = [];
  state.currentAdministrator.submittedItemsCount = undefined;
};
const updatePublishedItemsSearchPhrase = (state, newSearchPhrase) => {
  state.currentAdministrator.publishedItemsSearchPhrase = newSearchPhrase;

  state.currentAdministrator.publishedItems = [];
  state.currentAdministrator.publishedItemsCount = undefined;
};

const SET_ADMINISTRATION_SEARCH_PHRASE = (state, payload) => {
  const { publishedItemsSearchPhrase, submittedItemsSearchPhrase } = payload;
  if (submittedItemsSearchPhrase !== undefined) {
    updateSubmittedItemsSearchPhrase(state, submittedItemsSearchPhrase);
  }
  if (publishedItemsSearchPhrase !== undefined) {
    updatePublishedItemsSearchPhrase(state, publishedItemsSearchPhrase);
  }
};

export default SET_ADMINISTRATION_SEARCH_PHRASE;
