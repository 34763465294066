<template>
  <v-col justify="center" align-self="center">
    <v-row justify="end" class="flex-nowrap">
      <div v-if="search" class="mr-4 hidden-lg-and-down">
        <SearchInput
          :partner="partner"
          :category="searchCategory"
          :type="searchType"
          :hide-details="true"
          inverted
        />
      </div>
      <div v-if="rssFeed && links.rss && links.rss.url">
        <RssButton :rss-details="links.rss" :rss-subject="rssType" />
      </div>
    </v-row>
  </v-col>
</template>

<script>
import SearchInput from "~/components/search/SearchInput.vue";
import RssButton from "~/components/page/components/buttons/RssButton.vue";

export default {
  name: "SearchAndRss",
  components: { SearchInput, RssButton },
  props: {
    category: { type: Object, default: () => {} },
    subject: { type: Object, default: () => {} },
    rssFeed: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
    links: { type: Object, default: () => {} },
  },
  computed: {
    partner() {
      return this.$config.partner;
    },
    searchCategory() {
      return this.category || this.subject;
    },
    searchType() {
      return this.category ? "category" : this.subject ? "subject" : undefined;
    },
    rssType() {
      return this.category
        ? { type: "category", ...this.category }
        : this.subject
        ? { type: "subject", ...this.subject }
        : undefined;
    },
  },
};
</script>
