import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import LRUCache from "lru-cache";

import vContribDiv from "~/components/v-contrib-div.vue";

const USE_CACHE = false;
const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;

const themeCache = USE_CACHE
  ? new LRUCache({
      ttl: TWENTY_FOUR_HOURS,
      ttlAutopurge: true,
    })
  : null;

Vue.use(Vuetify);

Vue.component("VDiv", vContribDiv);

function hexToRgb(h) {
  let r = 0;
  let g = 0;
  let b = 0;

  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }
  return `${+r}, ${+g}, ${+b}`;
}

function generateRgbVar(name, color) {
  const colorRgb = hexToRgb(color);
  return `--v-${name}-rgb: ${colorRgb}`;
}

function createRgbVarsForCurrentTheme(vuetify) {
  const theme = vuetify.theme.currentTheme;
  let variablesCss = "";

  Object.entries(theme).forEach((colors) => {
    const key = colors[0];

    if (typeof colors[1] === "object") {
      Object.entries(colors[1]).forEach((color) => {
        const colorKey = color[0];
        const rgbVar = generateRgbVar(`${key}-${colorKey}`, color[1]);
        variablesCss += `  ${rgbVar};\n`;
      });
    } else {
      const rgbVar = generateRgbVar(key, colors[1].toString());
      variablesCss += `  ${rgbVar};\n`;
    }
  });

  return `:root {\n${variablesCss}}\n\n`;
}

export default (context) => {
  const { isInDevelopment } = context.$config;

  const light = context.store.state.theme.colors;
  const themes = { light };

  const vuetify = new Vuetify({
    icons: {
      iconfont: "mdiSvg",
    },
    theme: {
      dark: false,
      themes,
      options: {
        minifyTheme(css) {
          // Temporary hack to generate CSS variables for colors in RGB format: https://github.com/vuetifyjs/vuetify/issues/9282
          css = css + `\n\n${createRgbVarsForCurrentTheme(vuetify.framework)}`;
          return isInDevelopment
            ? css
            : css.replace(/\n/g, "").replace(/\s\s+/g, " ");
        },
        themeCache,
        customProperties: true,
      },
    },
  });

  context.app.vuetify = vuetify;
  context.$vuetify = vuetify.framework;
};
