import { set } from "vue";

const UPDATE_SUBMISSION_WEBLINK = (state, payload) => {
  if (!state.submissionItem.webLinks) {
    set(state.submissionItem, "webLinks", []);
  }

  const submissionWebLink = state.submissionItem.webLinks.find(
    (author) => author.gid === payload.id
  );

  if (submissionWebLink) {
    submissionWebLink[payload.field] = payload.value;
  }
};

export default UPDATE_SUBMISSION_WEBLINK;
