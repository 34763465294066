import processSubmissions from "~/store/manage/pages/authorDashboard/processSubmissions";

const SET_AUTHOR_DASHBOARD = (state, { payload, featureToggles }) => {
  state.currentAuthor.items = payload.user ? payload.user.items : [];
  state.currentAuthor.metrics = payload.user ? payload.user.metrics : [];

  let submissions = payload.user ? payload.user.submissions : [];
  submissions = submissions.filter((item) => item.hidden !== true);

  processSubmissions(submissions, featureToggles);

  state.currentAuthor.submissions = submissions;
  state.currentAuthor.articles = submissions.flatMap((submission) => [
    ...submission.items,
  ]);

  const { user } = payload;

  delete user.items;
  delete user.metrics;
  delete user.submissions;

  state.user = user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_AUTHOR_DASHBOARD;
