import checkProposalForErrors from "~/store/manage/validation/checkProposalForErrors";

const CLEAR_EVENT_ASSET = (state) => {
  if (state.proposedEvent) {
    state.proposedEvent.bannerAsset = {};
    state.proposedErrors = checkProposalForErrors(
      state.proposedEvent,
      null,
      state.proposedErrors
    );
  }
};

export default CLEAR_EVENT_ASSET;
