const CLEAR_AUTHOR_ORCID = (state) => {
  if (state.submissionItem && state.submissionItem.authors[0]) {
    state.submissionItem.authors[0].orcid = undefined;
  }
  if (state.user) {
    state.user.orcid = undefined;
  }
};

export default CLEAR_AUTHOR_ORCID;
