const jsonp = require("jsonp");

const logout = async function (context, logOutOfOrcid = false) {
  function orcidLogout(url) {
    return new Promise((resolve) => {
      jsonp(`${url}/userStatus.json?logUserOut=true`, null, (err, data) => {
        // eslint-disable-next-line no-console
        console.log(err, data);
        resolve();
      });
    });
  }
  const { commit, res } = context;

  const partner = this.$config.partner;
  const endpoint = this.$config.clientEndpoint;
  const currentLocation = window.location.href;

  const urlSubstrings = [
    "author-dashboard",
    "administration-dashboard",
    "submit-process",
    "processed-submission",
    "retract-process",
    "add-vor",
    "review-vor",
    "proposal-process",
    "comment-review",
    "discussion-review",
    "discussion-reply-review",
  ];

  const redirectUri = urlSubstrings.some((substring) =>
    currentLocation.includes(substring)
  )
    ? `${window.location.origin}/engage/${partner}/public-dashboard`
    : `${currentLocation}`;

  const logoutUri = `${endpoint}/logout?redirect-url=${redirectUri}`;

  commit("CLEAR_USER");

  if (process.server && res) {
    res.writeHead(302, { Location: logoutUri });
    return res.end();
  } else {
    if (logOutOfOrcid) {
      await orcidLogout(this.$config.orcidUrl);
    }
    window.location.replace(logoutUri);
  }
};

export default logout;
