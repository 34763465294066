<template>
  <!-- eslint-disable vue/no-v-html -->
  <span
    :class="truncateTitle && 'truncate-title'"
    v-html="sanitizedArticleTitle"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
export default {
  name: "ArticleTitle",
  props: {
    articleHtmlTitle: { type: String, required: true },
    truncateTitle: { type: Boolean, default: false },
  },
  computed: {
    sanitizedArticleTitle() {
      return this.$sanitize(this.articleHtmlTitle, {
        allowedTags: ["i", "sup", "sub"],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
