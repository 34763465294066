<template>
  <v-menu
    class="AnonymousMenu"
    role
    attach
    bottom
    left
    :dark="darkStyling"
    offset-y
    :max-height="maxMenuHeight"
  >
    <!-- HACK styles for IE11 to display correctly -->
    <template #activator="{ on, attrs }">
      <div ref="menuButtonContainer">
        <v-btn
          id="menubutton"
          aria-haspopup="true"
          aria-controls="menu"
          :aria-label="displayIsMobile ? 'Menu' : 'My Account Menu'"
          rounded
          icon
          style="vertical-align: top"
          :color="inverted ? 'inverted' : undefined"
          v-bind="attrs"
          v-on="on"
          @click="setMaxMenuHeight"
        >
          <v-icon aria-hidden="true">{{ mdiMenu }}</v-icon>
        </v-btn>
      </div>
    </template>
    <ul
      id="menu"
      role="menu"
      aria-labelledby="menubutton"
      class="menu-button v-list v-sheet no-bullet"
      :class="colourThemeClass"
    >
      <li v-if="showLoginOptions" role="none">
        <LoginButtons />
      </li>
      <AnonymousMenuItem
        link="search-dashboard"
        text="Search"
        :show-divider="true"
      ></AnonymousMenuItem>
      <AnonymousMenuItem
        link="submission-information"
        text="How to Submit"
      ></AnonymousMenuItem>
      <AnonymousMenuItem
        v-if="isInstitution"
        link="submit-process/step-one"
        text="Submit Your Content"
      ></AnonymousMenuItem>
      <AnonymousMenuItem
        v-if="isInstitution"
        link="departments-dashboard"
        text="Departments"
      ></AnonymousMenuItem>
      <AnonymousMenuItem
        v-if="!isInstitution"
        link="browse-dashboard"
        text="Browse"
      ></AnonymousMenuItem>
      <AnonymousMenuItem
        v-if="quickActionsShowEvents"
        link="events-dashboard"
        text="Events"
      ></AnonymousMenuItem>
      <AnonymousMenuItem
        v-if="quickActionShowCommunities"
        link="communities-dashboard"
        text="Communities"
      ></AnonymousMenuItem>
      <AnonymousMenuItem
        link="about-information"
        text="About"
      ></AnonymousMenuItem>

      <li v-if="quickActionShowNews" role="none">
        <v-list-item
          href="https://connect.acspubs.org/chemrxiv"
          target="_blank"
        >
          <v-list-item-title>
            News
            <span class="d-sr-only">[opens in a new tab]</span>
            <v-icon small color="primary" aria-hidden="true">{{
              mdiOpenInNew
            }}</v-icon>
          </v-list-item-title>
        </v-list-item>
      </li>
      <li v-if="!showLoginOptions" role="none">
        <v-divider aria-hidden="true" />
        <v-list-item @click="login">
          <v-list-item-title>Log in</v-list-item-title>
        </v-list-item>
      </li>
    </ul>
  </v-menu>
</template>

<script>
import { mdiMenu, mdiOpenInNew } from "@mdi/js";
import { mapState } from "vuex";
import LoginButtons from "~/components/page/components/buttons/LoginButtons";
import AnonymousMenuItem from "~/components/page/components/menus/AnonymousMenuItem";

import { login, orcidLogin } from "~/store/manage/pages/userDefaults/login";
import { displayIsMobile } from "~/components/utilities/displayBreakpoints";
import mapFlags from "~/plugins/utilities/mapFlags";

export default {
  name: "AnonymousMenu",
  components: {
    LoginButtons,
    AnonymousMenuItem,
  },
  props: {
    inverted: { type: Boolean, default: false },
  },
  data() {
    return {
      maxMenuHeight: 1000,
      // Icons
      mdiMenu,
      mdiOpenInNew,
    };
  },
  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
      isInstitution: (state) => state.theme.isInstitution,
    }),
    ...mapFlags([
      "supportCoreLogin",
      "supportOrcidLogin",
      "quickActionsShowEvents",
      "quickActionShowCommunities",
      "quickActionShowNews",
    ]),
    displayIsMobile,
    partner() {
      return this.$config.partner;
    },
    darkStyling() {
      return this.layout.anonymousMenuDarkStyling;
    },
    colourThemeClass() {
      return this.darkStyling ? "theme--dark" : "theme--light";
    },
    showLoginOptions() {
      return !!(this.supportCoreLogin && this.supportOrcidLogin);
    },
  },
  methods: {
    login({ currentTarget }) {
      if (!this.showLoginOptions) {
        if (this.supportCoreLogin) {
          this.$root.$emit("show-core-login-information", {
            login: (newPath) => login(this.$config, this.res, newPath),
            newPath: this.redirectUrl,
            currentTarget,
          });
        } else if (this.supportOrcidLogin) {
          orcidLogin(this.$config, this.res, this.redirectUrl);
        }
      }
    },
    setMaxMenuHeight() {
      const { top: menuTop, height: menuHeight } =
        this.$refs.menuButtonContainer.getBoundingClientRect();
      const paddingBottom = 20;

      this.maxMenuHeight =
        window.innerHeight - menuHeight - menuTop - paddingBottom;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-button {
  min-width: 200px;
}
</style>
