import externalExperts from "~/store/graphql/queries/externalExperts.gql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

export default async function loadSiteModerators({ commit }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(externalExperts, null, this.$axios);

  commit("SET_EXTERNAL_EXPERTS", data);
  commit("END_LOADING");

  return Promise.resolve(data);
}
