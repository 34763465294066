import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _afdf1e94 = () => interopDefault(import('../pages/engage/index.vue' /* webpackChunkName: "pages/engage/index" */))
const _36d35a98 = () => interopDefault(import('../pages/engage/_partner/index.vue' /* webpackChunkName: "pages/engage/_partner/index" */))
const _ecb3029c = () => interopDefault(import('../pages/engage/_partner/about-information.vue' /* webpackChunkName: "pages/engage/_partner/about-information" */))
const _1247271a = () => interopDefault(import('../pages/engage/_partner/accessibility-information.vue' /* webpackChunkName: "pages/engage/_partner/accessibility-information" */))
const _180915e2 = () => interopDefault(import('../pages/engage/_partner/administration-dashboard.vue' /* webpackChunkName: "pages/engage/_partner/administration-dashboard" */))
const _203926e2 = () => interopDefault(import('../pages/engage/_partner/archive-dashboard.vue' /* webpackChunkName: "pages/engage/_partner/archive-dashboard" */))
const _4dd3357c = () => interopDefault(import('../pages/engage/_partner/author-dashboard.vue' /* webpackChunkName: "pages/engage/_partner/author-dashboard" */))
const _b498df8a = () => interopDefault(import('../pages/engage/_partner/browse-dashboard.vue' /* webpackChunkName: "pages/engage/_partner/browse-dashboard" */))
const _35fc6254 = () => interopDefault(import('../pages/engage/_partner/communities-dashboard.vue' /* webpackChunkName: "pages/engage/_partner/communities-dashboard" */))
const _bed23476 = () => interopDefault(import('../pages/engage/_partner/contact-information.vue' /* webpackChunkName: "pages/engage/_partner/contact-information" */))
const _3767e9ee = () => interopDefault(import('../pages/engage/_partner/departments-dashboard.vue' /* webpackChunkName: "pages/engage/_partner/departments-dashboard" */))
const _49aeb6ec = () => interopDefault(import('../pages/engage/_partner/events-dashboard.vue' /* webpackChunkName: "pages/engage/_partner/events-dashboard" */))
const _44be3568 = () => interopDefault(import('../pages/engage/_partner/latest-listing.vue' /* webpackChunkName: "pages/engage/_partner/latest-listing" */))
const _7d16e5de = () => interopDefault(import('../pages/engage/_partner/legal-information.vue' /* webpackChunkName: "pages/engage/_partner/legal-information" */))
const _007afdaf = () => interopDefault(import('../pages/engage/_partner/login.vue' /* webpackChunkName: "pages/engage/_partner/login" */))
const _1c45152d = () => interopDefault(import('../pages/engage/_partner/privacy-information.vue' /* webpackChunkName: "pages/engage/_partner/privacy-information" */))
const _79242c4c = () => interopDefault(import('../pages/engage/_partner/public-dashboard.vue' /* webpackChunkName: "pages/engage/_partner/public-dashboard" */))
const _36f482d9 = () => interopDefault(import('../pages/engage/_partner/search-dashboard.vue' /* webpackChunkName: "pages/engage/_partner/search-dashboard" */))
const _66703ed5 = () => interopDefault(import('../pages/engage/_partner/submission-information.vue' /* webpackChunkName: "pages/engage/_partner/submission-information" */))
const _28072306 = () => interopDefault(import('../pages/engage/_partner/version-information.vue' /* webpackChunkName: "pages/engage/_partner/version-information" */))
const _48c6b716 = () => interopDefault(import('../pages/engage/_partner/submit-process/select-subject.vue' /* webpackChunkName: "pages/engage/_partner/submit-process/select-subject" */))
const _62077be1 = () => interopDefault(import('../pages/engage/_partner/vor/add-vor/not-found/_id.vue' /* webpackChunkName: "pages/engage/_partner/vor/add-vor/not-found/_id" */))
const _84ec5c56 = () => interopDefault(import('../pages/engage/_partner/article-details/not-found/_id.vue' /* webpackChunkName: "pages/engage/_partner/article-details/not-found/_id" */))
const _40f070f0 = () => interopDefault(import('../pages/engage/_partner/community-details/not-found/_id.vue' /* webpackChunkName: "pages/engage/_partner/community-details/not-found/_id" */))
const _1d895be5 = () => interopDefault(import('../pages/engage/_partner/event-details/event-submissions/_id.vue' /* webpackChunkName: "pages/engage/_partner/event-details/event-submissions/_id" */))
const _2edba5f9 = () => interopDefault(import('../pages/engage/_partner/event-details/not-found/_id.vue' /* webpackChunkName: "pages/engage/_partner/event-details/not-found/_id" */))
const _27b2c4f9 = () => interopDefault(import('../pages/engage/_partner/processed-submission/not-found/_id.vue' /* webpackChunkName: "pages/engage/_partner/processed-submission/not-found/_id" */))
const _199a9371 = () => interopDefault(import('../pages/engage/_partner/proposal-process/step-one/_id.vue' /* webpackChunkName: "pages/engage/_partner/proposal-process/step-one/_id" */))
const _76b37826 = () => interopDefault(import('../pages/engage/_partner/retract-process/step-one/_id.vue' /* webpackChunkName: "pages/engage/_partner/retract-process/step-one/_id" */))
const _7f2773e8 = () => interopDefault(import('../pages/engage/_partner/retract-process/step-two/_id.vue' /* webpackChunkName: "pages/engage/_partner/retract-process/step-two/_id" */))
const _977b0170 = () => interopDefault(import('../pages/engage/_partner/submit-process/not-found/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-process/not-found/_id" */))
const _512fbc32 = () => interopDefault(import('../pages/engage/_partner/submit-process/review-article/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-process/review-article/_id" */))
const _74966bc7 = () => interopDefault(import('../pages/engage/_partner/submit-process/step-four/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-process/step-four/_id" */))
const _3e86688b = () => interopDefault(import('../pages/engage/_partner/submit-process/step-one/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-process/step-one/_id" */))
const _d893917a = () => interopDefault(import('../pages/engage/_partner/submit-process/step-three/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-process/step-three/_id" */))
const _ef81931e = () => interopDefault(import('../pages/engage/_partner/submit-process/step-two/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-process/step-two/_id" */))
const _466ffee4 = () => interopDefault(import('../pages/engage/_partner/submit-to-journal/not-found/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-to-journal/not-found/_id" */))
const _6d350330 = () => interopDefault(import('../pages/engage/_partner/vor/add-vor/_id.vue' /* webpackChunkName: "pages/engage/_partner/vor/add-vor/_id" */))
const _99c106a2 = () => interopDefault(import('../pages/engage/_partner/vor/review-vor/_id.vue' /* webpackChunkName: "pages/engage/_partner/vor/review-vor/_id" */))
const _5f6e1548 = () => interopDefault(import('../pages/engage/_partner/article-details/_id.vue' /* webpackChunkName: "pages/engage/_partner/article-details/_id" */))
const _58136e32 = () => interopDefault(import('../pages/engage/_partner/article/_id.vue' /* webpackChunkName: "pages/engage/_partner/article/_id" */))
const _0d81ed5a = () => interopDefault(import('../pages/engage/_partner/category-dashboard/_id.vue' /* webpackChunkName: "pages/engage/_partner/category-dashboard/_id" */))
const _9047294a = () => interopDefault(import('../pages/engage/_partner/comment-review/_id.vue' /* webpackChunkName: "pages/engage/_partner/comment-review/_id" */))
const _5cd9decf = () => interopDefault(import('../pages/engage/_partner/community-details/_id.vue' /* webpackChunkName: "pages/engage/_partner/community-details/_id" */))
const _b3d764c8 = () => interopDefault(import('../pages/engage/_partner/department-details/_id.vue' /* webpackChunkName: "pages/engage/_partner/department-details/_id" */))
const _77dbd1de = () => interopDefault(import('../pages/engage/_partner/discussion-reply-review/_id.vue' /* webpackChunkName: "pages/engage/_partner/discussion-reply-review/_id" */))
const _25f8500e = () => interopDefault(import('../pages/engage/_partner/discussion-review/_id.vue' /* webpackChunkName: "pages/engage/_partner/discussion-review/_id" */))
const _7a6ae680 = () => interopDefault(import('../pages/engage/_partner/event-details/_id.vue' /* webpackChunkName: "pages/engage/_partner/event-details/_id" */))
const _00ccc580 = () => interopDefault(import('../pages/engage/_partner/processed-submission/_id.vue' /* webpackChunkName: "pages/engage/_partner/processed-submission/_id" */))
const _329fc23b = () => interopDefault(import('../pages/engage/_partner/question/_id.vue' /* webpackChunkName: "pages/engage/_partner/question/_id" */))
const _7ec5c8a4 = () => interopDefault(import('../pages/engage/_partner/subject-dashboard/_id.vue' /* webpackChunkName: "pages/engage/_partner/subject-dashboard/_id" */))
const _e33b4daa = () => interopDefault(import('../pages/engage/_partner/submit-to-journal/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-to-journal/_id" */))
const _5b735212 = () => interopDefault(import('../pages/engage/_partner/community-details/_communityId/discussion/_id.vue' /* webpackChunkName: "pages/engage/_partner/community-details/_communityId/discussion/_id" */))
const _15eafca6 = () => interopDefault(import('../pages/engage/_partner/submit-to-journal/_journalId/confirmation/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-to-journal/_journalId/confirmation/_id" */))
const _bf468206 = () => interopDefault(import('../pages/engage/_partner/submit-to-journal/_journalId/_id.vue' /* webpackChunkName: "pages/engage/_partner/submit-to-journal/_journalId/_id" */))
const _66a90430 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/engage",
    component: _afdf1e94,
    name: "engage"
  }, {
    path: "/engage/:partner",
    component: _36d35a98,
    name: "engage-partner"
  }, {
    path: "/engage/:partner/about-information",
    component: _ecb3029c,
    name: "engage-partner-about-information"
  }, {
    path: "/engage/:partner/accessibility-information",
    component: _1247271a,
    name: "engage-partner-accessibility-information"
  }, {
    path: "/engage/:partner/administration-dashboard",
    component: _180915e2,
    name: "engage-partner-administration-dashboard"
  }, {
    path: "/engage/:partner/archive-dashboard",
    component: _203926e2,
    name: "engage-partner-archive-dashboard"
  }, {
    path: "/engage/:partner/author-dashboard",
    component: _4dd3357c,
    name: "engage-partner-author-dashboard"
  }, {
    path: "/engage/:partner/browse-dashboard",
    component: _b498df8a,
    name: "engage-partner-browse-dashboard"
  }, {
    path: "/engage/:partner/communities-dashboard",
    component: _35fc6254,
    name: "engage-partner-communities-dashboard"
  }, {
    path: "/engage/:partner/contact-information",
    component: _bed23476,
    name: "engage-partner-contact-information"
  }, {
    path: "/engage/:partner/departments-dashboard",
    component: _3767e9ee,
    name: "engage-partner-departments-dashboard"
  }, {
    path: "/engage/:partner/events-dashboard",
    component: _49aeb6ec,
    name: "engage-partner-events-dashboard"
  }, {
    path: "/engage/:partner/latest-listing",
    component: _44be3568,
    name: "engage-partner-latest-listing"
  }, {
    path: "/engage/:partner/legal-information",
    component: _7d16e5de,
    name: "engage-partner-legal-information"
  }, {
    path: "/engage/:partner/login",
    component: _007afdaf,
    name: "engage-partner-login"
  }, {
    path: "/engage/:partner/privacy-information",
    component: _1c45152d,
    name: "engage-partner-privacy-information"
  }, {
    path: "/engage/:partner/public-dashboard",
    component: _79242c4c,
    name: "engage-partner-public-dashboard"
  }, {
    path: "/engage/:partner/search-dashboard",
    component: _36f482d9,
    name: "engage-partner-search-dashboard"
  }, {
    path: "/engage/:partner/submission-information",
    component: _66703ed5,
    name: "engage-partner-submission-information"
  }, {
    path: "/engage/:partner/version-information",
    component: _28072306,
    name: "engage-partner-version-information"
  }, {
    path: "/engage/:partner/submit-process/select-subject",
    component: _48c6b716,
    name: "engage-partner-submit-process-select-subject"
  }, {
    path: "/engage/:partner/vor/add-vor/not-found/:id?",
    component: _62077be1,
    name: "engage-partner-vor-add-vor-not-found-id"
  }, {
    path: "/engage/:partner/article-details/not-found/:id?",
    component: _84ec5c56,
    name: "engage-partner-article-details-not-found-id"
  }, {
    path: "/engage/:partner/community-details/not-found/:id?",
    component: _40f070f0,
    name: "engage-partner-community-details-not-found-id"
  }, {
    path: "/engage/:partner/event-details/event-submissions/:id?",
    component: _1d895be5,
    name: "engage-partner-event-details-event-submissions-id"
  }, {
    path: "/engage/:partner/event-details/not-found/:id?",
    component: _2edba5f9,
    name: "engage-partner-event-details-not-found-id"
  }, {
    path: "/engage/:partner/processed-submission/not-found/:id?",
    component: _27b2c4f9,
    name: "engage-partner-processed-submission-not-found-id"
  }, {
    path: "/engage/:partner/proposal-process/step-one/:id?",
    component: _199a9371,
    name: "engage-partner-proposal-process-step-one-id"
  }, {
    path: "/engage/:partner/retract-process/step-one/:id?",
    component: _76b37826,
    name: "engage-partner-retract-process-step-one-id"
  }, {
    path: "/engage/:partner/retract-process/step-two/:id?",
    component: _7f2773e8,
    name: "engage-partner-retract-process-step-two-id"
  }, {
    path: "/engage/:partner/submit-process/not-found/:id?",
    component: _977b0170,
    name: "engage-partner-submit-process-not-found-id"
  }, {
    path: "/engage/:partner/submit-process/review-article/:id?",
    component: _512fbc32,
    name: "engage-partner-submit-process-review-article-id"
  }, {
    path: "/engage/:partner/submit-process/step-four/:id?",
    component: _74966bc7,
    name: "engage-partner-submit-process-step-four-id"
  }, {
    path: "/engage/:partner/submit-process/step-one/:id?",
    component: _3e86688b,
    name: "engage-partner-submit-process-step-one-id"
  }, {
    path: "/engage/:partner/submit-process/step-three/:id?",
    component: _d893917a,
    name: "engage-partner-submit-process-step-three-id"
  }, {
    path: "/engage/:partner/submit-process/step-two/:id?",
    component: _ef81931e,
    name: "engage-partner-submit-process-step-two-id"
  }, {
    path: "/engage/:partner/submit-to-journal/not-found/:id?",
    component: _466ffee4,
    name: "engage-partner-submit-to-journal-not-found-id"
  }, {
    path: "/engage/:partner/vor/add-vor/:id?",
    component: _6d350330,
    name: "engage-partner-vor-add-vor-id"
  }, {
    path: "/engage/:partner/vor/review-vor/:id?",
    component: _99c106a2,
    name: "engage-partner-vor-review-vor-id"
  }, {
    path: "/engage/:partner/article-details/:id?",
    component: _5f6e1548,
    name: "engage-partner-article-details-id"
  }, {
    path: "/engage/:partner/article/:id?",
    component: _58136e32,
    name: "engage-partner-article-id"
  }, {
    path: "/engage/:partner/category-dashboard/:id?",
    component: _0d81ed5a,
    name: "engage-partner-category-dashboard-id"
  }, {
    path: "/engage/:partner/comment-review/:id?",
    component: _9047294a,
    name: "engage-partner-comment-review-id"
  }, {
    path: "/engage/:partner/community-details/:id?",
    component: _5cd9decf,
    name: "engage-partner-community-details-id"
  }, {
    path: "/engage/:partner/department-details/:id?",
    component: _b3d764c8,
    name: "engage-partner-department-details-id"
  }, {
    path: "/engage/:partner/discussion-reply-review/:id?",
    component: _77dbd1de,
    name: "engage-partner-discussion-reply-review-id"
  }, {
    path: "/engage/:partner/discussion-review/:id?",
    component: _25f8500e,
    name: "engage-partner-discussion-review-id"
  }, {
    path: "/engage/:partner/event-details/:id?",
    component: _7a6ae680,
    name: "engage-partner-event-details-id"
  }, {
    path: "/engage/:partner/processed-submission/:id?",
    component: _00ccc580,
    name: "engage-partner-processed-submission-id"
  }, {
    path: "/engage/:partner/question/:id?",
    component: _329fc23b,
    name: "engage-partner-question-id"
  }, {
    path: "/engage/:partner/subject-dashboard/:id?",
    component: _7ec5c8a4,
    name: "engage-partner-subject-dashboard-id"
  }, {
    path: "/engage/:partner/submit-to-journal/:id?",
    component: _e33b4daa,
    name: "engage-partner-submit-to-journal-id"
  }, {
    path: "/engage/:partner/community-details/:communityId?/discussion/:id?",
    component: _5b735212,
    name: "engage-partner-community-details-communityId-discussion-id"
  }, {
    path: "/engage/:partner/submit-to-journal/:journalId?/confirmation/:id?",
    component: _15eafca6,
    name: "engage-partner-submit-to-journal-journalId-confirmation-id"
  }, {
    path: "/engage/:partner/submit-to-journal/:journalId?/:id?",
    component: _bf468206,
    name: "engage-partner-submit-to-journal-journalId-id"
  }, {
    path: "/",
    component: _66a90430,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
