import { eventSponsorCreate } from "~/store/graphql/mutations/eventSponsorCreate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const createEventSponsor = async function ({ commit }, { params }) {
  const data = await runGraphQLQuery(
    eventSponsorCreate,
    { id: params.id },
    this.$axios
  );

  const newSponsor = {
    id: data.eventSponsorCreate.id,
    url: "",
    text: "",
    asset: null,
  };

  commit("CREATE_EVENT_SPONSOR", newSponsor);

  return Promise.resolve(data);
};

export default createEventSponsor;
