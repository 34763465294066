export function createCssHeaders(theme) {
  const applicationStyles = [];

  if (theme.overrideCss) {
    theme.overrideCss.forEach((item) => {
      applicationStyles.push({
        cssText: item,
        type: "text/css",
      });
    });
  }

  return {
    __dangerouslyDisableSanitizers: ["style"],
    style: applicationStyles,
  };
}
