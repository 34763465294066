import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

const publishForEvent = async function publishForEvent() {
  this.showErrors = true;
  this.hasSaved = true;

  try {
    await this.$store.dispatch("updateEventStatus", {
      params: {
        id: this.item.id,
        action: "PUBLISH",
      },
    });

    this.$root.$emit("change-view", {
      view: "administration-dashboard?show=event-listing",
    });
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }
};

export default publishForEvent;
