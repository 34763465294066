import administrationDashboardPageLoadWithSubmissions from "~/store/graphql/queries/administrationDashboardPageLoadWithSubmissions.graphql";
import administrationDashboardPageLoadWithPublished from "~/store/graphql/queries/administrationDashboardPageLoadWithPublished.graphql";
import administrationDashboardPageLoadWithRevisions from "~/store/graphql/queries/administrationDashboardPageLoadWithRevisions.graphql";
import administrationDashboardPageLoadWithRetracted from "~/store/graphql/queries/administrationDashboardPageLoadWithRetracted.graphql";
import administrationDashboardPageLoadWithVorRequests from "~/store/graphql/queries/administrationDashboardPageLoadWithVorRequests.graphql";
import administrationDashboardPageLoadWithEvents from "~/store/graphql/queries/administrationDashboardPageLoadWithEvents.graphql";
import administrationDashboardPageLoadWithNewComments from "~/store/graphql/queries/administrationDashboardPageLoadWithNewComments.graphql";
import administrationDashboardPageLoadWithProcessedComments from "~/store/graphql/queries/administrationDashboardPageLoadWithProcessedComments.graphql";
import administrationDashboardPageLoadWithReportedDiscussions from "~/store/graphql/queries/administrationDashboardPageLoadWithReportedDiscussions.graphql";
import administrationDashboardPageLoadWithReportedDiscussionReplies from "~/store/graphql/queries/administrationDashboardPageLoadWithReportedDiscussionReplies.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const graphqlQueryMap = [
  {
    urlQuery: "new-submissions",
    graphqlQuery: administrationDashboardPageLoadWithSubmissions,
  },
  {
    urlQuery: "processed-submissions",
    graphqlQuery: administrationDashboardPageLoadWithPublished,
  },
  {
    urlQuery: "revisions",
    graphqlQuery: administrationDashboardPageLoadWithRevisions,
  },
  {
    urlQuery: "retraction-requests",
    graphqlQuery: administrationDashboardPageLoadWithRetracted,
  },
  {
    urlQuery: "vor-requests",
    graphqlQuery: administrationDashboardPageLoadWithVorRequests,
  },
  {
    urlQuery: "event-listing",
    graphqlQuery: administrationDashboardPageLoadWithEvents,
  },
  {
    urlQuery: "new-reports",
    graphqlQuery: administrationDashboardPageLoadWithNewComments,
  },
  {
    urlQuery: "processed-reports",
    graphqlQuery: administrationDashboardPageLoadWithProcessedComments,
  },
  {
    urlQuery: "new-discussion-reports",
    graphqlQuery: administrationDashboardPageLoadWithReportedDiscussions,
  },
  {
    urlQuery: "new-discussion-replies-reports",
    graphqlQuery: administrationDashboardPageLoadWithReportedDiscussionReplies,
  },
];
const loadAdministrationDashboard = async function (
  { commit, dispatch },
  { query, req, res }
) {
  commit("START_LOADING");

  const queryForTab = graphqlQueryMap.find(
    (queryMapItem) => queryMapItem.urlQuery === query.show
  );

  const graphqlQuery =
    queryForTab?.graphqlQuery || administrationDashboardPageLoadWithSubmissions;

  const data = await runGraphQLQuery(graphqlQuery, {}, this.$axios, req, res);

  if (data.user) {
    const featureToggles = {
      administrationDashboardSiteModerators: this.$unleash.isEnabled(
        "administrationDashboardSiteModerators"
      ),
      showModerationNotes: this.$unleash.isEnabled("showModerationNotes"),
      administrationDashboardAssignCurators: this.$unleash.isEnabled(
        "administrationDashboardAssignCurators"
      ),
    };

    commit("SET_ADMINISTRATION_DASHBOARD", { payload: data, featureToggles });
  } else {
    commit("CLEAR_USER");
  }

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadAdministrationDashboard;
