export default function isUnauthorized(value, key) {
  if (
    !value ||
    !value.roles ||
    !Array.isArray(value.roles || key === undefined)
  ) {
    return true;
  }

  return !value.roles.includes(key.toUpperCase());
}
