<template>
  <div class="ErrorLayout">
    <component :is="errorPage" :error="error" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import getPageTitle from "~/plugins/utilities/getPageTitle.js";
import getSiteName from "~/plugins/utilities/getSiteName.js";
import error404 from "~/components/error/404.vue";
import error410 from "~/components/error/410.vue";
import genericError from "~/components/error/genericError.vue";

export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return { title: `Error | ${getPageTitle(this.siteName)}` };
  },
  computed: {
    ...mapState({
      siteName: (state) => getSiteName(state.theme),
    }),
    errorPage() {
      switch (this.error.statusCode) {
        case 404:
          return error404;
        case 410:
          return error410;
        default:
          return genericError;
      }
    },
  },
  mounted() {
    this.$store.commit("END_LOADING");
  },
};
</script>
