<template>
  <v-row class="LayoutFill" mb-5 :no-gutters="noGutters">
    <v-col :class="noPadding ? 'pb-0' : undefined" cols="12">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LayoutFill",
  props: {
    noGutters: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
  },
};
</script>
