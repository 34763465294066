const mandatoryAssetContentChecks = [
  {
    severity: "ERROR",
    type: "CONTENT_PROCESSING",
    fieldName: "fileName",
    errorMessage: "Main Content file in progress",
    resolutionMessage: "Please wait for the file to be successfully processed",
  },
];

export default mandatoryAssetContentChecks;
