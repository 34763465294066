import searchDashboardPageLoad from "~/store/graphql/queries/searchDashboardPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadSearchDashboard = async function ({ commit }, { params, req, res }) {
  commit("START_LOADING");
  commit("SET_SEARCH_REQUEST", params);

  // HACK - to be removed also in loadSearchMoreMatches
  const hackedParams = { ...params };
  const authors =
    params.authors && params.authors.length > 0
      ? params.authors.join(" ")
      : undefined;
  delete hackedParams.authors;
  if (authors) {
    hackedParams.authors = authors;
  }

  const data = await runGraphQLQuery(
    searchDashboardPageLoad,
    hackedParams,
    this.$axios,
    req,
    res
  );

  const featureToggles = {
    multipleCategoriesSubmission: this.$unleash.isEnabled(
      "multipleCategoriesSubmission"
    ),
    showSubcategorySearchFacets: this.$unleash.isEnabled(
      "showSubcategorySearchFacets"
    ),
  };

  commit("SET_SEARCH_DASHBOARD", { payload: data, featureToggles });
  commit("END_LOADING");

  return Promise.resolve([params, data]);
};

export default loadSearchDashboard;
