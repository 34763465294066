<template>
  <div class="GenericSearchInput width-percent-100 d-inline-block">
    <v-text-field
      ref="textField"
      v-model="value"
      :hide-details="hideDetails"
      :class="classes"
      :label="showPlaceholder ? undefined : label"
      :placeholder="showPlaceholder ? label : undefined"
      :aria-label="inputAriaLabel.toLowerCase()"
      :single-line="singleLine"
      :outlined="outlined"
      :solo="solo"
      :dense="dense"
      :background-color="background"
      :data-test-id="dataTestSearchBarId"
      @keyup.enter.prevent="onSearch"
      @click:append="onSearch"
    >
      <div slot="append" class="rounded width-24 height-24 has-action">
        <a
          href="#"
          :aria-label="submitAriaLabel.toLowerCase()"
          :data-test-id="dataTestSearchIconId"
          class="no-underline"
          @click.prevent="onSearch"
          @keyup.enter.prevent="onSearch"
        >
          <v-icon>{{ mdiMagnify }}</v-icon>
        </a>
      </div>
    </v-text-field>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "GenericSearchInput",
  props: {
    inverted: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    thick: { type: Boolean, default: false },
    label: { type: String, default: "Enter search term" },
    srText: { type: String, default: "" },
    showPlaceholder: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    background: { type: String, default: undefined },
    outlined: { type: Boolean, default: false },
    padding: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    dataTestSearchBarId: {
      type: [String, null],
      default: null,
    },
    dataTestSearchIconId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      value: null,
      // Icons
      mdiMagnify,
    };
  },
  computed: {
    classes() {
      return {
        "input-white": this.inverted,
        thick: this.thick,
        padding: this.padding,
      };
    },
    inputAriaLabel() {
      return this.srText
        ? `${this.label} to search ${this.srText}`
        : this.label;
    },
    submitAriaLabel() {
      return this.srText ? `Submit ${this.srText} search` : "Submit search";
    },
  },
  methods: {
    onSearch() {
      const value = this.$refs.textField.$refs.input.value;
      this.$emit("search", value);
    },
    clear() {
      this.value = null;
      this.$refs.textField.$refs.input.blur();
    },
  },
};
</script>

<style>
.GenericSearchInput .input-white input::placeholder {
  color: white !important;
  opacity: 0.5;
}

.SearchInput .input-white input::-ms-input-placeholder {
  color: white !important;
  opacity: 0.5;
}

.GenericSearchInput .v-text-field.input-white * {
  color: white !important;
  border-bottom-color: white !important;
}

.GenericSearchInput .v-text-field.input-white .v-input__slot:before {
  border-color: white !important;
}

.GenericSearchInput .v-text-field.input-white .v-input__slot:after {
  border-color: white !important;
}

.GenericSearchInput .v-text-field.thick .v-input__slot:before {
  border-top-width: medium;
}

.GenericSearchInput .v-text-field.thick .v-input__slot .v-label {
  font-weight: bold;
}
.GenericSearchInput .v-text-field.border .v-input__slot {
  padding: 10px;
}
</style>
