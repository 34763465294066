import discussionCreate from "~/store/graphql/mutations/discussionCreate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const createDiscussion = async function ({ state }) {
  const { title, type, description } = state.proposedDiscussion;
  const communityId = state.selectedCommunity.id;

  const discussionInput = {
    title,
    type,
    description,
    communityId,
  };

  const data = await runGraphQLQuery(
    discussionCreate,
    { discussionInput },
    this.$axios
  );
  return Promise.resolve(data);
};

export default createDiscussion;
