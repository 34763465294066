const processSubmissions = (submissions, featureToggles) => {
  submissions.forEach((submission) => {
    const { items } = submission;

    let latestLoadedId;
    let latestPublishedId;
    let itemAssetFileType;
    let canSubmitToJournalId;

    items.forEach((item) => {
      if (item.status === "LOADED") {
        latestLoadedId = item.id;
      }

      if (
        latestLoadedId === undefined &&
        (item.status === "PUBLISHED" || item.status === "RETRACTING") &&
        latestPublishedId === undefined
      ) {
        latestPublishedId = item.id;
      }
    });

    items.forEach((item) => {
      if (
        item.status === "LOADED" ||
        item.status === "SUBMITTED" ||
        item.status === "PROCESSING_SUBMISSION"
      ) {
        latestPublishedId = undefined;
      }
    });

    items.forEach((item) => {
      // check which item (if any) can be submitted to journal
      if (
        item.asset &&
        item.asset.fileName &&
        (item.status === "PUBLISHED" || item.status === "RETRACTING")
      ) {
        if (!item.contentType.allowJournalSubmission) {
          return;
        }

        const bits = item.asset.fileName.split(".");
        const lastBit = bits.length - 1;

        if (lastBit >= 0) {
          itemAssetFileType = bits[lastBit];

          if (
            itemAssetFileType === "pdf" &&
            featureToggles.authorDashboardSubmitToJournal &&
            canSubmitToJournalId === undefined
          ) {
            canSubmitToJournalId = item.id;
          }
        }
      }
    });

    items.forEach((item) => {
      item.latestLoadedId = latestLoadedId;
      item.latestPublishedId = latestPublishedId;

      item.hasLatestLoadedId = latestLoadedId !== undefined;
      item.isLatestPublishedId = latestPublishedId === item.id;

      item.canSubmitToJournal = canSubmitToJournalId === item.id;

      if (item.version !== undefined) {
        item.isFirstVersion =
          item.version[0] === "0" || item.version[0] === "1";
        item.isUpdatedVersion = !item.isFirstVersion;
      }
    });
  });
};

export default processSubmissions;
