import subjectDashboardPageLoad from "~/store/graphql/queries/subjectDashboardPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadSubjectDashboard = async function ({ commit }, { params, req, res }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    subjectDashboardPageLoad,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_SUBJECT_DASHBOARD", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadSubjectDashboard;
