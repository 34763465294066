import { set } from "vue";

const ADD_SUBMISSION_AUTHOR = (state, payload) => {
  set(
    state.submissionItem.authors,
    state.submissionItem.authors.length,
    payload
  );
};

export default ADD_SUBMISSION_AUTHOR;
