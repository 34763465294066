import siteModerators from "~/store/graphql/queries/siteModerators.gql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

export default async function loadSiteModerators({ commit }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(siteModerators, null, this.$axios);

  commit("SET_SITE_MODERATORS", data);
  commit("END_LOADING");

  return Promise.resolve(data);
}
