const isInDevelopment = process.env.NODE_ENV === "development";

export default (context) => {
  const { $config, $sentry } = context;
  const { DEPLOY_ENV } = $config;

  if (!isInDevelopment && $sentry) {
    const hub = $sentry.getCurrentHub();

    if (hub) {
      const options = hub.getClient().getOptions();

      options.environment = DEPLOY_ENV;
    }
  }
};
