<template>
  <ContainerPage class="ErrorPage">
    <LayoutFill :no-gutters="solidHeader" :no-padding="solidHeader">
      <MainBanner :title="pageTitle" :solid-header="solidHeader" />
    </LayoutFill>
    <LayoutFill>
      <div class="mx-10 headline text-center font-weight-bold">
        Oops! There was a problem rendering this page.
      </div>
    </LayoutFill>
    <LayoutFill>
      <div class="mx-10 text-center">
        <p>Please refresh the page to try again.</p>
        <p>
          If this error persists, please try clearing your cache and refreshing
          the page again. You should also ensure you are using the latest
          version of your browser.
        </p>
        <p>
          If you've tried the above but are still receiving the error, please
          report this problem to our technical support team. You can find their
          contact details on our
          <a :href="contactUsLink" target="_blank">
            Contact Us page
            <OpenInNewTabIcon />
          </a>
        </p>
      </div>
    </LayoutFill>
    <LayoutFill>
      <div class="headline text-center">
        <v-btn
          class="toPublicDashboard"
          rounded
          outlined
          color="primary"
          aria-label="go back to home page"
          :to="`/engage/${partner}/public-dashboard`"
          >go back to homepage</v-btn
        >
      </div>
    </LayoutFill>
  </ContainerPage>
</template>

<script>
import { mapState } from "vuex";
import ContainerPage from "~/components/layout/ContainerPage";
import LayoutFill from "~/components/layout/LayoutFill";
import MainBanner from "~/components/banner/MainBanner.vue";
import OpenInNewTabIcon from "~/components/OpenInNewTabIcon.vue";

export default {
  name: "GenericError",
  components: {
    ContainerPage,
    LayoutFill,
    MainBanner,
    OpenInNewTabIcon,
  },
  data() {
    return {
      pageTitle: "Error",
    };
  },
  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
    }),
    partner() {
      return this.$config.partner;
    },
    solidHeader() {
      return this.layout.mainBannerSolidHeader;
    },
    contactUsLink() {
      return `/engage/${this.partner}/contact-information?show=contact`;
    },
  },
};
</script>
