import { set } from "vue";

const ADD_SUBMISSION_FUNDER = (state, payload) => {
  set(
    state.submissionItem.funders,
    state.submissionItem.funders.length,
    payload
  );
};

export default ADD_SUBMISSION_FUNDER;
