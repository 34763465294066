<template>
  <v-container
    v-scroll="onScroll"
    v-resize="onResize"
    class="ContainerScrolling"
    pa-0
    fluid
  >
    <slot></slot>
  </v-container>
</template>

<script>
// SOURCE https://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom
const FIX_FOR_ISSUES_WITH_MAC = 2;

export const isAtBottomOfWindow = () => {
  return (
    window.innerHeight + window.pageYOffset >=
    document.body.offsetHeight - FIX_FOR_ISSUES_WITH_MAC
  );
};

export default {
  name: "ContainerScrolling",
  mounted() {
    this.onResize();
  },
  methods: {
    onScroll() {
      if (isAtBottomOfWindow() === true) {
        this.$emit("show-more");
      }
    },
    onResize() {
      this.onScroll();
    },
  },
};
</script>
