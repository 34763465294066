const mandatoryContentChecks = [
  {
    severity: "ERROR",
    type: "CONTENT",
    fieldName: "subjectId",
    errorMessage: "Subject missing",
    resolutionMessage: "Please select a subject from the drop down",
  },
  {
    severity: "ERROR",
    type: "CONTENT",
    fieldName: "contentTypeId",
    errorMessage: "Content Type missing",
    resolutionMessage: "Please select a content type from the drop down",
  },
  {
    severity: "ERROR",
    type: "CONTENT",
    fieldName: "title",
    errorMessage: "Paper Title missing",
    resolutionMessage: "Please enter a paper title",
  },
  {
    severity: "ERROR",
    type: "CONTENT",
    fieldName: "abstract",
    errorMessage: "Abstract missing",
    resolutionMessage: "Please enter an abstract",
  },
  {
    severity: "ERROR",
    type: "CONTENT",
    fieldName: "asset",
    errorMessage: "Main Content file missing",
    resolutionMessage: "Please upload your content",
  },
  {
    severity: "ERROR",
    type: "LICENSE",
    fieldName: "licenseId",
    errorMessage: "License missing",
    resolutionMessage: "Please select a license",
  },
  {
    severity: "ERROR",
    type: "ETHICS",
    fieldName: "gainedEthicsApproval",
    errorMessage: "Ethics confirmation missing",
    resolutionMessage: "Please complete the ethics statement",
  },
  {
    severity: "ERROR",
    type: "INTERESTS",
    fieldName: "hasCompetingInterests",
    errorMessage: "Competing interests declaration missing",
    resolutionMessage: "Please complete the competing interests declaration",
  },
];

export default mandatoryContentChecks;
