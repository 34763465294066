import Vue from "vue";

import VueSanitize from "vue-sanitize";

export default () => {
  Vue.use(VueSanitize, {
    allowedTags: [
      "h3",
      "h4",
      "h5",
      "h6",
      "blockquote",
      "p",
      "span",
      "ul",
      "ol",
      "li",
      "b",
      "i",
      "strong",
      "em",
      "strike",
      "code",
      "pre",
    ],
    allowedAttributes: false,
  });
};
