const regexAuthorChecks = [
  {
    severity: "ERROR",
    type: "AUTHOR",
    fieldName: "emailAddress",
    positiveRegex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    errorMessage: "Email Address",
    resolutionMessage:
      "Please enter an Email address ensuring it is formatted correctly",
  },
];

export default regexAuthorChecks;
