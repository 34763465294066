const REMOVE_DISCUSSION_VOTE = (state, payload) => {
  const discussion = state.selectedCommunity.latestDiscussions.results.find(
    (discussion) => discussion.id === payload.discussionId
  );
  if (discussion.hasUserVoted) {
    --discussion.votesCount;
    discussion.hasUserVoted = false;
  }

  const selectedDiscussion = state.selectedDiscussion;
  if (
    selectedDiscussion.id === payload.discussionId &&
    selectedDiscussion.hasUserVoted
  ) {
    --selectedDiscussion.votesCount;
    selectedDiscussion.hasUserVoted = false;
  }
};

export default REMOVE_DISCUSSION_VOTE;
