import submitEventPageLoad from "~/store/graphql/queries/submitEventPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadEventProcess = async function ({ commit }, { req, res, params }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    submitEventPageLoad,
    params,
    this.$axios,
    req,
    res
  );
  commit("SET_EVENT_WORKFLOW", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadEventProcess;
