const extractFirstResult = (prop) => {
  if (prop && prop.results && prop.results.length > 0) {
    return prop.results[0].item;
  }
};

const extractResults = (prop) => {
  if (prop && prop.results && prop.results.length > 0) {
    return prop.results.map((hit) => hit.item);
  }
  return [];
};

const SET_CATEGORY_DASHBOARD = (state, payload) => {
  state.selectedCategory = {
    id: payload.category.id,
    name: payload.category.name,
    description: payload.category.description,

    mostReadItem: extractFirstResult(payload.mostRead),
    mostSharedItem: extractFirstResult(payload.mostShared),
    mostCitedItem: extractFirstResult(payload.mostCited),

    latestItems: extractResults(payload.latestItems),
  };

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_CATEGORY_DASHBOARD;
