const checkIfAssetsAreProcessing = (item) => {
  if (item?.asset?.status !== "COMPLETED") {
    return true;
  }

  if (item.supplementaryAssets) {
    // eslint-disable-next-line array-callback-return
    const found = item.supplementaryAssets.find((supplementary) => {
      if (supplementary.asset && supplementary.asset.status !== "COMPLETED") {
        return true;
      }
    });
    if (found) {
      return true;
    }
  }

  return false;
};

export default checkIfAssetsAreProcessing;
