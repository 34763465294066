import isAuthorized from "~/plugins/utilities/isAuthorized";

const unauthorizedRedirect = (redirect, isServer, config, req, redirectTo) => {
  const partner = config.partner;

  let redirectUri = req
    ? config.baseUrl + req.originalUrl
    : window.location.href;

  if (redirectTo) {
    redirectUri = redirectTo;
  }
  const loginPath = `/engage/${partner}/login`;

  if (isServer) {
    redirect(loginPath, { "redirect-url": redirectUri });
  } else {
    window.location.assign(loginPath + `?redirect-url=${redirectUri}`);
  }
};

export const checkAuthorizedUserRedirect = (
  state,
  redirect,
  isServer,
  config,
  req,
  redirectTo
) => {
  if (!isAuthorized(state.user, "USER")) {
    unauthorizedRedirect(redirect, isServer, config, req, redirectTo);

    return false;
  }

  return true;
};

export default unauthorizedRedirect;
