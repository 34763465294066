import processItems from "~/store/manage/pages/adminstrationDashboard/utilities/processItems";

const SET_ADMINISTRATION_DASHBOARD = (state, { payload, featureToggles }) => {
  delete payload.user.itemsAwaitingApproval;

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;

  const { origin, user } = payload;

  const submittedItems = origin.submittedItems?.results || [];
  const retractedItems = origin.retractedItems?.results || [];
  const publishedItems = origin.publishedItems?.results || [];
  const revisionItems = origin.revisionItems?.results || [];
  const vorRequests = origin.vorRequests?.results || [];
  const events = origin.adminEvents?.results || [];
  const reportedComments = origin.reportedComments?.results || [];
  const processedComments = origin.reportedComments?.results || [];
  const reportedDiscussions = payload.reportedDiscussions?.results || [];
  const reportedDiscussionReplies =
    payload.reportedDiscussionReplies?.results || [];

  processItems(submittedItems, featureToggles);
  processItems(retractedItems, featureToggles);
  processItems(publishedItems, featureToggles);
  processItems(revisionItems, featureToggles);
  processItems(vorRequests, featureToggles);
  processItems(events, featureToggles);
  processItems(reportedComments, featureToggles);
  processItems(processedComments, featureToggles);
  processItems(reportedDiscussions, featureToggles);
  processItems(reportedDiscussionReplies, featureToggles);

  state.currentAdministrator.submittedItems = submittedItems;
  state.currentAdministrator.retractedItems = retractedItems;
  state.currentAdministrator.publishedItems = publishedItems;
  state.currentAdministrator.revisionItems = revisionItems;
  state.currentAdministrator.vorRequests = vorRequests;
  state.currentAdministrator.events = events;
  state.currentAdministrator.reportedComments = reportedComments;
  state.currentAdministrator.processedComments = processedComments;
  state.currentAdministrator.reportedDiscussions = reportedDiscussions;
  state.currentAdministrator.reportedDiscussionReplies =
    reportedDiscussionReplies;

  state.currentAdministrator.submittedItemsCount =
    origin.submittedItems?.totalCount || 0;
  state.currentAdministrator.retractedItemsCount =
    origin.retractedItems?.totalCount || 0;
  state.currentAdministrator.publishedItemsCount =
    origin.publishedItems?.totalCount || 0;

  state.currentAdministrator.metrics = origin.metrics;

  delete user.itemsAwaitingApproval;

  state.user = user;
};

export default SET_ADMINISTRATION_DASHBOARD;
