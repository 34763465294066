import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import mainItemUpdateCurationApproval from "~/store/graphql/mutations/mainItemUpdateCurationApproval.graphql";

const updateCurationProgress = async function (
  { commit },
  { id, name, value }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    mainItemUpdateCurationApproval,
    { itemId: id, name, value },
    this.$axios
  );

  commit("UPDATE_CURATION_PROGRESS", data);
  commit("END_LOADING");

  return await Promise.resolve(data);
};

export default updateCurationProgress;
