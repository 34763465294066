import submissionReportChecks from "~/store/manage/validation/checks/submissionReportChecks";
import createError from "~/store/manage/validation/utilities/createError";

const doiStatuses = ["APPROVED", "PUBLISHED", "RETRACTING", "RETRACTED"];

const SET_CITATIONS_REPORT_PART = (state, { payload }) => {
  state.currentAdministrator.citationsReport.totalCount = payload.totalCount;

  if (payload.totalCount === 0) {
    const check = submissionReportChecks.find(
      (check) => check.type === "NO_DATA"
    );
    state.fundersReportErrors.push(
      createError(state.currentAdministrator.fundersReport, check)
    );
  }

  const reportPart = payload.results.map((i) => {
    const firstAuthor = i.authors.find((a) => a.firstAuthor) || {};

    return {
      title: i.title,
      contentType: i.contentType?.name || "",
      version: i.version,
      doi: doiStatuses.includes(i.status) ? i.doi : "",
      subject: i.subject?.name || "",
      publishedDate: i.publishedDate,
      firstName: firstAuthor.firstName || "",
      lastName: firstAuthor.lastName || "",
      userId: mapUserId(firstAuthor),
      orcid: firstAuthor.orcid || "",
      VorDoi: i.vor?.url || "",
      citationsCount: i.citationsCount,
    };
  });

  state.currentAdministrator.citationsReport.items.push(...reportPart);
};

function mapUserId(author) {
  if (author.apsaMeta) {
    return author.apsaMeta.apsaId || "";
  }

  return author.gid || "";
}

export default SET_CITATIONS_REPORT_PART;
