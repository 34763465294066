function changeOrder(data, field, oldIndex, newIndex) {
  data.forEach((item) => {
    if (item[field] === oldIndex) {
      item[field] = null;
    }
  });

  data.forEach((item) => {
    if (item[field] !== null && item[field] > oldIndex) {
      item[field] = item[field] - 1;
    }
  });

  data.forEach((item) => {
    if (item[field] !== null && item[field] >= newIndex) {
      item[field] = item[field] + 1;
    }
  });

  data.forEach((item) => {
    if (item[field] === null) {
      item[field] = newIndex;
    }
  });
}

const UPDATE_SUBMISSION_AUTHOR_ORDER = (state, payload) => {
  const { submissionItem } = state;
  const { authors = [] } = submissionItem;
  const { value = {} } = payload;
  const { newIndex, oldIndex } = value;

  changeOrder(authors, "displayOrder", oldIndex, newIndex);
};

export default UPDATE_SUBMISSION_AUTHOR_ORDER;
