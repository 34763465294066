import generateGId from "~/store/manage/utilities/generateGId";
import {
  isRootCategory,
  hasParentCategory,
} from "~/plugins/utilities/category";

const SET_SUBMISSION_WORKFLOW = (state, { payload, featureToggles }) => {
  state.referenceData.subjectTypes = payload.subjectTypes;
  state.referenceData.categoryTypes = payload.categoryTypes;
  state.referenceData.contentTypes = payload.contentTypes;
  state.referenceData.filteredContentTypes =
    state.referenceData.contentTypes.filter(
      (contentType) =>
        (payload.submissionItemDependencies.community?.journalId &&
          contentType.allowResearchDirectionSubmission) ||
        (payload.submissionItemDependencies.community &&
          !payload.submissionItemDependencies.community.journalId &&
          contentType.allowCommunitySubmission) ||
        (!payload.submissionItemDependencies.community &&
          contentType.allowSubmission)
    );
  state.referenceData.communities = payload.communities;
  state.referenceData.relatedContent = payload.relatedContentOptions;

  const itemQuestion = payload.submissionItemDependencies.question;
  state.referenceData.questions = itemQuestion ? [itemQuestion] : [];

  const events = payload.origin.events.results;
  const itemEvent = payload.submissionItemDependencies.event;
  state.referenceData.events =
    !itemEvent || events.some((event) => event.id === itemEvent.id)
      ? events
      : events.concat(itemEvent);

  state.referenceData.confirmationTypes = payload.confirmationTypes.map(
    (item) => {
      item.label = item.description.substring(
        0,
        item.description.indexOf(" / ")
      );
      item.detail = item.description.substring(item.description.indexOf(" / "));
      return item;
    }
  );
  // HACK remove when data cleaned up
  state.referenceData.licenseTypes = payload.licenseTypes.map((item) => {
    item.label = item.label
      .replace("Licence", "")
      .replace("License", "")
      .trim();
    return item;
  });

  state.referenceData.authorTitleTypes = payload.authorTitleTypes.map(
    (item) => {
      return { id: item, name: item, description: item };
    }
  );

  if (payload.submissionItem) {
    // NOTE do this first because of vue array observation issues in IE11
    if (
      payload.submissionItem.authors &&
      payload.submissionItem.authors.length === 0
    ) {
      const newAuthor = {
        gid: generateGId(),
        titleTypeId: state.user.titleTypeId
          ? state.user.titleTypeId
          : undefined,
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        emailAddress: state.user.emailAddress,
        orcid: state.user.orcid,
        authorConfirmationId: undefined,
        displayOrder: 0,
        institutions: [],
      };

      payload.submissionItem.authors.push(newAuthor);
    } else if (payload.submissionItem.authors) {
      const firstAuthor = payload.submissionItem.authors[0];

      if (!firstAuthor.orcid) {
        firstAuthor.orcid = state.user.orcid;
      }
    }
  }

  const submissionItem = payload.submissionItem;
  if (submissionItem.categoryIds) {
    if (featureToggles.multipleCategoriesSubmission) {
      const submittedCategories = state.referenceData.categoryTypes
        .filter(({ id }) => submissionItem.categoryIds.includes(id))
        .sort(
          ({ id: a }, { id: b }) =>
            submissionItem.categoryIds.indexOf(a) -
            submissionItem.categoryIds.indexOf(b)
        );

      submissionItem.subcategoryIds = submittedCategories
        .filter(hasParentCategory)
        .map((category) => category.id);
      submissionItem.majorCategoryIds = submittedCategories
        .filter(isRootCategory)
        .map((category) => category.id);
      submissionItem.mainCategoryId = submissionItem.majorCategoryIds[0];
    } else {
      submissionItem.categoryId = payload.submissionItem.categoryIds[0];
    }

    delete submissionItem.categoryIds;
  }

  state.submissionItem = submissionItem;
  state.submissionItemDependencies = payload.submissionItemDependencies;

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_SUBMISSION_WORKFLOW;
