const mandatoryResponseChecks = [
  {
    severity: "ERROR",
    type: "REASON",
    fieldName: "message",
    errorMessage: "Retraction notice missing",
    resolutionMessage: "Please enter a notice for the retraction",
  },
];

export default mandatoryResponseChecks;
