import registerUsageEventQuery from "~/store/graphql/queries/registerUsageEvent.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

export default async function registerUsageEvent(
  { commit },
  { params, req, res }
) {
  return await runGraphQLQuery(
    registerUsageEventQuery,
    params,
    this.$axios,
    req,
    res
  );
}
