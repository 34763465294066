const SET_ADMINISTRATION_MORE_REPORTED_DISCUSSIONS = (state, payload) => {
  const results = payload.reportedDiscussions.results;

  state.currentAdministrator.reportedDiscussionsCount =
    payload.reportedDiscussions?.totalCount || 99999;

  if (results.length > 0) {
    const { reportedDiscussions } = state.currentAdministrator;
    state.currentAdministrator.reportedDiscussions = [
      ...reportedDiscussions,
      ...results,
    ];
  }
};

export default SET_ADMINISTRATION_MORE_REPORTED_DISCUSSIONS;
