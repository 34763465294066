const SET_ADMINISTRATION_MORE_COMMENTS = (state, payload) => {
  const results = payload.origin.reportedComments.results;

  state.currentAdministrator.reportedCommentsCount =
    payload.origin.reportedComments &&
    payload.origin.reportedComments.totalCount
      ? payload.origin.reportedComments.totalCount
      : 99999;

  if (results.length > 0) {
    const { reportedComments } = state.currentAdministrator;

    state.currentAdministrator.reportedComments = [
      ...reportedComments,
      ...results,
    ];
  }
};

export default SET_ADMINISTRATION_MORE_COMMENTS;
