// For assets served from constant URL, like event banner (URL only contains event id),
// Firefox uses memory cache despite 'no-store' cache setting.

const assetStepNames = [
  "smallThumbnail",
  "mediumThumbnail",
  "largeThumbnail",
  "original",
];

export default function cacheBustAssetUrls(asset) {
  assetStepNames.forEach((stepName) => {
    const url = new URL(asset[stepName].url);
    url.searchParams.append("cache-busting", Date.now());
    asset[stepName].url = url.toString();
  });
}
