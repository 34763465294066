import processItems from "~/store/manage/pages/adminstrationDashboard/utilities/processItems";

const SET_ADMINISTRATION_MORE_SUBMITTED = (
  state,
  { payload, featureToggles }
) => {
  const results = payload.origin.submittedItems.results;

  processItems(results, featureToggles);

  state.currentAdministrator.submittedItemsCount =
    payload.origin.submittedItems && payload.origin.submittedItems.totalCount
      ? payload.origin.submittedItems.totalCount
      : 0;

  if (results.length > 0) {
    const submittedItems = state.currentAdministrator.submittedItems;

    state.currentAdministrator.submittedItems = [...submittedItems, ...results];
  }
};

export default SET_ADMINISTRATION_MORE_SUBMITTED;
