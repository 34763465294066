<template>
  <div id="ShareRssFeedListener">
    <v-dialog
      ref="shareRssFeedDialogRef"
      v-model="show"
      attach="#ShareRssFeedListener"
      max-width="500"
      @keydown.esc="onClose"
    >
      <v-card>
        <v-card-title>
          <h2
            id="rss-dialog-title"
            class="headline"
            style="word-break: keep-all"
          >
            RSS feed for {{ rssFeedType }}
          </h2>
        </v-card-title>

        <v-card-text>
          <v-div pa-3 border-primary-all-thin>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="rssUrl" />
          </v-div>
          <v-div caption text-right overflow-hidden>
            <v-btn
              ref="copyToClipboardButton"
              class="onCopySuccess"
              :aria-label="`${hasCopied ? 'copied' : 'copy'} to clipboard`"
              text
              @click="copyClick"
            >
              {{ `${hasCopied ? "Copied" : "Copy"} to clipboard` }}
            </v-btn>
            <v-btn
              ref="copyButton"
              v-clipboard:copy="rssUrl"
              v-clipboard:success="onCopySuccess"
              v-clipboard:error="onCopyError"
              tabindex="-1"
              class="onCopySuccess copyButtonHidden"
              :aria-label="`${hasCopied ? 'copied' : 'copy'} to clipboard`"
              text
            >
              {{ `${hasCopied ? "Copied" : "Copy"} to clipboard` }}
            </v-btn>
          </v-div>
          <v-div caption
            >To subscribe to this feed, copy and paste the URL into your feed
            reader</v-div
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="onClose"
            color="warning"
            text
            aria-label="close"
            @click="onClose"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { displayIsMobile } from "~/components/utilities/displayBreakpoints";

export default {
  name: "ShareRssFeedListener",
  data() {
    return {
      show: false,
      rssUrl: undefined,
      rssFeedType: undefined,
      hasCopied: false,
      returnFocusTarget: undefined,
    };
  },
  computed: {
    displayIsMobile,
  },
  mounted() {
    this.$root.$on("show-rss-feed-share", this.onShowInformation);
  },
  methods: {
    onClose() {
      this.show = false;
      this.returnFocus();
    },
    setInitialFocus() {
      this.$nextTick(() => {
        this.$refs.copyToClipboardButton?.$el.focus();
      });
    },
    setAriaLabelAttribute() {
      this.$nextTick(() => {
        const dialogRef = this?.$refs?.shareRssFeedDialogRef?.$children[0]?.$el;

        if (dialogRef) {
          const titleByElementId =
            document.getElementById("rss-dialog-title").textContent;
          dialogRef.setAttribute("aria-label", titleByElementId);
        }
      });
    },
    copyClick() {
      this.$refs.copyButton.$el.click();
    },
    onShowInformation({ rssUrl, rssFeedType, returnFocusTarget }) {
      this.hasCopied = false;
      this.rssUrl = rssUrl;
      this.rssFeedType = rssFeedType;
      this.returnFocusTarget = returnFocusTarget;
      this.show = true;
      this.setInitialFocus();
      this.setAriaLabelAttribute();
    },
    onCopySuccess() {
      this.hasCopied = true;
    },
    onCopyError(e) {
      throw e;
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
  },
};
</script>

<style>
.rssTitle {
  white-space: pre-wrap;
  word-break: break-word;
}

/* vue-clipboard2 adds textarea to button after copy
   IE/Edge can tab into this text area, making copy button invisible
   so we are trying to hide it */
button.copyButtonHidden {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0 !important;
  position: absolute;
  left: -9999px;
  top: -9999px;
}
</style>
