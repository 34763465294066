const SET_PUBLIC_DASHBOARD = (state, payload) => {
  state.currentOrigin.subjectSummaries = payload.subjectSummaries;
  state.currentOrigin.categorySummaries = payload.categorySummaries;

  state.currentOrigin.metrics = payload.origin.metrics;

  state.currentOrigin.latestItems = payload.latestItems
    ? payload.latestItems.results.map((hit) => hit.item)
    : [];
  state.currentOrigin.latestEvents = payload.origin.eventSearch.results;

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;

  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_PUBLIC_DASHBOARD;
