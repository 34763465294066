import { set } from "vue";

import checkRetractionForErrors from "~/store/manage/validation/checkRetractionForErrors";

const NEW_REQUEST = { reason: "" };
const NEW_RESPONSE = { message: "" };
const NEW_RETRACTION = { request: NEW_REQUEST, response: NEW_RESPONSE };

const SET_RETRACTION_UPDATE = (state, payload) => {
  state.retractionItem =
    payload.itemSaveRetractionRequest ||
    payload.itemSubmitRetractionRequest ||
    payload.itemSaveRetractionResponse ||
    payload.itemSubmitRetractionResponse ||
    payload.itemCancelRetractionResponse;

  if (!state.retractionItem.retraction) {
    set(state.retractionItem, "retraction", NEW_RETRACTION);
  }

  if (!state.retractionItem.retraction.request) {
    set(state.retractionItem.retraction, "request", NEW_REQUEST);
  }

  if (!state.retractionItem.retraction.response) {
    set(state.retractionItem.retraction, "response", NEW_RESPONSE);
  }

  state.user = payload.user ? payload.user : state.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;

  state.retractionErrors = checkRetractionForErrors(state.retractionItem);
};

export default SET_RETRACTION_UPDATE;
