import { eventDelete } from "~/store/graphql/mutations/eventDelete.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import createEventForMutation from "~/store/manage/utilities/createEventForMutation";

const deleteEvent = async function ({ state }) {
  const proposedEvent = createEventForMutation(state.proposedEvent);

  await runGraphQLQuery(
    eventDelete,
    {
      id: proposedEvent.id.toString(),
    },
    this.$axios
  );
};

export default deleteEvent;
