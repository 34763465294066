<template>
  <LayoutGutters no-padding>
    <ContainerArea class="PlatformFooter">
      <v-row justify="space-between" class="px-4">
        <NotesActions class="pt-2" />
        <SocialActions class="pt-2" />
      </v-row>
    </ContainerArea>
  </LayoutGutters>
</template>

<script>
import ContainerArea from "~/components/layout/ContainerArea";
import LayoutGutters from "~/components/layout/LayoutGutters";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

import NotesActions from "~/components/page/components/actions/NotesActions";
import SocialActions from "~/components/page/components/actions/SocialActions";

export default {
  name: "PlatformFooter",
  components: {
    ContainerArea,
    LayoutGutters,
    NotesActions,
    SocialActions,
  },
  computed: {
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
  },
};
</script>

<style>
.PlatformFooter {
  z-index: 1;
}
</style>
