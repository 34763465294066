export default function sortedCaseInsensitive(values, key) {
  if (Array.isArray(values) && typeof key === "string") {
    return values.slice().sort((a, b) => {
      const A = a[key].toLowerCase();
      const B = b[key].toLowerCase();

      if (A !== undefined && B !== undefined) {
        if (A < B) return -1;
        if (A > B) return 1;
        return 0;
      }
      return 0;
    });
  }
}
