import eventSponsorUploadDataRefresh from "~/store/graphql/queries/eventSponsorUploadDataRefresh.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const refreshEventSponsorAsset = async function ({ commit, state }) {
  const id = state.proposedEvent ? state.proposedEvent.id : undefined;
  if (id) {
    const { proposedEvent } = await runGraphQLQuery(
      eventSponsorUploadDataRefresh,
      { id },
      this.$axios
    );
    proposedEvent.sponsors.forEach((sponsor) => {
      commit("MODIFY_EVENT_SPONSOR", {
        eventSponsorId: sponsor.id,
        field: "asset",
        value: sponsor.asset,
      });
    });
  }
};

export default refreshEventSponsorAsset;
