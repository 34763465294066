<template>
  <ContainerPage class="NotFoundPage">
    <LayoutFill :no-gutters="solidHeader" :no-padding="solidHeader">
      <MainBanner :title="pageTitle" :solid-header="solidHeader" />
    </LayoutFill>
    <LayoutFill>
      <div class="headline text-center">This page could not be found.</div>
    </LayoutFill>
    <LayoutFill>
      <div class="headline text-center">
        <v-btn
          class="toPublicDashboard"
          rounded
          outlined
          color="primary"
          aria-label="go back to home page"
          :to="`/engage/${partner}/public-dashboard`"
          >go back to homepage</v-btn
        >
      </div>
    </LayoutFill>
  </ContainerPage>
</template>

<script>
import { mapState } from "vuex";
import ContainerPage from "~/components/layout/ContainerPage";
import LayoutFill from "~/components/layout/LayoutFill";

import MainBanner from "~/components/banner/MainBanner.vue";

export default {
  name: "NotFoundPage",
  components: {
    ContainerPage,
    LayoutFill,
    MainBanner,
  },
  data() {
    return {
      pageTitle: "Page not found",
    };
  },
  computed: {
    ...mapState({
      layout: (state) => state.theme.layout,
    }),
    partner() {
      return this.$config.partner;
    },
    solidHeader() {
      return this.layout.mainBannerSolidHeader;
    },
  },
};
</script>
