const SET_SEARCH_REQUEST = (state, payload) => {
  state.searchRequest.text = payload.text;
  state.searchRequest.skip = payload.skip;

  state.searchRequest.sortBy = payload.sortBy;
  state.searchRequest.subject = payload.subject;
  state.searchRequest.category = payload.category;
  state.searchRequest.partner = payload.partner;
  state.searchRequest.content = payload.content;

  state.searchRequest.subjects = payload.subjects || [];
  state.searchRequest.categories = payload.categories || [];
  state.searchRequest.events = payload.events || [];
  state.searchRequest.publishedDates = payload.publishedDates || [];
  state.searchRequest.partners = payload.partners || [];
  state.searchRequest.contents = payload.contents || [];
  state.searchRequest.keywords = payload.keywords || [];

  state.searchRequest.authors = payload.authors || [];
};

export default SET_SEARCH_REQUEST;
