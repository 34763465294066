import authorDashboardPageLoad from "~/store/graphql/queries/authorDashboardPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadAuthorDashboard = async function ({ commit }, { req, res }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    authorDashboardPageLoad,
    {},
    this.$axios,
    req,
    res
  );

  if (data.user) {
    const featureToggles = {
      authorDashboardSubmitToJournal: this.$unleash.isEnabled(
        "authorDashboardSubmitToJournal"
      ),
    };

    commit("SET_AUTHOR_DASHBOARD", { payload: data, featureToggles });
  } else {
    commit("CLEAR_USER");
  }

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadAuthorDashboard;
