<template>
  <li role="none">
    <v-list-item :to="`/engage/${partner}/${link}`">
      <v-list-item-title>{{ text }}</v-list-item-title>
    </v-list-item>
    <v-divider v-if="showDivider" aria-hidden="true" />
  </li>
</template>

<script>
export default {
  props: {
    link: { type: String, required: true },
    text: { type: String, required: true },
    showDivider: { type: Boolean, default: false },
  },
  computed: {
    partner() {
      return this.$config.partner;
    },
  },
};
</script>
