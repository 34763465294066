import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import discussionReplyReport from "~/store/graphql/mutations/discussionReplyReport.graphql";

const reportDiscussionReply = async function (
  _,
  { discussionReplyId, reasonId }
) {
  const data = await runGraphQLQuery(
    discussionReplyReport,
    { discussionReplyId, reasonId },
    this.$axios
  );
  return Promise.resolve(data);
};

export default reportDiscussionReply;
