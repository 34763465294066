import { set } from "vue";

const UPDATE_SUBMISSION_ASSET = (state, payload) => {
  const { asset } = payload;

  if (
    state.submissionItem.asset &&
    state.submissionItem.asset.id === asset.id
  ) {
    set(state.submissionItem, "asset", asset);
  }

  const supplementaryAssetIndex =
    state.submissionItem.supplementaryAssets.indexOf(
      (item) => item.id === asset.id
    );

  if (supplementaryAssetIndex > -1) {
    state.submissionItem.supplementaryAssets[supplementaryAssetIndex] = asset;
  }
};

export default UPDATE_SUBMISSION_ASSET;
