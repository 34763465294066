import { set } from "vue";

const UPDATE_SUBMISSION_ASSETS = (state, payload) => {
  const oldData = state.submissionItem;
  const newData = payload.submissionItem;

  if (oldData && newData && oldData.id === newData.id) {
    set(oldData, "asset", newData.asset);

    const newSupplementaryAssets = [...oldData.supplementaryAssets];

    for (let i = 0; i < newSupplementaryAssets.length; i++) {
      const newSupplementaryAsset = { ...newSupplementaryAssets[i] };

      if (i < newData.supplementaryAssets.length) {
        newSupplementaryAsset.asset = newData.supplementaryAssets[i].asset;
      } else {
        newSupplementaryAsset.asset = undefined;
      }

      set(newSupplementaryAssets, i, newSupplementaryAsset);
    }

    set(oldData, "supplementaryAssets", newSupplementaryAssets);
  }
};

export default UPDATE_SUBMISSION_ASSETS;
