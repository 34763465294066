// NOTE have to use mutation name as defined, alias don't work
import { suppItemCreate } from "~/store/graphql/mutations/supplementaryCreate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const createSubmissionMaterial = async function ({ commit }, { params }) {
  const data = await runGraphQLQuery(
    suppItemCreate,
    { id: params.id },
    this.$axios
  );

  const newMaterial = {
    id: data.supplementaryCreate.id,
    title: "",
    description: "",
  };

  commit("CREATE_MATERIAL", newMaterial);

  return Promise.resolve(data);
};

export default createSubmissionMaterial;
