const CREATE_DISCUSSION_REPLY_VOTE = (state, payload) => {
  let discussionReply;
  if (payload.parentDiscussionReplyId) {
    const parentDiscussionReply =
      state.selectedDiscussion.latestDiscussionReplies.find(
        (discussionReply) =>
          discussionReply.id === payload.parentDiscussionReplyId
      );
    discussionReply = parentDiscussionReply.replies.find(
      (discussionReply) => discussionReply.id === payload.discussionReplyId
    );
  } else {
    discussionReply = state.selectedDiscussion.latestDiscussionReplies.find(
      (discussionReply) => discussionReply.id === payload.discussionReplyId
    );
  }

  if (discussionReply && !discussionReply.hasUserVoted) {
    ++discussionReply.votesCount;
    discussionReply.hasUserVoted = true;
  }
};

export default CREATE_DISCUSSION_REPLY_VOTE;
