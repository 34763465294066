import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

const submitForRespondRetraction = async function submitForReason() {
  const partner = this.$config.partner;

  this.showErrors = true;

  await this.$store.dispatch("validateRetraction");

  if (this.pageErrors && this.pageErrors.length > 0) {
    return;
  }

  try {
    await this.$store.dispatch("submitConfirmRetraction");
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }
  this.$router.push({
    path: `/engage/${partner}/administration-dashboard?show=retraction-requests`,
  });
};

export default submitForRespondRetraction;
