<template>
  <div class="VueListener" />
</template>

<script>
import { mapState } from "vuex";
import mapFlags from "~/plugins/utilities/mapFlags";

import ADMIN_VIEWS from "~/actions/utilities/ADMIN_VIEWS";
import DEFAULT_VIEWS from "~/actions/utilities/DEFAULT_VIEWS";
import localDate from "~/plugins/utilities/localDate";
import { hasModerationPrivilege } from "~/plugins/utilities/checkUserRoles";

export default {
  name: "VueListener",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      ...mapFlags(["showModerationNotes"]),
    }),
  },
  mounted() {
    this.$root.$on("change-view", this.onChangeView);
    this.$root.$on("confirm-before-change-view", this.validateBeforeChangeView);
    this.$root.$on("default-view", this.onDefaultView);
  },
  methods: {
    onDefaultView({ item = {}, event, comment, discussion, discussionReply }) {
      if (event) {
        this.onChangeView({ item: event, view: "proposal-process/step-one" });
      } else if (comment) {
        this.onChangeView({ item: comment, view: "comment-review" });
      } else if (discussion) {
        this.onChangeView({ item: discussion, view: "discussion-review" });
      } else if (discussionReply) {
        this.onChangeView({
          item: discussionReply,
          view: "discussion-reply-review",
        });
      } else {
        const adminAuthorized = hasModerationPrivilege(this.user);

        const reviewPageForPublishedItem = this.showModerationNotes
          ? {
              PUBLISHED: "processed-submission",
              RETRACTED: "processed-submission",
              REJECTED: "processed-submission",
            }
          : {};

        const ADMIN_ONLY_VIEWS = adminAuthorized
          ? { ...ADMIN_VIEWS, ...reviewPageForPublishedItem }
          : {};

        const { status } = item;
        const view = ADMIN_ONLY_VIEWS[status] || DEFAULT_VIEWS[status];

        if (view === null) {
          return;
        }

        this.onChangeView({ item, view });
      }
    },
    onChangeView({ item, view }) {
      const partner = this.$config.partner;
      const id =
        typeof item === "object" && item !== null
          ? item.id || item.gid || item.funderId
          : item;
      const newView = view.includes("/engage/")
        ? view
        : `/engage/${partner}/${view}`;
      const path = id ? newView + "/" + id : newView;
      this.$router.push({ path });
    },
    validateBeforeChangeView({ item, view, currentTarget }) {
      const journal = item.latestJournalSubmission;

      if (journal) {
        const submissionDate = localDate(
          journal.submissionDate,
          "EEE MMM dd yyyy"
        );
        this.$root.$emit("show-confirmation", {
          instruction: `You have already submitted this content to ${journal.journalName} on ${submissionDate}. It is against most journals' submission policies to have your content under review at more than one journal at the same time. Are you sure you want to continue?`,
          action: (_) => this.onChangeView({ item, view }),
          confirmationButtonText: "Proceed",
          currentTarget,
        });
      } else {
        this.onChangeView({ item, view });
      }
    },
  },
};
</script>
