import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

export default async function assignToCurator(params) {
  try {
    await this.$store.dispatch("assignToCurator", params);
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }
}
