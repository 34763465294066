const submissionReportChecks = [
  {
    severity: "ERROR",
    type: "END_DATE_VALIDATION",
    fieldName: "endDate",
    errorMessage: "End date cannot be before start date",
    resolutionMessage:
      "Please enter an end date which is greater than the start date",
  },
  {
    severity: "ERROR",
    type: "NO_DATA",
    fieldName: "endDate",
    errorMessage: "No data for specified period",
    resolutionMessage: "No data for specified period",
  },
];

export default submissionReportChecks;
