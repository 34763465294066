// NOTE have to use mutation name as defined, alias don't work
import { suppItemDelete } from "~/store/graphql/mutations/supplementaryDelete.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const deleteSubmissionMaterial = async function (
  { commit, dispatch },
  { params }
) {
  commit("DELETE_MATERIAL", params.id);
  dispatch("validateSubmission");

  const data = await runGraphQLQuery(
    suppItemDelete,
    { id: params.id },
    this.$axios
  );

  return Promise.resolve(data);
};

export default deleteSubmissionMaterial;
