import selectedArticlesLoad from "~/store/graphql/queries/selectedArticlesLoad.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadPlatformDashboardSelectedArticles = async function (
  { commit },
  params
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(selectedArticlesLoad, params, this.$axios);

  commit("SET_SELECTED_ARTICLES", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadPlatformDashboardSelectedArticles;
