import latestContentPageLoad from "~/store/graphql/queries/latestContentPageLoad.graphql";
import latestCategoryPageLoad from "~/store/graphql/queries/latestCategoryPageLoad.graphql";
import latestSubjectPageLoad from "~/store/graphql/queries/latestSubjectPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadLatestContent = async function ({ commit }, { query, req, res }) {
  commit("START_LOADING");

  const graphQL = query.category
    ? latestCategoryPageLoad
    : query.subject
    ? latestSubjectPageLoad
    : latestContentPageLoad;
  query.publishedRange = this.state.theme.lastItemsRange || "LAST_3_MONTHS";

  const data = await runGraphQLQuery(graphQL, query, this.$axios, req, res);

  commit("SET_LATEST_CONTENT", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadLatestContent;
