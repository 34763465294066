import { hasCurationPrivilege } from "~/plugins/utilities/checkUserRoles";

const REMOVE_ITEM_FROM_SITE_MODERATOR_SUBMISSIONS = (state, itemId) => {
  const { user } = state;

  if (hasCurationPrivilege(user)) {
    const moderatedItem = state.currentAdministrator.submittedItems.find(
      (i) => i.id === itemId
    );
    moderatedItem.isModerated = false;
    moderatedItem.moderators = [];
  } else {
    state.currentAdministrator.submittedItemsCount =
      state.currentAdministrator.submittedItemsCount - 1;
    state.currentAdministrator.submittedItems =
      state.currentAdministrator.submittedItems.filter((i) => i.id !== itemId);
  }
};

export default REMOVE_ITEM_FROM_SITE_MODERATOR_SUBMISSIONS;
