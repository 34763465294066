import reviewUserStepPageLoad from "~/store/graphql/queries/reviewUserStepPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadUserRevisionProcess = async function (
  { commit, dispatch },
  { req, res, params }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    reviewUserStepPageLoad,
    params,
    this.$axios,
    req,
    res
  );

  const featureToggles = {
    multipleCategoriesSubmission: this.$unleash.isEnabled(
      "multipleCategoriesSubmission"
    ),
  };

  commit("SET_REVISION_WORKFLOW", { payload: data, featureToggles });
  dispatch("validateSubmission");
  commit("END_LOADING");

  return data;
};

export default loadUserRevisionProcess;
