import reportedDiscussionReplyQuery from "~/store/graphql/queries/reportedDiscussionReply.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadDiscussionReplyReview = async function (
  { commit },
  { req, res, params }
) {
  await commit("START_LOADING");

  const data = await runGraphQLQuery(
    reportedDiscussionReplyQuery,
    { id: params.id },
    this.$axios,
    req,
    res
  );

  commit("SET_DISCUSSION_REPLY_REVIEW", data);
  commit("END_LOADING");

  return data;
};

export default loadDiscussionReplyReview;
