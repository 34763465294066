<template>
  <div class="notes-actions font-smaller">
    <div
      v-for="(note, i) in notes"
      :key="i"
      class="test inverted--text d-inline-block pt-2 mr-4"
    >
      <a
        v-if="setNoteExternal(note)"
        :href="setNoteUrl(note)"
        target="_blank"
        :aria-label="`${note.title} [opens in a new tab]`"
        rel="noopener"
        >{{ note.title }}</a
      >
      <nuxt-link v-else :to="note.url">{{ note.title }}</nuxt-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mapFlags from "~/plugins/utilities/mapFlags";

export default {
  name: "NotesActions",
  computed: {
    partner() {
      return this.$config.partner;
    },
    omniChannelUrl() {
      return this.$config.CONTACT_HELP_URL;
    },
    ...mapFlags(["redirectContactAndHelpUrl"]),
    ...mapState({
      notes: (state) => state.theme.notes,
    }),
  },
  methods: {
    setNoteParams(note, newValue, defaultValue) {
      if (note.title === "Contact and Help") {
        return this.redirectContactAndHelpUrl ? newValue : defaultValue;
      }
      return defaultValue;
    },
    setNoteUrl(note) {
      const contactAndHelpUrl = this.omniChannelUrl;
      return this.setNoteParams(note, contactAndHelpUrl, note.url);
    },
    setNoteExternal(note) {
      const isExternal = true;
      return this.setNoteParams(note, isExternal, note.external);
    },
  },
};
</script>
