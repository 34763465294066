<template>
  <v-app :key="displayIs" :style="mainContentStyle" class="DefaultLayout">
    <div v-if="globalHeader" class="d-flex">
      <div class="global-header-wrapper flex-grow-0" />
      <global-header :environment="deployEnv" class="flex-grow-1" />
    </div>

    <nav aria-label="accessibility help">
      <div ref="pageStart" tabindex="-1" class="d-sr-only"></div>
      <a ref="skipLink" class="skipLink" href="#main-content"
        >Skip to main content</a
      >
      <a class="skipLink" :href="`/engage/${partner}/accessibility-information`"
        >Accessibility information</a
      >
    </nav>
    <ApplicationAction />
    <div class="loading-indicator">
      <v-progress-circular
        v-show="isLoading === true"
        :indeterminate="true"
        size="128"
        width="8"
        color="primary"
        aria-label="Loading content"
      ></v-progress-circular>
    </div>

    <CookieInformation v-if="!useOneTrustCookieManager" />
    <v-app-bar
      v-if="showPlatformHeader"
      aria-label="platform header"
      class="platformHeader"
      :class="{
        'elevation-8': topOffset === platformHeaderMarginTop,
        stickyHeader,
      }"
      :height="platformHeaderHeight"
      :max-height="!displayIsMobile ? platformHeaderHeight : 'auto'"
      :min-height="!displayIsMobile ? platformHeaderHeight : 'auto'"
      :app="false"
      :fixed="false"
      flat
      :color="platformHeaderColor"
    >
      <PlatformHeader show-search :show-menu="platformShowUserMenu" />
    </v-app-bar>
    <v-app-bar
      aria-label="partner header"
      class="partnerHeader"
      :class="{ stickyHeader }"
      :height="pageHeaderHeight"
      :max-height="!displayIsMobile ? pageHeaderHeight : 'auto'"
      :min-height="!displayIsMobile ? pageHeaderHeight : 'auto'"
      :fixed="false"
      text
      :dark="pageHeaderInvertedText"
      :color="pageHeaderColor"
      :flat="pageHeaderFlat"
    >
      <PageHeaderInstitution v-if="isInstitution" />
      <PageHeader
        v-else
        :dashboard-link="pageHeaderDashboardLink"
        :reduced-size="reducedSize"
        :actions-center="!reducedSize"
        :inverted="pageHeaderInvertedText"
      />
    </v-app-bar>
    <v-main
      id="main-content"
      v-scroll="onScroll"
      tabindex="-1"
      :style="{ paddingBottom: 0 + 'px', minHeight: contentMinHeight }"
    >
      <nuxt />
    </v-main>
    <nav
      aria-label="partner footer links"
      :style="`height: ${pageFooterHeight}px`"
      class="dim pt-4"
    >
      <PageFooter />
    </nav>
    <transition name="fade-transition">
      <v-footer
        v-show="showPlatformFooter"
        :app="isPlatformFooterApp"
        :fixed="isPlatformFooterFixed"
        color="dimer"
        :min-height="platformFooterHeight"
        class="main-footer no-underline px-0"
      >
        <PlatformFooter />
        <v-div v-if="displayIsDesktop" absolute right="20" layer-999>
          <VersionActions
            :version="version"
            :usage-events-disabled="usageEventsDisabled"
          />
        </v-div>
      </v-footer>
    </transition>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import LRUCache from "lru-cache";
import mapFlags from "~/plugins/utilities/mapFlags";

import { createCssHeaders } from "~/components/utilities/createCssHeaders";
import { easeOutQuart } from "~/components/utilities/easing";

import {
  displayIsMobile,
  displayIsTablet,
  displayIsDesktop,
  displayIs,
} from "~/components/utilities/displayBreakpoints";

import ApplicationAction from "~/actions/ApplicationAction.vue";

import PlatformHeader from "~/components/page/PlatformHeader.vue";
import PlatformFooter from "~/components/page/PlatformFooter.vue";

import PageHeader from "~/components/page/PageHeader.vue";
import PageHeaderInstitution from "~/components/page/institution/PageHeaderInstitution.vue";

import PageFooter from "~/components/page/PageFooter.vue";

import VersionActions from "~/components/page/components/actions/VersionActions";
import CookieInformation from "~/components/page/CookieInformation";

const PLATFORM_HEADER_HEIGHT = 56;
const PLATFORM_HEADER_ZINDEX = 10;

const PAGE_HEADER_HEIGHT_LARGE = 104;
const PAGE_HEADER_HEIGHT_SMALL = 76;
const PAGE_HEADER_HEIGHT_REDUCED = 56;

const PAGE_FOOTER_HEIGHT_SMALL = 336;
const PAGE_FOOTER_HEIGHT_SMALL_MENU_EXTENDED = 400;
const PAGE_FOOTER_HEIGHT_LARGE = 348;
const PAGE_FOOTER_HEIGHT_LARGE_MENU_EXTENDED = 381;

const PLATFORM_FOOTER_HEIGHT_LARGE = 156;
const PLATFORM_FOOTER_HEIGHT_SMALL = 74;

const OVERLAY_RATE = 1;

// SOURCE https://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom
const FIX_FOR_ISSUES_WITH_MAC = 2;

const isAtBottomOfWindow = (offset = 0) => {
  return (
    window.innerHeight + window.pageYOffset >=
    document.body.offsetHeight - FIX_FOR_ISSUES_WITH_MAC - offset
  );
};

const USE_CACHE = false;
const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000;

const cache = new LRUCache({
  ttl: TWENTY_FOUR_HOURS,
  ttlAutopurge: true,
});

export default {
  components: {
    ApplicationAction,
    CookieInformation,
    PlatformHeader,
    PageHeader,
    PageHeaderInstitution,
    PageFooter,
    PlatformFooter,
    VersionActions,
  },
  data() {
    const { isInDevelopment, VERSION } = this.$config;
    const status = isInDevelopment ? " - DEV" : "";

    return {
      platformHeaderHeight: PLATFORM_HEADER_HEIGHT,

      scrollOffset: 0,

      isPlatformHeaderApp: true,
      isPlatformHeaderFixed: true,
      platformHeaderMarginTop: PLATFORM_HEADER_HEIGHT,

      contentMargintop: PLATFORM_HEADER_HEIGHT,

      isPlatformFooterApp: true,
      isPlatformFooterFixed: true,

      showPlatformFooter: false,

      version: VERSION + status,
    };
  },
  head() {
    const canCache = !!(USE_CACHE && process && process.server);
    const icon = this.theme.icon || "/favicon.ico";

    let headers;

    if (canCache) {
      headers = cache.get(this.theme.id);
    }

    if (!headers) {
      headers = createCssHeaders(this.theme);

      if (canCache) {
        cache.set(this.theme.id, headers);
      }
    }

    if (!headers.link) {
      headers.link = [];
    }

    headers.link.push({ rel: "icon", type: "image/x-icon", href: icon });

    const headerScripts = [
      {
        src: this.useNewGlobalHeaderUrl
          ? this.$config.NEW_GLOBAL_HEADER_URL
          : this.$config.GLOBAL_HEADER_URL,
        defer: true,
      },
    ];

    return {
      ...headers,
      script: headerScripts,
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      user: (state) => state.user,
      layout: (state) => state.theme.layout,
      isLoading: (state) => state.isLoading,
      usageEventsDisabled: (state) => state.usageEventsDisabled,
      isInstitution: (state) => state.theme.isInstitution,
    }),
    ...mapFlags([
      "quickActionShowCommunities",
      "globalHeader",
      "useOneTrustCookieManager",
      "useNewGlobalHeaderUrl",
    ]),
    displayIsMobile,
    displayIsTablet,
    displayIsDesktop,
    displayIs,
    partner() {
      return this.$config.partner;
    },
    deployEnv() {
      return this.$config.DEPLOY_ENV === "prod" ? "PROD" : "SI";
    },
    pageHeaderFlat() {
      return this.layout.defaultLayoutPageHeaderFlat;
    },
    platformHeaderColor() {
      return this.layout.defaultLayoutPlatformHeaderColor;
    },
    pageHeaderColor() {
      return this.layout.defaultLayoutPageHeaderColor;
    },
    pageHeaderInvertedText() {
      return this.layout.defaultLayoutPageHeaderInvertedText;
    },
    pageHeaderDashboardLink() {
      return this.layout.defaultLayoutPageHeaderDashboardLink;
    },
    reducedSize() {
      return this.layout.defaultLayoutReducedSize;
    },
    contentOffset() {
      if (this.showPlatformHeader === false) {
        return this.pageHeaderHeight;
      }

      return this.contentMargintop === 0 ? 0 : this.pageHeaderHeight;
    },
    contentMinHeight() {
      return `calc(100vh - ${
        this.contentOffset + this.pageFooterHeight + this.platformFooterHeight
      }px)`;
    },
    topOffset() {
      if (this.showPlatformHeader === false) {
        return 0;
      }

      const offset = this.platformHeaderMarginTop * OVERLAY_RATE;
      const position = this.scrollOffset;

      const isTop = position >= offset;

      let nudge =
        (offset - position) / OVERLAY_RATE / this.platformHeaderMarginTop;

      nudge = nudge > 0 ? easeOutQuart(nudge) : 0;

      return isTop ? 0 : Math.floor((nudge * offset) / OVERLAY_RATE);
    },
    topZIndex() {
      return this.topOffset === PLATFORM_HEADER_HEIGHT
        ? PLATFORM_HEADER_ZINDEX + 5
        : PLATFORM_HEADER_ZINDEX;
    },
    showPlatformHeader() {
      return this.layout.defaultLayoutPlatformShow;
    },
    platformShowUserMenu() {
      return this.layout.defaultLayoutPlatformShowUserMenu;
    },
    mainContentStyle() {
      return {
        "--main-content-top": `${
          this.platformHeaderHeight + this.pageHeaderHeight
        }px`,
        "--contentOffset": `${this.contentOffset}px`,
        "--insetHeight": `calc(100vh - ${this.contentOffset}px - ${this.platformFooterHeight}px)`,
        "--platformHeaderZIndex":
          this.topZIndex +
          (this.topOffset === this.platformHeaderMarginTop ? 1 : -1),
        "--partnerHeaderZIndex": this.topZIndex,
      };
    },
    pageFooterHeight() {
      if (this.displayIsMobile) {
        return this.quickActionShowCommunities
          ? PAGE_FOOTER_HEIGHT_LARGE_MENU_EXTENDED
          : PAGE_FOOTER_HEIGHT_LARGE;
      }

      return this.quickActionShowCommunities
        ? PAGE_FOOTER_HEIGHT_SMALL_MENU_EXTENDED
        : PAGE_FOOTER_HEIGHT_SMALL;
    },
    pageHeaderHeight() {
      if (this.displayIsMobile) {
        return this.reducedSize ? 0 : PAGE_HEADER_HEIGHT_SMALL;
      }

      if (this.displayIsTablet) {
        return this.reducedSize
          ? PAGE_HEADER_HEIGHT_REDUCED
          : PAGE_HEADER_HEIGHT_SMALL;
      }

      return this.reducedSize
        ? PAGE_HEADER_HEIGHT_REDUCED
        : PAGE_HEADER_HEIGHT_LARGE;
    },
    platformFooterHeight() {
      if (this.displayIsMobile) {
        return PLATFORM_FOOTER_HEIGHT_LARGE;
      }

      return PLATFORM_FOOTER_HEIGHT_SMALL;
    },
    stickyHeader() {
      return !this.displayIsMobile;
    },
  },

  watch: {
    displayIs() {
      if (this.displayIsMobile) {
        this.setFloatingDisplay();
      } else {
        this.setFixedDisplay();
      }
    },
  },

  mounted() {
    this.$root.$on("set-focus-to-page-start", () => {
      this.$refs.pageStart.focus();
    });

    if (this.displayIsDesktop) {
      this.setFixedDisplay();
    } else if (this.displayIsTablet) {
      this.setFixedDisplay();
    } else if (this.displayIsMobile) {
      this.setFloatingDisplay();
    }

    this.$store.commit("SET_IS_MOUNTED", true);
    this.showPlatformFooter = true;
    if (this.$route.query["author-sync"] === "success") {
      this.$root.$emit("orcid-id-sync-success");
    }
  },
  methods: {
    setFloatingDisplay() {
      this.isPlatformHeaderApp = false;
      this.isPlatformHeaderFixed = false;
      this.platformHeaderMarginTop = 0;

      this.contentMargintop = 0;

      this.isPlatformFooterApp = false;
      this.isPlatformFooterFixed = false;
    },
    setFixedDisplay() {
      this.isPlatformHeaderApp = true;
      this.isPlatformHeaderFixed = true;
      this.platformHeaderMarginTop = this.platformHeaderHeight;

      this.contentMargintop = this.platformHeaderHeight;

      this.isPlatformFooterApp = true;
      this.isPlatformFooterFixed = true;
    },
    onScroll() {
      const offset =
        this.contentMargintop === 0
          ? this.pageFooterHeight + this.platformFooterHeight
          : this.pageFooterHeight;

      this.scrollOffset = window.pageYOffset;

      if (isAtBottomOfWindow(offset) === true) {
        this.$root.$emit("bottom-of-page");
      }
    },
  },
};
</script>

<style>
.skipLink {
  top: -30%;
  padding: 0.5em;
  position: fixed;
  z-index: 9000;
  background-color: white;
  border: 1px solid black;
  text-decoration: none;
}
.skipLink:focus {
  top: 0;
}

#main-content {
  scroll-margin-top: var(--main-content-top);
  overflow: visible !important;
}

html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.fade-enter-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.DefaultLayout .loading-indicator {
  position: fixed;
  left: calc(50% - 64px);
  top: calc(50% - 64px);
  z-index: 9999;
  opacity: 0.1;
}

.stickyHeader {
  position: sticky !important;
}

.platformHeader,
.partnerHeader {
  top: 0;
}

.platformHeader {
  z-index: var(--platformHeaderZIndex);
}

.partnerHeader {
  z-index: var(--partnerHeaderZIndex);
}

.partnerHeader > .v-toolbar__content {
  padding-left: 0;
  padding-right: 0;
}

.global-header-wrapper {
  height: 46px;
}

.main-footer {
  z-index: 10;
}
</style>
