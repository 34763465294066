import submitChoosePageLoad from "~/store/graphql/queries/submitChoosePageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadChooseProcess = async function ({ commit }, { req, res, params }) {
  commit("START_LOADING");
  const data = await runGraphQLQuery(
    submitChoosePageLoad,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_SUBMISSION_CHOOSE", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadChooseProcess;
