<template>
  <div id="listener-container" class="ApplicationAction">
    <InformationListener />
    <ConfirmationListener />
    <ReportListener />
    <AssignToModeratorListener />
    <AssignToCuratorListener />
    <ShareRssFeedListener />
    <VerificationListener />
    <AssetListener />
    <StatusListener />
    <ViewListener />
    <RejectionOptionListener />
    <LoginListener />
    <AccessibleSnackbar />
    <BespokeEmailListener />
  </div>
</template>

<script>
import InformationListener from "~/actions/listeners/InformationListener.vue";
import ConfirmationListener from "~/actions/listeners/ConfirmationListener.vue";
import ReportListener from "~/actions/listeners/ReportListener.vue";
import AssignToModeratorListener from "~/actions/listeners/AssignToModeratorListener";
import AssignToCuratorListener from "~/actions/listeners/AssignToCuratorListener";
import ShareRssFeedListener from "~/actions/listeners/ShareRssFeedListener.vue";
import VerificationListener from "~/actions/listeners/VerificationListener.vue";
import AssetListener from "~/actions/listeners/AssetListener.vue";
import StatusListener from "~/actions/listeners/StatusListener.vue";
import ViewListener from "~/actions/listeners/ViewListener.vue";
import RejectionOptionListener from "~/actions/listeners/RejectionOptionListener.vue";
import LoginListener from "~/actions/listeners/LoginListener.vue";
import AccessibleSnackbar from "~/actions/listeners/AccessibleSnackbar.vue";
import BespokeEmailListener from "~/actions/listeners/BespokeEmailListener.vue";

export default {
  name: "ApplicationAction",
  components: {
    InformationListener,
    ConfirmationListener,
    ReportListener,
    AssignToModeratorListener,
    AssignToCuratorListener,
    ShareRssFeedListener,
    VerificationListener,
    AssetListener,
    StatusListener,
    ViewListener,
    RejectionOptionListener,
    LoginListener,
    AccessibleSnackbar,
    BespokeEmailListener,
  },
};
</script>
