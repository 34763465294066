<template>
  <v-app class="BarebonesLayout">
    <nuxt />
  </v-app>
</template>

<script>
import { mapState } from "vuex";

import { createCssHeaders } from "~/components/utilities/createCssHeaders";

export default {
  data() {
    return {};
  },
  head() {
    return createCssHeaders(this.theme);
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
    }),
  },
};
</script>

<style>
html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}
</style>
