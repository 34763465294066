import searchDashboardPageLoad from "~/store/graphql/queries/searchDashboardPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadSearchMoreMatches = async function (
  { commit },
  { params, req, res }
) {
  // HACK - to be removed also in loadSearchDashboard
  const hackedParams = { ...params };
  const authors =
    params.authors && params.authors.length > 0
      ? params.authors.join(" ")
      : undefined;
  delete hackedParams.authors;
  if (authors) {
    hackedParams.authors = authors;
  }

  const data = await runGraphQLQuery(
    searchDashboardPageLoad,
    hackedParams,
    this.$axios,
    req,
    res
  );

  commit("SET_SEARCH_MORE", data);

  return Promise.resolve(data);
};

export default loadSearchMoreMatches;
