const SET_ARTICLE_DETAILS = (state, payload) => {
  state.selectedItem = payload.selectedItem;
  state.selectedOrigin = payload.origin;

  state.referenceData.commentReportReasonTypes = payload.commentReportReasons;

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
  state.networkMapUrl = payload.networkMapUrl;
};

export default SET_ARTICLE_DETAILS;
