const regexVersionChecks = [
  {
    severity: "ERROR",
    type: "VERSION",
    fieldName: "versionNote",
    countRegex: /\s*\S+/gim,
    minCount: 1,
    maxCount: 150,
    errorMessage: "Change summary is too long",
    resolutionMessage:
      "Please enter a summary of changes ensuring it does not exceed 150 words",
  },
];

export default regexVersionChecks;
