const SET_ADMINISTRATION_MORE_REPORTED_DISCUSSION_REPLIES = (
  state,
  { reportedDiscussionReplies }
) => {
  const { results, totalCount } = reportedDiscussionReplies;

  state.currentAdministrator.reportedDiscussionRepliesCount =
    totalCount || 99999;

  if (results.length > 0) {
    const { reportedDiscussionReplies } = state.currentAdministrator;
    state.currentAdministrator.reportedDiscussionReplies = [
      ...reportedDiscussionReplies,
      ...results,
    ];
  }
};

export default SET_ADMINISTRATION_MORE_REPORTED_DISCUSSION_REPLIES;
