export default function isMainVideoContentAllowed(
  events,
  eventId,
  featureToggles
) {
  const event = events.find((event) => event.id === eventId);
  return typeof event?.allowVideo === "boolean"
    ? event.allowVideo
    : featureToggles.submissionAllowPrimaryVideoUpload;
}
