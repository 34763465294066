import emailValidator from "email-validator";
import createError from "~/store/manage/validation/utilities/createError";
import siteModeratorsEmailChecks from "~/store/manage/validation/checks/siteModeratorsEmailChecks.js";

export default function MODIFY_SITE_MODERATORS(state, payload) {
  const siteModerators = state.siteModerators;
  const { field, value } = payload;

  const validatedEmails = value
    .map((e) => e.trim())
    .map((e) => ({ email: e, isValid: emailValidator.validate(e) }))
    .filter((e) => e.email.length > 0);
  const validEmails = validatedEmails
    .filter((e) => e.isValid)
    .map((e) => e.email);
  const invalidEmails = validatedEmails
    .filter((e) => !e.isValid)
    .map((e) => e.email);

  siteModerators[field] = validEmails;

  const errors = state.proposedSiteModeratorsErrors.filter(
    (e) => e.fieldName !== field
  );

  if (invalidEmails.length > 0) {
    const errorMessage =
      siteModeratorsEmailChecks[0].errorMessage + invalidEmails.join(", ");
    errors.push(
      createError(siteModerators, {
        ...siteModeratorsEmailChecks[0],
        errorMessage,
      })
    );
  }

  state.proposedSiteModeratorsErrors = errors;
}
