<template>
  <div id="ConfirmationListener">
    <v-dialog
      ref="confirmationDialogRef"
      v-model="show"
      attach="#ConfirmationListener"
      class="ConfirmationListener"
      max-width="400"
      @keydown.esc="onClose"
    >
      <v-card>
        <v-card-title>
          <h2
            v-if="title"
            id="confirmation-dialog-title"
            class="headline"
            style="word-break: keep-all"
          >
            <ArticleTitle :article-html-title="title" />
          </h2>
        </v-card-title>
        <v-card-text>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="instruction"
            id="confirmation-dialog-instruction"
            v-html="instruction"
          />
          <!-- eslint-enable vue/no-v-html -->
          <v-text-field
            v-if="code"
            ref="userCodeInput"
            v-model="userCode"
            :aria-labelledby="title ? 'confirmation-dialog-title' : false"
            :aria-describedby="
              instruction ? 'confirmation-dialog-instruction' : false
            "
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            ref="closeConfirmationListener"
            class="closeConfirmationListener"
            color="warning"
            text
            aria-label="close"
            @click="onClose"
            >Close</v-btn
          >
          <v-btn
            class="okConfirmationListener"
            :disabled="isDisabled || confirmIsDisabled"
            color="success"
            text
            :aria-label="confirmationButtonAria"
            @click="onConfirm"
          >
            {{ code || confirmationButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ArticleTitle from "~/components/articles/components/ArticleTitle.vue";

export default {
  name: "ConfirmationListener",
  components: {
    ArticleTitle,
  },
  data() {
    return {
      show: false,
      confirmIsDisabled: false,
      title: undefined,
      instruction: undefined,
      code: undefined,
      confirmationButtonText: undefined,

      action: undefined,
      returnFocusTarget: undefined,
      userCode: "",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isDisabled() {
      return this.code
        ? this.code.toUpperCase() !== this.userCode.toUpperCase()
        : false;
    },
    confirmationButtonAria() {
      return this.code
        ? this.code.toLowerCase()
        : this.confirmationButtonText
        ? this.confirmationButtonText.toLowerCase()
        : "";
    },
  },
  mounted() {
    this.$root.$on("show-confirmation", this.onShowInformation);
  },
  methods: {
    onShowInformation({
      title,
      instruction,
      code,
      action,
      confirmationButtonText = "OK",
      currentTarget,
    }) {
      this.title = title;
      this.instruction = instruction;
      this.code = code;
      this.confirmationButtonText = confirmationButtonText;
      this.returnFocusTarget = currentTarget;
      this.action = action;

      this.show = true;
      this.setInitialFocus();
      this.setAriaLabelAttribute();
    },
    setInitialFocus() {
      this.$nextTick(() => {
        const { userCodeInput, closeConfirmationListener } = this.$refs;
        userCodeInput?.focus() || closeConfirmationListener?.$el.focus();
      });
    },
    setAriaLabelAttribute() {
      this.$nextTick(() => {
        const dialogRef = this?.$refs?.confirmationDialogRef?.$children[0]?.$el;

        if (dialogRef) {
          const ariaDescribedbyElement = this.title
            ? "confirmation-dialog-title"
            : "confirmation-dialog-instruction";
          const titleByElementId = document.getElementById(
            ariaDescribedbyElement
          ).textContent;
          dialogRef.setAttribute("aria-label", titleByElementId);
        }
      });
    },
    async onConfirm() {
      this.show = false;

      if (this.action && !this.confirmIsDisabled) {
        this.confirmIsDisabled = true;

        try {
          await this.action();
        } catch (error) {
          this.userCode = "";
          this.confirmIsDisabled = false;
          throw error;
        }
      }

      this.userCode = "";
      this.confirmIsDisabled = false;
    },
    onClose() {
      this.show = false;
      this.returnFocus();
      this.userCode = "";
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
  },
};
</script>
