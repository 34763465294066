import { eventCreate } from "~/store/graphql/mutations/eventCreate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const createEvent = async function (context, { req, res }) {
  const data = await runGraphQLQuery(eventCreate, {}, this.$axios, req, res);

  return Promise.resolve(data);
};

export default createEvent;
