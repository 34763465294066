import { userRemoveOrcid } from "~/store/graphql/mutations/userRemoveOrcid.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const deleteAuthorOrcid = async function ({ commit }) {
  commit("CLEAR_AUTHOR_ORCID");

  await runGraphQLQuery(userRemoveOrcid, {}, this.$axios);
};

export default deleteAuthorOrcid;
