export default function version(
  value,
  showMinor,
  status,
  nextProspectiveVersion = false
) {
  if (!value) {
    return undefined;
  }

  const major = value.includes(".") ? value.split(".")[0] : value;
  const minor = value.includes(".") ? value.split(".")[1] : undefined;

  if (status === "REJECTED") {
    return undefined;
  } else if (nextProspectiveVersion) {
    return Math.ceil(value);
  } else if (
    status === "LOADED" ||
    status === "PROCESSING_SUBMISSION" ||
    status === "SUBMITTED"
  ) {
    return "New";
  } else if (showMinor === true) {
    return major + "." + minor;
  } else {
    if (major === "0") {
      return undefined;
    }

    return major;
  }
}
