import { itemUpdate } from "~/store/graphql/mutations/itemUpdate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import createItemForMutation from "~/store/manage/utilities/createItemForMutation";

const removeSubmissionWebLink = async function (
  { commit, state, dispatch },
  { params }
) {
  const submission = createItemForMutation(state.submissionItem);
  const webLinks = submission.webLinks || [];
  const removeindex = webLinks.findIndex((item) => item.gid === params.id);

  if (removeindex > -1) {
    submission.webLinks.splice(removeindex, 1);
  }

  const data = await runGraphQLQuery(
    itemUpdate,
    {
      id: submission.id.toString(),
      item: submission,
    },
    this.$axios
  );

  if (data) {
    const featureToggles = {
      multipleCategoriesSubmission: this.$unleash.isEnabled(
        "multipleCategoriesSubmission"
      ),
    };

    commit("UPDATE_ITEM", { payload: data, featureToggles });
    dispatch("validateSubmission");
  }

  return Promise.resolve(data);
};

export default removeSubmissionWebLink;
