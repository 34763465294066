<template>
  <v-btn icon class="rssButton" @click="showShareRssModal">
    <img :src="rssDetails.logo" :alt="rssAltText" />
  </v-btn>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SocialActions",
  props: {
    rssDetails: { type: Object, default: undefined, required: true },
    rssSubject: { type: Object, default: undefined },
  },

  computed: {
    ...mapState({
      partnerName: (state) => `${state.theme.title} ${state.theme.subTitle}`,
    }),
    rssAltText() {
      return `RSS feed for ${
        this.rssSubject === undefined ? this.partnerName : this.rssSubject.name
      }`;
    },
    rssType() {
      return this.rssSubject === undefined
        ? this.partnerName
        : this.rssSubject.name;
    },
    rssUrl() {
      return this.rssSubject === undefined
        ? this.rssDetails.url
        : `${this.rssDetails.url}?${this.rssSubject.type}Id=${this.rssSubject.id}`;
    },
  },

  methods: {
    showShareRssModal({ currentTarget }) {
      this.$root.$emit("show-rss-feed-share", {
        rssUrl: this.rssUrl,
        rssFeedType: this.rssType,
        returnFocusTarget: currentTarget,
      });
    },
  },
};
</script>

<style>
button.rssButton {
  margin-right: 8px;
}
</style>
