import articleUploadsDataRefresh from "~/store/graphql/queries/articleUploadsDataRefresh.graphql";
import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import checkIfAssetsAreProcessing from "~/store/manage/utilities/checkIfAssetsAreProcessing";

const TWO_AND_A_HALF_MINUTES = 2.5 * 60 * 1000;

const getData = async (commit, dispatch, params, axios) => {
  try {
    const data = await runGraphQLQuery(
      articleUploadsDataRefresh,
      params,
      axios
    );

    if (checkIfAssetsAreProcessing(data.submissionItem)) {
      setTimeout(() => {
        getData(commit, dispatch, params, axios);
      }, TWO_AND_A_HALF_MINUTES);
    }

    commit("UPDATE_SUBMISSION_ASSETS", data);
    dispatch("validateSubmission");
    return data;
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }
};

const refreshSubmissionAssets = async function ({ commit, dispatch, state }) {
  const id = state.submissionItem ? state.submissionItem.id : undefined;
  const axios = this.$axios;
  const params = { id };

  if (id) {
    const data = await getData(commit, dispatch, params, axios);

    return Promise.resolve(data);
  }
};

export default refreshSubmissionAssets;
