import { set } from "vue";

const CREATE_EVENT_SPONSOR = (state, payload) => {
  set(
    state.proposedEvent.sponsors,
    state.proposedEvent.sponsors.length,
    payload
  );
};

export default CREATE_EVENT_SPONSOR;
