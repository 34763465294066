import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import communityContent from "~/store/graphql/queries/getCommunityByQuestionId.graphql";

const getCommunityIdByQuestionId = async function (_, { params, req, res }) {
  const questionId = params.id;

  const data = await runGraphQLQuery(
    communityContent,
    { questionId },
    this.$axios,
    req,
    res
  );

  return Promise.resolve(data.getCommunityByQuestionId.id);
};

export default getCommunityIdByQuestionId;
