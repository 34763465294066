const acceptanceRequestChecks = [
  {
    severity: "ERROR",
    type: "REQUEST",
    fieldName: "acceptsRetractionTerms",
    errorMessage: "Terms of submission  missing",
    resolutionMessage: "Please accept terms of submission ",
  },
];

export default acceptanceRequestChecks;
