import removeItem from "~/store/manage/pages/adminstrationDashboard/removeItem";
import loadItemByDoi from "~/store/manage/pages/adminstrationDashboard/loadItemByDoi";
import initialState from "~/store/manage/initialState";

import loadAdministrationDashboard from "~/store/manage/pages/adminstrationDashboard/loadAdministrationDashboard";
import loadAdministrationSubmissionsWithFilters from "~/store/manage/pages/adminstrationDashboard/loadAdministrationSubmissionsWithFilters";
import loadAdminstrationMorePublished from "~/store/manage/pages/adminstrationDashboard/loadAdminstrationMorePublished";
import loadAdminstrationMoreRetracted from "~/store/manage/pages/adminstrationDashboard/loadAdminstrationMoreRetracted";
import loadAdminstrationMoreSubmitted from "~/store/manage/pages/adminstrationDashboard/loadAdminstrationMoreSubmitted";
import loadAdminstrationMoreRevisions from "~/store/manage/pages/adminstrationDashboard/loadAdminstrationMoreRevisions";
import loadAdminstrationMoreDeleted from "~/store/manage/pages/adminstrationDashboard/loadAdminstrationMoreDeleted";
import loadAdminstrationMoreEvents from "~/store/manage/pages/adminstrationDashboard/loadAdminstrationMoreEvents";
import loadAdminstrationMoreVorRequests from "~/store/manage/pages/adminstrationDashboard/loadAdminstrationMoreVorRequests";
import loadAdminstrationMoreComments from "~/store/manage/pages/adminstrationDashboard/loadAdminstrationMoreComments";
import loadAdminstrationMoreProcessedComments from "~/store/manage/pages/adminstrationDashboard/loadAdminstrationMoreProcessedComments";
import loadAdministrationMoreReportedDiscussions from "~/store/manage/pages/adminstrationDashboard/loadAdministrationMoreReportedDiscussions";
import loadAdministrationMoreReportedDiscussionReplies from "~/store/manage/pages/adminstrationDashboard/loadAdministrationMoreReportedDiscussionReplies";
import updateCurationProgress from "~/store/manage/pages/adminstrationDashboard/updateCurationProgress";
import filterAdministrationDashboardResults from "~/store/manage/pages/adminstrationDashboard/filterAdministrationDashboardResults";
import sendCoAuthorSyncOrcidEmail from "~/store/manage/pages/adminstrationDashboard/sendCoAuthorSyncOrcidEmail";

import SET_ADMINISTRATION_DASHBOARD from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_DASHBOARD";
import SET_ADMINISTRATION_MORE_PUBLISHED from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_PUBLISHED";
import SET_ADMINISTRATION_MORE_RETRACTED from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_RETRACTED";
import SET_ADMINISTRATION_MORE_SUBMITTED from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_SUBMITTED";
import SET_ADMINISTRATION_MORE_REVISIONS from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_REVISIONS";
import SET_ADMINISTRATION_MORE_VOR_REQUESTS from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_VOR_REQUESTS";
import SET_ADMINISTRATION_MORE_DELETED from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_DELETED";
import SET_ADMINISTRATION_MORE_EVENTS from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_EVENTS";
import SET_ADMINISTRATION_MORE_COMMENTS from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_COMMENTS";
import SET_ADMINISTRATION_MORE_PROCESSED_COMMENTS from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_PROCESSED_COMMENTS";
import REMOVE_ITEM_FROM_SITE_MODERATOR_SUBMISSIONS from "~/store/manage/pages/adminstrationDashboard/REMOVE_ITEM_FROM_SITE_MODERATOR_SUBMISSIONS";
import UPDATE_CURATION_PROGRESS from "~/store/manage/pages/adminstrationDashboard/UPDATE_CURATION_PROGRESS";
import SET_ADMINISTRATION_SEARCH_PHRASE from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_SEARCH_PHRASE";
import SET_ADMINISTRATION_MORE_REPORTED_DISCUSSIONS from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_REPORTED_DISCUSSIONS";
import SET_ADMINISTRATION_MORE_REPORTED_DISCUSSION_REPLIES from "~/store/manage/pages/adminstrationDashboard/SET_ADMINISTRATION_MORE_REPORTED_DISCUSSION_REPLIES";

import loadMoreComments from "~/store/manage/pages/articleDetails/loadMoreComments";
import postArticleComment from "~/store/manage/pages/articleDetails/postArticleComment";
import loadArticleDetails from "~/store/manage/pages/articleDetails/loadArticleDetails";
import loadCommunityDetails from "~/store/manage/pages/communityDetails/loadCommunityDetails";
import SET_COMMUNITY_DETAILS from "~/store/manage/pages/communityDetails/SET_COMMUNITY_DETAILS";
import loadJournalDetails from "~/store/manage/pages/journalDetails/loadJournalDetails";
import loadSpecificJournalDetails from "~/store/manage/pages/journalDetails/loadSpecificJournalDetails";
import submitToJournalLoadMore from "~/store/manage/pages/journalDetails/submitToJournalLoadMore";
import setSelectedJournal from "~/store/manage/pages/journalDetails/setSelectedJournal";
import submitItemToJournal from "~/store/manage/pages/submitToJournal/submitItemToJournal";

import loadAddVor from "~/store/manage/pages/addVor/loadAddVor";
import SET_ADD_VOR_DETAILS from "~/store/manage/pages/addVor/SET_ADD_VOR_DETAILS";
import modifySelectedItem from "~/store/manage/pages/addVor/modifySelectedItem";
import MODIFY_SELECTED_ITEM from "~/store/manage/pages/addVor/MODIFY_SELECTED_ITEM";

import getArticleCitations from "~/store/manage/pages/articleDetails/getArticleCitations";
import SET_ARTICLE_CITATIONS from "~/store/manage/pages/articleDetails/SET_ARTICLE_CITATIONS";

import getIthenticateReportLink from "~/store/manage/pages/articleDetails/getIthenticateReportLink";
import reportComment from "~/store/manage/pages/articleDetails/reportComment";
import registerUsageEvent from "~/store/manage/pages/articleDetails/registerUsageEvent";
import SET_ARTICLE_DETAILS from "~/store/manage/pages/articleDetails/SET_ARTICLE_DETAILS";
import SET_JOURNAL_DETAILS from "~/store/manage/pages/journalDetails/SET_JOURNAL_DETAILS";
import SET_JOURNAL_DETAILS_MORE from "~/store/manage/pages/journalDetails/SET_JOURNAL_DETAILS_MORE";
import SET_SELECTED_JOURNAL from "~/store/manage/pages/journalDetails/SET_SELECTED_JOURNAL";

import SET_ARTICLE_MORE_COMMENTS from "~/store/manage/pages/articleDetails/SET_ARTICLE_MORE_COMMENTS";
import SET_LAST_GRAPHQL from "~/store/manage/pages/articleDetails/SET_LAST_GRAPHQL";

import START_VIDEO from "~/store/manage/pages/articleDetails/START_VIDEO";
import startVideoForSelectedItem from "~/store/manage/pages/articleDetails/startVideoForSelectedItem";

import loadEventDetails from "~/store/manage/pages/eventDetails/loadEventDetails";
import SET_EVENT_DETAILS from "~/store/manage/pages/eventDetails/SET_EVENT_DETAILS";

import loadEventSubmissions from "~/store/manage/pages/eventDetails/event-submissions/loadEventSubmissions";
import SET_EVENT_SUBMISSIONS from "~/store/manage/pages/eventDetails/event-submissions/SET_EVENT_SUBMISSIONS";

import loadAuthorDashboard from "~/store/manage/pages/authorDashboard/loadAuthorDashboard";
import SET_AUTHOR_DASHBOARD from "~/store/manage/pages/authorDashboard/SET_AUTHOR_DASHBOARD";

import loadLatestContent from "~/store/manage/pages/latestContent/loadLatestContent";
import SET_LATEST_CONTENT from "~/store/manage/pages/latestContent/SET_LATEST_CONTENT";

import loadBrowseDashboard from "~/store/manage/pages/browseDashboard/loadBrowseDashboard";
import SET_BROWSE_DASHBOARD from "~/store/manage/pages/browseDashboard/SET_BROWSE_DASHBOARD";

import loadCategoryDashboard from "~/store/manage/pages/categoryDashboard/loadCategoryDashboard";
import SET_CATEGORY_DASHBOARD from "~/store/manage/pages/categoryDashboard/SET_CATEGORY_DASHBOARD";

import loadSubjectDashboard from "~/store/manage/pages/subjectDashboard/loadSubjectDashboard";
import SET_SUBJECT_DASHBOARD from "~/store/manage/pages/subjectDashboard/SET_SUBJECT_DASHBOARD";

import loadEventsDashboard from "~/store/manage/pages/eventsDashboard/loadEventsDashboard";
import SET_EVENTS_DASHBOARD from "~/store/manage/pages/eventsDashboard/SET_EVENTS_DASHBOARD";

import loadCommunitiesDashboard from "~/store/manage/pages/communitiesDashboard/loadCommunitiesDashboard";
import SET_COMMUNITIES_DASHBOARD from "~/store/manage/pages/communitiesDashboard/SET_COMMUNITIES_DASHBOARD";

import loadDepartmentsDashboard from "~/store/manage/pages/departmentsDashboard/loadDepartmentsDashboard";
import SET_DEPARTMENTS_DASHBOARD from "~/store/manage/pages/departmentsDashboard/SET_DEPARTMENTS_DASHBOARD";
import loadDepartmentDetails from "~/store/manage/pages/departmentDetails/loadDepartmentDetails";
import SET_DEPARTMENT_DETAILS from "~/store/manage/pages/departmentDetails/SET_DEPARTMENT_DETAILS";

import loadPublicDashboard from "~/store/manage/pages/publicDashboard/loadPublicDashboard";
import SET_PUBLIC_DASHBOARD from "~/store/manage/pages/publicDashboard/SET_PUBLIC_DASHBOARD";

import loadDiscussionReplyReview from "~/store/manage/pages/discussionReplyReview/loadDiscussionReplyReview";
import SET_DISCUSSION_REPLY_REVIEW from "~/store/manage/pages/discussionReplyReview/SET_DISCUSSION_REPLY_REVIEW";

import loadPlatformDashboard from "~/store/manage/pages/platformDashboard/loadPlatformDashboard";
import SET_PLATFORM_DASHBOARD from "~/store/manage/pages/platformDashboard/SET_PLATFORM_DASHBOARD";
import loadPlatformDashboardSelectedArticles from "~/store/manage/pages/platformDashboard/loadPlatformDashboardSelectedArticles";
import SET_SELECTED_ARTICLES from "~/store/manage/pages/platformDashboard/SET_SELECTED_ARTICLES";

import loadSearchDashboard from "~/store/manage/pages/searchDashboard/loadSearchDashboard";
import loadSearchMoreMatches from "~/store/manage/pages/searchDashboard/loadSearchMoreMatches";
import modifySearch from "~/store/manage/pages/searchDashboard/modifySearch";
import SET_SEARCH_DASHBOARD from "~/store/manage/pages/searchDashboard/SET_SEARCH_DASHBOARD";
import SET_SEARCH_MORE from "~/store/manage/pages/searchDashboard/SET_SEARCH_MORE";
import SET_SEARCH_REQUEST from "~/store/manage/pages/searchDashboard/SET_SEARCH_REQUEST";
import UPDATE_SEARCH from "~/store/manage/pages/searchDashboard/UPDATE_SEARCH";

import loadUserDefaults from "~/store/manage/pages/userDefaults/loadUserDefaults";
import logout from "~/store/manage/pages/userDefaults/logout";
import CLEAR_USER from "~/store/manage/pages/userDefaults/CLEAR_USER";
import SET_USER_DEFAULTS from "~/store/manage/pages/userDefaults/SET_USER_DEFAULTS";
import UPDATE_USER from "~/store/manage/pages/userDefaults/UPDATE_USER";
import SET_THEME from "~/store/manage/pages/userDefaults/SET_THEME";
import SET_API_KEY from "~/store/manage/pages/userDefaults/SET_API_KEY";
import SET_NO_EVENTS from "~/store/manage/pages/userDefaults/SET_NO_EVENTS";
import SET_PARTNERS from "~/store/manage/pages/userDefaults/SET_PARTNERS";

import loadAdminRevisionProcess from "~/store/manage/pages/submitArticle/loadAdminRevisionProcess";
import loadUserRevisionProcess from "~/store/manage/pages/submitArticle/loadUserRevisionProcess";
import loadReviewProcessed from "~/store/manage/pages/reviewProcessed/loadReviewProcessed";

import loadSubmitProcess from "~/store/manage/pages/submitArticle/loadSubmitProcess";
import loadChooseProcess from "~/store/manage/pages/submitArticle/loadChooseProcess";
import addSubmissionAuthor from "~/store/manage/pages/submitArticle/addSubmissionAuthor";
import addSubmissionFunder from "~/store/manage/pages/submitArticle/addSubmissionFunder";
import addSubmissionWebLink from "~/store/manage/pages/submitArticle/addSubmissionWebLink";
import clearProposedDiscussion from "~/store/manage/pages/communityDetails/clearProposedDiscussion";
import createEventSponsor from "~/store/manage/pages/submitEvent/createEventSponsor";
import createSubmissionItem from "~/store/manage/pages/submitArticle/createSubmissionItem";
import createSubmissionMaterial from "~/store/manage/pages/submitArticle/createSubmissionMaterial";
import clearSubmissionItem from "~/store/manage/pages/submitArticle/clearSubmissionItem";
import deleteAuthorOrcid from "~/store/manage/pages/submitArticle/deleteAuthorOrcid";
import deleteEventSponsor from "~/store/manage/pages/submitEvent/deleteEventSponsor";
import deleteFile from "~/store/manage/pages/submitArticle/deleteFile";
import deleteSubmissionItem from "~/store/manage/pages/submitArticle/deleteSubmissionItem";
import deleteSubmissionMaterial from "~/store/manage/pages/submitArticle/deleteSubmissionMaterial";
import deleteSupplementaryFile from "~/store/manage/pages/submitArticle/deleteSupplementaryFile";
import finishedUpload from "~/store/manage/pages/submitArticle/finishedUpload";
import modifyProposedDiscussion from "~/store/manage/pages/communityDetails/modifyProposedDiscussion";
import modifySubmissionAuthor from "~/store/manage/pages/submitArticle/modifySubmissionAuthor";
import modifySubmissionAuthorOrder from "~/store/manage/pages/submitArticle/modifySubmissionAuthorOrder";
import modifySubmissionFunder from "~/store/manage/pages/submitArticle/modifySubmissionFunder";
import modifySubmissionItem from "~/store/manage/pages/submitArticle/modifySubmissionItem";
import modifySubmissionMaterial from "~/store/manage/pages/submitArticle/modifySubmissionMaterial";
import modifySubmissionWebLink from "~/store/manage/pages/submitArticle/modifySubmissionWebLink";
import modifySubmissionCommunity from "~/store/manage/pages/submitArticle/modifySubmissionCommunity";
import modifySubmissionQuestion from "~/store/manage/pages/submitArticle/modifySubmissionQuestion";
import newSubmissionVersion from "~/store/manage/pages/submitArticle/newSubmissionVersion";
import postModerationNote from "~/store/manage/pages/reviewArticle/postModerationNote";
import refreshEventAsset from "~/store/manage/pages/submitEvent/refreshEventAsset";
import refreshEventSponsorAsset from "~/store/manage/pages/submitEvent/refreshEventSponsorAsset";
import refreshSubmissionAssets from "~/store/manage/pages/submitArticle/refreshSubmissionAssets";
import removeSubmissionAuthor from "~/store/manage/pages/submitArticle/removeSubmissionAuthor";
import removeSubmissionFunder from "~/store/manage/pages/submitArticle/removeSubmissionFunder";
import removeSubmissionItem from "~/store/manage/pages/submitArticle/removeSubmissionItem";
import removeSubmissionWebLink from "~/store/manage/pages/submitArticle/removeSubmissionWebLink";
import replaceSubmissionFunder from "~/store/manage/pages/submitArticle/replaceSubmissionFunder";
import reportDiscussion from "~/store/manage/pages/communityDetails/reportDiscussion";
import reportDiscussionReply from "~/store/manage/pages/communityDetails/reportDiscussionReply";
import retriggerVimeo from "~/store/manage/pages/submitArticle/retriggerVimeo";
import reviseSubmissionStatus from "~/store/manage/pages/submitArticle/reviseSubmissionStatus";
import startedUpload from "~/store/manage/pages/submitArticle/startedUpload";
import updateMarketingPreferences from "~/store/manage/pages/submitArticle/updateMarketingPreferences";
import updateSubmissionItem from "~/store/manage/pages/submitArticle/updateSubmissionItem";
import updateSubmissionReviewItem from "~/store/manage/pages/submitArticle/updateSubmissionReviewItem";
import updateSubmissionStatus from "~/store/manage/pages/submitArticle/updateSubmissionStatus";
import validateSubmission from "~/store/manage/pages/submitArticle/validateSubmission";
import loadEmailTemplate from "~/store/manage/pages/submitArticle/loadEmailTemplate";
import validateDiscussion from "~/store/manage/pages/communityDetails/validateDiscussion";

import ADD_SUBMISSION_AUTHOR from "~/store/manage/pages/submitArticle/ADD_SUBMISSION_AUTHOR";
import ADD_SUBMISSION_FUNDER from "~/store/manage/pages/submitArticle/ADD_SUBMISSION_FUNDER";
import ADD_SUBMISSION_WEBLINK from "~/store/manage/pages/submitArticle/ADD_SUBMISSION_WEBLINK";
import CLEAR_AUTHOR_ORCID from "~/store/manage/pages/submitArticle/CLEAR_AUTHOR_ORCID";
import CLEAR_SUBMISSION from "~/store/manage/pages/submitArticle/CLEAR_SUBMISSION";
import CLEAR_SUBMISSION_ITEM from "~/store/manage/pages/submitArticle/CLEAR_SUBMISSION_ITEM";
import CREATE_EVENT_GROUP from "~/store/manage/pages/submitEvent/CREATE_EVENT_GROUP";
import CREATE_EVENT_SPONSOR from "~/store/manage/pages/submitEvent/CREATE_EVENT_SPONSOR";
import CREATE_MATERIAL from "~/store/manage/pages/submitArticle/CREATE_MATERIAL";
import DELETE_EVENT_SPONSOR from "~/store/manage/pages/submitEvent/DELETE_EVENT_SPONSOR";
import DELETE_FILE from "~/store/manage/pages/submitArticle/DELETE_FILE";
import DELETE_MATERIAL from "~/store/manage/pages/submitArticle/DELETE_MATERIAL";
import DELETE_SUPPLEMENTARY_FILE from "~/store/manage/pages/submitArticle/DELETE_SUPPLEMENTARY_FILE";
import FINISHED_UPLOAD from "~/store/manage/pages/submitArticle/FINISHED_UPLOAD";
import REMOVE_AUTHOR from "~/store/manage/pages/submitArticle/REMOVE_AUTHOR";
import REMOVE_ITEM from "~/store/manage/pages/submitArticle/REMOVE_ITEM";
import REPLACE_SUBMISSION_FUNDER from "~/store/manage/pages/submitArticle/REPLACE_SUBMISSION_FUNDER";
import SET_RELATED_CONTENT from "~/store/manage/pages/submitEvent/SET_RELATED_CONTENT";
import SET_REVISION_WORKFLOW from "~/store/manage/pages/submitArticle/SET_REVISION_WORKFLOW";
import SET_SUBMISSION_CHOOSE from "~/store/manage/pages/submitArticle/SET_SUBMISSION_CHOOSE";
import SET_SUBMISSION_WORKFLOW from "~/store/manage/pages/submitArticle/SET_SUBMISSION_WORKFLOW";
import STARTED_UPLOAD from "~/store/manage/pages/submitArticle/STARTED_UPLOAD";
import UPDATE_ITEM from "~/store/manage/pages/submitArticle/UPDATE_ITEM";
import UPDATE_MATERIAL from "~/store/manage/pages/submitArticle/UPDATE_MATERIAL";
import UPDATE_SUBMISSION from "~/store/manage/pages/submitArticle/UPDATE_SUBMISSION";
import UPDATE_SUBMISSION_ASSET from "~/store/manage/pages/submitArticle/UPDATE_SUBMISSION_ASSET";
import UPDATE_SUBMISSION_ASSETS from "~/store/manage/pages/submitArticle/UPDATE_SUBMISSION_ASSETS";
import UPDATE_SUBMISSION_AUTHOR from "~/store/manage/pages/submitArticle/UPDATE_SUBMISSION_AUTHOR";
import UPDATE_SUBMISSION_AUTHOR_ORDER from "~/store/manage/pages/submitArticle/UPDATE_SUBMISSION_AUTHOR_ORDER";
import UPDATE_SUBMISSION_FUNDER from "~/store/manage/pages/submitArticle/UPDATE_SUBMISSION_FUNDER";
import UPDATE_SUBMISSION_WEBLINK from "~/store/manage/pages/submitArticle/UPDATE_SUBMISSION_WEBLINK";
import UPDATE_SUBMISSION_COMMUNITY from "~/store/manage/pages/submitArticle/UPDATE_SUBMISSION_COMMUNITY";
import UPDATE_SUBMISSION_QUESTION from "~/store/manage/pages/submitArticle/UPDATE_SUBMISSION_QUESTION";
import VALIDATE_SUBMISSION from "~/store/manage/pages/submitArticle/VALIDATE_SUBMISSION";
import VALIDATE_DISCUSSION from "~/store/manage/pages/communityDetails/VALIDATE_DISCUSSION";

import loadEventProcess from "~/store/manage/pages/submitEvent/loadEventProcess";
import clearEventAsset from "~/store/manage/pages/submitEvent/clearEventAsset";
import clearEventSponsorAsset from "~/store/manage/pages/submitEvent/clearEventSponsorAsset";
import createEvent from "~/store/manage/pages/submitEvent/createEvent";
import createEventGroup from "~/store/manage/pages/submitEvent/createEventGroup";
import deleteEvent from "~/store/manage/pages/submitEvent/deleteEvent";
import deleteEventBannerAsset from "~/store/manage/pages/submitEvent/deleteEventBannerAsset";
import deleteEventSponsorAsset from "~/store/manage/pages/submitEvent/deleteEventSponsorAsset";
import modifyProposedEvent from "~/store/manage/pages/submitEvent/modifyProposedEvent";
import modifyProposedEventApprovedSpeakers from "~/store/manage/pages/submitEvent/modifyProposedEventApprovedSpeakers";
import modifyEventSponsor from "~/store/manage/pages/submitEvent/modifyEventSponsor";
import removeEvent from "~/store/manage/pages/submitEvent/removeEvent";
import updateProposedEvent from "~/store/manage/pages/submitEvent/updateProposedEvent";
import updateEventStatus from "~/store/manage/pages/submitEvent/updateEventStatus";
import ADD_EVENT_TO_REFERENCE_DATA from "~/store/manage/pages/submitEvent/ADD_EVENT_TO_REFERENCE_DATA";
import CLEAR_EVENT from "~/store/manage/pages/submitEvent/CLEAR_EVENT";
import CLEAR_EVENT_ASSET from "~/store/manage/pages/submitEvent/CLEAR_EVENT_ASSET";
import CLEAR_EVENT_SPONSOR_ASSET from "~/store/manage/pages/submitEvent/CLEAR_EVENT_SPONSOR_ASSET";
import SET_EVENT_WORKFLOW from "~/store/manage/pages/submitEvent/SET_EVENT_WORKFLOW";
import MODIFY_EVENT from "~/store/manage/pages/submitEvent/MODIFY_EVENT";
import MODIFY_EVENT_APPROVED_SPEAKERS from "~/store/manage/pages/submitEvent/MODIFY_EVENT_APPROVED_SPEAKERS";
import MODIFY_EVENT_SPONSOR from "~/store/manage/pages/submitEvent/MODIFY_EVENT_SPONSOR";
import UPDATE_EVENT from "~/store/manage/pages/submitEvent/UPDATE_EVENT";
import UPDATE_EVENT_MODERATOR_VALIDATION from "~/store/manage/pages/submitEvent/UPDATE_EVENT_MODERATOR_VALIDATION";
import validateModeratorAccounts from "~/store/manage/pages/submitEvent/validateModeratorAccounts";
import checkSubmissionForErrors from "~/store/manage/validation/checkSubmissionForErrors";

import clearRetractionItem from "~/store/manage/pages/retractArticle/clearRetractionItem";
import loadRetractProcess from "~/store/manage/pages/retractArticle/loadRetractProcess";
import modifyRetractionRequest from "~/store/manage/pages/retractArticle/modifyRetractionRequest";
import modifyRetractionResponse from "~/store/manage/pages/retractArticle/modifyRetractionResponse";
import submitRequestRetraction from "~/store/manage/pages/retractArticle/submitRequestRetraction";
import submitRejectRetraction from "~/store/manage/pages/retractArticle/submitRejectRetraction";
import submitConfirmRetraction from "~/store/manage/pages/retractArticle/submitConfirmRetraction";
import updateRequestRetraction from "~/store/manage/pages/retractArticle/updateRequestRetraction";
import updateConfirmRetraction from "~/store/manage/pages/retractArticle/updateConfirmRetraction";
import validateRetraction from "~/store/manage/pages/retractArticle/validateRetraction";
import CLEAR_RETRACTION from "~/store/manage/pages/retractArticle/CLEAR_RETRACTION";
import SET_RETRACTION_WORKFLOW from "~/store/manage/pages/retractArticle/SET_RETRACTION_WORKFLOW";
import SET_RETRACTION_UPDATE from "~/store/manage/pages/retractArticle/SET_RETRACTION_UPDATE";
import UPDATE_RETRACTION_REQUEST from "~/store/manage/pages/retractArticle/UPDATE_RETRACTION_REQUEST";
import UPDATE_RETRACTION_RESPONSE from "~/store/manage/pages/retractArticle/UPDATE_RETRACTION_RESPONSE";
import VALIDATE_RETRACTION from "~/store/manage/pages/retractArticle/VALIDATE_RETRACTION";

import loadArchiveDashboard from "~/store/manage/pages/archiveDashboard/loadArchiveDashboard";
import SET_ARCHIVE_REQUEST from "~/store/manage/pages/archiveDashboard/SET_ARCHIVE_REQUEST";
import SET_ARCHIVE_DASHBOARD from "~/store/manage/pages/archiveDashboard/SET_ARCHIVE_DASHBOARD";

import generateSubmissionsReport from "~/store/manage/pages/adminstrationDashboard/reports/generateSubmissionsReport.js";
import updateSubmissionsReportDateRange from "~/store/manage/pages/adminstrationDashboard/reports/updateSubmissionsReportDateRange.js";
import validateSubmissionReport from "~/store/manage/pages/adminstrationDashboard/reports/validateSubmissionReport.js";
import START_SUBMISSION_REPORT_FETCHING from "~/store/manage/pages/adminstrationDashboard/reports/START_SUBMISSION_REPORT_FETCHING";
import SET_SUBMISSIONS_REPORT_PART from "~/store/manage/pages/adminstrationDashboard/reports/SET_SUBMISSIONS_REPORT_PART";
import END_SUBMISSION_REPORT_FETCHING from "~/store/manage/pages/adminstrationDashboard/reports/END_SUBMISSION_REPORT_FETCHING";
import SET_SUBMISSION_REPORT_DATE_RANGE from "~/store/manage/pages/adminstrationDashboard/reports/SET_SUBMISSION_REPORT_DATE_RANGE";

import generateCitationsReport from "~/store/manage/pages/adminstrationDashboard/reports/generateCitationsReport.js";
import SET_CITATIONS_REPORT_PART from "~/store/manage/pages/adminstrationDashboard/reports/SET_CITATIONS_REPORT_PART";
import START_CITATION_REPORT_FETCHING from "~/store/manage/pages/adminstrationDashboard/reports/START_CITATION_REPORT_FETCHING";
import END_CITATION_REPORT_FETCHING from "~/store/manage/pages/adminstrationDashboard/reports/END_CITATION_REPORT_FETCHING";

import generateFundersReport from "~/store/manage/pages/adminstrationDashboard/reports/generateFundersReport.js";
import updateFundersReportDateRange from "~/store/manage/pages/adminstrationDashboard/reports/updateFundersReportDateRange.js";
import SET_FUNDERS_REPORT_PART from "~/store/manage/pages/adminstrationDashboard/reports/SET_FUNDERS_REPORT_PART";
import SET_FUNDER_REPORT_DATE_RANGE from "~/store/manage/pages/adminstrationDashboard/reports/SET_FUNDER_REPORT_DATE_RANGE";
import START_FUNDER_REPORT_FETCHING from "~/store/manage/pages/adminstrationDashboard/reports/START_FUNDER_REPORT_FETCHING";
import END_FUNDER_REPORT_FETCHING from "~/store/manage/pages/adminstrationDashboard/reports/END_FUNDER_REPORT_FETCHING";
import VALIDATE_FUNDER_REPORT from "~/store/manage/pages/adminstrationDashboard/reports/VALIDATE_FUNDER_REPORT";
import validateFunderReport from "~/store/manage/pages/adminstrationDashboard/reports/validateFunderReport.js";

import VALIDATE_SUBMISSION_REPORT from "~/store/manage/pages/adminstrationDashboard/reports/VALIDATE_SUBMISSION_REPORT";
import loadSiteModeratorsProcess from "~/store/manage/pages/manageSiteModerators/loadSiteModeratorsProcess";
import SET_SITE_MODERATOR_WORKFLOW from "~/store/manage/pages/manageSiteModerators/SET_SITE_MODERATOR_WORKFLOW";
import validateSiteModeratorAccounts from "~/store/manage/pages/manageSiteModerators/validateSiteModeratorAccounts";
import UPDATE_SITE_MODERATOR_VALIDATION from "~/store/manage/pages/manageSiteModerators/UPDATE_SITE_MODERATOR_VALIDATION";
import modifySiteModerators from "~/store/manage/pages/manageSiteModerators/modifySiteModerators";
import MODIFY_SITE_MODERATORS from "~/store/manage/pages/manageSiteModerators/MODIFY_SITE_MODERATORS";
import loadSiteModerators from "~/store/manage/pages/manageSiteModerators/loadSiteModerators";
import SET_SITE_MODERATORS from "~/store/manage/pages/manageSiteModerators/SET_SITE_MODERATORS";
import addSiteModerators from "~/store/manage/pages/manageSiteModerators/addSiteModerators";
import removeSiteModerator from "~/store/manage/pages/manageSiteModerators/removeSiteModerator";
import assignToModerator from "~/store/manage/pages/adminstrationDashboard/assignToModerator";
import unassignFromModerator from "~/store/manage/pages/adminstrationDashboard/unassignFromModerator";
import ASSIGN_ITEM_TO_MODERATOR from "~/store/manage/pages/adminstrationDashboard/ASSIGN_ITEM_TO_MODERATOR";

import loadExternalExperts from "~/store/manage/pages/adminstrationDashboard/loadExternalExperts";
import SET_EXTERNAL_EXPERTS from "~/store/manage/pages/adminstrationDashboard/SET_EXTERNAL_EXPERTS";

import loadCurators from "~/store/manage/pages/manageCurators/loadCurators";
import SET_CURATORS from "~/store/manage/pages/manageCurators/SET_CURATORS";
import assignToCurator from "~/store/manage/pages/manageCurators/assignToCurator";

import loadCommunityContent from "~/store/manage/pages/communityDetails/loadCommunityContent";
import SET_COMMUNITY_CONTENT from "~/store/manage/pages/communityDetails/SET_COMMUNITY_CONTENT";
import getCommunityIdByQuestionId from "~/store/manage/pages/communityDetails/getCommunityIdByQuestionId";
import CLEAR_PROPOSED_DISCUSSION from "~/store/manage/pages/communityDetails/CLEAR_PROPOSED_DISCUSSION";
import UPDATE_PROPOSED_DISCUSSION from "~/store/manage/pages/communityDetails/UPDATE_PROPOSED_DISCUSSION";
import createDiscussion from "~/store/manage/pages/communityDetails/createDiscussion";
import loadCommunityDiscussionListing from "~/store/manage/pages/communityDetails/loadCommunityDiscussionListing";
import SET_DISCUSSIONS from "~/store/manage/pages/communityDetails/SET_DISCUSSIONS";
import loadMoreCommunityDiscussionListing from "~/store/manage/pages/communityDetails/loadMoreCommunityDiscussionListing";
import SET_DISCUSSIONS_MORE from "~/store/manage/pages/communityDetails/SET_DISCUSSIONS_MORE";
import loadCommunityDiscussionDetails from "~/store/manage/pages/communityDetails/loadCommunityDiscussionDetails";
import SELECT_DISCUSSION from "~/store/manage/pages/communityDetails/SELECT_DISCUSSION";
import createDiscussionReply from "~/store/manage/pages/communityDetails/createCommunityDiscussionReply";
import loadMoreCommunityDiscussionReplies from "~/store/manage/pages/communityDetails/loadMoreCommunityDiscussionReplies";
import SET_DISCUSSION_REPLIES_MORE from "~/store/manage/pages/communityDetails/SET_DISCUSSION_REPLIES_MORE";
import createDiscussionVote from "~/store/manage/pages/communityDetails/createDiscussionVote";
import createDiscussionReplyVote from "~/store/manage/pages/communityDetails/createDiscussionReplyVote";
import CREATE_DISCUSSION_VOTE from "~/store/manage/pages/communityDetails/CREATE_DISCUSSION_VOTE";
import CREATE_DISCUSSION_REPLY_VOTE from "~/store/manage/pages/communityDetails/CREATE_DISCUSSION_REPLY_VOTE";
import removeDiscussionVote from "~/store/manage/pages/communityDetails/removeDiscussionVote";
import removeDiscussionReplyVote from "~/store/manage/pages/communityDetails/removeDiscussionReplyVote";
import REMOVE_DISCUSSION_VOTE from "~/store/manage/pages/communityDetails/REMOVE_DISCUSSION_VOTE";
import REMOVE_DISCUSSION_REPLY_VOTE from "~/store/manage/pages/communityDetails/REMOVE_DISCUSSION_REPLY_VOTE";
import loadAllCommunityCollectionListing from "~/store/manage/pages/communityDetails/loadAllCommunityCollectionsListing";
import SET_COMMUNITY_COLLECTIONS from "~/store/manage/pages/communityDetails/SET_COMMUNITY_COLLECTIONS";

import SET_IS_MOUNTED from "~/store/manage/pages/SET_IS_MOUNTED";
import SET_BROWSER_SUPPORT from "~/store/manage/pages/SET_BROWSER_SUPPORT";
import SET_UNLEASH from "~/store/manage/pages/SET_UNLEASH";

import selectCommunityContentFilter from "~/store/manage/pages/communityDetails/selectCommunityContentFilter";
import SELECT_COMMUNITY_CONTENT_FILTER from "~/store/manage/pages/communityDetails/SELECT_COMMUNITY_CONTENT_FILTER";
import getArticleIdByCoreProductId from "~/store/manage/pages/articleDetails/getArticleIdByCoreProductId";

export const state = initialState;

const START_LOADING = (state) => {
  state.isLoading = true;
};

const END_LOADING = (state) => {
  state.isLoading = false;
};

export const actions = {
  addSiteModerators,
  addSubmissionAuthor,
  addSubmissionFunder,
  addSubmissionWebLink,
  assignToCurator,
  assignToModerator,
  checkSubmissionForErrors,
  clearEventAsset,
  clearEventSponsorAsset,
  clearProposedDiscussion,
  clearRetractionItem,
  clearSubmissionItem,
  createDiscussion,
  createDiscussionReply,
  createDiscussionReplyVote,
  createDiscussionVote,
  createEvent,
  createEventGroup,
  createEventSponsor,
  createSubmissionItem,
  createSubmissionMaterial,
  deleteAuthorOrcid,
  deleteEvent,
  deleteEventBannerAsset,
  deleteEventSponsor,
  deleteEventSponsorAsset,
  deleteFile,
  deleteSubmissionItem,
  deleteSubmissionMaterial,
  deleteSupplementaryFile,
  filterAdministrationDashboardResults,
  sendCoAuthorSyncOrcidEmail,
  finishedUpload,
  generateCitationsReport,
  generateFundersReport,
  generateSubmissionsReport,
  getArticleCitations,
  getArticleIdByCoreProductId,
  getCommunityIdByQuestionId,
  getIthenticateReportLink,
  loadAddVor,
  loadAdministrationDashboard,
  loadAdministrationMoreReportedDiscussionReplies,
  loadAdministrationMoreReportedDiscussions,
  loadAdministrationSubmissionsWithFilters,
  loadAdminRevisionProcess,
  loadAdminstrationMoreComments,
  loadAdminstrationMoreDeleted,
  loadAdminstrationMoreEvents,
  loadAdminstrationMoreProcessedComments,
  loadAdminstrationMorePublished,
  loadAdminstrationMoreRetracted,
  loadAdminstrationMoreRevisions,
  loadAdminstrationMoreSubmitted,
  loadAdminstrationMoreVorRequests,
  loadAllCommunityCollectionListing,
  loadArchiveDashboard,
  loadArticleDetails,
  loadAuthorDashboard,
  loadBrowseDashboard,
  loadCategoryDashboard,
  loadChooseProcess,
  loadCommunitiesDashboard,
  loadCommunityContent,
  loadCommunityDetails,
  loadCommunityDiscussionDetails,
  loadCommunityDiscussionListing,
  loadCurators,
  loadDepartmentDetails,
  loadDepartmentsDashboard,
  loadDiscussionReplyReview,
  loadEmailTemplate,
  loadEventDetails,
  loadEventProcess,
  loadEventsDashboard,
  loadEventSubmissions,
  loadExternalExperts,
  loadItemByDoi,
  loadJournalDetails,
  loadLatestContent,
  loadMoreComments,
  loadMoreCommunityDiscussionListing,
  loadMoreCommunityDiscussionReplies,
  loadPlatformDashboard,
  loadPlatformDashboardSelectedArticles,
  loadPublicDashboard,
  loadRetractProcess,
  loadReviewProcessed,
  loadSearchDashboard,
  loadSearchMoreMatches,
  loadSiteModerators,
  loadSiteModeratorsProcess,
  loadSpecificJournalDetails,
  loadSubjectDashboard,
  loadSubmitProcess,
  loadUserDefaults,
  loadUserRevisionProcess,
  logout,
  modifyEventSponsor,
  modifyProposedDiscussion,
  modifyProposedEvent,
  modifyProposedEventApprovedSpeakers,
  modifyRetractionRequest,
  modifyRetractionResponse,
  modifySearch,
  modifySelectedItem,
  modifySiteModerators,
  modifySubmissionAuthor,
  modifySubmissionAuthorOrder,
  modifySubmissionCommunity,
  modifySubmissionFunder,
  modifySubmissionItem,
  modifySubmissionMaterial,
  modifySubmissionQuestion,
  modifySubmissionWebLink,
  newSubmissionVersion,
  postArticleComment,
  postModerationNote,
  refreshEventAsset,
  refreshEventSponsorAsset,
  refreshSubmissionAssets,
  registerUsageEvent,
  removeDiscussionReplyVote,
  removeDiscussionVote,
  removeEvent,
  removeItem,
  removeSiteModerator,
  removeSubmissionAuthor,
  removeSubmissionFunder,
  removeSubmissionItem,
  removeSubmissionWebLink,
  replaceSubmissionFunder,
  reportComment,
  reportDiscussion,
  reportDiscussionReply,
  retriggerVimeo,
  reviseSubmissionStatus,
  selectCommunityContentFilter,
  setSelectedJournal,
  startedUpload,
  startVideoForSelectedItem,
  submitConfirmRetraction,
  submitItemToJournal,
  submitRejectRetraction,
  submitRequestRetraction,
  submitToJournalLoadMore,
  unassignFromModerator,
  updateConfirmRetraction,
  updateCurationProgress,
  updateEventStatus,
  updateFundersReportDateRange,
  updateMarketingPreferences,
  updateProposedEvent,
  updateRequestRetraction,
  updateSubmissionItem,
  updateSubmissionReviewItem,
  updateSubmissionsReportDateRange,
  updateSubmissionStatus,
  validateDiscussion,
  validateFunderReport,
  validateModeratorAccounts,
  validateRetraction,
  validateSiteModeratorAccounts,
  validateSubmission,
  validateSubmissionReport,
};

export const mutations = {
  ADD_EVENT_TO_REFERENCE_DATA,
  ADD_SUBMISSION_AUTHOR,
  ADD_SUBMISSION_FUNDER,
  ADD_SUBMISSION_WEBLINK,
  ASSIGN_ITEM_TO_MODERATOR,
  CLEAR_AUTHOR_ORCID,
  CLEAR_EVENT_ASSET,
  CLEAR_EVENT_SPONSOR_ASSET,
  CLEAR_EVENT,
  CLEAR_PROPOSED_DISCUSSION,
  CLEAR_RETRACTION,
  CLEAR_SUBMISSION_ITEM,
  CLEAR_SUBMISSION,
  CLEAR_USER,
  CREATE_DISCUSSION_REPLY_VOTE,
  CREATE_DISCUSSION_VOTE,
  CREATE_EVENT_GROUP,
  CREATE_EVENT_SPONSOR,
  CREATE_MATERIAL,
  DELETE_EVENT_SPONSOR,
  DELETE_FILE,
  DELETE_MATERIAL,
  DELETE_SUPPLEMENTARY_FILE,
  END_CITATION_REPORT_FETCHING,
  END_FUNDER_REPORT_FETCHING,
  END_LOADING,
  END_SUBMISSION_REPORT_FETCHING,
  FINISHED_UPLOAD,
  MODIFY_EVENT_APPROVED_SPEAKERS,
  MODIFY_EVENT_SPONSOR,
  MODIFY_EVENT,
  MODIFY_SELECTED_ITEM,
  MODIFY_SITE_MODERATORS,
  REMOVE_AUTHOR,
  REMOVE_DISCUSSION_REPLY_VOTE,
  REMOVE_DISCUSSION_VOTE,
  REMOVE_ITEM_FROM_SITE_MODERATOR_SUBMISSIONS,
  REMOVE_ITEM,
  REPLACE_SUBMISSION_FUNDER,
  SELECT_COMMUNITY_CONTENT_FILTER,
  SELECT_DISCUSSION,
  SET_ADD_VOR_DETAILS,
  SET_ADMINISTRATION_DASHBOARD,
  SET_ADMINISTRATION_MORE_COMMENTS,
  SET_ADMINISTRATION_MORE_DELETED,
  SET_ADMINISTRATION_MORE_EVENTS,
  SET_ADMINISTRATION_MORE_PROCESSED_COMMENTS,
  SET_ADMINISTRATION_MORE_PUBLISHED,
  SET_ADMINISTRATION_MORE_REPORTED_DISCUSSION_REPLIES,
  SET_ADMINISTRATION_MORE_REPORTED_DISCUSSIONS,
  SET_ADMINISTRATION_MORE_RETRACTED,
  SET_ADMINISTRATION_MORE_REVISIONS,
  SET_ADMINISTRATION_MORE_SUBMITTED,
  SET_ADMINISTRATION_MORE_VOR_REQUESTS,
  SET_ADMINISTRATION_SEARCH_PHRASE,
  SET_API_KEY,
  SET_ARCHIVE_DASHBOARD,
  SET_ARCHIVE_REQUEST,
  SET_ARTICLE_CITATIONS,
  SET_ARTICLE_DETAILS,
  SET_ARTICLE_MORE_COMMENTS,
  SET_AUTHOR_DASHBOARD,
  SET_BROWSE_DASHBOARD,
  SET_BROWSER_SUPPORT,
  SET_CATEGORY_DASHBOARD,
  SET_CITATIONS_REPORT_PART,
  SET_COMMUNITIES_DASHBOARD,
  SET_COMMUNITY_COLLECTIONS,
  SET_COMMUNITY_CONTENT,
  SET_COMMUNITY_DETAILS,
  SET_CURATORS,
  SET_DEPARTMENT_DETAILS,
  SET_DEPARTMENTS_DASHBOARD,
  SET_DISCUSSION_REPLIES_MORE,
  SET_DISCUSSION_REPLY_REVIEW,
  SET_DISCUSSIONS_MORE,
  SET_DISCUSSIONS,
  SET_EVENT_DETAILS,
  SET_EVENT_SUBMISSIONS,
  SET_EVENT_WORKFLOW,
  SET_EVENTS_DASHBOARD,
  SET_EXTERNAL_EXPERTS,
  SET_FUNDER_REPORT_DATE_RANGE,
  SET_FUNDERS_REPORT_PART,
  SET_IS_MOUNTED,
  SET_JOURNAL_DETAILS_MORE,
  SET_JOURNAL_DETAILS,
  SET_LAST_GRAPHQL,
  SET_LATEST_CONTENT,
  SET_NO_EVENTS,
  SET_PARTNERS,
  SET_PLATFORM_DASHBOARD,
  SET_PUBLIC_DASHBOARD,
  SET_RELATED_CONTENT,
  SET_RETRACTION_UPDATE,
  SET_RETRACTION_WORKFLOW,
  SET_REVISION_WORKFLOW,
  SET_SEARCH_DASHBOARD,
  SET_SEARCH_MORE,
  SET_SEARCH_REQUEST,
  SET_SELECTED_ARTICLES,
  SET_SELECTED_JOURNAL,
  SET_SITE_MODERATOR_WORKFLOW,
  SET_SITE_MODERATORS,
  SET_SUBJECT_DASHBOARD,
  SET_SUBMISSION_CHOOSE,
  SET_SUBMISSION_REPORT_DATE_RANGE,
  SET_SUBMISSION_WORKFLOW,
  SET_SUBMISSIONS_REPORT_PART,
  SET_THEME,
  SET_UNLEASH,
  SET_USER_DEFAULTS,
  START_CITATION_REPORT_FETCHING,
  START_FUNDER_REPORT_FETCHING,
  START_LOADING,
  START_SUBMISSION_REPORT_FETCHING,
  START_VIDEO,
  STARTED_UPLOAD,
  UPDATE_CURATION_PROGRESS,
  UPDATE_EVENT_MODERATOR_VALIDATION,
  UPDATE_EVENT,
  UPDATE_ITEM,
  UPDATE_MATERIAL,
  UPDATE_PROPOSED_DISCUSSION,
  UPDATE_RETRACTION_REQUEST,
  UPDATE_RETRACTION_RESPONSE,
  UPDATE_SEARCH,
  UPDATE_SITE_MODERATOR_VALIDATION,
  UPDATE_SUBMISSION_ASSET,
  UPDATE_SUBMISSION_ASSETS,
  UPDATE_SUBMISSION_AUTHOR_ORDER,
  UPDATE_SUBMISSION_AUTHOR,
  UPDATE_SUBMISSION_COMMUNITY,
  UPDATE_SUBMISSION_FUNDER,
  UPDATE_SUBMISSION_QUESTION,
  UPDATE_SUBMISSION_WEBLINK,
  UPDATE_SUBMISSION,
  UPDATE_USER,
  VALIDATE_DISCUSSION,
  VALIDATE_FUNDER_REPORT,
  VALIDATE_RETRACTION,
  VALIDATE_SUBMISSION,
  VALIDATE_SUBMISSION_REPORT,
};
