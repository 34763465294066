import processItems from "~/store/manage/pages/adminstrationDashboard/utilities/processItems";

const SET_ADMINISTRATION_MORE_VOR_REQUESTS = (
  state,
  { payload, featureToggles }
) => {
  const results = payload.origin.vorRequests.results;

  processItems(results, featureToggles);

  state.currentAdministrator.vorRequestsCount =
    payload.origin.vorRequests && payload.origin.vorRequests.totalCount
      ? payload.origin.vorRequests.totalCount
      : 99999;

  if (results.length > 0) {
    const vorRequests = state.currentAdministrator.vorRequests;

    state.currentAdministrator.vorRequests = [...vorRequests, ...results];
  }
};

export default SET_ADMINISTRATION_MORE_VOR_REQUESTS;
