const UPDATE_SUBMISSION_COMMUNITY = (state, payload) => {
  const submissionItem = state.submissionItem;

  submissionItem.communityId = payload.value;
  submissionItem.questionId = null;

  state.submissionItemDependencies.community =
    submissionItem.communityId &&
    state.referenceData.communities.find(
      (community) => community.id === submissionItem.communityId
    );
  state.submissionItemDependencies.question = null;
  state.referenceData.questions = [];

  state.referenceData.filteredContentTypes =
    state.referenceData.contentTypes.filter(
      (contentType) =>
        (state.submissionItemDependencies.community?.journalId &&
          contentType.allowResearchDirectionSubmission) ||
        (state.submissionItemDependencies.community &&
          !state.submissionItemDependencies.community.journalId &&
          contentType.allowCommunitySubmission) ||
        (!state.submissionItemDependencies.community &&
          contentType.allowSubmission)
    );
  if (
    !state.referenceData.filteredContentTypes.some(
      (contentType) => contentType.id === submissionItem.contentTypeId
    )
  ) {
    submissionItem.contentTypeId = null;
  }
};

export default UPDATE_SUBMISSION_COMMUNITY;
