const removeErrorsForItemChoices = (error, item, contentTypes, layout) => {
  const ignore = contentTypes.filter(
    (item) => item.name.toUpperCase() === "NO MAIN CONTENT"
  );
  const contentTypeIdIgnoreError = ignore.length > 0 ? ignore[0].id : undefined;

  if (
    error.type === "CONTENT" &&
    error.fieldName === "asset" &&
    contentTypeIdIgnoreError === item.contentTypeId
  ) {
    return;
  }
  if (
    error.type === "CONTENT" &&
    error.fieldName === "categoryId" &&
    layout.submissionCanChooseCategory === false
  ) {
    return;
  }
  if (
    error.type === "CONTENT" &&
    error.fieldName === "subjectId" &&
    layout.submissionCanChooseSubject === false
  ) {
    return;
  }

  return error;
};

export default removeErrorsForItemChoices;
