<template>
  <div class="main-banner">
    <div
      v-if="communitiesHeader"
      class="main-banner__communities-header p-2 row"
      :style="{ backgroundImage: `url('${communitiesHeaderImage}')` }"
    >
      <div
        class="main-banner__communities-header__content height-percent-100 col"
      >
        <LayoutGutters no-padding>
          <div class="mx-2">
            <h1 class="text-h4 inverted--text mb-5">{{ pageTitle }}</h1>
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="inverted--text mb-5"
              v-html="sanitizedDescription"
            ></div>
            <!-- eslint-enable vue/no-v-html -->
            <v-btn class="primary--text" @click="action">Submit Content</v-btn>
          </div>
        </LayoutGutters>
      </div>
    </div>

    <!-- SOLID BANNER e.g. COE -->
    <v-row
      v-else-if="solidHeader"
      class="main-banner__container accent"
      no-gutters
    >
      <div
        class="main-banner__image-container main-banner__image-container--right"
      >
        <img
          class="main-banner__coe-background-image main-banner__coe-background-image--top-right"
          alt=""
          :src="images.bannerHeaderRight"
        />
      </div>
      <div
        class="main-banner__image-container main-banner__image-container--left"
      >
        <img
          class="main-banner__coe-background-image main-banner__coe-background-image--top-left"
          alt=""
          :src="images.bannerHeaderLeft"
        />
      </div>

      <LayoutGutters class="layer-3">
        <v-row
          justify="space-between"
          align="center"
          no-gutters
          class="pb-0 pt-4 pr-md-2 pr-sm-2"
        >
          <v-spacer />

          <BannerHeading
            :page-title="pageTitle"
            :current-step="currentStep"
            :rss-feed="rssFeed"
            :is-subject-link="isSubjectLink"
            :category="category"
            :subject="subject"
            :solid-header="solidHeader"
          />

          <SearchAndRss
            v-if="search || rssFeed"
            :category="category"
            :subject="subject"
            :rss-feed="rssFeed"
            :search="search"
            :links="links"
          />
          <v-spacer v-else />
        </v-row>
      </LayoutGutters>
    </v-row>

    <!-- PARTNER BANNER, e.g. APSA -->
    <v-row v-else class="main-banner__container position-relative" no-gutters>
      <div
        v-if="!bannerHeader"
        class="main-banner__apsa-banner__left height-percent-100 secondary"
      />
      <div
        v-if="!bannerHeader"
        class="main-banner__apsa-banner__right height-percent-100 primary"
      />

      <div
        v-if="bannerHeader"
        :style="{ backgroundImage: `url('${bannerHeader}')` }"
        alt=""
        class="main-banner__background-image position-absolute layer-1"
      />
      <div
        v-if="bannerHeader && showMainBannerOverlay"
        class="overlay layer-2 height-percent-100 width-percent-100 position-absolute top-0"
      />

      <LayoutGutters no-padding class="layer-3 position-relative pb-3">
        <v-row
          align="center"
          no-gutters
          class="py-5 pr-md-2 pr-sm-2 d-flex flex-nowrap"
        >
          <BannerHeading
            :page-title="pageTitle"
            :current-step="currentStep"
            :rss-feed="rssFeed"
            :is-subject-link="isSubjectLink"
            :category="category"
            :subject="subject"
            :solid-header="solidHeader"
          />

          <SearchAndRss
            v-if="search || rssFeed"
            :category="category"
            :subject="subject"
            :rss-feed="rssFeed"
            :search="search"
            :links="links"
          /><v-spacer v-else />
        </v-row>
      </LayoutGutters>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mapFlags from "~/plugins/utilities/mapFlags";

import { displayIsMobile } from "~/components/utilities/displayBreakpoints";

import LayoutGutters from "~/components/layout/LayoutGutters.vue";

import SearchAndRss from "~/components/banner/components/SearchAndRss";
import BannerHeading from "~/components/banner/components/BannerHeading";

export default {
  name: "MainBanner",
  components: {
    LayoutGutters,
    SearchAndRss,
    BannerHeading,
  },
  props: {
    category: { type: Object, default: () => {} },
    subject: { type: Object, default: () => {} },
    title: { type: String, default: "" },
    currentStep: { type: Number, default: null },
    solidHeader: { type: Boolean, default: false },
    rssFeed: { type: Boolean, default: false },
    search: { type: Boolean, default: false },
    isSubjectLink: { type: Boolean, default: false },
    communitiesHeader: { type: Boolean, default: false },
    description: { type: String, default: null },
    action: { type: Function, default: () => {} },
  },
  data() {
    return {};
  },
  computed: {
    displayIsMobile,
    ...mapState({
      theme: (state) => state.theme,
      layout: (state) => state.theme.layout,
      images: (state) => state.theme.images,
      links: (state) => state.theme.links,
    }),
    ...mapFlags(["showMainBannerOverlay"]),
    pageTitle() {
      // set defaults for various pages
      return this.category
        ? this.category.name
        : this.subject
        ? this.subject.name
        : this.title
        ? this.title
        : "";
    },
    bannerHeader() {
      return this.images.bannerHeader;
    },
    communitiesHeaderImage() {
      return this.displayIsMobile
        ? this.images.bannerCommunitiesMobile
        : this.images.bannerCommunitiesDesktop;
    },
    sanitizedDescription() {
      return this.$sanitize(this.description);
    },
  },
};
</script>

<style lang="scss">
.main-banner {
  &__container {
    position: relative;
  }

  &__image-container {
    position: absolute !important;
    top: 0 !important;
    height: 100% !important;
    width: 30% !important;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  &__title {
    top: 28px;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
  }

  &__coe-background-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    &--top-right {
      object-position: 0 50%;
    }
    &--top-left {
      object-position: 100% 50%;
    }
  }

  &__apsa-banner__left {
    position: absolute;
    border-radius: 0 500px 500px 0;
    left: 0;
    top: 0;
    width: calc(85% + 24px);
  }

  &__apsa-banner__right {
    position: absolute;
    border-radius: 500px 0 0 500px;
    right: 0;
    top: 0;
    width: calc(15% + 24px);
  }

  &__background-image {
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
  }

  .v-text-field {
    padding-top: 0;
  }

  a:hover {
    text-decoration: underline;
  }

  &__communities-header {
    background-size: cover;
    background-position-x: center;

    &__content {
      min-height: 260px;
      background: linear-gradient(
        90.2deg,
        #016364 1.19%,
        rgba(1, 99, 100, 0.9) 2.57%,
        rgba(1, 101, 101, 0.510225) 76.69%,
        rgba(1, 101, 101, 0.505195) 76.7%,
        rgba(1, 101, 101, 0.5) 76.71%,
        rgba(1, 101, 101, 0) 99.83%
      );

      @include media-breakpoint-up("md") {
        width: 720px;
      }
    }
  }
}

@media only screen and (min-width: 960px) {
  .main-banner {
    &__apsa-banner__left {
      width: calc(68% + 24px);
    }
    &__apsa-banner__right {
      width: calc(32% + 24px);
    }
  }
}
</style>
