import { suppItemUpdate } from "~/store/graphql/mutations/supplementaryUpdate.graphql";
import { itemUpdate } from "~/store/graphql/mutations/itemUpdate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import createItemForMutation from "~/store/manage/utilities/createItemForMutation";

const updateSubmissionItem = async function ({ commit, state, dispatch }) {
  const assets = state.submissionItem.supplementaryAssets || [];

  const updateAssets = assets.map((asset) => {
    const item = {
      title: asset.title,
      description: asset.description,
    };

    return runGraphQLQuery(
      suppItemUpdate,
      {
        id: asset.id.toString(),
        item,
      },
      this.$axios
    );
  });

  await Promise.all(updateAssets);

  const submission = createItemForMutation(state.submissionItem);

  const data = await runGraphQLQuery(
    itemUpdate,
    {
      id: submission.id.toString(),
      item: submission,
    },
    this.$axios
  );

  if (data) {
    const featureToggles = {
      multipleCategoriesSubmission: this.$unleash.isEnabled(
        "multipleCategoriesSubmission"
      ),
    };

    commit("UPDATE_ITEM", { payload: data, featureToggles });
    dispatch("validateSubmission");

    return Promise.resolve(data);
  }
};

export default updateSubmissionItem;
