<template>
  <v-container class="ContainerPage" fluid pa-0>
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: "ContainerPage",
};
</script>
