import checkProposalForErrors from "~/store/manage/validation/checkProposalForErrors";

const UPDATE_EVENT = (state, payload) => {
  state.proposedEvent = payload.event;
  state.proposedErrors = checkProposalForErrors(
    payload.event,
    payload.validation,
    state.proposedErrors
  );
};

export default UPDATE_EVENT;
