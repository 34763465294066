const mandatoryVersionChecks = [
  {
    severity: "ERROR",
    type: "VERSION",
    fieldName: "versionNote",
    errorMessage: "New Version description missing",
    resolutionMessage: "Please summarize your changes",
  },
];

export default mandatoryVersionChecks;
