import { itemSubmit } from "~/store/graphql/mutations/itemSubmit.graphql";
import { itemApprove } from "~/store/graphql/mutations/itemApprove.graphql";
import { itemReject } from "~/store/graphql/mutations/itemReject.graphql";
import { itemDelete } from "~/store/graphql/mutations/itemDelete.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const updateSubmissionStatus = async function (
  { commit, dispatch },
  { action, params }
) {
  const queries = {
    SUBMIT: itemSubmit,
    APPROVE: itemApprove,
    REJECT: itemReject,
    DELETE: itemDelete,
  };

  const query = queries[action];

  let data;

  if (query) {
    data = await runGraphQLQuery(query, params, this.$axios);
  }

  if (data) {
    const featureToggles = {
      multipleCategoriesSubmission: this.$unleash.isEnabled(
        "multipleCategoriesSubmission"
      ),
    };

    commit("UPDATE_ITEM", { payload: data, featureToggles });
    dispatch("validateSubmission");
  }

  return Promise.resolve(data);
};

export default updateSubmissionStatus;
