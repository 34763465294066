const SECONDS = 1000;

// eslint-disable-next-line no-unused-vars
function logRequest(config) {
  const method = config.method.toUpperCase();
  const baseURL = config.baseURL;
  const url = config.url;

  let data = JSON.stringify(config.data, undefined, 2)
    .replace(/\\n/g, "")
    .replace(/\s\s+/g, " ");

  if (data.length > 150) {
    data = data.substr(0, 150) + "...";
  }

  // eslint-disable-next-line no-console
  console.info(`FETCHING: ${method} ${baseURL}${url}`);
  // eslint-disable-next-line no-console
  console.info("          " + data);
}

// eslint-disable-next-line no-unused-vars
function logResponse(response) {
  const status = response.statusText;
  const url = response.config.url;

  let data = JSON.stringify(response.data);

  if (data.length > 150) {
    data = data.substr(0, 150) + "...";
  }

  // eslint-disable-next-line no-console
  console.info(`RECEIVED: ${status} ${url}`);
  // eslint-disable-next-line no-console
  console.info("          " + data);
}

export default (context, inject) => {
  const { $config, $axios, req } = context;
  const { url } = req || {};

  const { ORP_API_ACCESS_KEY: API_KEY = "MISSING" } = $config;

  if (url) {
    let noEvents;

    if (url.includes("orp-no-events=")) {
      noEvents = url.includes("orp-no-events=true");
    }

    if (noEvents !== undefined) {
      inject("noEvents", noEvents);
      context.store.commit("SET_NO_EVENTS", noEvents);
    }
  }

  $axios.onRequest((config) => {
    const noEvents = context.app.$noEvents
      ? context.app.$noEvents
      : context.store.state.noEvents;

    const serverEndpoint = $config.serverEndpoint;
    const clientEndpoint = $config.clientEndpoint;

    config.baseURL = process.server ? serverEndpoint : clientEndpoint;

    if (process.server) {
      const { "cf-connecting-ip": cfIpHeader } = req.headers;
      if (cfIpHeader) {
        config.headers["cf-connecting-ip"] = cfIpHeader;
      }
    }

    config.timeout = 25 * SECONDS;
    config.withCredentials = true;
    config.retry = { retries: 3 };

    if (!config.url.match(/(http|https):\/\/(sandbox.)?orcid.org/i)) {
      config.headers["x-api-key"] = API_KEY;
    }

    if (noEvents === true) {
      config.headers["orp-no-events"] = "true";
    } else if (noEvents === false) {
      config.headers["orp-no-events"] = "false";
    }

    return config;
  });

  $axios.onResponse((response) => {
    return response;
  });

  $axios.onError((error) => {
    let code = "";
    let description = "";

    if (error.response) {
      code = parseInt(error.response.status);
      description =
        description +
        error.response.data +
        " " +
        +error.response.status +
        " " +
        +error.response.headers;
    } else {
      description = error.message;
    }

    // eslint-disable-next-line no-console
    console.error(`ERROR: ${code} ${description}`);
  });
};
