<template>
  <div id="RejectionOptionListener">
    <v-dialog
      ref="rejectionOptionDialogRef"
      v-model="show"
      attach="#RejectionOptionListener"
      max-width="400"
      @keydown.esc="onClose"
    >
      <v-card>
        <v-card-title>
          <h2
            id="rejection-option-dialog-title"
            class="headline"
            style="word-break: keep-all"
          >
            {{ title }}
          </h2>
        </v-card-title>
        <v-card-text class="rejection-options">
          <v-radio-group v-model="model" :label="title" column>
            <v-radio
              v-for="(option, index) in options"
              ref="rejectOptionRadio"
              :key="index"
              :class="
                displayIsMobile === true ? 'button--mobile' : 'button--desktop'
              "
              :label="option.name"
              :value="option"
            />
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="closeRejectionOptionListener"
            color="warning"
            text
            aria-label="close"
            @click="onClose"
            >Close</v-btn
          >
          <v-btn
            class="okRejectionOptionListener"
            :disabled="isDisabled"
            color="success"
            text
            :aria-label="'reject'"
            @click="onConfirm"
            >Reject</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { displayIsMobile } from "~/components/utilities/displayBreakpoints";

export default {
  name: "RejectionOptionListener",
  data() {
    return {
      model: undefined,
      show: false,
      title: undefined,
      item: undefined,
      options: undefined,
      action: undefined,
      returnFocusTarget: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    displayIsMobile,
    isDisabled() {
      return this.model === undefined;
    },
  },
  mounted() {
    this.$root.$on("show-rejection-options", this.onShowInformation);
  },
  methods: {
    onShowInformation({ title, item, options, action, currentTarget }) {
      this.title = title;
      this.options = options;
      this.action = action;
      this.item = item;
      this.returnFocusTarget = currentTarget;
      this.show = true;
      this.setInitialFocus();
      this.setAriaLabelAttribute();
    },
    setInitialFocus() {
      this.$nextTick(() => {
        this.$refs.rejectOptionRadio[0]?.$el.children[0].children[1].focus();
      });
    },
    setAriaLabelAttribute() {
      this.$nextTick(() => {
        const dialogRef =
          this?.$refs?.rejectionOptionDialogRef?.$children[0]?.$el;

        if (dialogRef) {
          const titleByElementId = document.getElementById(
            "rejection-option-dialog-title"
          ).textContent;
          dialogRef.setAttribute("aria-label", titleByElementId);
        }
      });
    },
    onConfirm() {
      this.$root.$emit("update-status", {
        status: "BESPOKE_EMAIL_REJECTED",
        item: { ...this.item, rejectionReason: this.model },
        currentTarget: this.returnFocusTarget,
      });
      this.show = false;
    },
    onClose() {
      this.show = false;
      this.returnFocus();
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
  },
};
</script>

<style>
.rejection-options .v-input legend {
  display: none;
}
</style>
