<template>
  <div v-if="links" class="SocialActions">
    <RssButton
      v-if="links.rss && links.rss.url"
      :rss-details="links.rss"
      :rss-type="partnerName"
    />
    <a
      v-if="links.facebook && links.facebook.url"
      :href="links.facebook.url"
      target="_blank"
    >
      <img :src="links.facebook.logo" :alt="socialLogoAltText('Facebook')" />
    </a>
    <a
      v-if="links.twitter && links.twitter.url"
      :href="links.twitter.url"
      target="_blank"
    >
      <img :src="links.twitter.logo" :alt="socialLogoAltText('Twitter')" />
    </a>
    <a
      v-if="links.youtube && links.youtube.url"
      :href="links.youtube.url"
      target="_blank"
    >
      <img :src="links.youtube.logo" :alt="socialLogoAltText('Youtube')" />
    </a>
    <a
      v-if="links.linkedin && links.linkedin.url"
      :href="links.linkedin.url"
      target="_blank"
    >
      <img :src="links.linkedin.logo" :alt="socialLogoAltText('LinkedIn')" />
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RssButton from "../buttons/RssButton";
import getSiteName from "~/plugins/utilities/getSiteName.js";

export default {
  name: "SocialActions",
  components: { RssButton },
  computed: {
    ...mapState({
      links: (state) => state.theme.links,
      layout: (state) => state.theme.layout,
      partnerName: (state) => `${state.theme.title} ${state.theme.subTitle}`,
      siteName: (state) => getSiteName(state.theme),
    }),
  },
  methods: {
    socialLogoAltText(socialPlatform) {
      return `${this.siteName} ${socialPlatform} home | opens in a new tab`;
    },
  },
};
</script>

<style>
.SocialActions {
  display: inline-block;
  margin-left: -6px;
}

.SocialActions button {
  float: left;
}

.SocialActions a:focus,
.SocialActions button:focus {
  outline: 1px solid #fff;
}

.SocialActions a {
  display: inline-block;
}

.SocialActions img {
  padding: 0 4px;
}
</style>
