const isDefined = (value) => value !== undefined;

export function hasParentCategory(category) {
  const { parentId } = category;

  if (parentId) return true;
  if (parentId === null) return false;

  throw new Error(
    "Could not determine if category has a parent because parentId is undefined."
  );
}

export function isRootCategory(category) {
  if (isDefined(category.parentId)) return !hasParentCategory(category);

  throw new Error(
    "Could not determine if category does not have a parent because parentId is undefined."
  );
}
