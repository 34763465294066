import displayName from "~/plugins/utilities/displayName";
import localDateTime from "~/plugins/utilities/localDateTime";

const SET_DISCUSSION_REPLY_REVIEW = (state, payload) => {
  const { discussionReportReasons, reportedDiscussionReply } = payload;

  const reasonsWithNames = reportedDiscussionReply.reasons.map((reason) => ({
    ...reason,
    name: discussionReportReasons.find((r) => r.id === reason.id)?.name,
  }));

  reportedDiscussionReply.moderationChanges =
    reportedDiscussionReply.moderationChanges.map((change) => ({
      ...change,
      operation: change.operation.replace("_", " "),
      formattedDate: localDateTime(change.createdAt),
      authorFullName: displayName(change.author),
    }));

  state.selectedReportedDiscussionReply = reportedDiscussionReply;
  state.referenceData.discussionReportReasonTypes = reasonsWithNames;
};

export default SET_DISCUSSION_REPLY_REVIEW;
