<template>
  <div id="InformationListener">
    <v-dialog
      ref="informationDialogRef"
      v-model="show"
      attach="#InformationListener"
      max-width="400"
      @keydown.esc="onCancel"
      @click:outside="onClickOutside"
    >
      <v-card>
        <v-card-title>
          <h2
            id="information-dialog-title"
            class="headline"
            style="word-break: keep-all"
          >
            {{ title }}
          </h2>
        </v-card-title>
        <v-card-text v-if="message">{{ message }}</v-card-text>
        <v-card-actions v-if="confirmWithCheckbox">
          <v-checkbox
            v-model="userConfirmation"
            :label="checkboxText"
          ></v-checkbox>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="cancelButton"
            ref="cancelInformationDialog"
            class="cancelInformationDialog"
            color="warning"
            text
            aria-label="cancel"
            @click="onCancel"
            >Cancel</v-btn
          >
          <v-btn
            ref="closeInformationDialog"
            class="closeInformationDialog"
            color="success"
            text
            :aria-label="buttonText"
            :disabled="disableActionButton"
            @click="onClose"
            >{{ buttonText }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "InformationListener",
  data() {
    return {
      show: false,
      title: undefined,
      message: undefined,
      buttonText: undefined,
      cancelButton: undefined,
      confirmWithCheckbox: undefined,
      userConfirmation: false,
      handleClose: undefined,
      action: undefined,
      returnFocusTarget: undefined,
      actionClickOutside: undefined,
    };
  },
  computed: {
    partner() {
      return this.$config.partner;
    },
    disableActionButton() {
      return this.confirmWithCheckbox ? !this.userConfirmation : false;
    },
  },
  mounted() {
    this.$root.$on("show-information", this.onShowInformation);
    this.$root.$on(
      "show-core-login-information",
      this.onShowCoreLoginInformation
    );
    this.$root.$on("orcid-id-sync-success", this.onShowOrcidSyncSuccess);
  },
  methods: {
    onShowInformation({
      title,
      message,
      action,
      checkboxText,
      buttonText = "OK",
      cancelButton = false,
      currentTarget,
      confirmWithCheckbox = false,
      actionClickOutside,
    }) {
      this.show = true;
      this.title = title;
      this.message = message;
      this.buttonText = buttonText;
      this.cancelButton = cancelButton;
      this.confirmWithCheckbox = confirmWithCheckbox;
      this.checkboxText = checkboxText;
      this.action = action;
      this.returnFocusTarget = currentTarget;
      this.actionClickOutside = actionClickOutside;
      this.setInitialFocus();
      this.setAriaLabelAttribute();
    },
    setInitialFocus() {
      this.$nextTick(() => {
        const { cancelInformationDialog, closeInformationDialog } = this.$refs;
        cancelInformationDialog?.$el.focus() ||
          closeInformationDialog?.$el.focus();
      });
    },
    setAriaLabelAttribute() {
      this.$nextTick(() => {
        const dialogRef = this?.$refs?.informationDialogRef?.$children[0]?.$el;

        if (dialogRef) {
          const titleByElementId = document.getElementById(
            "information-dialog-title"
          ).textContent;
          dialogRef.setAttribute("aria-label", titleByElementId);
        }
      });
    },
    onClose() {
      this.show = false;

      if (this.action) {
        this.action();
      }
      this.returnFocus();
    },
    onCancel() {
      this.show = false;
      this.returnFocus();
    },
    onClickOutside() {
      this.show = false;
      if (this.actionClickOutside) {
        this.actionClickOutside();
      }
      this.returnFocus();
    },
    onShowOrcidSyncSuccess() {
      this.onShowInformation({
        title: `ORCID iD Assignment Success`,
        message: `Your ORCID iD was successfully assigned to the submission`,
        action: () => {
          this.show = false;
          this.$router.replace({ query: null });
        },
        actionClickOutside: () => {
          this.$router.replace({ query: null });
        },
        buttonText: "OK",
      });
    },
    onShowCoreLoginInformation({ login, newPath, currentTarget }) {
      const storedCookieMessage = this.$localStorage.get("showCoreLoginInfo");
      const showCoreLoginInfo =
        storedCookieMessage !== false && this.partner === "coe";

      if (showCoreLoginInfo === true)
        this.onShowInformation({
          title: `Log in information`,
          message: `To upload content to Cambridge Open Engage you need a Cambridge University Press Academic account. You can log in or create an account on our research site, Cambridge Core. Please click 'proceed' to log in and register, and return to Cambridge Open Engage if you are creating a new account.`,
          action: () => {
            this.$localStorage.set("showCoreLoginInfo", false);
            login(newPath);
          },
          buttonText: "Proceed",
          cancelButton: true,
          currentTarget,
        });
      else {
        login(newPath);
      }
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
  },
};
</script>
