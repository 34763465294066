import coAuthorSyncOrcidEmailSend from "~/store/graphql/mutations/coAuthorSyncOrcidEmailSend.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const sendCoAuthorSyncOrcidEmail = async function (_, params) {
  const data = await runGraphQLQuery(
    coAuthorSyncOrcidEmailSend,
    params,
    this.$axios
  );

  return Promise.resolve(data.coAuthorSyncOrcidEmailSend);
};

export default sendCoAuthorSyncOrcidEmail;
