import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

import { discussionVoteRemove } from "~/store/graphql/mutations/discussionVoteRemove.graphql";

const createDiscussionVote = async function (
  { commit, state },
  { discussionId }
) {
  commit("START_LOADING");

  await runGraphQLQuery(
    discussionVoteRemove,
    {
      entityId: discussionId,
      entityType: "DISCUSSION",
    },
    this.$axios
  );

  commit("REMOVE_DISCUSSION_VOTE", { discussionId });

  commit("END_LOADING");

  return Promise.resolve();
};

export default createDiscussionVote;
