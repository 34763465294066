import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

const rejectForSubmitArticle = async function rejectForSubmitArticle() {
  try {
    await this.$store.dispatch("updateSubmissionStatus", {
      action: "REJECT",
      params: {
        id: this.item.id,
        rejectionReason: this.rejectionReason.id,
        emailContent: this.model || null,
      },
    });
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }

  this.$root.$emit("change-view", {
    view: "administration-dashboard?show=new-submissions",
  });
};

export default rejectForSubmitArticle;
