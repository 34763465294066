import browseDashboardPageLoad from "~/store/graphql/queries/browseDashboardPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadBrowseDashboard = async function ({ commit, state }, { req, res }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    browseDashboardPageLoad,
    {},
    this.$axios,
    req,
    res
  );

  commit("SET_BROWSE_DASHBOARD", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadBrowseDashboard;
