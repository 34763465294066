const mandatoryAuthorChecks = [
  {
    severity: "ERROR",
    type: "AUTHOR",
    fieldName: "firstName",
    errorMessage: "First Name missing",
    resolutionMessage: "Please enter a first name",
  },
  {
    severity: "ERROR",
    type: "AUTHOR",
    fieldName: "lastName",
    errorMessage: "Last Name missing",
    resolutionMessage: "Please enter a last name",
  },
  {
    severity: "ERROR",
    type: "AUTHOR",
    fieldName: "emailAddress",
    errorMessage: "Email Address missing",
    resolutionMessage: "Please enter an email address",
  },
  {
    severity: "ERROR",
    type: "AUTHOR",
    fieldName: "showEmailAddress",
    errorMessage: "Corresponding author preference missing",
    resolutionMessage: "Please confirm your corresponding author preference",
  },
  {
    severity: "ERROR",
    type: "AUTHOR",
    fieldName: "institutions",
    alternativeFieldName: "unaffiliated",
    errorMessage: "Institutions are missing, or select no affiliation",
    resolutionMessage: "Please enter an institution or select no affiliation",
  },
  {
    severity: "ERROR",
    type: "AUTHOR",
    fieldName: "unaffiliated",
    errorMessage: "Affiliation selection is missing",
    resolutionMessage: "Please enter an institution or select no affiliation",
  },
];

export default mandatoryAuthorChecks;
