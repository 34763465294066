export default function processItems(items, featureToggles) {
  items.forEach((item) => {
    const versions = item.versionRefs
      ? item.versionRefs.sort((a, b) => (a.version < b.version ? 1 : -1))
      : undefined;

    const latestItem = versions ? versions[0] : undefined;

    item.isLatestPublishedId = latestItem
      ? latestItem.itemId === item.id
      : undefined;

    item.isAssignable =
      !item.isModerated && featureToggles.administrationDashboardSiteModerators;
    item.isUnassignable =
      item.isModerated && featureToggles.administrationDashboardSiteModerators;
    item.canReviewSubmission = featureToggles.showModerationNotes;

    item.isCuratorAssignable =
      featureToggles.administrationDashboardAssignCurators;
  });
}
