import communitiesDashboardPageLoad from "~/store/graphql/queries/communitiesDashboardPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadCommunitiesDashboard = async function (
  { commit, state },
  { req, res }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    communitiesDashboardPageLoad,
    {},
    this.$axios,
    req,
    res
  );

  commit("SET_COMMUNITIES_DASHBOARD", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadCommunitiesDashboard;
