import submissionReportChecks from "~/store/manage/validation/checks/submissionReportChecks";
import createError from "~/store/manage/validation/utilities/createError";

export default function checkSubmissionsReportForErrors(state) {
  const { submissionsReport, fundersReport } = state.currentAdministrator;

  if (!submissionsReport || !fundersReport) {
    return [];
  }

  const errors = [];
  const { startDate: submissionStartDate, endDate: submissionEndDate } =
    submissionsReport;
  const { startDate: fundersStartDate, endDate: fundersEndDate } =
    fundersReport;

  if (submissionEndDate < submissionStartDate) {
    const check = submissionReportChecks.find(
      (check) => check.type === "END_DATE_VALIDATION"
    );
    errors.push(createError(submissionsReport, check));
  }
  if (fundersEndDate < fundersStartDate) {
    const check = submissionReportChecks.find(
      (check) => check.type === "END_DATE_VALIDATION"
    );
    errors.push(createError(fundersReport, check));
  }
  return errors;
}
