import { format, isDate, parseISO } from "date-fns";

export default function localDate(value, displayFormat = "MMM dd, yyyy") {
  if (!value) {
    return undefined;
  }

  if (!isDate(value)) {
    return format(parseISO(value), displayFormat);
  }
}
