import submitToSpecificJournalLoad from "~/store/graphql/queries/submitToSpecificJournalLoad.graphql";
import runGraphQLQuery, {
  lastQuery,
} from "~/store/manage/utilities/runGraphQLQuery";

const loadSpecificJournalDetails = async function (
  { commit },
  { params, req, res, saveLastGraphQL }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    submitToSpecificJournalLoad,
    params,
    this.$axios,
    req,
    res
  );
  if (saveLastGraphQL === true) {
    commit("SET_LAST_GRAPHQL", lastQuery());
  }

  commit("SET_ARTICLE_DETAILS", data);
  commit("SET_SELECTED_JOURNAL", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadSpecificJournalDetails;
