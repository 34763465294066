import Vue from "vue";

import timeInterval from "./utilities/timeInterval";
import concatenateValues from "./utilities/concatenateValues";
import displayArrayAsString from "./utilities/displayArrayAsString";
import displayName from "./utilities/displayName";
import extractMetricsName from "./utilities/extractMetricsName";
import extractMetricsValue from "./utilities/extractMetricsValue";
import isAuthorized from "./utilities/isAuthorized";
import isLoggedIn from "./utilities/isLoggedIn";
import isLoggedOut from "./utilities/isLoggedOut";
import isUnauthorized from "./utilities/isUnauthorized";
import localDate from "./utilities/localDate";
import localDateTime from "./utilities/localDateTime";
import localNumber from "./utilities/localNumber";
import localNumberWithNo from "./utilities/localNumberWithNo";
import localNumberWithZero from "./utilities/localNumberWithZero";
import localTime from "./utilities/localTime";
import sorted from "./utilities/sorted";
import sortedCaseInsensitive from "./utilities/sortedCaseInsensitive";
import upperCase from "./utilities/upperCase";
import reference from "./utilities/reference";
import version from "./utilities/version";
import getFirstAuthorEmail from "./utilities/getFirstAuthorEmail";

export default () => {
  Vue.filter("concatenateValues", concatenateValues);
  Vue.filter("displayName", displayName);
  Vue.filter("displayArrayAsString", displayArrayAsString);
  Vue.filter("extractMetricsName", extractMetricsName);
  Vue.filter("extractMetricsValue", extractMetricsValue);
  Vue.filter("isAuthorized", isAuthorized);
  Vue.filter("isLoggedIn", isLoggedIn);
  Vue.filter("isLoggedOut", isLoggedOut);
  Vue.filter("isUnauthorized", isUnauthorized);
  Vue.filter("localDate", localDate);
  Vue.filter("localDateTime", localDateTime);
  Vue.filter("localNumber", localNumber);
  Vue.filter("localNumberWithNo", localNumberWithNo);
  Vue.filter("localNumberWithZero", localNumberWithZero);
  Vue.filter("localTime", localTime);
  Vue.filter("sorted", sorted);
  Vue.filter("sortedCaseInsensitive", sortedCaseInsensitive);
  Vue.filter("timeInterval", timeInterval);
  Vue.filter("upperCase", upperCase);
  Vue.filter("reference", reference);
  Vue.filter("version", version);
  Vue.filter("getFirstAuthorEmail", getFirstAuthorEmail);
};
