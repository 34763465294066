export default function localNumberWithNo(value) {
  if (!value) {
    return "No";
  }

  if (!isNaN(value)) {
    return value.toLocaleString();
  } else {
    return "No";
  }
}
