<template>
  <div
    :class="['v-div', displayClasses]"
    :style="displayedStyles"
    @click="onClicked"
  >
    <nuxt-link v-if="to !== undefined" :to="to">
      <slot></slot>
    </nuxt-link>
    <slot v-else></slot>
  </div>
</template>

<script>
import { formatCSSSize } from "~/components/utilities/formatCSSSize";

const passThroughDirectives = [
  "display-4",
  "display-3",
  "display-2",
  "display-1",
  "headline",
  "title",
  "subtitle-1",
  "body-2",
  "body-1",
  "caption",

  "font-weight-thin",
  "font-weight-light",
  "font-weight-regular",
  "font-weight-medium",
  "font-weight-bold",
  "font-weight-black",

  "font-italic",

  "text-capitalize",
  "text-lowercase",
  "text-uppercase",

  "text-no-wrap",
  "text-truncate",

  "overflow-hidden",
  "overflow-x-hidden",
  "overflow-y-hidden",

  "d-none",
  "d-inline",
  "d-inline-block",
  "d-block",
  "d-table",
  "d-table-cell",
  "d-table-row",
  "d-flex",
  "d-inline-flex",

  "d-sm-none",
  "d-sm-inline",
  "d-sm-inline-block",
  "d-sm-block",
  "d-sm-table",
  "d-sm-table-cell",
  "d-sm-table-row",
  "d-sm-flex",
  "d-sm-inline-flex",

  "d-md-none",
  "d-md-inline",
  "d-md-inline-block",
  "d-md-block",
  "d-md-table",
  "d-md-table-cell",
  "d-md-table-row",
  "d-md-flex",
  "d-md-inline-flex",

  "d-lg-none",
  "d-lg-inline",
  "d-lg-inline-block",
  "d-lg-block",
  "d-lg-table",
  "d-lg-table-cell",
  "d-lg-table-row",
  "d-lg-flex",
  "d-lg-inline-flex",

  "d-xl-none",
  "d-xl-inline",
  "d-xl-inline-block",
  "d-xl-block",
  "d-xl-table",
  "d-xl-table-cell",
  "d-xl-table-row",
  "d-xl-flex",
  "d-xl-inline-flex",

  "hidden-xs-only",
  "hidden-sm-only",
  "hidden-md-only",
  "hidden-lg-only",
  "hidden-xl-only",

  "hidden-sm-and-down",
  "hidden-md-and-down",
  "hidden-lg-and-down",
  "hidden-xl-and-down",

  "hidden-sm-and-up",
  "hidden-md-and-up",
  "hidden-lg-and-up",
  "hidden-xl-and-up",

  "ma-auto",
  "ma-0",
  "ma-1",
  "ma-2",
  "ma-3",
  "ma-4",
  "ma-5",
  "mt-auto",
  "mt-0",
  "mt-1",
  "mt-2",
  "mt-3",
  "mt-4",
  "mt-5",
  "mb-auto",
  "mb-0",
  "mb-1",
  "mb-2",
  "mb-3",
  "mb-4",
  "mb-5",
  "ml-auto",
  "ml-0",
  "ml-1",
  "ml-2",
  "ml-3",
  "ml-4",
  "ml-5",
  "mr-auto",
  "mr-0",
  "mr-1",
  "mr-2",
  "mr-3",
  "mr-4",
  "mr-5",
  "mx-auto",
  "mx-0",
  "mx-1",
  "mx-2",
  "mx-3",
  "mx-4",
  "mx-5",
  "my-auto",
  "my-0",
  "my-1",
  "my-2",
  "my-3",
  "my-4",
  "my-5",

  "pa-auto",
  "pa-0",
  "pa-1",
  "pa-2",
  "pa-3",
  "pa-4",
  "pa-5",
  "pt-auto",
  "pt-0",
  "pt-1",
  "pt-2",
  "pt-3",
  "pt-4",
  "pt-5",
  "pb-auto",
  "pb-0",
  "pb-1",
  "pb-2",
  "pb-3",
  "pb-4",
  "pb-5",
  "pl-auto",
  "pl-0",
  "pl-1",
  "pl-2",
  "pl-3",
  "pl-4",
  "pl-5",
  "pr-auto",
  "pr-0",
  "pr-1",
  "pr-2",
  "pr-3",
  "pr-4",
  "pr-5",
  "px-auto",
  "px-0",
  "px-1",
  "px-2",
  "px-3",
  "px-4",
  "px-5",
  "py-auto",
  "py-0",
  "py-1",
  "py-2",
  "py-3",
  "py-4",
  "py-5",

  "text-left",
  "text-center",
  "text-right",
  "text-sm-left",
  "text-sm-center",
  "text-sm-right",
  "text-md-left",
  "text-md-center",
  "text-md-right",
  "text-lg-left",
  "text-lg-center",
  "text-lg-right",
  "text-xl-left",
  "text-xl-center",
  "text-xl-right",

  "elevation-0",
  "elevation-1",
  "elevation-2",
  "elevation-3",
  "elevation-4",
  "elevation-5",
  "elevation-6",
  "elevation-7",
  "elevation-8",
  "elevation-9",
  "elevation-10",
  "elevation-11",
  "elevation-12",
  "elevation-13",
  "elevation-14",
  "elevation-15",
  "elevation-16",
  "elevation-17",
  "elevation-18",
  "elevation-19",
  "elevation-20",
  "elevation-21",
  "elevation-22",
  "elevation-23",
  "elevation-24",
];

const additionalDirectives = [
  "work-in-progress",

  "hidden",

  "partially-transparent",
  "slightly-transparent",
  "almost-transparent",

  "font-small",
  "font-smaller",
  "font-very-small",
  "font-large",
  "font-larger",
  "font-very-large",

  "has-action",

  "float-left",
  "float-center",
  "float-right",
  "clear-both",

  "clear-fix",

  "rounded",
  "rounded-16",
  "rounded-32",
  "rounded-64",
  "rounded-128",
  "rounded-left-16",
  "rounded-left-32",
  "rounded-left-64",
  "rounded-left-128",
  "rounded-right-16",
  "rounded-right-32",
  "rounded-right-64",
  "rounded-right-128",

  "rounded-top-left-64",
  "rounded-top-right-64",
  "rounded-bottom-left-64",
  "rounded-bottom-right-64",

  "height-8",
  "height-16",
  "height-24",
  "height-32",
  "height-48",
  "height-64",
  "height-80",
  "height-90",
  "height-96",
  "height-105",
  "height-128",
  "height-256",
  "height-512",
  "height-25-percent",
  "height-33-percent",
  "height-50-percent",
  "height-66-percent",
  "height-100-percent",

  "width-8",
  "width-16",
  "width-24",
  "width-32",
  "width-48",
  "width-64",
  "width-96",
  "width-128",
  "width-256",
  "width-512",
  "width-25-percent",
  "width-33-percent",
  "width-50-percent",
  "width-66-percent",
  "width-100-percent",

  "width-100-minus-24",
  "width-100-minus-48",
  "width-100-minus-64",
  "width-100-minus-96",
  "width-100-minus-128",
  "width-100-minus-256",
  "width-100-minus-512",

  "min-height-8",
  "min-height-16",
  "min-height-24",
  "min-height-32",
  "min-height-48",
  "min-height-64",
  "min-height-80",
  "min-height-96",
  "min-height-128",
  "min-height-256",
  "min-height-512",
  "min-height-25-percent",
  "min-height-33-percent",
  "min-height-50-percent",
  "min-height-66-percent",

  "min-width-8",
  "min-width-16",
  "min-width-24",
  "min-width-32",
  "min-width-48",
  "min-width-64",
  "min-width-96",
  "min-width-128",
  "min-width-256",
  "min-width-512",
  "min-width-25-percent",
  "min-width-33-percent",
  "min-width-50-percent",
  "min-width-66-percent",

  "max-width-80",
  "max-width-160",
  "max-width-240",
  "max-width-320",
  "max-width-640",
  "max-width-720",
  "max-width-800",
  "max-width-960",
  "max-width-1280",

  "max-width-33-percent",
  "max-width-50-percent",
  "max-width-66-percent",
  "max-width-100-percent",

  "layer-0",

  "layer-4",
  "layer-6",
  "layer-8",
  "layer-12",
  "layer-16",
  "layer-24",

  "layer-1",
  "layer-2",
  "layer-3",
  "layer-5",

  "layer-10",
  "layer-20",
  "layer-30",

  "layer-100",
  "layer-200",
  "layer-300",

  "layer-999",

  "relative",
  "absolute",
  "fixed",
  "sticky",

  "left-0",
  "left-8",
  "left-16",
  "left-24",
  "left-32",
  "left-64",

  "right-0",
  "right-8",
  "right-16",
  "right-24",
  "right-32",
  "right-64",

  "top-0",
  "top-8",
  "top-16",
  "top-24",
  "top-32",
  "top-64",

  "bottom-0",
  "bottom-8",
  "bottom-16",
  "bottom-24",
  "bottom-32",
  "bottom-64",

  "vertical-center-outer",
  "vertical-center-inner",

  "align-top",
  "align-bottom",
];

const backgroundDirective = "background-";
const foregroundDirective = "foreground-";
const borderDirective = "border-";
const borderDirectives = [
  "-all",
  "-topbottom",
  "-leftright",
  "-top",
  "-left",
  "-bottom",
  "-right",
];
const thicknessDirectives = ["-thin", "-thick", "-verythick"];

const colorDirectives = [
  "primary",
  "secondary",
  "accent",
  "error",
  "info",
  "success",
  "warning",
  "focused",
  "hardened",
  "putted",
  "softened",
  "subdued",
  "inverted",
  "bright",
  "brighter",
  "dim",
  "dimer",
];

const directionDirectives = ["ltr", "rtl", "ttb", "btt"];

colorDirectives.forEach((color) => {
  directionDirectives.forEach((direction) => {
    additionalDirectives.push("background-" + color + "-gradient-" + direction);
  });
});

export default {
  name: "VDiv",
  props: {
    to: { type: String, default: undefined },
    foreground: { type: String, default: undefined },
    background: { type: String, default: undefined },
    height: { type: [String, Number], default: undefined },
    width: { type: [String, Number], default: undefined },
    left: { type: [String, Number], default: undefined },
    right: { type: [String, Number], default: undefined },
    top: { type: [String, Number], default: undefined },
    bottom: { type: [String, Number], default: undefined },
    radius: { type: [String, Number], default: undefined },
    radiusLeft: { type: [String, Number], default: undefined },
    radiusRight: { type: [String, Number], default: undefined },
    minHeight: { type: [String, Number], default: undefined },
    minWidth: { type: [String, Number], default: undefined },
  },
  computed: {
    displayedStyles() {
      let style;

      if (this.height) {
        style = style || {};

        style.height = formatCSSSize(this.height);
      }

      if (this.width) {
        style = style || {};

        style.width = formatCSSSize(this.width);
      }

      if (this.minHeight) {
        style = style || {};

        style.minHeight = formatCSSSize(this.minHeight);
      }

      if (this.minWidth) {
        style = style || {};

        style.minWidth = formatCSSSize(this.minWidth);
      }

      if (this.left) {
        style = style || {};

        style.left = formatCSSSize(this.left);
      }

      if (this.right) {
        style = style || {};

        style.right = formatCSSSize(this.right);
      }

      if (this.top) {
        style = style || {};

        style.top = formatCSSSize(this.top);
      }

      if (this.bottom) {
        style = style || {};

        style.bottom = formatCSSSize(this.bottom);
      }

      if (this.radius) {
        style = style || {};

        style.borderRadius = formatCSSSize(this.radius);
      }

      if (this.radiusLeft) {
        style = style || {};

        style.borderTopLeftRadius = formatCSSSize(this.radiusLeft);
        style.borderBottomLeftRadius = formatCSSSize(this.radiusLeft);
      }

      if (this.radiusRight) {
        style = style || {};

        style.borderTopRightRadius = formatCSSSize(this.radiusRight);
        style.borderBottomRightRadius = formatCSSSize(this.radiusRight);
      }

      return style;
    },
    displayClasses() {
      const vnode = this.$vnode || {};
      const data = vnode.data || {};
      const attrs = data.attrs || {};

      const displayClasses = {};

      if (this.foreground) {
        displayClasses[`${this.foreground}--text`] = true;
      }

      if (this.background !== undefined) {
        displayClasses[`${this.background}`] = true;
      }

      for (const attr in attrs) {
        let handled = false;

        if (attrs[attr] === false) {
          handled = true;
        }

        if (handled === false) {
          // eslint-disable-next-line array-callback-return
          passThroughDirectives.some((passThroughClass) => {
            if (
              passThroughClass === attr &&
              (attrs[passThroughClass] === true ||
                attrs[passThroughClass] === "")
            ) {
              displayClasses[passThroughClass] = true;
              handled = true;
              return handled;
            }
          });
        }

        if (handled === false) {
          // eslint-disable-next-line array-callback-return
          additionalDirectives.some((additionalClass) => {
            if (
              additionalClass === attr &&
              (attrs[additionalClass] === true || attrs[additionalClass] === "")
            ) {
              displayClasses[additionalClass] = true;
              handled = true;
              return handled;
            }
          });
        }

        if (handled === false) {
          if (attr.indexOf(backgroundDirective) === 0) {
            const backgroundClass = attr.replace(backgroundDirective, "");
            displayClasses[backgroundClass] = true;
            handled = true;
          }
        }

        if (handled === false) {
          if (attr.indexOf(foregroundDirective) === 0) {
            const foregroundClass =
              attr.replace(foregroundDirective, "") + "--text";
            displayClasses[foregroundClass] = true;
            handled = true;
          }
        }

        if (handled === false) {
          if (attr.indexOf(borderDirective) === 0) {
            let borderClass = attr;
            let thicknessClass = "-normal";
            let colorClass;

            // eslint-disable-next-line array-callback-return
            thicknessDirectives.some((directive) => {
              if (attr.includes(directive)) {
                borderClass = borderClass.replace(directive, "");
                thicknessClass = directive;
                return true;
              }
            });

            // eslint-disable-next-line array-callback-return
            borderDirectives.some((directive) => {
              if (attr.includes(directive)) {
                borderClass = (borderDirective + directive).replace("--", "-");
                return true;
              }
            });

            thicknessClass = (borderDirective + thicknessClass).replace(
              "--",
              "-"
            );

            // eslint-disable-next-line array-callback-return
            colorDirectives.some((directive) => {
              if (attr.includes(directive)) {
                colorClass = (borderDirective + directive).replace("--", "-");
                return true;
              }
            });

            displayClasses[borderClass] = true;
            displayClasses[thicknessClass] = true;

            if (colorClass) {
              displayClasses[colorClass] = true;
            }

            handled = true;
          }
        }
      }

      return displayClasses;
    },
  },
  methods: {
    onClicked() {
      if (this.$listeners && this.$listeners.click) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style>
.v-div.work-in-progress {
  filter: blur(1px);
  background: rgba(242, 242, 242, 0.5);
  border: 1px dashed crimson;
}

.v-div.hidden {
  display: none;
}

.v-div.partially-transparent {
  opacity: 0.75;
}
.v-div.slightly-transparent {
  opacity: 0.5;
}
.v-div.almost-transparent {
  opacity: 0.2;
}

.v-div.has-action {
  cursor: pointer;
}

.v-div.float-left {
  float: left;
  clear: none;
  display: block;
}
.v-div.float-center {
  margin: 0 auto !important;
  clear: none;
  display: block;
}
.v-div.float-right {
  float: right;
  clear: none;
  display: block;
}

.v-div.clear-both {
  clear: both;
}

.v-div.font-small {
  font-size: small;
}
.v-div.font-smaller {
  font-size: smaller;
}
.v-div.font-very-small {
  font-size: x-small;
}
.v-div.font-large {
  font-size: large;
}
.v-div.font-larger {
  font-size: larger;
}
.v-div.font-very-large {
  font-size: x-large;
}

.v-div.border-all {
  border-style: solid;
}
.v-div.border-top {
  border-top-style: solid;
}
.v-div.border-left {
  border-left-style: solid;
}
.v-div.border-bottom {
  border-bottom-style: solid;
}
.v-div.border-right {
  border-right-style: solid;
}
.v-div.border-topbottom {
  border-top-style: solid;
  border-bottom-style: solid;
}
.v-div.border-leftright {
  border-left-style: solid;
  border-right-style: solid;
}

.v-div.border-thin {
  border-width: 1px;
}
.v-div.border-normal {
  border-width: 2px;
}
.v-div.border-thick {
  border-width: 3px;
}
.v-div.border-verythick {
  border-width: 8px;
}

.v-div.clear-fix::after {
  content: "";
  clear: both;
  display: table;
}

.v-div.rounded {
  border-radius: 16px;
}
.v-div.rounded-16 {
  border-radius: 16px;
}
.v-div.rounded-32 {
  border-radius: 32px;
}
.v-div.rounded-64 {
  border-radius: 64px;
}
.v-div.rounded-128 {
  border-radius: 128px;
}

.v-div.rounded-right-16 {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.v-div.rounded-right-32 {
  border-top-right-radius: 32px;
  border-bottom-right-radius: 32px;
}
.v-div.rounded-right-64 {
  border-top-right-radius: 64px;
  border-bottom-right-radius: 64px;
}
.v-div.rounded-right-128 {
  border-top-right-radius: 128px;
  border-bottom-right-radius: 128px;
}

.v-div.rounded-left-16 {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.v-div.rounded-left-32 {
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
}
.v-div.rounded-left-64 {
  border-top-left-radius: 64px;
  border-bottom-left-radius: 64px;
}
.v-div.rounded-left-128 {
  border-top-left-radius: 128px;
  border-bottom-left-radius: 128px;
}

.v-div.rounded-top-left-64 {
  border-top-left-radius: 64px;
}
.v-div.rounded-top-right-64 {
  border-top-right-radius: 64px;
}
.v-div.rounded-bottom-right-64 {
  border-bottom-right-radius: 64px;
}
.v-div.rounded-bottom-left-64 {
  border-bottom-left-radius: 64px;
}

.v-div.height-8 {
  height: 8px;
}
.v-div.height-16 {
  height: 16px;
}
.v-div.height-24 {
  height: 24px;
}
.v-div.height-32 {
  height: 32px;
}
.v-div.height-48 {
  height: 48px;
}
.v-div.height-64 {
  height: 64px;
}
.v-div.height-80 {
  height: 80px;
}
.v-div.height-90 {
  height: 90px;
}
.v-div.height-96 {
  height: 96px;
}
.v-div.height-105 {
  height: 105px;
}
.v-div.height-128 {
  height: 128px;
}
.v-div.height-256 {
  height: 256px;
}
.v-div.height-512 {
  height: 512px;
}
.v-div.height-25-percent {
  height: 25%;
}
.v-div.height-33-percent {
  height: 33%;
}
.v-div.height-50-percent {
  height: 50%;
}
.v-div.height-66-percent {
  height: 66%;
}
.v-div.height-100-percent {
  height: 100%;
}

.v-div.width-8 {
  width: 8px;
}
.v-div.width-16 {
  width: 16px;
}
.v-div.width-24 {
  width: 24px;
}
.v-div.width-32 {
  width: 32px;
}
.v-div.width-48 {
  width: 48px;
}
.v-div.width-64 {
  width: 64px;
}
.v-div.width-96 {
  width: 96px;
}
.v-div.width-128 {
  width: 128px;
}
.v-div.width-256 {
  width: 256px;
}
.v-div.width-512 {
  width: 512px;
}
.v-div.width-25-percent {
  width: 25%;
}
.v-div.width-33-percent {
  width: 33%;
}
.v-div.width-50-percent {
  width: 50%;
}
.v-div.width-66-percent {
  width: 66%;
}
.v-div.width-100-percent {
  width: 100%;
}

.v-div.width-100-minus-24 {
  width: calc(100% - 24px);
}
.v-div.width-100-minus-48 {
  width: calc(100% - 48px);
}
.v-div.width-100-minus-64 {
  width: calc(100% - 64px);
}
.v-div.width-100-minus-96 {
  width: calc(100% - 96px);
}
.v-div.width-100-minus-128 {
  width: calc(100% - 128px);
}
.v-div.width-100-minus-256 {
  width: calc(100% - 256px);
}
.v-div.width-100-minus-512 {
  width: calc(100% - 512px);
}

.v-div.min-height-8 {
  min-height: 8px;
}
.v-div.min-height-16 {
  min-height: 16px;
}
.v-div.min-height-24 {
  min-height: 24px;
}
.v-div.min-height-32 {
  min-height: 32px;
}
.v-div.min-height-48 {
  min-height: 48px;
}
.v-div.min-height-64 {
  min-height: 64px;
}
.v-div.min-height-80 {
  min-height: 80px;
}
.v-div.min-height-96 {
  min-height: 96px;
}
.v-div.min-height-128 {
  min-height: 128px;
}
.v-div.min-height-256 {
  min-height: 256px;
}
.v-div.min-height-512 {
  min-height: 512px;
}
.v-div.min-height-25-percent {
  min-height: 25%;
}
.v-div.min-height-33-percent {
  min-height: 33%;
}
.v-div.min-height-50-percent {
  min-height: 50%;
}
.v-div.min-height-66-percent {
  min-height: 66%;
}

.v-div.min-width-8 {
  min-width: 8px;
}
.v-div.min-width-16 {
  min-width: 16px;
}
.v-div.min-width-24 {
  min-width: 24px;
}
.v-div.min-width-32 {
  min-width: 32px;
}
.v-div.min-width-48 {
  min-width: 48px;
}
.v-div.min-width-64 {
  min-width: 64px;
}
.v-div.min-width-96 {
  min-width: 96px;
}
.v-div.min-width-128 {
  min-width: 128px;
}
.v-div.min-width-256 {
  min-width: 256px;
}
.v-div.min-width-512 {
  min-width: 512px;
}
.v-div.min-width-25-percent {
  min-width: 25%;
}
.v-div.min-width-33-percent {
  min-width: 33%;
}
.v-div.min-width-50-percent {
  min-width: 50%;
}
.v-div.min-width-66-percent {
  min-width: 66%;
}

.v-div.max-width-80 {
  max-width: 80px;
}
.v-div.max-width-160 {
  max-width: 160px;
}
.v-div.max-width-240 {
  max-width: 240px;
}
.v-div.max-width-320 {
  max-width: 320px;
}
.v-div.max-width-640 {
  max-width: 640px;
}
.v-div.max-width-720 {
  max-width: 720px;
}
.v-div.max-width-800 {
  max-width: 800px;
}
.v-div.max-width-960 {
  max-width: 960px;
}
.v-div.max-width-1280 {
  max-width: 1280px;
}

.v-div.max-width-33-percent {
  max-width: 33%;
}
.v-div.max-width-50-percent {
  max-width: 50%;
}
.v-div.max-width-66-percent {
  max-width: 66%;
}
.v-div.max-width-100-percent {
  max-width: 100%;
}

.v-div.layer-0 {
  z-index: 0;
}

.v-div.layer-1 {
  z-index: 1;
}
.v-div.layer-2 {
  z-index: 2;
}
.v-div.layer-3 {
  z-index: 3;
}
.v-div.layer-5 {
  z-index: 5;
}

.v-div.layer-10 {
  z-index: 10;
}
.v-div.layer-20 {
  z-index: 20;
}
.v-div.layer-30 {
  z-index: 30;
}

.v-div.layer-100 {
  z-index: 100;
}
.v-div.layer-200 {
  z-index: 200;
}
.v-div.layer-300 {
  z-index: 300;
}

.v-div.layer-999 {
  z-index: 999;
}

.v-div.layer-4 {
  z-index: 4;
}
.v-div.layer-6 {
  z-index: 6;
}
.v-div.layer-8 {
  z-index: 8;
}
.v-div.layer-12 {
  z-index: 12;
}
.v-div.layer-16 {
  z-index: 16;
}
.v-div.layer-24 {
  z-index: 24;
}

.v-div.relative {
  position: relative;
}
.v-div.absolute {
  position: absolute;
}
.v-div.fixed {
  position: fixed;
}
.v-div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.v-div.left-0 {
  left: 0;
}
.v-div.left-8 {
  left: 8px;
}
.v-div.left-16 {
  left: 16px;
}
.v-div.left-24 {
  left: 24px;
}
.v-div.left-32 {
  left: 24px;
}
.v-div.left-64 {
  left: 24px;
}

.v-div.right-0 {
  right: 0;
}
.v-div.right-8 {
  right: 8px;
}
.v-div.right-16 {
  right: 16px;
}
.v-div.right-24 {
  right: 24px;
}
.v-div.right-32 {
  right: 24px;
}
.v-div.right-64 {
  right: 24px;
}

.v-div.top-0 {
  top: 0;
}
.v-div.top-8 {
  top: 8px;
}
.v-div.top-16 {
  top: 16px;
}
.v-div.top-24 {
  top: 24px;
}
.v-div.top-32 {
  top: 32px;
}
.v-div.top-64 {
  top: 24px;
}

.v-div.bottom-0 {
  bottom: 0;
}
.v-div.bottom-8 {
  bottom: 8px;
}
.v-div.bottom-16 {
  bottom: 16px;
}
.v-div.bottom-24 {
  bottom: 24px;
}
.v-div.bottom-32 {
  bottom: 24px;
}
.v-div.bottom-64 {
  bottom: 24px;
}

.v-div.vertical-center-outer {
  display: table;
}
.v-div.vertical-center-inner {
  display: table-cell;
  vertical-align: middle;
}

.v-div.align-top {
  vertical-align: top;
}
.v-div.align-bottom {
  vertical-align: bottom;
}
</style>
