import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import discussionReport from "~/store/graphql/mutations/discussionReport.graphql";

const reportDiscussion = async function (_, { discussionId, reasonId }) {
  const data = await runGraphQLQuery(
    discussionReport,
    { discussionId, reasonId },
    this.$axios
  );

  return Promise.resolve(data);
};

export default reportDiscussion;
