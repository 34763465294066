<template>
  <div id="LoginListener">
    <v-dialog
      v-model="show"
      aria-modal="true"
      attach="#LoginListener"
      aria-label="login"
      class="LoginListener"
      max-width="400"
      @keydown.esc="onClose"
    >
      <v-card>
        <v-card-text class="px-6 pb-0 pt-4"
          ><LoginButtons is-modal-display :redirect-url="redirectUrl"
        /></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="onClose"
            color="warning"
            text
            aria-label="close"
            @click="onClose"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LoginButtons from "~/components/page/components/buttons/LoginButtons";
import { login, orcidLogin } from "~/store/manage/pages/userDefaults/login";
import mapFlags from "~/plugins/utilities/mapFlags";

export default {
  name: "LoginListener",
  components: { LoginButtons },
  data() {
    return {
      show: false,
      redirectUrl: null,
      returnFocusTarget: undefined,
    };
  },
  computed: {
    ...mapFlags(["supportCoreLogin", "supportOrcidLogin"]),
  },
  mounted() {
    this.$root.$on("show-login-options", this.onLoginOptions);
    this.$root.$on("show-login-options-redirect", this.onLoginRedirect);
  },
  methods: {
    onLoginRedirect(redirectUrl, currentTarget) {
      this.redirectUrl = redirectUrl;
      this.returnFocusTarget = currentTarget;

      if (this.supportCoreLogin && this.supportOrcidLogin) {
        this.show = true;
      } else if (this.supportCoreLogin) {
        this.$root.$emit("show-core-login-information", {
          login: (newPath) => login(this.$config, this.res, newPath),
          newPath: this.redirectUrl,
          currentTarget,
        });
      } else if (this.supportOrcidLogin) {
        orcidLogin(this.$config, this.res, redirectUrl);
      }
    },
    onLoginOptions(currentTarget) {
      this.redirectUrl = null;
      this.returnFocusTarget = currentTarget;

      if (this.supportCoreLogin && this.supportOrcidLogin) {
        this.show = true;
      } else if (this.supportCoreLogin) {
        this.$root.$emit("show-core-login-information", {
          login: (newPath) => login(this.$config, this.res, newPath),
          newPath: this.redirectUrl,
          currentTarget,
        });
      } else if (this.supportOrcidLogin) {
        orcidLogin(this.$config, this.res, this.redirectUrl);
      }
    },
    onClose() {
      this.show = false;
      this.returnFocus();
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
  },
};
</script>
