export function getRegexContentChecks(maxAbstractWordCount = 150) {
  return [
    {
      severity: "ERROR",
      type: "CONTENT",
      fieldName: "abstract",
      countRegex: /\s*\S+/gim,
      minCount: 1,
      maxCount: maxAbstractWordCount,
      errorMessage: "Abstract is too long",
      resolutionMessage: `Please enter an abstract ensuring it does not exceed ${maxAbstractWordCount} words`,
    },
    {
      severity: "ERROR",
      type: "INTERESTS",
      fieldName: "competingInterestsDeclaration",
      fieldData: ["hasCompetingInterests", "competingInterestsDeclaration"],
      negativeRegex: /true\|\|/gim,
      errorMessage: "Competing interests description missing",
      resolutionMessage: "Please complete the competing interests declaration",
    },
  ];
}
