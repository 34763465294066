import { itemUpdate } from "~/store/graphql/mutations/itemUpdate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import createItemForMutation from "~/store/manage/utilities/createItemForMutation";

const removeSubmissionFunder = async function (
  { commit, state, dispatch },
  { params }
) {
  const submission = createItemForMutation(state.submissionItem);
  const funders = submission.funders || [];
  const removeIndex = funders.findIndex((funder) => funder.gid === params.id);

  if (removeIndex > -1) {
    submission.funders.splice(removeIndex, 1);
  }

  const data = await runGraphQLQuery(
    itemUpdate,
    {
      id: submission.id.toString(),
      item: submission,
    },
    this.$axios
  );

  if (data) {
    const featureToggles = {
      multipleCategoriesSubmission: this.$unleash.isEnabled(
        "multipleCategoriesSubmission"
      ),
    };

    commit("UPDATE_ITEM", { payload: data, featureToggles });
    dispatch("validateSubmission");
  }

  return Promise.resolve(data);
};

export default removeSubmissionFunder;
