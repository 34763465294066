<template>
  <div id="AssignToCuratorListener">
    <v-dialog
      ref="assignToCuratorDialogRef"
      v-model="show"
      attach="#AssignToCuratorListener"
      class="AssignToCuratorListener"
      max-width="500"
      @keydown.esc="onClose"
    >
      <v-card>
        <v-card-title>
          <h2
            id="assign-to-curator-dialog-title"
            class="headline"
            style="word-break: keep-all"
          >
            <ArticleTitle :article-html-title="title" />
          </h2>
        </v-card-title>
        <v-card-text class="assign-to-curator-options">
          <v-radio-group v-model="model" :label="title" column>
            <h2 class="headline" style="word-break: keep-all">
              Society Admins
            </h2>
            <v-radio
              v-for="(curator, index) in societyAdmins"
              ref="socAdminRadio"
              :key="index"
              :class="displayIsMobile ? 'button--mobile' : 'button--desktop'"
              :value="curator"
            >
              <template #label
                >{{ curator | displayName }} ({{ curator.email }})</template
              >
            </v-radio>
            <div v-if="curationCurators.length">
              <h2 class="headline" style="word-break: keep-all">Curators</h2>
              <v-radio
                v-for="(curator, index) in curationCurators"
                :key="index"
                :class="displayIsMobile ? 'button--mobile' : 'button--desktop'"
                :value="curator"
              >
                <template #label
                  >{{ curator | displayName }} ({{ curator.email }})</template
                >
              </v-radio>
            </div>
            <div v-if="curationAdmins.length">
              <h2 class="headline" style="word-break: keep-all">
                Curation Admins
              </h2>
              <v-radio
                v-for="(curator, index) in curationAdmins"
                :key="index"
                :class="displayIsMobile ? 'button--mobile' : 'button--desktop'"
                :value="curator"
              >
                <template #label
                  >{{ curator | displayName }} ({{ curator.email }})</template
                >
              </v-radio>
            </div>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="closeConfirmationListener"
            color="warning"
            text
            aria-label="close"
            @click="onClose"
            >Close</v-btn
          >
          <v-btn
            class="okConfirmationListener"
            :disabled="isDisabled"
            color="success"
            text
            aria-label="confirm assigning content to curator"
            @click="onConfirm"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import sorted from "~/plugins/utilities/sorted";

import { displayIsMobile } from "~/components/utilities/displayBreakpoints";
import assignToCurator from "~/actions/tasks/assignToCurator";

import ArticleTitle from "~/components/articles/components/ArticleTitle.vue";

export default {
  name: "AssignToCuratorListener",
  components: {
    ArticleTitle,
  },
  data() {
    return {
      showSaved: false,
      model: undefined,
      show: false,
      itemId: undefined,
      title: undefined,
      text: undefined,
      confirmationMessage: undefined,
      returnFocusTarget: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      curators: (state) => state.curators,
    }),
    displayIsMobile,
    isDisabled() {
      return this.model === undefined;
    },
    societyAdmins() {
      return this.curatorsByRole("SOCIETY_ADMIN");
    },
    curationAdmins() {
      return this.curatorsByRole("CURATION_ADMIN");
    },
    curationCurators() {
      return this.curatorsByRole("CURATION_CURATOR");
    },
  },
  mounted() {
    this.$root.$on("assign-to-curator", this.onShowInformation);
  },
  methods: {
    assignToCurator,
    async onShowInformation({ item, currentTarget }) {
      await this.$store.dispatch("loadCurators");
      this.confirmationMessage = "Submission was assigned to selected curator.";
      this.title = item.title;
      this.itemId = item.id;
      this.returnFocusTarget = currentTarget;
      this.show = true;
      this.setInitialFocus();
      this.setAriaLabelAttribute();
    },
    setInitialFocus() {
      this.$nextTick(() => {
        this.$refs.socAdminRadio[0]?.$el.children[0].children[1].focus();
      });
    },
    setAriaLabelAttribute() {
      this.$nextTick(() => {
        const dialogRef =
          this?.$refs?.assignToCuratorDialogRef?.$children[0]?.$el;

        if (dialogRef) {
          const titleByElementId = document.getElementById(
            "assign-to-curator-dialog-title"
          ).textContent;
          dialogRef.setAttribute("aria-label", titleByElementId);
        }
      });
    },
    onConfirm() {
      this.show = false;
      this.assignToCurator({ itemId: this.itemId, email: this.model.email });
      this.$root.$emit("show-accessible-snackbar", {
        confirmationMessage: this.confirmationMessage,
      });
      this.showSaved = true;
      this.model = null;
    },
    onClose() {
      this.show = false;
      this.returnFocus();
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
    curatorsByRole(role) {
      return sorted(
        this.curators.filter((curator) => curator.role === role),
        "firstName"
      );
    },
  },
};
</script>

<style>
.assign-to-curator-options .v-input legend {
  display: none;
}
</style>
