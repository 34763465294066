import administrationDashboardMoreRevisionItems from "~/store/graphql/queries/administrationDashboardMoreRevisionItems.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadAdminstrationMoreRevisions = async function (
  { commit },
  { params, req, res }
) {
  const data = await runGraphQLQuery(
    administrationDashboardMoreRevisionItems,
    params,
    this.$axios,
    req,
    res
  );

  const featureToggles = {
    administrationDashboardSiteModerators: this.$unleash.isEnabled(
      "administrationDashboardSiteModerators"
    ),
    showModerationNotes: this.$unleash.isEnabled("showModerationNotes"),
    administrationDashboardAssignCurators: this.$unleash.isEnabled(
      "administrationDashboardAssignCurators"
    ),
  };

  commit("SET_ADMINISTRATION_MORE_REVISIONS", {
    payload: data,
    featureToggles,
  });

  return Promise.resolve(data);
};

export default loadAdminstrationMoreRevisions;
