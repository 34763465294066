import processItems from "~/store/manage/pages/adminstrationDashboard/utilities/processItems";

const SET_ADMINISTRATION_MORE_PUBLISHED = (
  state,
  { payload, featureToggles }
) => {
  const results = payload.origin.publishedItems.results;

  processItems(results, featureToggles);

  state.currentAdministrator.publishedItemsCount =
    payload.origin.publishedItems && payload.origin.publishedItems.totalCount
      ? payload.origin.publishedItems.totalCount
      : 99999;

  if (results.length > 0) {
    const publishedItems = state.currentAdministrator.publishedItems;

    state.currentAdministrator.publishedItems = [...publishedItems, ...results];
  }
};

export default SET_ADMINISTRATION_MORE_PUBLISHED;
