<template>
  <v-row
    class="LayoutGutters mx-0"
    :class="noPadding ? 'mb-0' : 'mb-5'"
    :no-gutters="noGutters"
    :align="align"
    :justify="align"
  >
    <v-col
      :class="noPadding ? 'pb-0' : undefined"
      cols="12"
      style="max-width: 1300px"
    >
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LayoutGutters",
  props: {
    noGutters: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    align: { type: String, default: "center" },
  },
};
</script>
