import { mainItemAssetDelete } from "~/store/graphql/mutations/itemAssetDelete.graphql";
import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const deleteFile = async function ({ commit, dispatch }, file) {
  const { id } = file;

  commit("DELETE_FILE", id);
  dispatch("validateSubmission");

  try {
    await runGraphQLQuery(
      mainItemAssetDelete,
      {
        id,
      },
      this.$axios
    );
    return Promise.resolve(id);
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }
};

export default deleteFile;
