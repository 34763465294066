export function unique(array) {
  return [...new Set(array)];
}

export function uniqueStrings(array) {
  return unique(array.map((string) => string.toLowerCase().trim()));
}

export function uniqueBy(array, key) {
  const seen = new Set();
  return array.filter((object) => {
    const property = key(object);
    if (property && !seen.has(property)) {
      return seen.add(property);
    }
    return false;
  });
}
