import { eventPublish } from "~/store/graphql/mutations/eventPublish.graphql";
import { eventUnpublish } from "~/store/graphql/mutations/eventUnpublish.graphql";
import { eventOpenSubmission } from "~/store/graphql/mutations/eventOpenSubmission.graphql";
import { eventCloseSubmission } from "~/store/graphql/mutations/eventCloseSubmission.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const updateEventStatus = async function ({ commit }, { params }) {
  const queries = {
    PUBLISH: eventPublish,
    UNPUBLISH: eventUnpublish,
    OPEN: eventOpenSubmission,
    CLOSE: eventCloseSubmission,
  };

  const query = queries[params.action];

  let data;

  if (query) {
    data = await runGraphQLQuery(
      query,
      {
        id: params.id,
      },
      this.$axios
    );
  }

  if (data) {
    commit("UPDATE_EVENT", data);
  }

  return Promise.resolve(data);
};

export default updateEventStatus;
