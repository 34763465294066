import processItems from "~/store/manage/pages/adminstrationDashboard/utilities/processItems";

const SET_ADMINISTRATION_MORE_DELETED = (
  state,
  { payload, featureToggles }
) => {
  const results = payload.origin.deletedItems.results;

  processItems(results, featureToggles);

  state.currentAdministrator.deletedItemsCount =
    payload.origin.deletedItems && payload.origin.deletedItems.totalCount
      ? payload.origin.deletedItems.totalCount
      : 99999;

  if (results.length > 0) {
    const deletedItems = state.currentAdministrator.deletedItems;

    state.currentAdministrator.deletedItems = [...deletedItems, ...results];
  }
};

export default SET_ADMINISTRATION_MORE_DELETED;
