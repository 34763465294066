import Vue from "vue";

const DELETE_MATERIAL = (state, payload) => {
  const { submissionItem } = state;
  const { supplementaryAssets } = submissionItem || {};

  if (supplementaryAssets) {
    const removeindex = supplementaryAssets.findIndex(
      (item) => item.id === payload
    );

    if (removeindex > -1) {
      Vue.delete(supplementaryAssets, removeindex);
    }
  }
};

export default DELETE_MATERIAL;
