<template>
  <span>
    <span class="d-sr-only">[opens in a new tab]</span>
    <v-icon small :color="color" aria-hidden="true">{{ mdiOpenInNew }}</v-icon>
  </span>
</template>
<script>
import { mdiOpenInNew } from "@mdi/js";
export default {
  name: "OpenInNewTabIcon",
  props: {
    size: { type: Number, default: 16 },
    color: { type: String, default: "primary" },
  },
  data() {
    return {
      mdiOpenInNew,
    };
  },
};
</script>
