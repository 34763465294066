import processItems from "~/store/manage/pages/adminstrationDashboard/utilities/processItems";

const SET_ADMINISTRATION_MORE_RETRACTED = (
  state,
  { payload, featureToggles }
) => {
  const results = payload.origin.retractedItems.results;

  processItems(results, featureToggles);

  state.currentAdministrator.retractedItemsCount =
    payload.origin.retractedItems && payload.origin.retractedItems.totalCount
      ? payload.origin.retractedItems.totalCount
      : 99999;

  if (results.length > 0) {
    const retractedItems = state.currentAdministrator.retractedItems;

    state.currentAdministrator.retractedItems = [...retractedItems, ...results];
  }
};

export default SET_ADMINISTRATION_MORE_RETRACTED;
