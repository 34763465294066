import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import { eventAssetDelete } from "~/store/graphql/mutations/eventAssetDelete.graphql";

const deleteEventBannerAsset = async function ({ commit }, event) {
  const { id } = event;
  commit("CLEAR_EVENT_ASSET");
  await runGraphQLQuery(eventAssetDelete, { id }, this.$axios);
};

export default deleteEventBannerAsset;
