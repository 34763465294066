import { set } from "vue";

import { isObject } from "~/store/manage/utilities/createItemForMutation";
import generateGId from "~/store/manage/utilities/generateGId";

const setInstitutions = (value, field, submissionAuthor) => {
  if (!isObject(value)) {
    return;
  }

  if (value.name?.trim().length === 0) {
    return;
  }

  const { institutions } = submissionAuthor;
  const itemIndex = submissionAuthor.institutions.findIndex(
    (item) => item.name === value.name
  );
  itemIndex < 0
    ? set(submissionAuthor, field, [...institutions, value])
    : set(submissionAuthor, field, [
        ...institutions.slice(0, itemIndex),
        ...institutions.slice(itemIndex + 1),
      ]);
};
const setUnaffiliated = (value, field, submissionAuthor) =>
  set(submissionAuthor, field, value);
const setDefault = (value, field, submissionAuthor) =>
  (submissionAuthor[field] = value);

const UPDATE_SUBMISSION_AUTHOR = (state, payload) => {
  const submissionItem = state.submissionItem;
  const submissionAuthor = submissionItem.authors.find(
    (author) => author.gid === payload.id
  );
  const { field, value } = payload;

  const switchSubmissionAuthorField = {
    institutions: () => setInstitutions(value, field, submissionAuthor),
    unaffiliated: () => setUnaffiliated(value, field, submissionAuthor),
    default: () => setDefault(value, field, submissionAuthor),
  };

  if (submissionAuthor) {
    const switchFn =
      switchSubmissionAuthorField[field] || switchSubmissionAuthorField.default;
    switchFn();
  }

  if (submissionItem.authors[0].authorConfirmationId === "SOLE_AUTHOR") {
    submissionItem.authors = [submissionItem.authors[0]];
  } else if (
    submissionItem.authors[0].authorConfirmationId === "CO_AUTHOR" &&
    submissionItem.authors.length === 1
  ) {
    const newAuthor = {
      gid: generateGId(),
      title: undefined,
      firstName: undefined,
      lastName: undefined,
      emailAddress: undefined,
      institutions: [],
      displayOrder: submissionItem.authors.length,
    };

    set(submissionItem.authors, submissionItem.authors.length, newAuthor);
  }
};

export default UPDATE_SUBMISSION_AUTHOR;
