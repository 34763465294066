<template>
  <GenericSearchInput
    ref="searchInput"
    :inverted="inverted"
    :thick="thick"
    :hide-details="hideDetails"
    :label="displayLabel"
    :show-placeholder="showPlaceholder"
    :full-width="fullWidth"
    :single-line="singleLine"
    :outlined="outlined"
    :solo="solo"
    :data-test-search-bar-id="dataTestSearchBarId"
    :data-test-search-icon-id="dataTestSearchIconId"
    @search="onSearch"
  />
</template>

<script>
import formatSearchQueryForText from "~/components/utilities/formatSearchQueryForText";
import GenericSearchInput from "~/components/search/GenericSearchInput";

export default {
  name: "SearchInput",
  components: {
    GenericSearchInput,
  },
  props: {
    inverted: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    thick: { type: Boolean, default: false },
    label: { type: String, default: "Enter search term" },
    showPlaceholder: { type: Boolean, default: false },
    category: { type: Object, default: undefined },
    type: { type: String, default: "category" },
    partner: { type: String, required: true },
    fullWidth: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    dataTestSearchBarId: {
      type: [String, null],
      default: null,
    },
    dataTestSearchIconId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return { value: null };
  },
  computed: {
    displayLabel() {
      return this.category ? `Search within ${this.category.name}` : this.label;
    },
    classes() {
      return { "input-white": this.inverted, thick: this.thick };
    },
  },
  methods: {
    onSearch(value) {
      const searchQuery = formatSearchQueryForText(value);

      let path = `/engage/${this.partner}/search-dashboard?${searchQuery}`;

      if (this.category) {
        const plural = this.type === "subject" ? "subjects" : "categories";
        path =
          path +
          `&${this.type}=${this.category.id}&${plural}=${this.category.id}`;
      }

      this.$refs.searchInput.clear();

      if (this.partner === this.$config.partner) {
        this.$router.push({
          path,
        });
      } else {
        window.document.location.href = path;
      }
    },
  },
};
</script>

<style>
.SearchInput .input-white input::placeholder {
  color: white !important;
  opacity: 0.5;
}

.SearchInput .input-white input::-ms-input-placeholder {
  color: white !important;
  opacity: 0.5;
}

.SearchInput .v-text-field.input-white * {
  color: white !important;
  border-bottom-color: white !important;
}

.SearchInput .v-text-field.input-white .v-input__slot:before {
  border-color: white !important;
}

.SearchInput .v-text-field.input-white .v-input__slot:after {
  border-color: white !important;
}

.SearchInput .v-text-field.thick .v-input__slot:before {
  border-top-width: medium;
}

.SearchInput .v-text-field.thick .v-input__slot .v-label {
  font-weight: bold;
}
</style>
