import { videoProcessingTrigger } from "~/store/graphql/mutations/videoProcessingTrigger.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const retriggerVimeo = async function ({ commit, dispatch }, { params }) {
  const { id } = params;

  const data = await runGraphQLQuery(
    videoProcessingTrigger,
    {
      id,
    },
    this.$axios
  );

  if (data) {
    commit("UPDATE_SUBMISSION_ASSET", data);
    dispatch("validateSubmission");
  }

  return Promise.resolve(data);
};

export default retriggerVimeo;
