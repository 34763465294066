<template>
  <v-row class="quick-actions-institution" fill-height>
    <QuickActionButton
      link="departments-dashboard"
      button-text="Departments"
      :bold-link="false"
    />
    <QuickActionButton
      link="events-dashboard"
      button-text="Events"
      :bold-link="false"
    />
    <QuickActionButton
      link="submission-information"
      button-text="How to Submit"
      :bold-link="false"
    />
    <QuickActionButton
      link="about-information"
      button-text="About Us"
      :bold-link="false"
    />
  </v-row>
</template>

<script>
import QuickActionButton from "~/components/page/components/buttons/QuickActionButton";

export default {
  name: "QuickActionsInstitution",
  components: {
    QuickActionButton,
  },
};
</script>
