<template>
  <div
    class="text-right inverted--text mt-2 mr-2 font-very-small slightly-transparent"
  >
    {{ usageEventsDisabled === true ? "Events Disabled " : "" }}
    {{ version }}
  </div>
</template>

<script>
export default {
  name: "VersionActions",
  props: {
    version: { type: String, required: true },
    usageEventsDisabled: { type: Boolean, default: undefined },
  },
};
</script>
