import md5 from "md5";

import assetFragment from "~/store/graphql/fragments/assetFragment.graphql";
import assetResourceFragment from "~/store/graphql/fragments/assetResourceFragment.gql";
import authorFragment from "~/store/graphql/fragments/authorFragment.gql";
import authorSummaryFragment from "~/store/graphql/fragments/authorSummaryFragment.gql";
import categoryFragment from "~/store/graphql/fragments/categoryFragment.graphql";
import categoryTypeFragment from "~/store/graphql/fragments/categoryTypeFragment.gql";
import commentFragment from "~/store/graphql/fragments/commentFragment.graphql";
import eventSubmissionFragment from "~/store/graphql/fragments/eventSubmissionFragment.gql";
import commentReportFragment from "~/store/graphql/fragments/commentReportFragment.gql";
import commentReportReasonsFragment from "~/store/graphql/fragments/commentReportReasonsFragment.gql";
import communityDetailedFragment from "~/store/graphql/fragments/communityDetailedFragment.gql";
import confirmationTypeFragment from "~/store/graphql/fragments/confirmationTypeFragment.gql";
import contentTypeFragment from "~/store/graphql/fragments/contentTypeFragment.gql";
import curationFragment from "~/store/graphql/fragments/curationFragment.graphql";
import discussionReportFragment from "~/store/graphql/fragments/discussionReportFragment.gql";
import discussionReportReasonsFragment from "~/store/graphql/fragments/discussionReportReasonsFragment.gql";
import discussionReplyReportFragment from "~/store/graphql/fragments/discussionReplyReportFragment.gql";
import eventFragment from "~/store/graphql/fragments/eventFragment.graphql";
import eventGroupFragment from "~/store/graphql/fragments/eventGroupFragment.graphql";
import eventSponsorFragment from "~/store/graphql/fragments/eventSponsorFragment.graphql";
import eventSummaryFragment from "~/store/graphql/fragments/eventSummaryFragment.gql";
import reportedCommentSummaryFragment from "~/store/graphql/fragments/reportedCommentSummaryFragment.gql";
import funderFragment from "~/store/graphql/fragments/funderFragment.gql";
import itemArchiveFragment from "~/store/graphql/fragments/itemArchiveFragment.gql";
import itemDetailedFragment from "~/store/graphql/fragments/itemDetailedFragment.graphql";
import itemEventFragment from "~/store/graphql/fragments/itemEventFragment.graphql";
import itemQuestionFragment from "~/store/graphql/fragments/itemQuestionFragment.graphql";
import itemCommunityFragment from "~/store/graphql/fragments/itemCommunityFragment.graphql";
import itemMatchFragment from "~/store/graphql/fragments/itemMatchFragment.gql";
import itemPublishedFragment from "~/store/graphql/fragments/itemPublishedFragment.graphql";
import itemRejectionReasonsFragment from "~/store/graphql/fragments/itemRejectionReasonsFragment.graphql";
import itemRelatedContentFragment from "~/store/graphql/fragments/itemRelatedContentFragment.gql";
import itemRetractedFragment from "~/store/graphql/fragments/itemRetractedFragment.graphql";
import itemOverviewFragment from "~/store/graphql/fragments/itemOverviewFragment.gql";
import itemSummaryFragment from "~/store/graphql/fragments/itemSummaryFragment.graphql";
import itemUnpublishedFragment from "~/store/graphql/fragments/itemUnpublishedFragment.graphql";
import itemProcessedSubmissionFragment from "~/store/graphql/fragments/itemProcessedSubmissionFragment.graphql";
import journalFragment from "~/store/graphql/fragments/journalFragment.gql";
import licenseTypeFragment from "~/store/graphql/fragments/licenseTypeFragment.gql";
import metricFragment from "~/store/graphql/fragments/metricFragment.gql";
import moderationNoteFragment from "~/store/graphql/fragments/moderationNoteFragment.gql";
import originFragment from "~/store/graphql/fragments/originFragment.gql";
import questionFragment from "~/store/graphql/fragments/questionFragment.graphql";
import revisionNoteFragment from "~/store/graphql/fragments/revisionNoteFragment.gql";
import retractionFragment from "~/store/graphql/fragments/retractionFragment.gql";
import searchBucketFragment from "~/store/graphql/fragments/searchBucketFragment.gql";
import supplementaryFragment from "~/store/graphql/fragments/supplementaryFragment.graphql";
import subjectTypeFragment from "~/store/graphql/fragments/subjectTypeFragment.gql";
import userItemsFragment from "~/store/graphql/fragments/userItemsFragment.graphql";
import userRoleFragment from "~/store/graphql/fragments/userRoleFragment.gql";
import weblinkFragment from "~/store/graphql/fragments/weblinkFragment.gql";
import versionHistoryFragment from "~/store/graphql/fragments/versionHistoryFragment.gql";
import vorMetadataFragment from "~/store/graphql/fragments/vorMetadataFragment.gql";
import communityFragment from "~/store/graphql/fragments/communityFragment.gql";
import discussionFragment from "~/store/graphql/fragments/discussionFragment.gql";
import discussionReplyFragment from "~/store/graphql/fragments/discussionReplyFragment.graphql";
import citationFragment from "~/store/graphql/fragments/citationFragment.gql";
import citationAuthorFragment from "~/store/graphql/fragments/citationAuthorFragment.gql";
import reportedDiscussionSummaryFragment from "~/store/graphql/fragments/reportedDiscussionSummaryFragment.graphql";
import reportedDiscussionReplyFragment from "~/store/graphql/fragments/reportedDiscussionReplyFragment.graphql";
import reportedDiscussionReplySummaryFragment from "~/store/graphql/fragments/reportedDiscussionReplySummaryFragment.graphql";
import collectionFragment from "~/store/graphql/fragments/collectionFragment.graphql";

/* eslint-disable no-console */

const LOG_GRAPHQL = false;
const LOG_TIMINGS = false;
const LOG_CACHE = false;

const FRAGMENTS = [
  assetFragment,
  assetResourceFragment,
  authorFragment,
  authorSummaryFragment,
  categoryFragment,
  categoryTypeFragment,
  commentFragment,
  eventSubmissionFragment,
  commentReportFragment,
  commentReportReasonsFragment,
  communityDetailedFragment,
  confirmationTypeFragment,
  contentTypeFragment,
  curationFragment,
  discussionReportFragment,
  discussionReportReasonsFragment,
  discussionReplyReportFragment,
  eventFragment,
  eventGroupFragment,
  eventSponsorFragment,
  eventSummaryFragment,
  reportedCommentSummaryFragment,
  funderFragment,
  itemArchiveFragment,
  itemDetailedFragment,
  itemEventFragment,
  itemQuestionFragment,
  itemCommunityFragment,
  itemMatchFragment,
  itemOverviewFragment,
  itemPublishedFragment,
  itemRejectionReasonsFragment,
  itemRelatedContentFragment,
  itemRetractedFragment,
  itemSummaryFragment,
  itemUnpublishedFragment,
  itemProcessedSubmissionFragment,
  journalFragment,
  licenseTypeFragment,
  metricFragment,
  moderationNoteFragment,
  originFragment,
  questionFragment,
  retractionFragment,
  revisionNoteFragment,
  searchBucketFragment,
  subjectTypeFragment,
  supplementaryFragment,
  userItemsFragment,
  userRoleFragment,
  versionHistoryFragment,
  weblinkFragment,
  vorMetadataFragment,
  communityFragment,
  discussionFragment,
  discussionReplyFragment,
  citationFragment,
  citationAuthorFragment,
  reportedDiscussionSummaryFragment,
  reportedDiscussionReplyFragment,
  reportedDiscussionReplySummaryFragment,
  collectionFragment,
];

function addMissingFragments(query, fragments = FRAGMENTS) {
  if (!query.includes("...")) {
    return query;
  }

  const MAX_NESTING_DEPTH = 20;

  let result = query;
  let cycleCounter = 0;

  while (true) {
    const matches = result.match(/\.\.\.[a-zA-Z0-9]*/g);
    let allFound = true;

    matches.forEach((match) => {
      const find = match.substr(3);
      const check = "fragment " + find;

      if (!result.includes(check)) {
        allFound = false;

        fragments.forEach((fragment) => {
          fragment.definitions.forEach((definition) => {
            if (definition.name.value === find) {
              result = result + "\n" + fragment.loc.source.body;
            }
          });
        });
      }
    });

    cycleCounter++;

    if (allFound === true) {
      break;
    } else if (cycleCounter > MAX_NESTING_DEPTH) {
      throw new Error(
        "runGraphQLQuery - max nesting depth exceeted - check all fragments included in list"
      );
    }
  }

  return result;
}

function logGraphQL(query, variables, force) {
  if (LOG_GRAPHQL || force === true) {
    console.info(
      "======================================================================================"
    );
    console.info("GraphQL Query");
    console.info(
      "======================================================================================"
    );
    console.info(query);
    console.info(
      "--------------------------------------------------------------------------------------"
    );
    console.info(JSON.stringify(variables, undefined, 2));
  }
}

function logData(data, force) {
  if (LOG_GRAPHQL || force === true) {
    console.info(
      "======================================================================================"
    );
    console.info("GraphQL Response");
    console.info(
      "======================================================================================"
    );
    console.info(JSON.stringify(data, undefined, 2));
  }
}

function logStore(name, signature, data, force) {
  if (LOG_CACHE || force === true) {
    console.info(
      ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
    );
    console.info(name, signature);
    console.info(
      "---------------------------------------------------------------------------------------"
    );
    console.info(data.substring(0, 86));
    console.info(
      ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
    );
  }
}

function logRetrieve(name, signature, data, force) {
  if (LOG_CACHE || force === true) {
    console.info(
      "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
    );
    console.info(name, signature);
    console.info(
      "---------------------------------------------------------------------------------------"
    );
    console.info(data.substring(0, 86));
    console.info(
      "<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<"
    );
  }
}

function logErrors(errors) {
  console.error(
    "========================================================================================"
  );
  console.error("GraphQL Error");
  console.error(
    "========================================================================================"
  );
  console.error(JSON.stringify(errors, undefined, 2));
}

function queryName(query) {
  const signature = JSON.stringify(query);

  let name = signature
    .substring(0, signature.indexOf("{") - 1)
    .replace("query ", "");

  if (name.includes("(")) {
    name = name.substring(0, name.indexOf("("));
  }

  name = name.replace('"', "").trim();

  return name;
}

function querySignature(query, variables) {
  return md5(JSON.stringify({ query, variables }));
}

const USE_CACHE = false;
const FIVE_MINUTES = 1000 * 60 * 5;
const GRAPHQL_CACHE = [];

let lastGraphQL;

export function lastQuery() {
  const { query, variables, data } = lastGraphQL;

  const newQuery = query.replace(/\\"/gm, '"').replace(/(\r\n|\n|\r)/gm, "");

  return { query: newQuery, variables, data };
}

export default async function runGraphQLQuery(
  task,
  variables,
  $axios,
  req,
  res
) {
  const startTime = LOG_TIMINGS === true ? new Date() : undefined;
  const headers = {};

  if (req && req.headers && req.headers.cookie) {
    headers.Cookie = req.headers.cookie;
  }

  const query = addMissingFragments(task.loc.source.body);
  const name = queryName(query);
  const signature = querySignature(JSON.stringify({ name, variables }));
  const isQuery = query.includes("query");
  const now = new Date();
  const useCache = USE_CACHE && isQuery === true && req === undefined;

  let result;

  if (useCache) {
    result = GRAPHQL_CACHE.find(
      (item) => item.signature === signature && now - item.date < FIVE_MINUTES
    );

    if (result) {
      logRetrieve(name, signature, result.data);

      return Promise.resolve(JSON.parse(result.data));
    }
  }

  try {
    result = await $axios({
      url: "/graphql",
      method: "post",
      headers,
      data: {
        query,
        variables,
      },
    });
  } catch (error) {
    logGraphQL(query, variables, true);
    logErrors({
      name: error.name,
      error: error.message,
      date: now,
    });

    return Promise.reject(new Error(error.message));
  }

  const data =
    result && result.data && result.data.data && result.data.data.viewer
      ? result.data.data.viewer
      : result.data.data;
  const errors = result.data ? result.data.errors : undefined;

  if (useCache && data && !errors) {
    const storeDataAsString = JSON.stringify(data);

    logStore(name, signature, storeDataAsString);

    GRAPHQL_CACHE.push({
      name,
      date: now,
      signature,
      data: storeDataAsString,
    });
  }

  if (errors) {
    logGraphQL(query, variables, true);
    logData(data, true);
    logErrors(errors);
    const errorMessage = errors.map((e) => e.message);
    return Promise.reject(new Error(errorMessage.toString()));
  } else {
    logGraphQL(query, variables, variables);
    logData(data);
  }

  const newCookie = result.headers["set-cookie"];

  if (newCookie) {
    if (res) {
      res.setHeader("Set-Cookie", newCookie);
    } else if (typeof document === "object") {
      document.cookie = newCookie;
    }
  }

  if (startTime) {
    const endTime = new Date();

    let parametersFrom = query.indexOf("(");
    const resultsFrom = query.indexOf("{") - 1;

    if (parametersFrom === -1) {
      parametersFrom = 99999999;
    }

    const tag = query.substr(0, Math.min(parametersFrom, resultsFrom));
    const time = endTime.getTime() - startTime.getTime() + "ms";

    console.info(tag, time);
  }

  lastGraphQL = { query, variables, data };

  return Promise.resolve(data);
}
