<template>
  <div class="platform-actions pt-1 pr-3">
    <SearchInput
      v-if="showSearch && displayIsLargeDesktop"
      partner="coe"
      :label="`Search Cambridge Open Engage`"
      inverted
    />
    <SearchButton
      v-if="(showSearch && displayIsTablet) || displayIsSmallDesktop"
      partner="coe"
      inverted
    />
    <LoginMenu
      v-if="showMenu && isLoggedOut(user) && displayIsDesktop"
      style="vertical-align: top"
      inverted
    />
    <AnonymousMenu
      v-if="showMenu && isLoggedOut(user) && !displayIsDesktop"
      inverted
    />
    <UserMenu v-if="showMenu && !isLoggedOut(user)" inverted partner="coe" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  displayIsTablet,
  displayIsDesktop,
} from "~/components/utilities/displayBreakpoints";

import SearchInput from "~/components/search/SearchInput.vue";
import SearchButton from "~/components/page/components/buttons/SearchButton.vue";
import LoginMenu from "~/components/page/components/menus/LoginMenu.vue";
import UserMenu from "~/components/page/components/menus/UserMenu.vue";
import AnonymousMenu from "~/components/page/components/menus/AnonymousMenu.vue";

import isLoggedOut from "~/plugins/utilities/isLoggedOut";

export default {
  name: "PlatformActions",
  components: {
    SearchInput,
    SearchButton,
    LoginMenu,
    UserMenu,
    AnonymousMenu,
  },
  props: {
    showMenu: { type: Boolean, default: true },
    showSearch: { type: Boolean, default: true },
    noGutters: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    inverted: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      theme: (state) => state.theme,
      searchPartner: (state) =>
        state.theme ? state.theme.searchPartner : undefined,
    }),
    displayIsTablet,
    displayIsDesktop,
    displayIsSmallDesktop() {
      return this.$vuetify.breakpoint.md;
    },
    displayIsLargeDesktop() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
    partner() {
      return this.$config.partner;
    },
  },
  methods: {
    isLoggedOut(value) {
      return isLoggedOut(value);
    },
  },
};
</script>
