import generateGId from "~/store/manage/utilities/generateGId";

const addSubmissionFunder = function ({ commit }) {
  const newFunder = {
    gid: generateGId(),
  };

  commit("ADD_SUBMISSION_FUNDER", newFunder);

  return Promise.resolve(newFunder);
};

export default addSubmissionFunder;
