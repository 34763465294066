import { itemCancelRetractionResponse } from "~/store/graphql/mutations/itemCancelRetractionResponse.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const submitRejectRetraction = async function ({ commit, state }) {
  const id = state.retractionItem.id;

  const data = await runGraphQLQuery(
    itemCancelRetractionResponse,
    {
      id,
      retractionStatus: "REJECTED",
    },
    this.$axios
  );

  if (data) {
    commit("SET_RETRACTION_UPDATE", data);

    return Promise.resolve(data);
  }
};

export default submitRejectRetraction;
