import eventDetailPageLoad from "~/store/graphql/queries/eventDetailPageLoad.graphql";

import runGraphQLQuery, {
  lastQuery,
} from "~/store/manage/utilities/runGraphQLQuery";

const loadEventDetails = async function (
  { commit },
  { params, req, res, saveLastGraphQL }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    eventDetailPageLoad,
    params,
    this.$axios,
    req,
    res
  );

  if (saveLastGraphQL === true) {
    commit("SET_LAST_GRAPHQL", lastQuery());
  }

  commit("SET_EVENT_DETAILS", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadEventDetails;
