import { itemSaveRetractionResponse } from "~/store/graphql/mutations/itemSaveRetractionResponse.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const updateConfirmRetraction = async function ({ commit, state }) {
  const id = state.retractionItem.id;
  const message = state.retractionItem.retraction.response.message;

  const data = await runGraphQLQuery(
    itemSaveRetractionResponse,
    {
      id,
      message,
    },
    this.$axios
  );

  if (data) {
    commit("SET_RETRACTION_UPDATE", data);

    return Promise.resolve(data);
  }
};

export default updateConfirmRetraction;
