const SET_EVENT_DETAILS = (state, payload) => {
  state.selectedEvent = payload.selectedEvent;
  state.selectedOrigin = payload.origin;
  state.currentOrigin.selectedEventArticles = payload.latestContent.results.map(
    (i) => i.item
  );

  state.user = payload.user;
};

export default SET_EVENT_DETAILS;
