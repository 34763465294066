import itemDoi from "~/store/graphql/queries/itemDoi.graphql";
import itemShortOverview from "~/store/graphql/queries/itemShortOverview.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadItemByDoi = async function ({ commit }, { req, res, params }) {
  commit("START_LOADING");
  try {
    const itemDoiData = await runGraphQLQuery(
      itemDoi,
      params,
      this.$axios,
      req,
      res
    );
    const data = await runGraphQLQuery(
      itemShortOverview,
      { id: itemDoiData.mainItemByDoi.id },
      this.$axios,
      req,
      res
    );
    commit("END_LOADING");
    return data;
  } catch (error) {
    commit("END_LOADING");
    throw error;
  }
};

export default loadItemByDoi;
