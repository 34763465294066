<template>
  <div id="reportListener">
    <v-dialog
      ref="reportDialogRef"
      v-model="show"
      attach="#reportListener"
      max-width="400"
      @keydown.esc="onClose"
    >
      <v-card>
        <v-card-title>
          <h2
            id="report-dialog-title"
            class="headline"
            style="word-break: keep-all"
          >
            {{ title }}
          </h2>
        </v-card-title>
        <v-card-text>
          <blockquote class="blockquote">
            <em>{{ reportContent }}</em>
          </blockquote>
        </v-card-text>
        <v-card-text>
          <v-div>{{ text }}</v-div>
        </v-card-text>
        <v-card-text class="report-options">
          <v-radio-group v-model="model" :label="`${title} reasons`" column>
            <v-radio
              v-for="(option, index) in options"
              ref="reportRadio"
              :key="index"
              :class="
                displayIsMobile === true ? 'button--mobile' : 'button--desktop'
              "
              :label="option.name"
              :value="option"
            />
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="closeReportListener"
            color="warning"
            text
            aria-label="close"
            @click="onClose"
            >Close</v-btn
          >
          <v-btn
            class="okReportListener"
            :disabled="isDisabled"
            color="success"
            text
            :aria-label="'report'"
            @click="onConfirm"
            >Report</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { displayIsMobile } from "~/components/utilities/displayBreakpoints";

export default {
  name: "ReportListener",
  data() {
    return {
      showSaved: false,
      model: undefined,
      show: false,
      title: undefined,
      text: undefined,
      reportContent: undefined,
      reportContentId: undefined,
      options: undefined,
      action: undefined,
      returnFocusTarget: undefined,
      captchaResponse: undefined,
      MAX_REPORT_SNIPPET_LENGTH: 200,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    displayIsMobile,
    isDisabled() {
      return this.model === undefined;
    },
    confirmationMessage() {
      return "Your report has been sent to the administrator of the site for moderation.";
    },
  },
  mounted() {
    this.$root.$on("show-report", this.onShowInformation);
  },
  methods: {
    onShowInformation({
      title,
      reportContent,
      reportContentId,
      action,
      text,
      options,
      currentTarget,
      captchaResponse,
    }) {
      this.title = title;
      this.reportContent = this.getReportContent(reportContent);
      this.reportContentId = reportContentId;
      this.action = action;
      this.text = text;
      this.options = options;
      this.returnFocusTarget = currentTarget;
      this.show = true;
      this.captchaResponse = captchaResponse;
      this.setInitialFocus();
      this.setAriaLabelAttribute();
    },
    getReportContent(reportContent) {
      if (reportContent.length > this.MAX_REPORT_SNIPPET_LENGTH) {
        return `${reportContent.substring(
          0,
          this.MAX_REPORT_SNIPPET_LENGTH
        )}...`;
      }
      return reportContent;
    },
    setInitialFocus() {
      this.$nextTick(() => {
        this.$refs.reportRadio[0]?.$el.children[0].children[1].focus();
      });
    },
    setAriaLabelAttribute() {
      this.$nextTick(() => {
        const dialogRef = this?.$refs?.reportDialogRef?.$children[0]?.$el;

        if (dialogRef) {
          const titleByElementId = document.getElementById(
            "report-dialog-title"
          ).textContent;
          dialogRef.setAttribute("aria-label", titleByElementId);
        }
      });
    },
    onConfirm() {
      this.show = false;

      const { model, reportContentId, captchaResponse } = this;
      this.action({ reasonId: model.id, reportContentId, captchaResponse });

      this.$root.$emit("show-accessible-snackbar", {
        confirmationMessage: this.confirmationMessage,
      });
      this.model = undefined;
      this.showSaved = true;
    },
    onClose() {
      this.model = undefined;
      this.show = false;
      this.returnFocus();
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
  },
};
</script>

<style>
.report-options .v-input legend {
  display: none;
}
</style>
