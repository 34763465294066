import { mainItemAssetUploaded } from "~/store/graphql/mutations/mainItemAssetUploaded.graphql";
import { suppItemAssetUploaded } from "~/store/graphql/mutations/suppItemAssetUploaded.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const finishedUpload = async function ({ commit, state, dispatch }, id) {
  const assetUploaded =
    state.submissionItem.id === id
      ? mainItemAssetUploaded
      : suppItemAssetUploaded;

  await runGraphQLQuery(assetUploaded, { id }, this.$axios);

  commit("FINISHED_UPLOAD", id);
  dispatch("validateSubmission");

  return Promise.resolve(id);
};

export default finishedUpload;
