<template>
  <v-container class="ContainerPage" pa-0 fluid>
    <slot></slot>
  </v-container>
</template>

<script>
export default {
  name: "ContainerPage",
};
</script>
