import communityDetailPageLoad from "~/store/graphql/queries/communityDetailPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadCommunityDetails = async function (
  { commit, dispatch, state },
  { params, query, req, res }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    communityDetailPageLoad,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_COMMUNITY_DETAILS", data);

  const questionId = query?.question;
  const selectedQuestion =
    data.selectedCommunity.questions.find((q) => q.id === questionId) || null;
  await dispatch("selectCommunityContentFilter", {
    question: selectedQuestion,
    contentType: null,
    collection: null,
  });

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadCommunityDetails;
