<template>
  <v-menu class="loginMenu" offset-y content-class="loginMenu">
    <template #activator="activator">
      <v-btn
        v-if="isLoggedOut(user)"
        class="onLogin"
        :rounded="buttonRounded"
        :outlined="buttonOutlined"
        :color="buttonColor"
        @click="login"
        v-on="activator.on"
      >
        <span>Log in</span>
      </v-btn>
    </template>
    <v-div v-if="showLoginOptions" class="loginMenuContainer">
      <v-row>
        <v-col class="px-6">
          <LoginButtons />
        </v-col>
      </v-row>
    </v-div>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import mapFlags from "~/plugins/utilities/mapFlags";

import LoginButtons from "~/components/page/components/buttons/LoginButtons";
import isLoggedOut from "~/plugins/utilities/isLoggedOut";
import { login, orcidLogin } from "~/store/manage/pages/userDefaults/login";

export default {
  name: "LoginMenu",
  components: {
    LoginButtons,
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      layout: (state) => state.theme.layout,
      user: (state) => state.user,
    }),
    ...mapFlags(["supportCoreLogin", "supportOrcidLogin"]),
    focusedStyle() {
      return this.layout.loginButtonFocusedStyle;
    },
    buttonRounded() {
      return !this.focusedStyle;
    },
    buttonOutlined() {
      return !this.focusedStyle;
    },
    buttonColor() {
      return this.inverted
        ? "inverted"
        : this.focusedStyle
        ? "focused"
        : "button-action";
    },
    partner() {
      return this.$config.partner;
    },
    showLoginOptions() {
      return !!(this.supportCoreLogin && this.supportOrcidLogin);
    },
  },
  methods: {
    login({ currentTarget }) {
      if (!this.showLoginOptions) {
        if (this.supportCoreLogin) {
          this.$root.$emit("show-core-login-information", {
            login: (newPath) => login(this.$config, this.res, newPath),
            newPath: this.redirectUrl,
            currentTarget,
          });
        } else if (this.supportOrcidLogin) {
          orcidLogin(this.$config, this.res, this.redirectUrl);
        }
      }
    },
    isLoggedOut(value) {
      return isLoggedOut(value);
    },
  },
};
</script>

<style>
.loginBtn {
  width: 100%;
  position: relative;
}
.loginMenu {
  margin-top: 13px;
  contain: initial;
  overflow: visible;
}
.loginMenuContainer {
  top: 50px;
  right: 10px;
  width: 350px;
  border-radius: 5px;
  background-color: white;
}
.loginMenuContainer::before {
  position: absolute;
  content: "";
  top: 0;
  right: 28px;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}
</style>
