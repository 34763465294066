import validateAccounts from "~/store/graphql/queries/validateAccounts.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const validateModeratorAccounts = async function validateModeratorAccounts(
  { commit, state },
  emails
) {
  const data = await runGraphQLQuery(validateAccounts, { emails }, this.$axios);
  if (data) {
    commit("UPDATE_EVENT_MODERATOR_VALIDATION", data.accountExistsVerification);

    return Promise.resolve(data);
  }
};

export default validateModeratorAccounts;
