const login = function ($config, redirect, redirectPath) {
  const endpoint = $config.clientEndpoint;
  const loginUrl = `${endpoint}/login`;
  loginRedirect($config, redirect, redirectPath, loginUrl);
};

const orcidLogin = function ($config, redirect, redirectPath) {
  const endpoint = $config.clientEndpoint;
  const loginUrl = `${endpoint}/login/orcid`;
  loginRedirect($config, redirect, redirectPath, loginUrl);
};

function loginRedirect($config, redirect, redirectPath, loginProviderUrl) {
  const partner = $config.partner;
  const globalWindowRef = typeof window === "undefined" ? undefined : window;

  let redirectUri =
    redirectPath ||
    globalWindowRef?.location?.href ||
    `/engage/${partner}/public-dashboard`;
  if (!redirectUri.startsWith("http")) {
    if (!globalWindowRef) {
      redirectUri = $config.baseUrl + redirectUri;
    } else {
      redirectUri = globalWindowRef.location.origin + redirectUri;
    }
  }

  const loginUri = `${loginProviderUrl}?redirect-url=${redirectUri}`;

  if (process.server && redirect) {
    redirect(loginUri);
  } else {
    globalWindowRef.location.assign(loginUri);
  }
}

export { login, orcidLogin };
