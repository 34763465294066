const eventModeratorsEmailChecks = [
  {
    severity: "ERROR",
    type: "EVENT",
    fieldName: "admins",
    errorMessage: "Following e-mail address is not valid: ",
    resolutionMessage: "Please fix the invalid admin email address",
  },
];

export default eventModeratorsEmailChecks;
