import { format, isDate, parseISO } from "date-fns";

export default function localTime(value) {
  if (!value) {
    return undefined;
  }

  if (!isDate(value)) {
    return format(parseISO(value), "HH:mm");
  }
}
