import { itemUpdate } from "~/store/graphql/mutations/itemUpdate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import createItemForMutation from "~/store/manage/utilities/createItemForMutation";

const removeSubmissionAuthor = async function (
  { commit, state, dispatch },
  { params }
) {
  const submission = createItemForMutation(state.submissionItem);
  const authors = submission.authors || [];
  const removeIndex = authors.findIndex((item) => item.gid === params.id);

  if (removeIndex > -1) {
    const removeDisplayOrder = submission.authors[removeIndex].displayOrder;
    submission.authors.splice(removeIndex, 1);

    submission.authors.forEach((author) => {
      if (author.displayOrder > removeDisplayOrder) {
        author.displayOrder--;
      }
    });
  }

  const data = await runGraphQLQuery(
    itemUpdate,
    {
      id: submission.id.toString(),
      item: submission,
    },
    this.$axios
  );

  if (data) {
    const featureToggles = {
      multipleCategoriesSubmission: this.$unleash.isEnabled(
        "multipleCategoriesSubmission"
      ),
    };

    commit("UPDATE_ITEM", { payload: data, featureToggles });
    dispatch("validateSubmission");
  }

  return Promise.resolve(data);
};

export default removeSubmissionAuthor;
