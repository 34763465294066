import defaultPageLoad from "~/store/graphql/queries/defaultPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadUserDefaults = async function ({ app, commit }, { req, res }) {
  const data = await runGraphQLQuery(
    defaultPageLoad,
    {},
    this.$axios,
    req,
    res
  );

  commit("SET_USER_DEFAULTS", data);

  return Promise.resolve(data.user);
};

export default loadUserDefaults;
