import { itemSaveRetractionRequest } from "~/store/graphql/mutations/itemSaveRetractionRequest.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const updateRequestRetraction = async function ({ commit, state }) {
  const id = state.retractionItem.id;
  const reason = state.retractionItem.retraction.request.reason;
  const acceptsRetractionTerms =
    state.retractionItem.retraction.request.acceptsRetractionTerms;

  const data = await runGraphQLQuery(
    itemSaveRetractionRequest,
    {
      id,
      reason,
      acceptsRetractionTerms,
    },
    this.$axios
  );

  if (data) {
    commit("SET_RETRACTION_UPDATE", data);

    return Promise.resolve(data);
  }
};

export default updateRequestRetraction;
