const mandatoryFirstAuthorChecks = [
  {
    severity: "ERROR",
    type: "AUTHOR",
    fieldName: "authorConfirmationId",
    errorMessage: "Author confirmation missing",
    resolutionMessage:
      "Please confirm if you are the sole author, creator, co-author, or co-creator",
  },
];

export default mandatoryFirstAuthorChecks;
