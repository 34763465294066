import { isEmpty } from "~/store/manage/utilities/createItemForMutation";

export function characterCount() {
  if (!this.model) {
    return 0;
  }
  const text = this.model.trim();
  return text ? text.match(/\S/g).length : 0;
}

export function wordCount() {
  if (isEmpty(this.model)) {
    return 0;
  }
  const text = this.model.trim();
  const words = text.match(/\s*\S+/gim);
  return words ? words.length : 0;
}

export function maxLengthMessage() {
  if (this.maxCharacters) {
    if (this.model) {
      return `${this.maxCharacters - this.characterCount} ${
        this.maxCharacters - this.characterCount === 1
          ? "character"
          : "characters"
      } remaining`;
    }
    return `${this.maxCharacters} characters allowed`;
  } else if (this.maxWords) {
    if (this.model && !this.maxLengthError()) {
      return `${this.maxWords - this.wordCount} ${
        this.maxWords - this.wordCount === 1 ? "word" : "words"
      } remaining`;
    }
    return `${this.maxWords} words allowed`;
  }
  return undefined;
}

export function maxLengthError() {
  if (!this.model) {
    return;
  }

  const hasTooManyCharacters =
    this.maxCharacters && this.characterCount > this.maxCharacters;
  const hasTooManyWords =
    this.maxWords && this.model && this.wordCount > this.maxWords;

  if (hasTooManyCharacters) {
    return `Limit exceeded by ${this.characterCount - this.maxCharacters} ${
      this.wordCount - this.maxCharacters === 1 ? "character" : "characters"
    }`;
  } else if (hasTooManyWords) {
    return `Limit exceeded by ${this.wordCount - this.maxWords} ${
      this.wordCount - this.maxWords === 1 ? "word" : "words"
    }`;
  }
}
export function limitSRHintMessage() {
  return this.maxWords
    ? `You can enter up to ${this.maxWords} words.`
    : this.maxCharacters
    ? `You can enter up to ${this.maxCharacters} characters.`
    : "";
}
