import administrationDashboardMoreReportedDiscussions from "~/store/graphql/queries/administrationDashboardMoreReportedDiscussions.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadAdministrationMoreReportedDiscussions = async function (
  { commit },
  { params, req, res }
) {
  const data = await runGraphQLQuery(
    administrationDashboardMoreReportedDiscussions,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_ADMINISTRATION_MORE_REPORTED_DISCUSSIONS", data);

  return Promise.resolve(data);
};

export default loadAdministrationMoreReportedDiscussions;
