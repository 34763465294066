// NOTE have to use mutation name as defined, alias don't work
import { eventSponsorDelete } from "~/store/graphql/mutations/eventSponsorDelete.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const deleteEventSponsor = async function ({ commit }, { params }) {
  commit("DELETE_EVENT_SPONSOR", params.id);

  const data = await runGraphQLQuery(
    eventSponsorDelete,
    { id: params.id },
    this.$axios
  );

  return Promise.resolve(data);
};

export default deleteEventSponsor;
