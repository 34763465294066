import checkProposalForErrors from "~/store/manage/validation/checkProposalForErrors";

const DELETE_EVENT_SPONSOR = (state, eventSponsorId) => {
  state.proposedEvent.sponsors = state.proposedEvent.sponsors.filter(
    (sponsor) => sponsor.id !== eventSponsorId
  );

  state.proposedErrors = checkProposalForErrors(
    state.proposedEvent,
    null,
    state.proposedErrors
  );
};

export default DELETE_EVENT_SPONSOR;
