import { startOfMonth, endOfMonth, parseISO, format } from "date-fns";

import archiveDashboardPageLoad from "~/store/graphql/queries/archiveDashboardPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadArchiveDashboard = async function ({ commit }, { params, req, res }) {
  commit("START_LOADING");
  commit("SET_ARCHIVE_REQUEST", params);

  const periodDate = parseISO(params.period);

  const isoFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  params.searchDateFrom = format(startOfMonth(periodDate), isoFormat);
  params.searchDateTo = format(endOfMonth(periodDate), isoFormat);

  const data = await runGraphQLQuery(
    archiveDashboardPageLoad,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_ARCHIVE_DASHBOARD", data);
  commit("END_LOADING");

  return Promise.resolve([params, data]);
};

export default loadArchiveDashboard;
