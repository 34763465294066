import articleLoadMoreComments from "~/store/graphql/queries/articleLoadMoreComments.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadMoreComments = async function ({ commit }, { params, req, res }) {
  const data = await runGraphQLQuery(
    articleLoadMoreComments,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_ARTICLE_MORE_COMMENTS", data);
  return Promise.resolve(data);
};

export default loadMoreComments;
