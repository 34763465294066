const mandatoryFunderChecks = [
  {
    severity: "ERROR",
    type: "FUNDER",
    fieldName: "name",
    errorMessage: "Funder Name missing",
    resolutionMessage: "Please select a funder, or remove item from the form",
  },
];

export default mandatoryFunderChecks;
