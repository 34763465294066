export default function isAuthorized(user, role) {
  if (
    !user ||
    !user.roles ||
    !Array.isArray(user.roles || role === undefined)
  ) {
    return false;
  }

  return user.roles.includes(role.toUpperCase());
}
