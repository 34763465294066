const SET_DEPARTMENTS_DASHBOARD = (state, payload) => {
  const { collections } = payload.origin;

  state.currentOrigin.departmentSummaries = collections.results;
  state.allDepartmentsCount = collections.totalCount;
  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_DEPARTMENTS_DASHBOARD;
