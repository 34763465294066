import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

const TEN_SECONDS = 10000;

const submitForSubmitArticle = async function submitForSubmitArticle() {
  try {
    await this.$store.dispatch("updateSubmissionStatus", {
      action: "SUBMIT",
      params: {
        id: this.item.id,
      },
    });
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }

  this.$root.$emit("change-view", { view: "author-dashboard" });

  setTimeout(() => {
    this.$store.dispatch("loadAuthorDashboard", {});
  }, TEN_SECONDS);
};

export default submitForSubmitArticle;
