const checkNoMatches = (value, regex) => {
  if (!value) {
    return false;
  }

  const check = value.trim();

  const found = check.match(regex);

  return found ? found.length : 0;
};

export default checkNoMatches;
