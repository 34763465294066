const SET_DISCUSSION_REPLIES_MORE = (state, payload) => {
  state.selectedDiscussion.latestDiscussionReplies = [
    ...state.selectedDiscussion.latestDiscussionReplies,
    ...payload.discussionReplies.results,
  ];
  state.discussionRepliesLoadedAll =
    payload.discussionReplies.results.length === 0;
};

export default SET_DISCUSSION_REPLIES_MORE;
