export default function extractMetricsValue(value, key) {
  if (Array.isArray(value) && typeof key === "string") {
    const found = value.find((metric) => {
      return metric.metricType === key;
    });

    if (found) {
      return found.value;
    }
  }
}
