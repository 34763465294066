import { eventUpdate } from "~/store/graphql/mutations/eventUpdate.graphql";
import { eventSponsorUpdate } from "~/store/graphql/mutations/eventSponsorUpdate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import createEventForMutation from "~/store/manage/utilities/createEventForMutation";

const updateProposedEvent = async function updateProposedEvent({
  commit,
  state,
  dispatch,
}) {
  if (state.proposedEvent.eventGroup) {
    if (!state.proposedEvent.eventGroup.id) {
      await dispatch("createEventGroup", state.proposedEvent.eventGroup.name);
    } else {
      commit("MODIFY_EVENT", {
        field: "eventGroupId",
        value: state.proposedEvent.eventGroup.id,
      });
    }
  }

  const sponsors = state.proposedEvent.sponsors || [];

  const updateEventSponsors = sponsors.map((sponsor) => {
    const eventSponsor = {
      text: sponsor.text,
      url: sponsor.url,
    };

    return runGraphQLQuery(
      eventSponsorUpdate,
      {
        id: sponsor.id.toString(),
        eventSponsor,
      },
      this.$axios
    );
  });

  await Promise.all(updateEventSponsors);

  const proposedEvent = createEventForMutation(state.proposedEvent);

  const data = await runGraphQLQuery(
    eventUpdate,
    {
      id: proposedEvent.id,
      event: proposedEvent,
    },
    this.$axios
  );

  if (data) {
    commit("UPDATE_EVENT", data.eventUpdate);

    return Promise.resolve(data);
  }
};

export default updateProposedEvent;
