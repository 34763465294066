import checkProposalForErrors from "~/store/manage/validation/checkProposalForErrors";

const SET_EVENT_WORKFLOW = (state, payload) => {
  state.proposedEvent = payload.proposedEvent;
  state.proposedEvent.sponsors = payload.proposedEvent.sponsors
    ? payload.proposedEvent.sponsors
    : [];
  state.proposedEvent.approvedSpeakers =
    payload.proposedEvent.approvedSpeakers || [];
  state.proposedEvent.admins = payload.proposedEvent.admins || [];

  state.proposedErrors = checkProposalForErrors(
    payload.proposedEvent,
    null,
    state.proposedErrors
  );

  state.referenceData.eventTypes = payload.eventGroups;

  state.user = payload.user;
  state.usageEventsDisabled = payload.usageEventsDisabled;
};

export default SET_EVENT_WORKFLOW;
