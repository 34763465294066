import submitStepPageLoad from "~/store/graphql/queries/submitStepPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import generateGId from "~/store/manage/utilities/generateGId";

const loadSubmitProcess = async function (
  { commit, dispatch },
  { req, res, params }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    submitStepPageLoad,
    params,
    this.$axios,
    req,
    res
  );

  for (const author of data.submissionItem.authors) {
    if (!author.gid) {
      author.gid = generateGId();
    }
  }

  for (const funder of data.submissionItem.funders) {
    if (!funder.gid) {
      funder.gid = generateGId();
    }
  }

  for (const webLink of data.submissionItem.webLinks) {
    if (!webLink.gid) {
      webLink.gid = generateGId();
    }
  }

  const featureToggles = {
    multipleCategoriesSubmission: this.$unleash.isEnabled(
      "multipleCategoriesSubmission"
    ),
  };

  commit("SET_SUBMISSION_WORKFLOW", { payload: data, featureToggles });
  dispatch("validateSubmission");

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadSubmitProcess;
