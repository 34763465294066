const MAX_ABSTRACT_WORD_COUNT_FOR_EXTERNAL = 400;

const getMaxAbstractWordCount = (submission, submissionTheme) => {
  const isExternalItem = submission.external;
  const maxAbstractWordCountForPartner = submissionTheme.maxAbstractWordCount;

  return isExternalItem
    ? MAX_ABSTRACT_WORD_COUNT_FOR_EXTERNAL
    : maxAbstractWordCountForPartner;
};

export default getMaxAbstractWordCount;
