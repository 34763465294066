import { reviewItemUpdate } from "~/store/graphql/mutations/reviewItemUpdate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const updateSubmissionReviewItem = async function ({ state }) {
  const {
    id,
    contentTypeId,
    categoryIds,
    subjectId,
    eventId,
    communityId,
    versionNote,
  } = state.submissionItem;

  const mainItemReviewInput = {
    contentTypeId,
    categoryIds,
    subjectId,
    eventId,
    communityId,
    versionNote,
  };

  const data = await runGraphQLQuery(
    reviewItemUpdate,
    {
      id: id.toString(),
      mainItemReviewInput,
    },
    this.$axios
  );

  return Promise.resolve(data);
};

export default updateSubmissionReviewItem;
