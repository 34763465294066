import eventUploadDataRefresh from "~/store/graphql/queries/eventUploadDataRefresh.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import cacheBustAssetUrls from "~/store/manage/utilities/cacheBustAssetUrls";

const refreshEventAsset = async function ({ commit, state }) {
  const id = state.proposedEvent ? state.proposedEvent.id : undefined;

  if (id) {
    const {
      proposedEvent: { bannerAsset },
    } = await runGraphQLQuery(eventUploadDataRefresh, { id }, this.$axios);

    cacheBustAssetUrls(bannerAsset);

    commit("MODIFY_EVENT", { field: "bannerAsset", value: bannerAsset });
  }
};

export default refreshEventAsset;
