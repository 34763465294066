const mandatoryRequestChecks = [
  {
    severity: "ERROR",
    type: "REQUEST",
    fieldName: "reason",
    errorMessage: "Retraction reason missing",
    resolutionMessage: "Please enter a reason for the retraction",
  },
];

export default mandatoryRequestChecks;
