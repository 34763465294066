<template>
  <v-dialog
    ref="verificationDialogRef"
    v-model="show"
    attach="#listener-container"
    class="VerificationListener"
    max-width="400"
    @keydown.esc="onClose"
  >
    <v-card>
      <v-card-title>
        <h2
          id="verification-dialog-title"
          class="headline"
          style="word-break: keep-all"
        >
          <ArticleTitle :article-html-title="title" />
        </h2>
      </v-card-title>
      <v-card-text v-if="instruction">
        <v-div id="verification-dialog-instruction">{{ instruction }}</v-div>
        <v-textarea
          ref="verificationUserCodeInput"
          v-model="userNotes"
          aria-labelledby="verification-dialog-title"
          aria-describedby="verification-dialog-instruction"
          rows="3"
          auto-grow
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          ref="closeVerificationListener"
          class="closeVerificationListener"
          color="warning"
          text
          aria-label="close"
          @click="onClose"
          >Close</v-btn
        >
        <v-btn
          class="okConfirmationListener"
          :disabled="isDisabled"
          color="success"
          text
          :aria-label="confirmationButtonAria"
          @click="onConfirm"
        >
          {{ code || confirmationButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

import ArticleTitle from "~/components/articles/components/ArticleTitle.vue";

export default {
  name: "VerificationListener",
  components: {
    ArticleTitle,
  },
  data() {
    return {
      show: false,
      title: undefined,
      instruction: undefined,
      code: undefined,
      confirmationButtonText: "OK",
      action: undefined,
      item: undefined,
      userNotes: "",
      returnFocusTarget: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    isDisabled() {
      return this.notes ? this.userNotes.length === 0 : false;
    },
    confirmationButtonAria() {
      return this.code
        ? this.code.toLowerCase()
        : this.confirmationButtonText.toLowerCase();
    },
  },
  mounted() {
    this.$root.$on("show-verification", this.onShowInformation);
  },
  methods: {
    onShowInformation({
      title,
      instruction,
      code,
      confirmationButtonText,
      action,
      item,
      currentTarget,
    }) {
      this.title = title;
      this.instruction = instruction;
      this.code = code;
      this.confirmationButtonText =
        confirmationButtonText || this.confirmationButtonText;
      this.action = action;
      this.item = item;
      this.returnFocusTarget = currentTarget;
      this.show = true;
      this.setInitialFocus();
      this.setAriaLabelAttribute();
    },
    setInitialFocus() {
      this.$nextTick(() => {
        const { verificationUserCodeInput, closeVerificationListener } =
          this.$refs;
        verificationUserCodeInput?.focus() ||
          closeVerificationListener?.$el.focus();
      });
    },
    setAriaLabelAttribute() {
      this.$nextTick(() => {
        const dialogRef = this?.$refs?.verificationDialogRef?.$children[0]?.$el;

        if (dialogRef) {
          const titleByElementId = document.getElementById(
            "verification-dialog-title"
          ).textContent;
          dialogRef.setAttribute("aria-label", titleByElementId);
        }
      });
    },
    onConfirm() {
      this.show = false;

      this.$root.$emit("show-confirmation", {
        title: this.title,
        confirmationButtonText: this.confirmationButtonText,
        action: this.action.bind(this),
        currentTarget: this.returnFocusTarget,
      });
    },
    onClose() {
      this.show = false;
      this.userNotes = "";
      this.returnFocus();
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
  },
};
</script>
