import { isUserInRoleGroup } from "~/plugins/utilities/checkUserRoles";
import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

export default function requireAdminRole(context) {
  const {
    store: {
      state: { user },
    },
    $config,
    redirect,
    req,
  } = context;

  if (!user || !user.id) {
    unauthorizedRedirect(redirect, process.server, $config, req);
  }

  if (
    !isUserInRoleGroup(user, [
      "SOCIETY_ADMIN",
      "CURATION_CURATOR",
      "CURATION_ADMIN",
    ])
  ) {
    redirect(`/engage/${$config.partner}`);
  }
}
