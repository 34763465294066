import administrationDashboardMoreSubmitted from "~/store/graphql/mutations/assignToModerator.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

export default async function ({ commit }, { itemId, email }) {
  const data = await runGraphQLQuery(
    administrationDashboardMoreSubmitted,
    { itemId, email },
    this.$axios
  );

  const featureToggles = {
    administrationDashboardSiteModerators: this.$unleash.isEnabled(
      "administrationDashboardSiteModerators"
    ),
  };

  commit("ASSIGN_ITEM_TO_MODERATOR", { itemId, email, featureToggles });
  return Promise.resolve(data);
}
