import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";
import mainItemWithdraw from "~/store/graphql/mutations/itemWithdraw.graphql";

const removeItem = async function ({ commit }, { req, res, params }) {
  commit("START_LOADING");

  await runGraphQLQuery(mainItemWithdraw, params, this.$axios, req, res);

  commit("END_LOADING");
};
export default removeItem;
