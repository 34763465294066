import departmentsDashboardPageLoad from "~/store/graphql/queries/departmentsDashboardPageLoad.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadDepartmentsDashboard = async function (
  { commit },
  { params, req, res }
) {
  commit("START_LOADING");
  const data = await runGraphQLQuery(
    departmentsDashboardPageLoad,
    { ...params },
    this.$axios,
    req,
    res
  );

  commit("SET_DEPARTMENTS_DASHBOARD", data);

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadDepartmentsDashboard;
