import discussionChecks from "~/store/manage/validation/checks/discussionChecks";
import checkForMissingErrors from "~/store/manage/validation/utilities/checkForMissingErrors";

export default function checkDiscussionForErrors(item) {
  const { proposedDiscussion } = item;

  if (!proposedDiscussion) {
    return [];
  }

  return checkForMissingErrors(proposedDiscussion, discussionChecks);
}
