import citationsReportPartLoad from "~/store/graphql/queries/citationsReportPartLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const generateCitationsReport = async function (
  { commit, state },
  { req, res }
) {
  commit("START_CITATION_REPORT_FETCHING");

  do {
    const skip = state.currentAdministrator.citationsReport.items.length;
    const data = await runGraphQLQuery(
      citationsReportPartLoad,
      { skip },
      this.$axios,
      req,
      res
    );

    commit("SET_CITATIONS_REPORT_PART", { payload: data.origin.mainItems });
  } while (
    state.currentAdministrator.citationsReport.items.length <
    state.currentAdministrator.citationsReport.totalCount
  );

  commit("END_CITATION_REPORT_FETCHING");
};

export default generateCitationsReport;
