export default function mapFlags(flags) {
  const res = {};
  flags.forEach((key) => {
    res[key] = function mappedFlag() {
      // eslint-disable-next-line no-unused-expressions
      this.$store.state.unleash.repo;
      return this.$unleash.isEnabled(key);
    };
  });
  return res;
}
