import { set } from "vue";

const CREATE_MATERIAL = (state, payload) => {
  set(
    state.submissionItem.supplementaryAssets,
    state.submissionItem.supplementaryAssets.length,
    payload
  );
};

export default CREATE_MATERIAL;
