import { format, isDate, parseISO } from "date-fns";

export default function localDate(
  value,
  displayFormat = "MMM dd, yyyy, HH:mm"
) {
  if (!value) {
    return undefined;
  }

  const date = isDate(value) ? value : parseISO(value);
  return format(date, displayFormat);
}
