export function isUserInRoleGroup(user, roleGroup) {
  return user?.roles?.some((role) => roleGroup.includes(role)) || false;
}

export function hasCurationPrivilege(user) {
  return isUserInRoleGroup(user, [
    "SOCIETY_ADMIN",
    "CURATION_CURATOR",
    "CURATION_ADMIN",
  ]);
}

export function hasAdminPrivilege(user) {
  return isUserInRoleGroup(user, [
    "SOCIETY_ADMIN",
    "EVENT_MODERATOR",
    "SITE_MODERATOR",
    "CURATION_CURATOR",
    "CURATION_ADMIN",
    "COMMUNITY_MODERATOR",
  ]);
}

export function hasModerationPrivilege(user) {
  return isUserInRoleGroup(user, [
    "SOCIETY_ADMIN",
    "EVENT_MODERATOR",
    "SITE_MODERATOR",
    "CURATION_CURATOR",
    "CURATION_ADMIN",
    "COMMUNITY_MODERATOR",
    "EXTERNAL_EXPERT",
  ]);
}

export function hasItemApprovePrivilege(user) {
  return isUserInRoleGroup(user, [
    "SOCIETY_ADMIN",
    "EVENT_MODERATOR",
    "SITE_MODERATOR",
    "CURATION_CURATOR",
    "COMMUNITY_MODERATOR",
  ]);
}

export function hasResendOrcidEmailPrivilege(user) {
  return isUserInRoleGroup(user, ["SOCIETY_ADMIN"]);
}
