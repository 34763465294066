import checkProposalForErrors from "~/store/manage/validation/checkProposalForErrors";
import { uniqueStrings } from "~/store/manage/utilities/unique";

const MODIFY_EVENT = (state, payload) => {
  const proposedEvent = state.proposedEvent;
  const { field, value } = payload;

  if (field === "admins") {
    // get distinct lowercase values
    proposedEvent[field] = uniqueStrings(value);
  } else {
    proposedEvent[field] = value;
  }

  state.proposedErrors = checkProposalForErrors(
    proposedEvent,
    null,
    state.proposedErrors
  );
};

export default MODIFY_EVENT;
