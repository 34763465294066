const validateSubmission = function ({ commit, state }) {
  const featureToggles = {
    multipleCategoriesSubmission: this.$unleash.isEnabled(
      "multipleCategoriesSubmission"
    ),
    submissionAllowPrimaryVideoUpload: this.$unleash.isEnabled(
      "submissionAllowPrimaryVideoUpload"
    ),
  };

  commit("VALIDATE_SUBMISSION", featureToggles);

  return Promise.resolve(state.submissionErrors);
};

export default validateSubmission;
