export default function concatenateValues(value, keys) {
  const source = Array.isArray(value) ? value : [value];

  return source.reduce((result, item) => {
    let data = "";

    if (Array.isArray(keys)) {
      keys.forEach((key) => {
        if (typeof item === "object" && item[key]) {
          data = data + item[key] + " ";
        }
      });
    } else if (typeof keys === "string") {
      if (typeof item === "object" && item[keys]) {
        data = data + item[keys] + " ";
      }
    }

    data = data.trim();

    if (data === "") {
      return result;
    }

    return `${result}${result === "" ? "" : ", "}${data}`;
  }, "");
}
