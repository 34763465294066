<template>
  <div id="assignToModeratorListener">
    <v-dialog
      ref="assignToModeratorDialogRef"
      v-model="show"
      attach="#assignToModeratorListener"
      class="assignToModeratorListener"
      max-width="500"
      @keydown.esc="onClose"
    >
      <v-card>
        <v-card-title>
          <h2
            id="assign-to-moderator-dialog-title"
            class="headline"
            style="word-break: keep-all"
          >
            <ArticleTitle :article-html-title="title" />
          </h2>
        </v-card-title>
        <v-card-subtitle class="pt-8 pb-0">
          <h3 class="text-h6">Site Moderators</h3>
        </v-card-subtitle>
        <v-card-text class="assign-to-moderator-options">
          <v-radio-group v-model="model" :label="title" column>
            <v-radio
              v-for="(option, index) in moderators"
              ref="moderatorRadio"
              :key="index"
              :class="displayIsMobile ? 'button--mobile' : 'button--desktop'"
              :value="option"
            >
              <template #label
                >{{ option | displayName }} ({{ option.email }})</template
              >
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-subtitle class="pt-8 pb-0">
          <h3 class="text-h6">External Experts</h3>
        </v-card-subtitle>
        <v-card-text>
          <v-radio-group v-model="model" column>
            <v-radio
              v-for="(option, index) in externalExperts"
              ref="moderatorRadio"
              :key="index"
              :class="displayIsMobile ? 'button--mobile' : 'button--desktop'"
              :value="option"
            >
              <template #label
                >{{ option | displayName }} ({{ option.email }})</template
              >
            </v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="closeConfirmationListener"
            color="warning"
            text
            aria-label="close"
            @click="onClose"
            >Close</v-btn
          >
          <v-btn
            class="okConfirmationListener"
            :disabled="isDisabled"
            color="success"
            text
            aria-label="confirm assigning content to moderator"
            @click="onConfirm"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { displayIsMobile } from "~/components/utilities/displayBreakpoints";
import assignToModerator from "~/actions/tasks/assignToModerator";
import unassignFromModerator from "~/actions/tasks/unassignFromModerator";

import ArticleTitle from "~/components/articles/components/ArticleTitle.vue";

export default {
  name: "AssignToModeratorListener",
  components: {
    ArticleTitle,
  },
  data() {
    return {
      showSaved: false,
      model: undefined,
      show: false,
      itemId: undefined,
      title: undefined,
      text: undefined,
      comment: undefined,
      commentId: undefined,
      options: undefined,
      action: undefined,
      commentSnippetLength: 200,
      confirmationMessage: undefined,
      returnFocusTarget: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      moderators: (state) => state.siteModerators.current,
      externalExperts: (state) => state.externalExperts.current,
    }),
    displayIsMobile,
    isDisabled() {
      return this.model === undefined;
    },
  },
  mounted() {
    this.$root.$on("assign-to-moderator", this.onShowInformation);
    this.$root.$on("unassign-from-moderator", this.onUnassign);
  },
  methods: {
    assignToModerator,
    unassignFromModerator,
    async onShowInformation({ item, currentTarget }) {
      await this.$store.dispatch("loadSiteModerators");
      await this.$store.dispatch("loadExternalExperts");
      this.confirmationMessage =
        "Submission was assigned to selected moderator.";
      this.title = item.title;
      this.itemId = item.id;
      this.returnFocusTarget = currentTarget;
      this.show = true;
      this.setInitialFocus();
      this.setAriaLabelAttribute();
    },
    setInitialFocus() {
      this.$nextTick(() => {
        this.$refs.moderatorRadio[0]?.$el.children[0].children[1].focus();
      });
    },
    setAriaLabelAttribute() {
      this.$nextTick(() => {
        const dialogRef =
          this?.$refs?.assignToModeratorDialogRef?.$children[0]?.$el;

        if (dialogRef) {
          const titleByElementId = document.getElementById(
            "assign-to-moderator-dialog-title"
          ).textContent;
          dialogRef.setAttribute("aria-label", titleByElementId);
        }
      });
    },
    onUnassign({ item, currentTarget }) {
      this.unassignFromModerator({
        itemId: item.id,
        email: this.user.emailAddress,
      });
      this.confirmationMessage = "Submission was unassigned.";
      this.$root.$emit("show-accessible-snackbar", {
        confirmationMessage: this.confirmationMessage,
      });
      this.returnFocusTarget = currentTarget;
      this.returnFocus();
      this.showSaved = true;
    },
    onConfirm() {
      this.show = false;
      this.assignToModerator({ itemId: this.itemId, email: this.model.email });
      this.$root.$emit("show-accessible-snackbar", {
        confirmationMessage: this.confirmationMessage,
      });
      this.showSaved = true;
    },
    onClose() {
      this.show = false;
      this.returnFocus();
    },
    returnFocus() {
      this.$nextTick(() => {
        this.returnFocusTarget?.focus();
      });
    },
  },
};
</script>

<style>
.assign-to-moderator-options .v-input legend {
  display: none;
}
</style>
