import fundersReportLoad from "~/store/graphql/queries/fundersReportsLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const generateFundersReport = async function ({ commit, state }, { req, res }) {
  commit("START_FUNDER_REPORT_FETCHING");
  const { startDate, endDate } = state.currentAdministrator.fundersReport;

  do {
    const skip = state.currentAdministrator.fundersReport.items.length;
    const data = await runGraphQLQuery(
      fundersReportLoad,
      { startDate, endDate, skip },
      this.$axios,
      req,
      res
    );
    commit("SET_FUNDERS_REPORT_PART", { payload: data.origin.mainItems });
  } while (
    state.currentAdministrator.fundersReport.items.length <
    state.currentAdministrator.fundersReport.totalCount
  );

  commit("END_FUNDER_REPORT_FETCHING");
};

export default generateFundersReport;
