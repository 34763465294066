const REPLACE_SUBMISSION_FUNDER = (state, payload) => {
  const submissionItem = state.submissionItem;
  const submissionFunder = submissionItem.funders.find(
    (funder) => funder.gid === payload.id
  );

  if (submissionFunder) {
    if (payload.value) {
      submissionFunder.funderId = payload.value.funderId;
      submissionFunder.name = payload.value.name;
    } else {
      submissionFunder.funderId = undefined;
      submissionFunder.name = payload.name;
    }
  }
};

export default REPLACE_SUBMISSION_FUNDER;
