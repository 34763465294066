<template>
  <LayoutGutters class="page-header-institution" no-padding>
    <v-row justify="space-between" align="center" class="px-lg-3">
      <div class="page-header-institution__logo-link">
        <nuxt-link :to="`/engage/${partner}/public-dashboard`">
          <img
            :src="theme.images.coloredLogo"
            :alt="partnerLogoAltText"
            :class="{ svgLogo: isSvg }"
          />
        </nuxt-link>
      </div>
      <v-col class="page-header-institution__quick-actions pa-0 ml-6">
        <QuickActionsInstitution />
      </v-col>
      <v-col
        class="page-header-institution__authorized-actions user-region pa-0 pr-2"
      >
        <AuthorizedActionsInstitution />
      </v-col>
    </v-row>
  </LayoutGutters>
</template>

<script>
import { mapState } from "vuex";
import LayoutGutters from "~/components/layout/LayoutGutters";

import QuickActionsInstitution from "~/components/page/institution/QuickActionsInstitution.vue";
import AuthorizedActionsInstitution from "~/components/page/institution/AuthorizedActionsInstitution.vue";
import getSiteName from "~/plugins/utilities/getSiteName.js";

export default {
  name: "PageHeaderInstitution",
  components: {
    LayoutGutters,
    QuickActionsInstitution,
    AuthorizedActionsInstitution,
  },
  computed: {
    ...mapState({
      baseUrl: (state) => state.theme.baseUrl,
      theme: (state) => state.theme,
      user: (state) => state.user,
      siteName: (state) => getSiteName(state.theme),
      altTextSiteName: (state) => state.theme.altTextTitle,
      isSvg: (state) => state.theme.images.coloredLogo.endsWith(".svg"),
    }),
    partner() {
      return this.$config.partner;
    },
    partnerLogoAltText() {
      return `${
        this.altTextSiteName ? this.altTextSiteName : this.siteName
      } Home`;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-header-institution {
  &__logo-link {
    a {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: left;
      align-content: left;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;

      box-sizing: border-box;
      max-width: 260px;
      max-height: 60px;
    }
  }
  &__authorized-actions {
    max-width: 300px;
  }
}

@media only screen and (max-width: 960px) {
  .page-header-institution {
    &__quick-actions {
      display: none;
    }
  }
}
</style>
