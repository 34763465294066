import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

import communityContent from "~/store/graphql/queries/communityContent.graphql";

const loadCommunityContent = async function (
  { commit, state },
  { params, req, res }
) {
  commit("START_LOADING");

  const questionId =
    state.communityDetails.communityContentFilter?.question?.id;
  const contentTypeId =
    state.communityDetails.communityContentFilter?.contentType?.id;
  const searchTerm = state.communityDetails.communityContentFilter?.searchTerm;
  const collectionId =
    state.communityDetails.communityContentFilter?.collection?.id;

  const data = await runGraphQLQuery(
    communityContent,
    { questionId, contentTypeId, searchTerm, collectionId, ...params },
    this.$axios,
    req,
    res
  );

  commit("SET_COMMUNITY_CONTENT", data);

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadCommunityContent;
