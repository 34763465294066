const mandatoryEventSponsorChecks = [
  {
    severity: "ERROR",
    type: "EVENT_SPONSOR",
    fieldName: "url",
    errorMessage: "Sponsor link missing",
    resolutionMessage: "Please add URL",
  },
  {
    severity: "ERROR",
    type: "EVENT_SPONSOR",
    fieldName: "text",
    errorMessage: "Sponsor alt text missing",
    resolutionMessage: "Please add alt text",
  },
  {
    severity: "ERROR",
    type: "EVENT_SPONSOR",
    fieldName: "asset",
    errorMessage: "Sponsor image missing",
    resolutionMessage: "Please add an image",
  },
];

export default mandatoryEventSponsorChecks;
