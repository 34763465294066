const regexWebLinkChecks = [
  {
    severity: "ERROR",
    type: "WEBLINK",
    fieldName: "url",
    positiveRegex:
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim,
    errorMessage: "URL is incorrect",
    resolutionMessage: "Please check the URL",
  },
  {
    severity: "ERROR",
    type: "WEBLINK",
    fieldName: "description",
    countRegex: /\s*\S+/gim,
    minCount: 1,
    maxCount: 150,
    errorMessage: "Description is too long",
    resolutionMessage:
      "Please enter a description ensuring it does not exceed 150 words",
  },
];

export default regexWebLinkChecks;
