const regexMaterialChecks = [
  {
    severity: "ERROR",
    type: "MATERIAL",
    fieldName: "description",
    countRegex: /\s*\S+/gim,
    minCount: 1,
    maxCount: 150,
    errorMessage: "Description is too long",
    resolutionMessage:
      "Please enter a description ensuring it does not exceed 150 words",
  },
];

export default regexMaterialChecks;
