import processItems from "~/store/manage/pages/adminstrationDashboard/utilities/processItems";

const SET_ADMINISTRATION_MORE_REVISIONS = (
  state,
  { payload, featureToggles }
) => {
  const results = payload.origin.revisionItems.results;

  processItems(results, featureToggles);

  state.currentAdministrator.revisionItemsCount =
    payload.origin.revisionItems && payload.origin.revisionItems.totalCount
      ? payload.origin.revisionItems.totalCount
      : 99999;

  if (results.length > 0) {
    const revisionItems = state.currentAdministrator.revisionItems;

    state.currentAdministrator.revisionItems = [...revisionItems, ...results];
  }
};

export default SET_ADMINISTRATION_MORE_REVISIONS;
