const FINISHED_UPLOAD = (state, payload) => {
  if (state.submissionItem) {
    if (state.submissionItem.id === payload) {
      state.submissionItem.asset = {};
    }

    for (let i = 0; i < state.submissionItem.supplementaryAssets.length; i++) {
      if (state.submissionItem.supplementaryAssets[i].id === payload) {
        state.submissionItem.supplementaryAssets[i].asset = {};
      }
    }
  }
};

export default FINISHED_UPLOAD;
