import article from "~/store/graphql/queries/getArticleIdByCoreProductId.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const getArticleIdByCoreProductId = async function (_, { params, req, res }) {
  const coreId = params.id;
  const data = await runGraphQLQuery(
    article,
    { coreId },
    this.$axios,
    req,
    res
  );

  return Promise.resolve(data.getArticleIdByCoreProductId);
};

export default getArticleIdByCoreProductId;
