export function isNativeDateInputSupported(window) {
  if (!window)
    throw new Error("Cannot check browser support as window is undefined");

  const element = window.document.createElement("input");
  element.setAttribute("type", "date");
  // The following is a random value, we just want to check if valueAsDate property will be defined once we set a date value
  element.value = "2021-01-01";
  return !!element.valueAsDate;
}
