const substitutionFields = [{ from: "titleTypeId", to: "title" }];

const readOnlyFields = [
  "asset",
  "assets",
  "authorConfirmation",
  "createdById",
  "displayName",
  "gEditCount",
  "isApprovedSpeaker",
  "isNew",
  "metrics",
  "homePage",
  "origin",
  "revisionNotes",
  "status",
  "statusDate",
  "statusLabel",
  "submittedDate",
  "supplementaryAssets",
  "uploadParameters",
  "uploadSettings",
  "version",
  "versionRefs",
  "vor",
  "firstAuthor",
  "external",
];

const allowNullFields = ["eventId", "communityId", "questionId"];
const enforceMandatoryFields = ["eventId", "communityId", "questionId"];

export const checkType = (value) => {
  return value === null
    ? "Null"
    : value === undefined
    ? "Undefined"
    : Object.prototype.toString.call(value).slice(8, -1);
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  value === "" ||
  (isArray(value) && value.length === 0);
export const isObject = (value) => checkType(value) === "Object";
export const isNumber = (value) => checkType(value) === "Number";
export const isBoolean = (value) => checkType(value) === "Boolean";
export const isString = (value) => checkType(value) === "String";
export const isNull = (value) => checkType(value) === "Null";
export const isArray = (value) => checkType(value) === "Array";
export const isRegExp = (value) => checkType(value) === "RegExp";
export const isFunction = (value) => checkType(value) === "Function";
export const isUndefined = (value) => checkType(value) === "Undefined";

const removePrivateFields = (obj) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (key.indexOf("_") === 0 || key.indexOf("#") === 0) {
      delete obj[key];
    } else if (val === null || val === undefined || val === "") {
      if (!allowNullFields.includes(key)) {
        delete obj[key];
      }
    } else if (val && typeof val === "object") {
      removePrivateFields(val);
    }
  });

  return obj;
};

const addMandatoryFields = (obj) => {
  enforceMandatoryFields.forEach((field) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj.hasOwnProperty(field)) {
      obj[field] = null;
    }
  });
};

const removeReadonlyFields = (obj) => {
  readOnlyFields.forEach((key) => {
    if (obj[key] !== undefined) {
      delete obj[key];
    }
  });
};

const substituteFields = (obj) => {
  substitutionFields.forEach((replace) => {
    if (obj[replace.from] !== undefined) {
      obj[replace.to] = obj[replace.from];

      delete obj[replace.from];
    }
  });
};

const cleanseObject = (obj) => {
  substituteFields(obj);
  removeReadonlyFields(obj);
  removePrivateFields(obj);

  for (const field in obj) {
    if (isArray(obj[field])) {
      const data = obj[field];

      data.forEach((item) => {
        cleanseObject(item);
      });
    } else if (isObject(obj[field])) {
      const data = obj[field];

      substituteFields(data);
      removeReadonlyFields(data);
      removePrivateFields(data);
    }
  }
};

const addCategoryIdsProperty = (item) => {
  if (item.categoryId) {
    item.categoryIds = [item.categoryId];
    item.mainCategoryId = item.categoryId;
  } else if (item.majorCategoryIds?.length) {
    item.categoryIds = [
      ...item.majorCategoryIds,
      ...(item.subcategoryIds || []),
    ];
    item.mainCategoryId = item.majorCategoryIds[0];
  }

  delete item.categoryId;
  delete item.subcategoryIds;
  delete item.majorCategoryIds;
};

const addRelatedContentIdsProperty = (item) => {
  if (!item.relatedContent) return;
  item.relatedContentIds =
    item.relatedContent?.map((relatedContent) => relatedContent.id) || [];
  delete item.relatedContent;
};

export default function createItemForMutation(input) {
  const output = JSON.parse(JSON.stringify(input));

  addCategoryIdsProperty(output);
  addRelatedContentIdsProperty(output);

  cleanseObject(output);
  addMandatoryFields(output);

  return output;
}
