import { itemCreate } from "~/store/graphql/mutations/itemCreate.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const createSubmissionItem = async function (context, { req, res, params }) {
  const data = await runGraphQLQuery(itemCreate, params, this.$axios, req, res);

  return Promise.resolve(data);
};

export default createSubmissionItem;
