import { suppItemAssetDelete } from "~/store/graphql/mutations/supplementaryAssetDelete.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const deleteSupplementaryFile = async function ({ commit, dispatch }, file) {
  const { id } = file;

  commit("DELETE_SUPPLEMENTARY_FILE", id);
  dispatch("validateSubmission");

  await runGraphQLQuery(
    suppItemAssetDelete,
    {
      id,
    },
    this.$axios
  );

  return Promise.resolve(id);
};

export default deleteSupplementaryFile;
