<template>
  <div class="AssetListener" />
</template>

<script>
export default {
  name: "AssetListener",
  mounted() {
    this.$root.$on("open-asset", this.onOpenAsset);
  },
  methods: {
    async onOpenAsset({ item, currentTarget }) {
      const status = item.ithenticate?.status ?? "NOT YET CREATED";
      let message;

      if (status === "DONE") {
        const params = { id: item.id };
        const url = await this.$store.dispatch("getIthenticateReportLink", {
          params,
        });
        window.open(url, "_blank");
        message = `Your iThenticate report will open in a new window. This might take a few seconds to retrieve.`;
      } else if (status === "FAILED") {
        message =
          "iThenticate check failed: " +
          (item.ithenticate?.error ?? "unknown error");
      } else {
        const info = status.toLowerCase();
        message = `You can't view the iThenticate report as it is ${info}.`;
      }

      this.$root.$emit("show-information", {
        title: "iThenticate Report",
        message,
        currentTarget,
      });
    },
  },
};
</script>
