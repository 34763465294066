import eventSubmissionsPageLoad from "~/store/graphql/queries/eventSubmissionsPageLoad.graphql";

import runGraphQLQuery, {
  lastQuery,
} from "~/store/manage/utilities/runGraphQLQuery";

const loadEventSubmissions = async function (
  { commit },
  { query, req, res, saveLastGraphQL }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    eventSubmissionsPageLoad,
    query,
    this.$axios,
    req,
    res
  );

  if (saveLastGraphQL === true) {
    commit("SET_LAST_GRAPHQL", lastQuery());
  }

  commit("SET_EVENT_SUBMISSIONS", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadEventSubmissions;
