import generateGId from "~/store/manage/utilities/generateGId";

const addSubmissionAuthor = function ({ commit, state }) {
  const newAuthor = {
    gid: generateGId(),
    title: undefined,
    firstName: undefined,
    lastName: undefined,
    emailAddress: undefined,
    institutions: [],
    displayOrder: state.submissionItem.authors.length,
  };

  commit("ADD_SUBMISSION_AUTHOR", newAuthor);

  return Promise.resolve(newAuthor);
};

export default addSubmissionAuthor;
