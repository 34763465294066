import publicDashboardPageLoad from "~/store/graphql/queries/publicDashboardPageLoad.graphql";
import publicDashboardInstitutionPageLoad from "~/store/graphql/queries/publicDashboardInstitutionPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadPublicDashboard = async function ({ commit }, { req, res }) {
  commit("START_LOADING");
  const { isInstitution } = this.state.theme;
  const publicDashboardLoad = isInstitution
    ? publicDashboardInstitutionPageLoad
    : publicDashboardPageLoad;

  const data = await runGraphQLQuery(
    publicDashboardLoad,
    {},
    this.$axios,
    req,
    res
  );

  commit("SET_PUBLIC_DASHBOARD", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadPublicDashboard;
