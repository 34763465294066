const regexEventSponsorChecks = [
  {
    severity: "ERROR",
    type: "EVENT_SPONSOR",
    fieldName: "url",
    positiveRegex:
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim,
    errorMessage: "URL is incorrect",
    resolutionMessage: "Please check the URL",
  },
];

export default regexEventSponsorChecks;
