const mandatoryEventChecks = [
  {
    severity: "ERROR",
    type: "EVENT",
    fieldName: "eventGroup",
    errorMessage: "Event group missing",
    resolutionMessage: "Please add the event group",
  },
  {
    severity: "ERROR",
    type: "EVENT",
    fieldName: "title",
    errorMessage: "Title missing",
    resolutionMessage: "Please enter a title",
  },
  {
    severity: "ERROR",
    type: "EVENT",
    fieldName: "description",
    errorMessage: "Description missing",
    resolutionMessage: "Please enter a description",
  },
  {
    severity: "ERROR",
    type: "EVENT",
    fieldName: "bannerAsset",
    errorMessage: "Image missing",
    resolutionMessage: "Please add an image",
  },
];

export default mandatoryEventChecks;
