import mandatoryContentChecks from "~/store/manage/validation/checks/mandatoryContentChecks";
import mandatoryAssetContentChecks from "~/store/manage/validation/checks/mandatoryAssetContentChecks";
import mandatoryAuthorChecks from "~/store/manage/validation/checks/mandatoryAuthorChecks";
import mandatoryFunderChecks from "~/store/manage/validation/checks/mandatoryFunderChecks";
import mandatoryMaterialChecks from "~/store/manage/validation/checks/mandatoryMaterialChecks";
import mandatoryWebLinkChecks from "~/store/manage/validation/checks/mandatoryWebLinkChecks";
import mandatoryFirstAuthorChecks from "~/store/manage/validation/checks/mandatoryFirstAuthorChecks";
import mandatoryVersionChecks from "~/store/manage/validation/checks/mandatoryVersionChecks";
import acceptanceItemChecks from "~/store/manage/validation/checks/acceptanceItemChecks";
import mandatoryCommunityChecks from "~/store/manage/validation/checks/mandatoryCommunityChecks";

import regexAuthorChecks from "~/store/manage/validation/checks/regexAuthorChecks";
import { getRegexContentChecks } from "~/store/manage/validation/checks/regexContentChecks";
import regexMaterialChecks from "~/store/manage/validation/checks/regexMaterialChecks";
import regexWebLinkChecks from "~/store/manage/validation/checks/regexWebLinkChecks";
import regexVersionChecks from "~/store/manage/validation/checks/regexVersionChecks";

import checkForMissingErrors from "~/store/manage/validation/utilities/checkForMissingErrors";
import checkForAcceptanceErrors from "~/store/manage/validation/utilities/checkForAcceptanceErrors";
import checkForRegexErrors from "~/store/manage/validation/utilities/checkForRegexErrors";

import contentMediaTypeCheck from "~/store/manage/validation/checks/contentMediaTypeCheck";
import contentAllowedFileTypeCheck from "~/store/manage/validation/checks/contentAllowedFileTypeCheck";

import version from "~/plugins/utilities/version";
import getMaxAbstractWordCount from "~/plugins/utilities/getMaxAbstractWordCount";
import createError from "~/store/manage/validation/utilities/createError";
import isMainVideoContentAllowed from "~/store/manage/utilities/isMainVideoContentAllowed";

export default function checkSubmissionForErrors(state, featureToggles) {
  const submission = state.submissionItem;
  if (!submission) {
    return [];
  }

  const submissionTheme = state.theme.submission;
  const maxAbstractWordCount = getMaxAbstractWordCount(
    submission,
    submissionTheme
  );

  const errors = [];

  const mandatoryContentErrors = checkForMissingErrors(
    submission,
    mandatoryContentChecks
  );
  const acceptanceItemErrors = checkForAcceptanceErrors(
    submission,
    acceptanceItemChecks
  );
  const regexContentErrors = checkForRegexErrors(
    submission,
    getRegexContentChecks(maxAbstractWordCount)
  );

  errors.push(...mandatoryContentErrors);
  errors.push(...acceptanceItemErrors);
  errors.push(...regexContentErrors);

  if (submission.asset) {
    const mandatoryErrors = checkForMissingErrors(
      submission.asset,
      mandatoryAssetContentChecks
    );
    errors.push(...mandatoryErrors);

    const contentType = state.referenceData.filteredContentTypes.find(
      (contentType) => contentType.id === submission.contentTypeId
    );
    if (contentType) {
      const checkVideoAllowed =
        !state.submissionItemDependencies.community &&
        contentType.videoAllowedCheck;

      const isVideo = submission.asset.mimeType
        ?.toLowerCase()
        .includes("video");
      if (
        isVideo &&
        checkVideoAllowed &&
        !isMainVideoContentAllowed(
          state.referenceData.events,
          submission.eventId,
          featureToggles
        )
      ) {
        errors.push(createError(submission, contentMediaTypeCheck));
      }

      const fileExtension = submission.asset.fileName?.substring(
        submission.asset.fileName?.lastIndexOf(".")
      );
      const allowedFileTypes =
        checkVideoAllowed &&
        isMainVideoContentAllowed(
          state.referenceData.events,
          submission.eventId,
          featureToggles
        )
          ? contentType.allowedFileTypes.concat(
              contentType.allowedVideoFileTypes || []
            )
          : contentType.allowedFileTypes;
      if (fileExtension && !allowedFileTypes.includes(fileExtension)) {
        errors.push(createError(submission, contentAllowedFileTypeCheck));
      }
    }
  }

  if (submission.authors) {
    const MAX_AMOUNT_OF_INSTITUTIONS = 5;

    if (
      !submission.external &&
      !submission.authors.some((author) => author.showEmailAddress)
    ) {
      errors.push(
        createError(submission.authors, {
          severity: "ERROR",
          type: "AUTHOR",
          fieldName: "showEmailAddress",
          errorMessage: `Show one email address required`,
          resolutionMessage: `Please select at least one corresponding author`,
        })
      );
    }
    // added for uniqueness check
    // email master list
    const authorEmails = [];

    submission.authors.forEach((author, index) => {
      const mandatoryAuthorErrors = checkForMissingErrors(
        author,
        mandatoryAuthorChecks,
        index,
        "author",
        featureToggles
      );
      const regexAuthorErrors = checkForRegexErrors(
        author,
        regexAuthorChecks,
        index,
        "author"
      );

      if (
        author.institutions &&
        author.institutions.length > MAX_AMOUNT_OF_INSTITUTIONS
      ) {
        errors.push(
          createError(author, {
            severity: "ERROR",
            type: "AUTHOR",
            fieldName: "institutions",
            errorMessage: `You are only able to include up to ${MAX_AMOUNT_OF_INSTITUTIONS} affiliations`,
            resolutionMessage: `Please include a maximum of ${MAX_AMOUNT_OF_INSTITUTIONS} affiliations.`,
          })
        );
      }

      // email uniqueness check
      authorEmails.forEach((email) => {
        if (author.emailAddress?.toLowerCase() === email?.toLowerCase()) {
          errors.push(
            createError(author, {
              severity: "ERROR",
              type: "AUTHOR",
              fieldName: "emailAddress",
              errorMessage: `Email address must be unique`,
              resolutionMessage: `Email address must be unique`,
            })
          );
        }
      });
      // populate email master list
      authorEmails.push(author.emailAddress);

      errors.push(...mandatoryAuthorErrors);
      errors.push(...regexAuthorErrors);

      if (index === 0) {
        const firstAuthorErrors = checkForMissingErrors(
          author,
          mandatoryFirstAuthorChecks,
          undefined,
          "author"
        );

        errors.push(...firstAuthorErrors);
      }
    });
  }

  if (submission.funders) {
    submission.funders.forEach((funder, index) => {
      const mandatoryFunderErrors = checkForMissingErrors(
        funder,
        mandatoryFunderChecks,
        index,
        "funder"
      );

      errors.push(...mandatoryFunderErrors);
    });
  }

  errors.push(...checkForCategoryErrors(state, featureToggles));

  if (submission.supplementaryAssets) {
    submission.supplementaryAssets.forEach((material, index) => {
      const mandatoryMaterialErrors = checkForMissingErrors(
        material,
        mandatoryMaterialChecks,
        index,
        "supplementary material"
      );
      const regexMaterialErrors = checkForRegexErrors(
        material,
        regexMaterialChecks,
        index,
        "supplementary material"
      );

      if (material && material.asset) {
        const mandatoryErrors = checkForMissingErrors(
          material.asset,
          mandatoryAssetContentChecks,
          index,
          "supplementary material"
        );
        errors.push(...mandatoryErrors);
      }

      errors.push(...mandatoryMaterialErrors, ...regexMaterialErrors);
    });
  }

  if (submission.webLinks) {
    submission.webLinks.forEach((webLink, index) => {
      const mandatoryWebLinkErrors = checkForMissingErrors(
        webLink,
        mandatoryWebLinkChecks,
        index,
        "weblink"
      );
      const regexWebLinkErrors = checkForRegexErrors(
        webLink,
        regexWebLinkChecks,
        index,
        "weblink"
      );

      errors.push(...mandatoryWebLinkErrors, ...regexWebLinkErrors);
    });
  }

  if (version(submission.version)) {
    const mandatoryVersionErrors = checkForMissingErrors(
      submission,
      mandatoryVersionChecks
    );
    const regexVersionErrors = checkForRegexErrors(
      submission,
      regexVersionChecks
    );

    errors.push(...mandatoryVersionErrors, ...regexVersionErrors);
  }

  if (
    submission.communityId &&
    state.submissionItemDependencies.community?.journalId
  ) {
    const mandatoryCommunityErrors = checkForMissingErrors(
      submission,
      mandatoryCommunityChecks
    );
    errors.push(...mandatoryCommunityErrors);
  }

  return errors;
}

function checkMultiCategoryErrors({
  submissionItem,
  referenceData: { categoryTypes },
}) {
  const errors = [];

  if (submissionItem.majorCategoryIds?.length) {
    const MAX_AMOUNT_OF_CATEGORIES = 3;

    if (submissionItem.majorCategoryIds.length > MAX_AMOUNT_OF_CATEGORIES) {
      errors.push(
        createError(submissionItem, {
          severity: "ERROR",
          type: "CONTENT",
          fieldName: "majorCategoryIds",
          errorMessage: "Too many categories",
          resolutionMessage: `You can select a maximum of ${MAX_AMOUNT_OF_CATEGORIES} categories`,
        })
      );
    }
  } else {
    errors.push(
      createError(submissionItem, {
        severity: "ERROR",
        type: "CONTENT",
        fieldName: "majorCategoryIds",
        errorMessage: "Category missing",
        resolutionMessage:
          "Please select at least one category from the drop down",
      })
    );
  }

  if (submissionItem.subcategoryIds?.length) {
    const MAX_AMOUNT_OF_SUBCATEGORIES = 3;

    if (submissionItem.subcategoryIds.length > MAX_AMOUNT_OF_SUBCATEGORIES) {
      errors.push(
        createError(submissionItem, {
          severity: "ERROR",
          type: "CONTENT",
          fieldName: "subcategoryIds",
          errorMessage: "Too many subcategories",
          resolutionMessage: `You can select a maximum of ${MAX_AMOUNT_OF_SUBCATEGORIES} subcategories`,
        })
      );
    }

    const selectedSubcategories = categoryTypes.filter((category) =>
      submissionItem.subcategoryIds.includes(category.id)
    );
    const selectedSubcategoriesWithoutMatchingParents =
      selectedSubcategories.filter(
        (category) =>
          !submissionItem.majorCategoryIds ||
          submissionItem.majorCategoryIds.length === 0 ||
          !submissionItem.majorCategoryIds.includes(category.parentId)
      );

    if (selectedSubcategoriesWithoutMatchingParents.length) {
      errors.push(
        createError(submissionItem, {
          severity: "ERROR",
          type: "CONTENT",
          fieldName: "subcategoryIds",
          errorMessage: "Subcategory without matching category",
          resolutionMessage: `You cannot select subcategories without selecting their parent categories first. Illegal subcategories: ${selectedSubcategoriesWithoutMatchingParents.map(
            (category) => category.name
          )}`,
        })
      );
    }
  }

  return errors;
}

function checkForCategoryErrors(state, featureToggles) {
  if (featureToggles.multipleCategoriesSubmission) {
    return checkMultiCategoryErrors(state);
  }

  return checkForMissingErrors(state.submissionItem, [
    {
      severity: "ERROR",
      type: "CONTENT",
      fieldName: "categoryId",
      errorMessage: "Category missing",
      resolutionMessage: "Please select a category from the drop down",
    },
  ]);
}
