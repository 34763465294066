const SET_COMMUNITY_DETAILS = (
  state,
  {
    selectedCommunity,
    latestContent,
    contentTypes,
    allCollections,
    collections,
    latestCollections,
  }
) => {
  state.selectedCommunity = selectedCommunity;
  state.selectedCommunity.collections = collections;
  state.selectedCommunity.latestCollections = latestCollections;

  state.currentOrigin.latestCommunityContent = latestContent.results.map(
    (i) => i.item
  );
  state.currentOrigin.communityContentTotalCount = latestContent.totalCount;

  state.referenceData.contentTypes = contentTypes;
  state.referenceData.collections = allCollections.results;

  state.discussionsLoadedAll = false;
};

export default SET_COMMUNITY_DETAILS;
