const mandatoryCommunityChecks = [
  {
    severity: "ERROR",
    type: "CONTENT",
    fieldName: "questionId",
    errorMessage: "Research Question missing",
    resolutionMessage: "Please select a research question from the drop down",
  },
];

export default mandatoryCommunityChecks;
