import unauthorizedRedirect from "~/components/utilities/unauthorizedRedirect";

const rejectForRespondRetraction = async function rejectForRespondRetraction() {
  const partner = this.$config.partner;

  try {
    await this.$store.dispatch("submitRejectRetraction");
  } catch (error) {
    if (
      error.status &&
      error.status.find((err) => err.message === "Access Denied")
    ) {
      unauthorizedRedirect(
        this.$router.replace,
        this.$isServer,
        this.$config,
        undefined
      );
    } else {
      throw error;
    }
  }
  this.$router.push({
    path: `/engage/${partner}/administration-dashboard`,
  });
};

export default rejectForRespondRetraction;
