const SET_ADMINISTRATION_MORE_EVENTS = (state, payload) => {
  const { adminEvents } = payload.origin;
  const results = adminEvents.results;

  state.currentAdministrator.eventsCount =
    adminEvents && adminEvents.totalCount ? adminEvents.totalCount : 99999;

  if (results.length > 0) {
    const events = state.currentAdministrator.events;

    state.currentAdministrator.events = [...events, ...results];
  }
};

export default SET_ADMINISTRATION_MORE_EVENTS;
