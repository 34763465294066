import communityDiscussionListingLoad from "~/store/graphql/queries/communityDiscussionListingLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadMoreCommunityDiscussionListing = async function (
  { commit },
  { params, req, res }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    communityDiscussionListingLoad,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_DISCUSSIONS_MORE", data);

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadMoreCommunityDiscussionListing;
