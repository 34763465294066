import eventsDashboardPageLoad from "~/store/graphql/queries/eventsDashboardPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadEventsDashboard = async function ({ commit, state }, { req, res }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    eventsDashboardPageLoad,
    {},
    this.$axios,
    req,
    res
  );

  commit("SET_EVENTS_DASHBOARD", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadEventsDashboard;
