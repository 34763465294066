const siteModeratorsAccountChecks = [
  {
    severity: "ERROR",
    type: "EVENT_SERVER_SIDE",
    fieldName: "proposed",
    errorMessage: "Following account does not exist: ",
    resolutionMessage:
      "Please remove the non-existing account address and add it again after account has been created",
  },
];

export default siteModeratorsAccountChecks;
