import displayName from "~/plugins/utilities/displayName";

const ASSIGN_ITEM_TO_MODERATOR = (state, { itemId, email, featureToggles }) => {
  const item = state.currentAdministrator.submittedItems.find(
    (i) => i.id === itemId
  );
  const moderator = state.siteModerators.current.find((m) => m.email === email);

  item.moderators = item.moderators || [];
  item.moderators.push(displayName(moderator));

  item.isModerated = true;
  item.isAssignable = false;
  item.isUnassignable = featureToggles.administrationDashboardSiteModerators;
};

export default ASSIGN_ITEM_TO_MODERATOR;
