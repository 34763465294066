const SET_SEARCH_MORE = (state, payload) => {
  const { searchResult } = state;
  const { searchItems: search } = payload;

  searchResult.results = [...searchResult.results, ...search.results];

  state.searchResult = { ...searchResult };
};

export default SET_SEARCH_MORE;
