import platformDashboardPageLoad from "~/store/graphql/queries/platformDashboardPageLoad.graphql";
import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadPlatformDashboard = async function ({ commit, state }, { req, res }) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    platformDashboardPageLoad,
    {},
    this.$axios,
    req,
    res
  );

  commit("SET_PLATFORM_DASHBOARD", data);
  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadPlatformDashboard;
