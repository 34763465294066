import { isDate, parseISO, formatDistanceToNow } from "date-fns";

export default function timeInterval(value) {
  if (!value) {
    return undefined;
  }

  if (!isDate(value)) {
    let result;

    try {
      result = formatDistanceToNow(parseISO(value));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      result = value;
    }
    return result;
  }
}
