<template>
  <BaseLayout />
</template>

<script>
import BaseLayout from "./base-layout.vue";
export default {
  components: {
    BaseLayout,
  },
};
</script>
