import departmentDetailPageLoad from "~/store/graphql/queries/departmentDetailPageLoad.graphql";

import runGraphQLQuery from "~/store/manage/utilities/runGraphQLQuery";

const loadDepartmentDetails = async function (
  { commit },
  { params, req, res }
) {
  commit("START_LOADING");

  const data = await runGraphQLQuery(
    departmentDetailPageLoad,
    params,
    this.$axios,
    req,
    res
  );

  commit("SET_DEPARTMENT_DETAILS", data);

  commit("END_LOADING");

  return Promise.resolve(data);
};

export default loadDepartmentDetails;
