const checkIfMissing = (value) => {
  if (value === undefined) {
    return true;
  } else if (value === null) {
    return true;
  } else if (value === "") {
    return true;
  } else if (value.toString().trim() === "") {
    return true;
  }
  return false;
};

export default checkIfMissing;
